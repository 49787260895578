/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
/* eslint-disable array-callback-return */
import React from 'react'
import { Tree, Row, Col, Button } from 'antd';
import lang from '../../../../language/language';
import { CheckSquareOutlined, StopOutlined } from '@ant-design/icons';
const StaffActionService = ({ dataTree, checkedService, setCheckedService }) => {
  const formatTree = (roleListAction = []) => {
    if (roleListAction.length > 0) {
      return roleListAction.map((item) => {
        return {
          title: item[0]?.label,
          key: item[0]?.value,
          children: item[0]?.children?.map(({ value, label }) => {
            return {
              title: label,
              key: value
            }
          })
        }
      })
    } else {
      return [];
    }
  };
  const checkArrays = (arrA = [], arrB = []) => {
    if (arrA.length !== arrB.length) return false;

    const cA = arrA.slice().sort().join(',');
    const cB = arrB.slice().sort().join(',');

    return cA === cB;
  }

  const showContentButton = (type) => {
    if (dataTree.length > 0) {
      const newSelect = [];
      dataTree.map(item => {
        item[0]?.children?.map(({ value }) => newSelect.push(value))
      })
      if (type === 1) {
        return checkArrays(newSelect, checkedService) ? <StopOutlined /> : <CheckSquareOutlined />
      } else {
        return !checkArrays(newSelect, checkedService) ? lang.select_all : lang.unselect_all
      }
    }
  }

  const onCheck = (checkedKeys, info) => {
    setCheckedService(checkedKeys.filter((item) => Number.isInteger(item)));
  };

  const handleCheck = () => {
    if (dataTree.length > 0) {
      const newSelect = [];
      dataTree.map(item => {
        item[0]?.children?.map(({ value }) => newSelect.push(value))
      })
      if (checkArrays(newSelect, checkedService)) {
        setCheckedService([]);
      } else {
        setCheckedService(newSelect);
      }
    }
  };

  return (
    <Row gutter={24}>
      <Col xs={24} md={24} className="pb-15 pt-15">
        <Button
          size="middle"
          icon={showContentButton(1)}
          onClick={handleCheck}
          type="primary">
          {showContentButton(2)}
        </Button>
      </Col>
      <Col xs={24} md={24}>
        <Tree
          onCheck={onCheck}
          checkable
          treeData={formatTree(dataTree)}
          // expandedKeys={checkedService}
          selectedKeys={checkedService}
          checkedKeys={checkedService}
          autoExpandParent
        />
      </Col>
    </Row>
  )
}

export default StaffActionService
