import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { customGetDay } from '../Common/components/CustomGetDay';
import * as timeKeepingThunk from './thunk';

const getDate = customGetDay();

export const timeKeeping = createSlice({
	name: 'timeKeeping',
	initialState: {
		isShowTimeKeepingAction: false,
		currentStaffTimeKeeping: null,
		timeKeepingQueryData: {
			branch_id: null,
			fromDate: moment(getDate.fisrtdayThisMonth).format('YYYY-MM-DD'),
			toDate: moment(getDate.lastdayThisMonth).format('YYYY-MM-DD'),
		},
		timeKeepingId: null,
		timeKeepingListData: [],
	},
	reducers: {
		setIsShowTimeKeepingAction: (state, action) => {
			state.isShowTimeKeepingAction = action.payload;
		},
		setCurrentStaffTimeKeeping: (state, action) => {
			state.currentStaffTimeKeeping = action.payload;
		},
		setTimeKeepingQueryData: (state, action) => {
			state.timeKeepingQueryData = action.payload;
		},
		setTimeKeepingId: (state, action) => {
			state.timeKeepingId = action.payload;
		},
	},
	extraReducers: {
		// Add reducers for additional action types here, and handle loading state as needed
		[timeKeepingThunk.timeKeepinglist.fulfilled]: (state, action) => {
			state.timeKeepingListData = action.payload;
		},
	},
});

export const {
	// timeKeeping
	setIsShowTimeKeepingAction,
	setCurrentStaffTimeKeeping,
	setTimeKeepingQueryData,
	setTimeKeepingId,
} = timeKeeping.actions;

export default timeKeeping.reducer;
