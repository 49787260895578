import CallApi from '../../utils/CallAPI';
import {
  CUSTOMER_GROUP,
  CUSTOMER_GROUP_DETAIL,
  CUSTOMER_GROUP_LIST_BY_CUSTOMER
} from './constants/endpoint';

export const customerGroupList = (data) => {
  return CallApi(CUSTOMER_GROUP, 'GET', data)
};

export const customerGroupCreateUpdate = (data, method) => {
  return CallApi(CUSTOMER_GROUP, method, data)
};

export const customerGroupDetail = (data) => {
  return CallApi(CUSTOMER_GROUP_DETAIL, 'GET', data)
};

export const customerGroupListByCustomer = (data) => {
  return CallApi(CUSTOMER_GROUP_LIST_BY_CUSTOMER, 'GET', data)
};
