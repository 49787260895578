import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import Routes from './routes';
import NotFound from './themes/Antd/NotFound';
import Footer from './themes/Antd/Footer/Footer';
// import LeftMenu from './themes/Antd/Menu/LeftMenu';
import BreadCrumbs from './themes/Antd/BreadCrumbs';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
// import TopMenu from './themes/Antd/Menu/TopMenu/TopMenu';
// import * as c from './constants/config';
import { Layout } from 'antd';

import HeaderApp from './themes/Antd/Header/HeaderApp';
import DrawerMenu from './themes/Antd/Menu/DrawerMenu';
const { Content, Header } = Layout;

const Main = () => {
  // const isHome = useSelector(state => state.common.isHome);
  const role = useSelector(state => state.role)
  // const themeMenu = useSelector(state => state.user.userInfo.theme_menu);
  const userInfo = useSelector(state => state.user.userInfo);
  const [collapsed] = useState(false);
  // const color = useSelector(state => state.user.userInfo.theme_color);

  const showApp = (routes) => {
    let result = null;
    // const menus = [];
    if (routes.length > 0) {
      result = routes.map((route, index) => {
        const permis = route.permission && route.permission !== 'undefined' ? route.permission : ''
        const permission = permis ? role.getRoleByUser.some(i => permis.indexOf(i.permission_code) >= 0) : ''
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={
              permission || userInfo.is_main === 1 || route.permission === 'ALL' ? route.main : null
            }
          />
        );
      });
    }
    return (
      <Switch>
        <Redirect exact from="/" to="/dash-board" />
        {result}
        <Route component={NotFound} />
      </Switch>
    );
  };

  return (
    <Router>
      {userInfo &&
        <Layout>
          {/* <LeftMenu className={`${themeMenu !== c.MENU_TYPE_LEFT && 'd-none'}`} collapsed={collapsed} /> */}
          <Layout className="site-layout">
            <Header
              style={{
                // marginLeft: !collapsed ? 230 : 60,
                width: !collapsed ? 'calc(100%)' : 'calc(100%)'
              }}
              className={'site-layout-background header-fixed'}
            >
              <HeaderApp collapsed={collapsed} />
              <DrawerMenu />
            </Header>
            <BreadCrumbs collapsed={collapsed} />
            {/* (themeMenu === c.MENU_TYPE_GRID || themeMenu === c.MENU_TYPE_DROPDOWN) && !isHome && <TopMenu /> */}
            <Content
              className="mt-5 content-wrapper"
            // style={{ marginLeft: !collapsed ? 245 : 75 }}
            // className={`${!isHome && `content-wrapper ${themeMenu === c.MENU_TYPE_GRID || themeMenu === c.MENU_TYPE_DROPDOWN ? 'mt-85 mt-5' : 'mt-5'} `}`}

            >
              {showApp(Routes)}
            </Content>
            <Footer />
          </Layout>
        </Layout>
      }
    </Router>
  );
};

export default Main;
