/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { updateTitle, setLoadingTable } from '../../Common/slice';
// import CustomTable from '../../../modules/Common/components/CustomTable/CustomTable';
import { setStaffId, setStaffQueryData, setStaffListId } from '../slice';
// import { staffExport } from '../service';
import * as staffThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import StaffDetail from './StaffDetail/StaffDetail';
import StaffActionChecked from './StaffAction/StaffActionChecked';
import StaffFilter from './StaffFilter';
import StaffAction from './StaffAction/StaffAction';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { Row, Col, Space, Input } from 'antd';
import * as c from '../../../constants/common';

const { Search } = Input;

const Staff = (props) => {
  const dispatch = useDispatch();
  const staffQueryData = useSelector(state => state.staff.staffQueryData);
  const staffListId = useSelector(state => state.staff.staffListId);
  const dataList = useSelector(state => state.staff.staffList);
  const currentBranch = JSON.parse(localStorage.getItem('branch'))

  useEffect(() => {
    dispatch(setStaffQueryData({ ...staffQueryData, branchIdList: [currentBranch.branch_id] }));
    return function cleanup() {
      dispatch(setStaffListId([]));
      dispatch(setStaffId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.staff));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(staffQueryData);
  }, [staffQueryData]);

  /// /funtion///////
  const fetchDataList = (staffQueryData) => {
    dispatch(staffThunk.staffList(staffQueryData));
  };

  const onSearch = (value, e) => {
    dispatch(setStaffQueryData({ ...staffQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  // const onExportData = () => {
  //   dispatch(setLoading(true));
  //   const data = { staff_id: [] }
  //   staffExport(data).then(res => {
  //     if (res && res.data) {
  //       dispatch(setLoading(false));
  //       window.location.href = res.data.file;
  //     }
  //   }).finally(() => {
  //     dispatch(setLoading(false))
  //   });
  // }

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.staff_list}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_staff}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <StaffActionChecked />
                <StaffAction />
                <StaffFilter />
                {/* <Button size="large" onClick={onExportData} type="primary" icon={<DownloadOutlined />} />
                <StaffImport /> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={staffQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={staffListId}
            queryData={staffQueryData}
            keyField="staff_id"
            clickField="staff_code"
            setListIdChecked={setStaffListId}
            setQueryData={setStaffQueryData}
            expandedRowRender={(record) => <StaffDetail data={record} queryData={staffQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Staff;
