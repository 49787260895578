import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import lang from '../../../../language/language';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
import noImage from '../../../../assets/images/default-user.png';
import { Button, Row, Col, Form, Input, Radio, DatePicker, Switch, Image, Checkbox } from 'antd';
import {
    CameraOutlined
} from '@ant-design/icons';
import UserSelect from '../../../Common/components/UserSelect';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import BranchSelect from '../../../Common/components/BranchSelect';
import StaffTitleSelect from '../../../Common/components/StaffTitleSelect';
import StaffTypeSelect from '../../../Common/components/StaffTypeSelect';

const { TextArea } = Input;

const StaffActionInfo = (props) => {
    const { dataEdit, dataAvatar, setDataAvatar, form } = props
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const dataList = useSelector(state => state.staff.staffList);
    const [listUserIdRemove, setListUserIdRemove] = useState([])

    useEffect(() => {
        if (dataEdit.user_id) {
            const listId = dataList.rows.map(x => x.user_id).filter(x => x && x !== parseInt(dataEdit.user_id))
            setListUserIdRemove(listId)
        } else {
            const listId = dataList.rows.map(x => x.user_id).filter(x => x)
            setListUserIdRemove(listId)
        }
    }, [dataList, dataEdit])

    /// ///function////
    const onSaveChangeImage = (data) => {
        if (data.length > 0) {
            setDataAvatar(data[0])
        }
    }
    /// render/////
    return (
        <Row gutter={24}>
            <ImageGallerySelect id="galleryStaffAction" onSave={onSaveChangeImage} />
            <Col xs={24} md={24} className="mb-15 ">
                <div className="text-center">
                    <Image
                        width="100%"
                        className="img-avatar"
                        preview={false}
                        src={dataAvatar.image_gallery_url || noImage}
                    />
                </div>
                <div className="text-center pt-10">
                    <Button size="middle" icon={<CameraOutlined />}
                        onClick={() => document.getElementById('galleryStaffAction').click()}
                        key="update_staff_avatar"
                        type="primary"
                    >
                        {lang.choose_image}
                    </Button>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_code}
                    name="staff_code"
                    rules={[
                        { required: true, message: lang.value_is_required },
                        { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_fullname}
                    name="staff_fullname"
                    rules={[
                        { required: true, message: lang.value_is_required }
                        // {
                        //     pattern: /^[A-Za-z 0-9 _ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                        //     message: lang.not_allow_special_characters
                        // }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_phone_number}
                    name="staff_phone_number"
                    rules={[
                        { required: true, message: lang.value_is_required },
                        { pattern: /^[0-9]*$/, message: lang.not_format },
                        { min: 10, message: lang.phone_min_max },
                        { max: 11, message: lang.phone_min_max }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_id_card}
                    name="staff_id_card"
                    rules={[
                        { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label="Email"
                    name="staff_email"
                    rules={[{ type: 'email', message: lang.email_not_format }]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_birthday}
                    name="staff_birthday"
                >
                    <DatePicker size="large" format={c.FORMAT_DATE} placeholder={lang.staff_birthday} style={{ width: '100%' }} inputReadOnly />
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_gender}
                    name="staff_gender"
                >
                    <Radio.Group size="large">
                        <Radio value={c.GENDER.MALE}>{lang.male}</Radio>
                        <Radio value={c.GENDER.FEMALE}>{lang.female}</Radio>
                        <Radio value={c.GENDER.OTHER}>{lang.other}</Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col xs={24} md={12}>
                <StaffTypeSelect required />
            </Col>
            <Col xs={24} md={12}>
                <BranchSelect required />
            </Col>
            <Col xs={24} md={12}>
                <StaffTitleSelect />
            </Col>
            <Col xs={24} md={12}>
                <UserSelect listIdRemove={listUserIdRemove} label={lang.user} name="user" />
            </Col>
            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.staff_address}
                    name="staff_address"
                >
                    <Input size="large" />
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item
                    label="Facebook"
                    name="staff_facebook"
                >
                    <Input size="large" />
                </Form.Item>
            </Col>

            <Col xs={24} md={12}>
                <Form.Item
                    label={lang.note}
                    name="staff_note"
                >
                    <TextArea size="large" allowClear rows={1} />
                </Form.Item>
            </Col>
            <Col xs={24} md={8} >
                <Form.Item
                    name="is_technician"
                    valuePropName="checked"
                >
                    <Checkbox> {lang.technician}</Checkbox>
                </Form.Item>
            </Col>
            {form.getFieldValue('is_technician') && <Col xs={24} md={8} >
                <Form.Item
                    name="staff_allow_booking"
                    valuePropName="checked"
                >
                    <Checkbox> {lang.staff_allow_booking}</Checkbox>
                </Form.Item>
            </Col>}
            {form.getFieldValue('is_technician') && <Col xs={24} md={8} >
                <Form.Item
                    name="staff_allow_checkin"
                    valuePropName="checked"
                >
                    <Checkbox> {lang.staff_allow_checkin}</Checkbox>
                </Form.Item>
            </Col>}
            <Col xs={24} md={24} className="text-right">
                <Form.Item
                    name="is_active"
                    valuePropName="checked"
                >
                    <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default StaffActionInfo;
