/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import lang from '../../../../../language/language';
import CheckPermission from '../../../../Common/components/CheckPermission';
import { useSelector, useDispatch } from 'react-redux';
import * as userThunk from '../../thunk';
import { setUserId } from '../../slice';
import * as commonService from '../../../../Common/service';
import * as userService from '../../service';
import UserDetailInfo from './UserDetailInfo';
import UserDetailBranchRole from './UserDetailBranchRole';
import { Tabs, Row, Col, Button, Space, Popconfirm, message } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;

const UserDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const loading = useSelector(state => state.common.loading);
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const [dataDetail, setDataDetail] = useState([]);

    useEffect(() => {
        if (data.user_id) {
            userService.userDetail({ user_id: data.user_id }).then(res => {
                if (res) {
                    const { data } = res;
                    setDataDetail(data)
                }
            })
        }
    }, [data.user_id, loading]);
    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.user_id) {
            const userId = data.user_id
            const dataDelete = {
                list_id: [userId],
                field_name: 'user_id',
                table: 'user',
                update_user: userInfo.user_id,
                permission: ['USER_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(userThunk.userList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.user_id) {
            document.getElementById('userAction').click();
            dispatch(setUserId(data.user_id));
        }
    };

    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24}>
                <Tabs defaultActiveKey={`1${dataDetail.user_id}`} >
                    <TabPane tab={lang.user_info} key={`1${dataDetail.user_id}`}>
                        <UserDetailInfo dataDetail={data} />
                    </TabPane>
                    <TabPane tab={lang.branch_and_role} key={`2${dataDetail.user_id}`}>
                        <UserDetailBranchRole dataDetail={dataDetail} />
                    </TabPane>
                </Tabs>
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['USER_EDIT']}>
                                {dataDetail.user_id &&
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={onEdit}
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['USER_DELETE']}>
                                {dataDetail.user_id && dataDetail.is_main === 0 &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
};

export default UserDetail;
