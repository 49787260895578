import { createSlice } from '@reduxjs/toolkit';
import * as smsThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';

export const smsSlice = createSlice({
  name: 'sms',
  initialState: {
    smsHistoryList: {},
    smsHistoryInfo: {},
    smsHistoryId: '',
    smsHistoryListId: [],
    smsHistoryListAction: [],
    smsCustomerListId: [],
    smsHistoryQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      orderBy: '',
      userId: getInfoUser.user_id,
      userIdList: null
    },
    // sms sample
    smsSampleList: {},
    smsSampleInfo: {},
    smsSampleId: '',
    smsSampleListId: [],
    smsSampleListAction: [],
    smsSampleQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      orderBy: '',
      userId: getInfoUser.user_id,
      userIdList: null,
      isActive: null
    }
  },
  reducers: {
    setSMSCustomerListId: (state, action) => {
      state.smsCustomerListId = action.payload;
    },
    clearSMSHistoryList: (state, action) => {
      state.smsHistoryList = {};
    },
    clearSMSHistoryRow: (state, action) => {
      state.smsHistoryList.rows = [];
    },
    setSMSHistoryQueryData: (state, action) => {
      state.smsHistoryQueryData = action.payload;
    },
    setSMSHistoryListId: (state, action) => {
      state.smsHistoryListId = action.payload;
    },
    clearSMSHistoryId: (state, action) => {
      state.smsHistoryId = '';
    },
    setSMSHistoryId: (state, action) => {
      state.smsHistoryId = action.payload;
    },
    // sms sample
    clearSMSSampleList: (state, action) => {
      state.smsSampleList = {};
    },
    clearSMSSampleRow: (state, action) => {
      state.smsSampleList.rows = [];
    },
    setSMSSampleQueryData: (state, action) => {
      state.smsSampleQueryData = action.payload;
    },
    setSMSSampleListId: (state, action) => {
      state.smsSampleListId = action.payload;
    },
    clearSMSSampleId: (state, action) => {
      state.smsSampleId = '';
    },
    setSMSSampleId: (state, action) => {
      state.smsSampleId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [smsThunk.smsHistoryList.fulfilled]: (state, action) => {
      state.smsHistoryList = action.payload;
    },
    [smsThunk.smsSampleList.fulfilled]: (state, action) => {
      state.smsSampleList = action.payload;
    }
  }
});

export const {
  clearSMSHistoryList,
  clearSMSHistoryRow,
  setSMSHistoryQueryData,
  setSMSHistoryListId,
  clearSMSHistoryId,
  setSMSHistoryId,
  // sms sample
  clearSMSSampleList,
  clearSMSSampleRow,
  setSMSSampleQueryData,
  setSMSSampleListId,
  clearSMSSampleId,
  setSMSSampleId,
  setSMSCustomerListId
} = smsSlice.actions;

export default smsSlice.reducer;
