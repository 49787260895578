import React from 'react';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { Form, Select } from 'antd';
const { Option } = Select;

const GenderSelect = (props) => {
  const options = [
    { value: c.GENDER.MALE, label: lang.male },
    { value: c.GENDER.FEMALE, label: lang.female },
    { value: c.GENDER.OTHER, label: lang.other }
  ];
  /// render/////
  return (
    <Form.Item
      label={lang.gender}
      name="gender"
    >
      <Select
        size="large"
        allowClear
        placeholder={lang.gender}
      >
        {options.map((item) => {
          return <Option key={item.value} value={item.value}>{item.label}</Option>
        })}
      </Select>
    </Form.Item>
  );
};

export default GenderSelect;
