/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import lang from '../../../../language/language';
import CheckPermission from '../../../Common/components/CheckPermission';
import { useSelector, useDispatch } from 'react-redux';
import * as staffThunk from '../../thunk';
import { setStaffId } from '../../slice';
import * as staffService from '../../service';
import * as commonService from '../../../Common/service';
import { Row, Col, Button, Space, Popconfirm, message, Tabs } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import StaffDetailInfo from './StaffDetailInfo';
// import StaffDetailBranchTitle from './StaffDetailBranchTitle';
import StaffDetailService from './StaffDetailService';
import * as c from '../../../../constants/common';

const { TabPane } = Tabs;

const StaffDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.common.loading);
    const userInfo = useSelector(state => state.user.userInfo);
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const [dataDetail, setDataDetail] = useState([]);

    useEffect(() => {
        if (data.staff_id) {
            staffService.staffDetail({ staff_id: data.staff_id }).then(res => {
                if (res) {
                    const { data } = res;
                    setDataDetail(data)
                }
            })
        }
    }, [data.staff_id, loading]);
    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.staff_id) {
            const staffId = data.staff_id
            const dataDelete = {
                list_id: [staffId],
                field_name: 'staff_id',
                table: 'staff',
                update_user: userInfo.user_id,
                permission: ['STAFF_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(staffThunk.staffList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.staff_id) {
            document.getElementById('staffAction').click();
            dispatch(setStaffId(data.staff_id));
        }
    };

    /// /render///////
    return (
        <Row >
            <Col xs={24} md={24}>
                <Tabs defaultActiveKey={`1${dataDetail.staff_id}`} >
                    <TabPane tab={lang.staff_info} key={`1${dataDetail.staff_id}`}>
                        <StaffDetailInfo data={data} />
                    </TabPane>
                    {/* <TabPane tab={lang.branch_and_title} key={`2${dataDetail.staff_id}`}>
                        <StaffDetailBranchTitle data={dataDetail} />
                    </TabPane> */}
                    {dataDetail.is_technician === c.STAFF_TYPE.TECHNICIAN && <TabPane tab={lang.service} key={`3${dataDetail.staff_id}`}>
                        <StaffDetailService data={dataDetail} />
                    </TabPane>}
                </Tabs>
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['STAFF_EDIT']}>
                                {data.staff_id &&
                                    <Button
                                        type="primary"
                                        onClick={onEdit}
                                        size="large"
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['STAFF_DELETE']}>
                                {data.staff_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"
                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default StaffDetail;
