/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Button, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../../Common/slice';
import * as commonService from '../../../Common/service'
import {
    PlusOutlined
} from '@ant-design/icons';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import CustomImage from '../../../Common/components/CustomImage';
import { checkinSettingSlider } from '../../service';

const CheckinSettingSlider = (props) => {
    const { isHideAdd } = props
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const dataImageSelected = useSelector(state => state.imageGallery.dataImageSelected);
    const [dataSlider, setDataSlider] = useState([])

    useEffect(() => {
        getCheckinSlider()
    }, [])

    const getCheckinSlider = () => {
        checkinSettingSlider({}).then(res => {
            if (res.data) {
                setDataSlider(res.data)
            }
        })
    }
    // click button delete
    const onDelete = (listId = []) => {
        if (listId.length > 0) {
            const dataDelete = {
                list_id: listId,
                field_name: 'checkin_app_slider_id',
                table: 'checkin_app_slider',
                update_user: userInfo.user_id,
                permission: ['CHECKIN_SETTING_EDIT']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res.data) {
                    message.success(lang.delete_success)
                    getCheckinSlider()
                }
            }).finally(z => {
                dispatch(setLoading(false))
            })
        }
    };

    const onSave = () => {
        dispatch(setLoading(true))
        const slider_list = dataImageSelected.map((item) => {
            return {
                ...item,
                checkin_app_slider_image: item.image_gallery_name,
                create_user: userInfo.user_id
            }
        })
        checkinSettingSlider({ slider_list }, 'POST').then(res => {
            if (res.data) {
                message.success(lang.save_success)
            }
        }).finally(x => {
            dispatch(setLoading(false))
            getCheckinSlider()
        })
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_TITLE} - {lang.slider}</title>
            </Helmet>

            <Row gutter={24} className="mt-5">
                {!isHideAdd && <Col className="mb-15" xs={24} md={4} sm={6}>
                    <Button
                        style={{ minHeight: dataSlider && dataSlider.length > 0 ? 0 : 135 }}
                        className="btn-add-image"
                        type="primary" icon={<PlusOutlined />}
                        block
                        onClick={() => document.getElementById('galleryAction').click()}
                    />
                    <ImageGallerySelect onSave={onSave} isMultiple />
                </Col>}
                {dataSlider && dataSlider.length > 0 && dataSlider && dataSlider.map((item, index) => {
                    return (
                        <Col className="mb-15" key={index} xs={24} md={4} sm={6}>
                            <CustomImage
                                onDelete={(data) => onDelete([data.checkin_app_slider_id])}
                                className="img-cover img-background-list"
                                item={item}
                                src={item.checkin_app_slider_image}
                            />
                        </Col>
                    )
                })}
                {/* {
                        dataSlider && dataSlider.length > 0 && <Col className="text-right" xs={24} md={24} sm={24}>
                            <Space>
                                {imageSelected.length > 0 &&
                                    <Popconfirm
                                        placement="top"
                                        title={lang.sure_delete}
                                        onConfirm={() => onDelete(imageSelected.map(x => x.gift_card_background_id))}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="middle"
                                            icon={<DeleteOutlined />}
                                            style={{ marginBottom: 20 }} >
                                        </Button>
                                    </Popconfirm>
                                }
                            </Space>
                        </Col>
                    } */}
            </Row>
        </div>
    );
};

export default CheckinSettingSlider;
