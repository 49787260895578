import { createAsyncThunk } from '@reduxjs/toolkit';
import * as roleService from './service';

export const getRoleByUser = createAsyncThunk(
  'role/getRoleByUser',
  async (data, thunkAPI) => {
    const response = await roleService.getRoleByUser(data);
    return response.data;
  }
);

export const roleList = createAsyncThunk(
  'role/getList',
  async (data, thunkAPI) => {
    const response = await roleService.roleList(data);
    return response.data;
  }
);

export const roleListAction = createAsyncThunk(
  'role/list-action',
  async (data, thunkAPI) => {
    const response = await roleService.roleListAction(data);
    return response.data;
  }
);

export const roleListAll = createAsyncThunk(
  'role/all',
  async (data, thunkAPI) => {
    const response = await roleService.roleListAll(data);
    return response.data;
  }
);

export const getRoleListByAppDeActive = createAsyncThunk(
  'role/role-list-by-app-deactive',
  async (data, thunkAPI) => {
    const response = await roleService.getRoleListByAppDeActive(data);
    return response.data;
  }
);
