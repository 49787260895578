/* eslint-disable array-callback-return */

import React from 'react';
import { Card, Col, Row } from 'antd';
import lang from '../../../language/language';
import { useSelector } from 'react-redux';
import Barcode from 'react-barcode';
import { formatDate } from '../../Common/components/FormatData';

const GiftCardTemplate = (props) => {
    const { data } = props;
    const generalSetting = useSelector(state => state.generalSetting);
    const giftCardSettingData = useSelector(state => state.giftCardSetting.giftCardSettingData);
    /// /render///////
    return (
        <Row id={data.gift_card_code} className="mb-15 bg-white page-break " >
            <Col md={24}>
                <Card
                    hoverable
                    className='card-to-print'
                    cover={<div>
                        <img height={200} width="100%" src={data.gift_card_background_image_url} />
                        <img width="100" height="100" src={giftCardSettingData.gift_card_setting_logo} className="gift-card-logo"></img>
                    </div>}
                >
                    <div className="p-10">
                        <h3 className="text-center pt-10"><b>{giftCardSettingData.gift_card_setting_title || ''}</b></h3>
                        <div className="text-center">{giftCardSettingData.gift_card_setting_description || ''}</div>
                        <h1 className="text-center"><b>${data.gift_card_balance}</b></h1>
                        <hr className="mb-0" />
                    </div>
                    <div className="p-10">
                        {data.gift_card_from_name && <div className="clearfix">
                            <div style={{ width: '40%', float: 'left' }}>
                                <div className="pb-5">{lang.gift_card_send_email_from}:</div>
                            </div>
                            <div>
                                <div className="pb-5"><b>{data.gift_card_from_name}</b></div>
                            </div>
                        </div>}
                        {data.gift_card_to_name && <div className="clearfix">
                            <div style={{ width: '40%', float: 'left' }}>
                                <div className="pb-5">{lang.gift_card_send_email_to}:</div>
                            </div>
                            <div>
                                <div className=" pb-5"><b>{data.gift_card_to_name}</b> </div>
                            </div>
                        </div>}
                        {data.gift_card_message && <div>
                            <div className="pb-5">{lang.gift_card_message}:</div>
                            <div className="pb-5"><b>{data.gift_card_message}</b></div>
                        </div>}
                        <div className="text-center">
                            <Barcode
                                value={data.gift_card_code}
                                width={1.8}
                                height={50}
                                fontSize={20}
                            />
                        </div>
                        <hr />
                        {giftCardSettingData.gift_card_setting_terms_conditions && <div>
                            <div className="pt-5">
                                <b>{lang.gift_card_setting_terms_conditions}:</b>
                            </div>
                            <div className="pt-5">
                                {giftCardSettingData.gift_card_setting_terms_conditions}
                            </div>
                        </div>}
                        <div className="pt-5">
                            {lang.gift_card_expiry_date}: <b>{formatDate(data.gift_card_expiry_date, true, generalSetting) || lang.not_expiry}</b>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    )
};

export default GiftCardTemplate;
