import { createAsyncThunk } from '@reduxjs/toolkit';
import * as reportAppointmentService from './service';

export const reportAppointmentList = createAsyncThunk(
  'reportAppointment/reportAppointmentList',
  async (data, thunkAPI) => {
    const response = await reportAppointmentService.reportAppointmentList(data);
    return response.data;
  }
);
