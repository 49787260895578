/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setGiftCardId, setGiftCardQueryData, setGiftCardListId } from '../slice';
import * as giftCardThunk from '../thunk';
// import ServiceFilter from './GiftCardFilter';
// import GiftCardAction from './GiftCardAction/ServiceGroupAction';
// import ServiceActionChecked from './ServiceGroupAction/GiftCardActionChecked';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import GiftCardDetail from './GiftCardDetail/GiftCardDetail';
import GiftCardActionChecked from './GiftCardAction/GiftCardActionChecked';
import GiftCardFilter from './GiftCardFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import GiftCardAction from './GiftCardAction/GiftCardAction';
import * as c from '../../../constants/common';

const { Search } = Input;

const GiftCard = (props) => {
  const dispatch = useDispatch();
  const giftCardQueryData = useSelector(state => state.giftCard.giftCardQueryData);
  const giftCardListId = useSelector(state => state.giftCard.giftCardListId);
  const dataList = useSelector(state => state.giftCard.giftCardList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setGiftCardListId([]));
      dispatch(setGiftCardId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.gift_card));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(giftCardQueryData);
  }, [giftCardQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(giftCardThunk.giftCardList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setGiftCardQueryData({ ...giftCardQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.gift_card_list}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_gift_card}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <GiftCardActionChecked />
                <GiftCardAction />
                <GiftCardFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={giftCardQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={giftCardListId}
            queryData={giftCardQueryData}
            keyField="gift_card_id"
            clickField="gift_card_code"
            setListIdChecked={setGiftCardListId}
            setQueryData={setGiftCardQueryData}
            expandedRowRender={(record) => <GiftCardDetail data={record} queryData={giftCardQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCard;
