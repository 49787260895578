/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import CheckPermission from '../../../Common/components/CheckPermission';
import { useSelector, useDispatch } from 'react-redux';
import { setCheckinUserId } from '../../slice';
import * as commonService from '../../../Common/service';
import * as userService from '../../../Setting/User/service';

import { Row, Col, Button, Space, Popconfirm, message, Image, List, Typography } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import noImage from '../../../../assets/images/default-user.png'
import { formatDate, formatIsActive, formatPhone } from '../../../Common/components/FormatData';
import * as checkinUserThunk from '../../thunk';
const { Text } = Typography;

const CheckinUserDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    // const color = useSelector(state => state.user.userInfo.theme_color);
    // const generalSetting = useSelector(state => state.generalSetting);

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.user_id) {
            const userId = data.user_id
            const dataDelete = {
                user_id: [userId],
                field_name: 'user_id',
                table: 'user',
                update_user: userInfo.user_id,
                permission: ['CHECKIN_USER_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(checkinUserThunk.checkinUserList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.user_id) {
            document.getElementById('userAction').click();
            dispatch(setCheckinUserId(data.user_id));
        }
    };

    const onLogout = () => {
        if (data.user_id) {
            const userId = data.user_id
            const reqData = { user_id: userId };
            userService.userLogout(reqData).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.user_id) {
                        dispatch(checkinUserThunk.checkinUserList(queryData));
                        message.success(lang.logout_success);
                    }
                }
            })
        }
    };

    /// /render///////
    return (
        <Row >
            <Col xs={8} md={4} lg={4}>
                <Image
                    width="100%"
                    className="mb-15 img-avatar img-avatar-detail"
                    preview={false}
                    src={data.user_avatar || noImage}
                />
            </Col>
            <Col xs={24} md={20} lg={20} className="pl-15">
                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'user_id' &&
                                x !== 'key' &&
                                x !== 'branch' &&
                                x !== 'is_main' &&
                                x !== 'user_avatar' &&
                                x !== 'user_birthday' &&
                                x !== 'user_email' &&
                                x !== 'user_address' &&
                                x !== 'user_phone_number' &&
                                x !== 'branch_id' &&
                                x !== 'access_token' &&
                                x !== 'refresh_token'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    (
                                        item === 'create_date' ||
                                        item === 'user_birthday'
                                    )
                                        ? formatDate(data[item])
                                        : (
                                            item === 'user_phone_number'
                                        )
                                            ? formatPhone(data[item])
                                            : (item === 'is_active')
                                                ? formatIsActive(data[item])
                                                : (String(data[item]).includes('{DELETE}'))
                                                    ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                    : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>

            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['CHECKIN_USER_LOGOUT']}>
                                {data.user_id && data.access_token &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_logout}
                                        onConfirm={onLogout}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<LogoutOutlined />}
                                        >{lang.logout}</Button>
                                    </Popconfirm>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CHECKIN_USER_EDIT']}>
                                {data.user_id &&
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={onEdit}
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CHECKIN_USER_DELETE']}>
                                {data.user_id && data.is_main === 0 &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>

    );
};

export default CheckinUserDetail;
