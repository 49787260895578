import CallApi from '../../utils/CallAPI';
import {
  STAFF_TITLE,
  STAFF_TITLE_DETAIL
} from './constants/endpoint';

export const staffTitleList = (data) => {
  return CallApi(STAFF_TITLE, 'GET', data)
};

export const staffTitleCreateUpdate = (data, method) => {
  return CallApi(STAFF_TITLE, method, data)
};

export const staffTitleDetail = (data) => {
  return CallApi(STAFF_TITLE_DETAIL, 'GET', data)
};
