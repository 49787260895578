/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Menu } from 'antd';
import { NavLink, Link } from 'react-router-dom';
import * as dashboardRoute from '../../../modules/DashBoard/constants/routes';

import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import IconHexcore from './../../../assets/images/icon-hexcore.png';
import Logo from './../../../assets/images/logo-hexcore.png';

const { SubMenu } = Menu;

const DrawerMenu = (props) => {
	const app = useSelector((state) => state.common.appList);
	// const role = useSelector(state => state.role)

	const [visible, setVisible] = useState(false);
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	const showMenu = (item, index) => {
		const menu = item.app_menu ? item.app_menu : [];
		let result = '';
		if (menu.length <= 0) {
			result = (
				<Menu.Item key={item.app_id} icon={<i className={item.app_icon}></i>}>
					<NavLink to={item.app_route} exact>
						{item.app_name}
					</NavLink>
				</Menu.Item>
			);
		} else {
			result = (
				<SubMenu icon={<i className={item.app_icon}></i>} key={item.app_id} title={item.app_name}>
					{menu.length > 0 &&
						menu.map((itemMenu, indexMenu) => {
							return (
								<Menu.Item key={itemMenu.app_id}>
									<NavLink to={itemMenu.app_route} exact>
										{itemMenu.app_name}
									</NavLink>
								</Menu.Item>
							);
						})}
				</SubMenu>
			);
		}

		return result;
	};

	return (
		<>
			<Link to={dashboardRoute.ROUTE_DASH_BOARD}>
				<img src={IconHexcore} width="35" height={35} className="mr-15" style={{ marginBottom: 4 }} />
			</Link>
			{visible ? (
				<MenuUnfoldOutlined className="text-primary" style={{ fontSize: 20 }} onClick={onClose} />
			) : (
				<MenuFoldOutlined className="text-primary" style={{ fontSize: 20 }} onClick={showDrawer} />
			)}
			<Drawer
				placement="left"
				title={
					<Link to={dashboardRoute.ROUTE_DASH_BOARD}>
						<img src={Logo} width="80%" height="40" />
					</Link>
				}
				className={'drawer-menu'}
				onClose={onClose}
				visible={visible}
			>
				<Menu mode="inline" theme="light">
					{app &&
						app.length > 0 &&
						app.map((item, index) => {
							return showMenu(item, index);
						})}
				</Menu>
			</Drawer>
		</>
	);
};

export default DrawerMenu;
