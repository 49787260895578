/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { updateTitle, setLoadingTable } from '../../../../modules/Common/slice';
import { setBranchId, setBranchQueryData, setBranchListId } from '../../../../modules/Setting/Branch/slice';
import * as branchThunk from '../../../../modules/Setting/Branch/thunk';
import BranchFilter from './BranchFilter';
import BranchAction from './BranchAction/BranchAction';
import BranchActionChecked from './BranchAction/BranchActionChecked';
import BranchDetail from './BranchDetail';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { Row, Col, Space, Input } from 'antd';
import * as c from '../../../../constants/common';

const { Search } = Input;

const Branch = (props) => {
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const branchQueryData = useSelector(state => state.branch.branchQueryData);
  const branchListId = useSelector(state => state.branch.branchListId);
  const dataList = useSelector(state => state.branch.branchList);
  const [listNoSelect, setListNoSelect] = useState([]);

  useEffect(() => {
    return function cleanup() {
      dispatch(setBranchListId([]));
      dispatch(setBranchId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.setting));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(branchQueryData);
  }, [branchQueryData]);

  useEffect(() => {
    setLoadingTable(false);
    if (dataList.rows && dataList.rows.length > 0) {
      const data = [...listNoSelect]
      dataList.rows.map((item) => {
        if (item.is_main === 1) {
          data.push(item.branch_id)
        }
      })
      setListNoSelect(data)
    }
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (branchQueryData) => {
    dispatch(branchThunk.branchList(branchQueryData));
  };

  const onSearch = (value, e) => {
    dispatch(setBranchQueryData({ ...branchQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.branch}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_branch}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <BranchActionChecked />
                <BranchAction />
                <BranchFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={branchQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={branchListId}
            queryData={branchQueryData}
            keyField="branch_id"
            clickField="branch_code"
            setListIdChecked={setBranchListId}
            setQueryData={setBranchQueryData}
            expandedRowRender={(record) => <BranchDetail data={record} queryData={branchQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Branch;
