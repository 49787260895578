import { createAsyncThunk } from '@reduxjs/toolkit';
import * as checkinUserService from './service';

export const checkinUserList = createAsyncThunk(
  'checkinUser/getList',
  async (data, thunkAPI) => {
    const response = await checkinUserService.checkinUserList(data);
    return response.data;
  }
);
