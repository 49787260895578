/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import { List, Row, Col } from 'antd';

const StaffDetailService = (props) => {
    const { data } = props;

    /// /funtion///////

    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24} lg={24}>
                <List
                    // itemLayout="horizontal"
                    grid={{ gutter: 24, column: 4, md: 3, xs: 2 }}
                    dataSource={data.service_list}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{item.service_name}</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    );
};

export default StaffDetailService;
