import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import {
	DollarCircleOutlined,
	ArrowUpOutlined,
	ArrowDownOutlined,
	UserOutlined,
	FormOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency } from '../../Common/components/FormatData';
import '../styles/style.css';
import lang from '../../../language/language';
import { setLoading } from '../../Common/slice';
import { getReportStatistic } from '../service';
import { rateOfChange } from '../../Common/components/Caculator';
import moment from 'moment';

const DashBoardDailyStatistical = () => {
	const dispatch = useDispatch();

	const generalSetting = useSelector((state) => state.generalSetting);
	const [dataStatistic, setDataStatistic] = useState({});

	useEffect(() => {
		getNewReportStatistic();
	}, []);

	const getNewReportStatistic = () => {
		dispatch(setLoading(true));
		const reqData = { date: moment().format('YYYY-MM-DD') };
		getReportStatistic(reqData)
			.then((res) => {
				if (res.data) {
					setDataStatistic(res.data);
				}
			})
			.finally((x) => {
				dispatch(setLoading(false));
			});
	};

	return (
		<Card className="statistic-body-card mb-15 pb-5">
			<Row gutter={24}>
				<Col xs={24} md={24} className="font-weight-bold mb-15">
					{lang.report_statistic}
				</Col>
				<Col md={8}>
					{/* Doanh thu */}
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<DollarCircleOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic
									title={lang.income}
									value={formatCurrency(dataStatistic.income && dataStatistic.income.today, generalSetting)}
									suffix={
										((dataStatistic.income && dataStatistic.income.today) > 0 ||
											(dataStatistic.income && dataStatistic.income.yesterday) > 0) && (
											<Statistic
												value={
													rateOfChange(
														dataStatistic.income && dataStatistic.income.today,
														dataStatistic.income && dataStatistic.income.yesterday
													).rate
												}
												valueStyle={{ fontSize: 15 }}
												prefix={
													rateOfChange(
														dataStatistic.income && dataStatistic.income.today,
														dataStatistic.income && dataStatistic.income.yesterday
													).increase > 0 ? (
														<ArrowUpOutlined />
													) : (
														<ArrowDownOutlined />
													)
												}
												suffix="%"
											/>
										)
									}
								/>
								<div className="yesterday-parameter">
									{lang.yesterday}:{' '}
									{formatCurrency(dataStatistic.income && dataStatistic.income.yesterday, generalSetting)}
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col md={8}>
					{/* Lịch hẹn */}
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<FormOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic
									title={lang.booking}
									value={dataStatistic.appointment && dataStatistic.appointment.today}
									suffix={
										((dataStatistic.appointment && dataStatistic.appointment.today) > 0 ||
											(dataStatistic.appointment && dataStatistic.appointment.yesterday) > 0) && (
											<Statistic
												value={
													rateOfChange(
														dataStatistic.appointment && dataStatistic.appointment.today,
														dataStatistic.appointment && dataStatistic.appointment.yesterday
													).rate
												}
												valueStyle={{ fontSize: 15 }}
												prefix={
													rateOfChange(
														dataStatistic.appointment && dataStatistic.appointment.today,
														dataStatistic.appointment && dataStatistic.appointment.yesterday
													).increase ? (
														<ArrowUpOutlined />
													) : (
														<ArrowDownOutlined />
													)
												}
												suffix="%"
											/>
										)
									}
								/>
								<div className="yesterday-parameter">
									{lang.yesterday}: {dataStatistic.appointment && dataStatistic.appointment.yesterday}
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col md={8}>
					{/* Khách hàng */}
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<UserOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic
									title={lang.customer}
									value={dataStatistic.customer && dataStatistic.customer.today}
									suffix={
										((dataStatistic.customer && dataStatistic.customer.today) > 0 ||
											(dataStatistic.customer && dataStatistic.customer.yesterday) > 0) && (
											<Statistic
												value={
													rateOfChange(
														dataStatistic.customer && dataStatistic.customer.today,
														dataStatistic.customer && dataStatistic.customer.yesterday
													).rate
												}
												valueStyle={{ fontSize: 15 }}
												prefix={
													rateOfChange(
														dataStatistic.customer && dataStatistic.customer.today,
														dataStatistic.customer && dataStatistic.customer.yesterday
													).increase ? (
														<ArrowUpOutlined />
													) : (
														<ArrowDownOutlined />
													)
												}
												suffix="%"
											/>
										)
									}
								/>
								<div className="yesterday-parameter">
									{lang.yesterday}: {dataStatistic.customer && dataStatistic.customer.yesterday}
								</div>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Card>
	);
};

export default DashBoardDailyStatistical;
