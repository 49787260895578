import React, { useEffect, useState } from 'react';
import lang from '../../../language/language';
import { getStaffTitle } from '../service';
import { Form, Select } from 'antd';
import * as c from '../../../constants/common';
const { Option } = Select;

const StaffTitleSelect = (props) => {
  const { name, isMultiple, hideLabel, style, required, listIdRemove } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getStaffTitle().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const validateStaffTitleSelect = (rule, value, getFieldValue) => {
    if (value) {
      if (!isMultiple) {
        const res = options.find((i) => i.staff_title_id === value);
        if (value && res.is_active === c.IS_DEACTIVE) {
          return Promise.reject(new Error(`${res.staff_title_name} ${lang.option_deactive_validation}`));
        }
        return Promise.resolve();
      } else {
        if (value) {
          const arr = options.filter(i => value.includes(i.staff_title_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
          const text = arr.map((y) => y.staff_title_name)
          if (arr.length > 0 && value.length > 0) {
            return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
          }
          return Promise.resolve();
        }
      }
    } else return Promise.resolve();
  }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : lang.title}
      name={name || 'title'}
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateStaffTitleSelect(rule, value, getFieldValue)
        })
      ]}
    >
      <Select
        showSearch
        allowClear
        style={style}
        maxTagCount='responsive'
        placeholder={lang.title}
        mode={isMultiple ? 'multiple' : ''}
        size="large"
      >
        {options.map((item) => {
          return <Option
            className={listIdRemove && listIdRemove.length > 0 && listIdRemove.filter(e => e !== undefined).includes(item.staff_title_id) ? 'd-none' : ''}
            key={item.staff_title_id}
            value={item.staff_title_id}
          >{item.staff_title_name}</Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default StaffTitleSelect;
