import { createSlice } from '@reduxjs/toolkit';
import * as serviceThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';

export const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    serviceList: {},
    serviceInvoiceList: {},
    serviceInfo: {},
    serviceId: '',
    serviceListId: [],
    serviceListAction: [],
    serviceCondition: [],
    discountType: c.DISCOUNT_TYPE.PERCENT,
    serviceQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      serviceGroupId: null,
      userId: getInfoUser.user_id
    },
    serviceInvoiceQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_GRID,
      search: '',
      orderBy: '',
      serviceGroupId: null,
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearServiceList: (state, action) => {
      state.serviceList = {};
    },
    clearServiceRow: (state, action) => {
      state.serviceList.rows = [];
    },
    setServiceId: (state, action) => {
      state.serviceId = action.payload;
    },
    clearServiceId: (state, action) => {
      state.serviceId = '';
    },
    setServiceQueryData: (state, action) => {
      state.serviceQueryData = action.payload;
    },
    setServiceListId: (state, action) => {
      state.serviceListId = action.payload;
    },
    setServiceCondition: (state, action) => {
      state.serviceCondition = action.payload;
    },
    setDiscountType: (state, action) => {
      state.discountType = action.payload;
    },
    setServiceInvoiceQueryData: (state, action) => {
      state.serviceInvoiceQueryData = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [serviceThunk.serviceList.fulfilled]: (state, action) => {
      state.serviceList = action.payload;
    },
    [serviceThunk.serviceInvoiceList.fulfilled]: (state, action) => {
      state.serviceInvoiceList = action.payload;
    }
  }
});

export const {
  setCurrentService,
  clearServiceList,
  clearServiceRow,
  setServiceId,
  clearServiceId,
  setServiceQueryData,
  setServiceListId,
  setServiceGroupCondition,
  setDiscountType,
  setServiceInvoiceQueryData
} = serviceSlice.actions;

export default serviceSlice.reducer;
