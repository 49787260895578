import * as giftCardService from './service';
import { toPng } from 'html-to-image';

export const giftCardSendEmail = (data) => {
    if (data.length > 0) {
        data.forEach(item => {
            toPng(document.getElementById(item.gift_card_code)).then(dataUrl => {
                giftCardService.giftCardSendEmail({
                    ...item,
                    list_file_gift_card: [{
                        filename: `gift_card_${item.gift_card_code}.png`,
                        path: dataUrl
                    }]
                })
            })
        });
    }
}
