/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useRef, useState } from 'react';
import lang from '../../../../../language/language';
import { Row, Col, Button, Popover, Space, Input, Select, Form, Table, message, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import {
    DollarOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined,
    InfoCircleOutlined,
    EditOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { formatCurrency } from '../../../../Common/components/FormatData';
import * as c from '../../../../../constants/common';
import { clearInvoiceDetail, setInvoiceDetail, setInvoiceId } from '../../../slice';
import { getGiftCardTotal, getInvoiceMoneyReceived } from '../../../common';
import * as invoiceService from '../../../service';
import { getInvoiceList } from '../../../../Common/thunk';
import { setLoading } from '../../../../Common/slice';
import { useReactToPrint } from 'react-to-print';
import InvoicePrint from '../../InvoicePrint';
import InvoiceActionDetailGiftCard from './InvoiceActionDetailGiftCard';

const InvoiceActionDetailPay = (props) => {
    const { resetData } = props
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const [isVisible, setIsvisible] = useState(false)
    const [paymentMethodSelected, setPaymentMethodSelected] = useState(null)
    const [giftCardMoneyUse, setGiftCardMoneyUse] = useState(null)
    const [dataPrint, setDataPrint] = useState({})
    const [form] = Form.useForm();
    const generalSetting = useSelector(state => state.generalSetting);
    const { Option } = Select;
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.user.userInfo);
    const printRef = useRef();

    useEffect(() => {
        const invoiceMoneyReceived = getInvoiceMoneyReceived(invoiceDetail.invoicePaymentMethodList)
        const invoiceChange = invoiceDetail.invoice_money_received - invoiceDetail.invoice_customer_need_to_pay
        const defaultPaymentMethodMoney = (invoiceDetail.invoice_customer_need_to_pay - invoiceMoneyReceived).toFixed(2)
        dispatch(setInvoiceDetail({
            ...invoiceDetail,
            invoice_money_received: invoiceMoneyReceived,
            invoice_change: invoiceChange > 0 ? invoiceChange : 0,
            invoice_tip: 0
        }))
        form.setFieldsValue({
            invoice_payment_method_money: defaultPaymentMethodMoney > 0 ? defaultPaymentMethodMoney : null,
            invoice_tip: 0
        })
    }, [
        invoiceDetail.invoicePaymentMethodList,
        invoiceDetail.giftCardList,
        invoiceDetail.invoice_money_received,
        invoiceDetail.invoice_customer_need_to_pay
    ])

    /// /funtion///////

    const onClose = () => {
        onClearData()
    }

    const onClearData = () => {
        setIsvisible(false)
        form.resetFields()
        form.setFieldsValue({
            invoice_payment_method_money: invoiceDetail.invoice_customer_need_to_pay.toFixed(2),
            invoice_tip: 0
        })

        setDataPrint({})
        setPaymentMethodSelected(null)
        dispatch(setInvoiceDetail({
            ...invoiceDetail,
            invoicePaymentMethodList: [],
            invoice_money_received: 0,
            invoice_tip: 0,
            invoice_change: 0,
            giftCardList: []

        }))
    }

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    const onSave = () => {
        const staffList = invoiceDetail.invoiceItemList.filter(z => z.staff_id === null)
        if (staffList.length > 0) {
            message.error(lang.staff_is_required)
        } else if (invoiceDetail.invoice_money_received < invoiceDetail.invoice_customer_need_to_pay.toFixed(1)) {
            message.error(lang.invoice_money_received_error)
        } else if (!invoiceDetail.customer_id) {
            message.error(lang.please_select_customer)
        } else if (invoiceDetail.invoice_status !== c.INVOICE_STATUS.UNPAID) {
            message.error(lang.invoice_not_invalid)
            dispatch(setInvoiceId(null))
            resetData()
            dispatch(getInvoiceList())
        } else {
            dispatch(setLoading(true))
            const reqData = {
                ...invoiceDetail,
                invoice_status: c.INVOICE_STATUS.PAID,
                invoice_code: invoiceDetail.invoice_code,
                invoice_id: invoiceDetail.invoice_id,
                update_user: userInfo.user_id,
                customer_id: invoiceDetail.customer_id
            }
            invoiceService.invoiceCreateUpdate(reqData, 'PUT').then(res => {
                if (res.data) {
                    onClearData()
                    resetData()
                    dispatch(clearInvoiceDetail())
                    dispatch(getInvoiceList())
                    dispatch(setInvoiceId(null))
                    message.success(lang.pay_success)
                    setDataPrint(res.data)
                    setTimeout(() => {
                        handlePrint()
                    }, 1000)
                }
            }).finally(x => {
                dispatch(setLoading(false))
            })
        }
    }

    const onDeleteGiftCard = (giftCardCode) => {
        const giftCardList = [...invoiceDetail.giftCardList]
        if (giftCardCode) {
            const giftCardListFilter = giftCardList.filter((x) => x.gift_card_code !== giftCardCode)

            dispatch(setInvoiceDetail({
                ...invoiceDetail,
                giftCardList: giftCardListFilter,
                invoicePaymentMethodList: giftCardListFilter.length === 0
                    ? invoiceDetail.invoicePaymentMethodList.filter(z => z.invoice_payment_method !== c.PAYMENT_METHOD[2].value)
                    : invoiceDetail.invoicePaymentMethodList.map(x => {
                        if (x.invoice_payment_method === c.PAYMENT_METHOD[2].value) {
                            return { ...x, invoice_payment_method_money: getGiftCardTotal(giftCardListFilter) };
                        }
                        return x;
                    })
            }))
        }
    }

    const columns = [

        {
            title: lang.invoice_payment_method,
            dataIndex: 'invoice_payment_method',
            render: (text, record) => {
                return <div>
                    {c.PAYMENT_METHOD.filter(x => x.value === record.invoice_payment_method)[0].label}
                    {invoiceDetail.giftCardList && invoiceDetail.giftCardList.length > 0 &&
                        record.invoice_payment_method === c.PAYMENT_METHOD[2].value &&
                        <div className="pl-15 f-13">
                            {invoiceDetail.giftCardList.map((i, index) => {
                                return <div key={index}><MinusCircleOutlined onClick={() => onDeleteGiftCard(i.gift_card_code)} /> {i.gift_card_code}</div>
                            })}
                        </div>
                    }
                </div>
            }
        },
        {
            title: lang.invoice_payment_method_money,
            dataIndex: 'invoice_payment_method_money',
            render: (text, record) => {
                return (
                    <div>
                        {formatCurrency(record.invoice_payment_method_money, generalSetting)}
                        {invoiceDetail.invoicePaymentMethodList && invoiceDetail.giftCardList && invoiceDetail.giftCardList.length > 0 && record.invoice_payment_method === c.PAYMENT_METHOD[2].value && invoiceDetail.giftCardList.map((i, index) => {
                            return <Popover
                                key={index}
                                placement="bottomRight"
                                trigger="click"
                                style={{ width: 100 }}
                                overlayClassName="popover-pos"
                                content={
                                    <Row>
                                        <Col xs={24} md={24}>
                                            <Input
                                                size="middle"
                                                type="number"
                                                defaultValue={giftCardMoneyUse || i.gift_card_money_use}
                                                value={giftCardMoneyUse}
                                                onChange={(e) => { setGiftCardMoneyUse(parseInt(e.target.value)) }}
                                                addonAfter={
                                                    <Space>
                                                        <Button
                                                            type="primary"
                                                            size="small"
                                                            onClick={() => onSaveGiftCardMoneyUse(i)}
                                                        ><SaveOutlined /> {lang.save}</Button>
                                                    </Space>
                                                }
                                                addonBefore={
                                                    <div>{lang.gift_card_balance}: {formatCurrency(i.gift_card_balance, generalSetting)}</div>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                }
                            >
                                <div className="f-13" key={index}>{formatCurrency(i.gift_card_money_use, generalSetting)}<EditOutlined /></div>
                            </Popover>
                        })}
                    </div>
                )
            }
        },
        {
            title: <MinusCircleOutlined onClick={() => onDeletePaymentMethod(null)}
                className="cursor-pointer"
                style={{ fontSize: 18 }}
            />,
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => {
                return <MinusCircleOutlined
                    onClick={() => onDeletePaymentMethod(record.invoice_payment_method)}
                    className="cursor-pointer"
                    style={{ fontSize: 18 }}
                />
            }
        }
    ];

    const onAdd = (data) => {
        const list = invoiceDetail.invoicePaymentMethodList ? [...invoiceDetail.invoicePaymentMethodList] : []
        if (data.invoice_payment_method_money === 0) {
            message.error(lang.method_price_greater_than_0)
        } else if (list.some(e => e.invoice_payment_method === data.invoice_payment_method)) {
            message.error(lang.method_duplicate)
        } else {
            list.push({
                invoice_payment_method: data.invoice_payment_method,
                invoice_payment_method_money: Number(data.invoice_payment_method_money),
                invoice_id: invoiceId,
                create_user: userInfo.user_id
            })
            const invoiceMoneyReceived = getInvoiceMoneyReceived(list)

            dispatch(setInvoiceDetail({
                ...invoiceDetail,
                invoicePaymentMethodList: list,
                invoice_money_received: invoiceMoneyReceived,
                giftCardList: data.invoice_payment_method === c.PAYMENT_METHOD[2].value ? data.giftCardList : invoiceDetail.giftCardList
            }))
        }
    }

    const onDeletePaymentMethod = (invoicePaymentMethod) => {
        const list = [...invoiceDetail.invoicePaymentMethodList]
        const filterList = invoicePaymentMethod ? list.filter(x => x.invoice_payment_method !== invoicePaymentMethod) : []
        const invoiceMoneyReceived = getInvoiceMoneyReceived(filterList)

        dispatch(setInvoiceDetail({
            ...invoiceDetail,
            invoicePaymentMethodList: filterList,
            invoice_money_received: invoiceMoneyReceived,
            giftCardList: invoicePaymentMethod === c.PAYMENT_METHOD[2].value ? [] : invoiceDetail.giftCardList
        }))
    }

    const onTip = () => {
        const newTip = invoiceDetail.invoice_change + invoiceDetail.invoice_tip
        dispatch(setInvoiceDetail({
            ...invoiceDetail,
            invoice_tip: newTip,
            invoice_change: 0
        }))
        form.setFieldsValue({ invoice_tip: newTip.toFixed(2) })
    }

    const onChangeTip = (e) => {
        const value = Number(e.target.value)
        const newChange = invoiceDetail.invoice_money_received - (invoiceDetail.invoice_customer_need_to_pay + Number(value))
        const maxTip = invoiceDetail.invoice_money_received - invoiceDetail.invoice_customer_need_to_pay
        dispatch(setInvoiceDetail({
            ...invoiceDetail,
            invoice_change: newChange > 0 ? newChange : 0,
            invoice_tip: newChange > 0 ? value : maxTip
        }))
        form.setFieldsValue({ invoice_tip: newChange > 0 ? e.target.value : maxTip })
    }

    const onTipPercent = (percent) => {
        const maxTip = invoiceDetail.invoice_money_received - invoiceDetail.invoice_customer_need_to_pay
        const moneyPercentTip = (invoiceDetail.invoice_customer_need_to_pay * percent) / 100
        const newChange = invoiceDetail.invoice_money_received - (invoiceDetail.invoice_customer_need_to_pay + moneyPercentTip)

        if (moneyPercentTip > maxTip) {
            message.error(lang.percent_tip_error)
        } else {
            dispatch(setInvoiceDetail({
                ...invoiceDetail,
                invoice_change: newChange,
                invoice_tip: moneyPercentTip
            }))
            form.setFieldsValue({ invoice_tip: moneyPercentTip })
        }
    }

    const onSaveGiftCardMoneyUse = (data) => {
        const needToPay = Number((invoiceDetail.invoice_money_received - data.gift_card_money_use).toFixed(2))
        const newMoneyReceive = needToPay + giftCardMoneyUse

        if (giftCardMoneyUse < 1) {
            message.error(lang.money_than_0)
        } else if (giftCardMoneyUse > data.gift_card_balance) {
            message.error(lang.money_than_balance)
        } else if (newMoneyReceive > invoiceDetail.invoice_customer_need_to_pay) {
            message.error(lang.gift_card_max_use)
            setGiftCardMoneyUse(data.gift_card_money_use)
        } else {
            const newListGiftCard = invoiceDetail.giftCardList.map(x => {
                if (x.gift_card_code === data.gift_card_code) {
                    // 👇️ change value of name property
                    return { ...x, gift_card_money_use: giftCardMoneyUse };
                }
                return x;
            });
            const newMethodList = invoiceDetail.invoicePaymentMethodList.map(x => {
                if (x.invoice_payment_method === c.PAYMENT_METHOD[2].value) {
                    // 👇️ change value of name property
                    return { ...x, invoice_payment_method_money: getGiftCardTotal(newListGiftCard) };
                }
                return x;
            });
            dispatch(setInvoiceDetail({
                ...invoiceDetail,
                giftCardList: newListGiftCard,
                invoicePaymentMethodList: newMethodList
            }))
        }
        setGiftCardMoneyUse(null)
    }

    const onClickPay = () => {
        if (!invoiceDetail.customer_id) {
            message.error(lang.please_select_customer)
        } else {
            setIsvisible(true)
        }
    }

    /// /render///////
    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            overlayClassName="popover-large"
            visible={isVisible}
            content={
                <Form
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onAdd}
                    form={form}
                    initialValues={{
                        invoice_payment_method_money: invoiceDetail.customer_need_to_pay,
                        invoice_payment_method: c.PAYMENT_METHOD[0].value,
                        invoice_tip: invoiceDetail.invoice_tip
                    }}
                >
                    {dataPrint && <InvoicePrint dataItem={dataPrint.invoiceItemList} data={dataPrint} printRef={printRef} />}

                    <Row gutter={12}>
                        <Col xs={24} md={24} className="border-bottom-1 mb-15">
                            <h3>{lang.payment}</h3>
                        </Col>
                        <Col xs={6} md={6}>
                            <div className="ant-card pl-5 pr-5 pt-5 pb-5" style={{ height: '100%' }}>
                                <div className="border-bottom-1">
                                    <div className="f-16">{lang.customer_need_to_pay}</div>
                                    <div className="font-weight-bold f-20">
                                        {formatCurrency(invoiceDetail.invoice_customer_need_to_pay, generalSetting)}
                                    </div>
                                </div>
                                <div className="border-bottom-1">
                                    <div className="f-16">{lang.invoice_money_received}</div>
                                    <div className="font-weight-bold f-20">
                                        {formatCurrency(invoiceDetail.invoice_money_received, generalSetting)}

                                    </div>
                                </div>
                                <div className="border-bottom-1">
                                    <div className="f-16">{lang.invoice_change} {invoiceDetail.invoice_money_received > invoiceDetail.invoice_customer_need_to_pay && <Tooltip trigger="click" placement="topLeft" title={<div className="text-black text-center">{lang.description_btn_tip}</div>} >
                                        <InfoCircleOutlined />
                                    </Tooltip>}
                                    </div>
                                    <div className="font-weight-bold f-20">
                                        <Row>
                                            <Col xs={18} md={18} >
                                                {formatCurrency(invoiceDetail.invoice_change, generalSetting)}
                                            </Col>
                                            {invoiceDetail.invoice_change > 0 && <Col xs={6} md={6} className="text-right">
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    onClick={onTip}
                                                >
                                                    {lang.tip}
                                                </Button>
                                            </Col>}
                                        </Row>
                                    </div>
                                </div>
                                <div>
                                    <div className="f-20">
                                        <Form.Item
                                            label={<div>
                                                {lang.invoice_tip} <Tooltip trigger="click" placement="topLeft" title={<div className="text-black text-center">{lang.description_percent_tip}</div>} >
                                                    <InfoCircleOutlined />
                                                </Tooltip>
                                            </div>}
                                            name="invoice_tip"
                                        >
                                            <Input
                                                size="middle"
                                                type='number'
                                                min={1}
                                                disabled={!(invoiceDetail.invoice_money_received > invoiceDetail.invoice_customer_need_to_pay)}
                                                onChange={onChangeTip}
                                            />
                                        </Form.Item>
                                    </div>
                                    <Space className="pb-5">
                                        {c.TIP_PERCENT.map(x => {
                                            return <Button
                                                key={x}
                                                type="primary"
                                                size="small"
                                                style={{ fontSize: 13 }}
                                                onClick={() => onTipPercent(x)}
                                            >
                                                {x}%
                                            </Button>
                                        })}
                                    </Space>

                                </div>
                            </div>
                        </Col>
                        <Col xs={18} md={18} >
                            <Row gutter={12}>
                                <Col xs={10} md={10} >
                                    <Form.Item
                                        name="invoice_payment_method"
                                        label={lang.invoice_payment_method}
                                    >
                                        <Select
                                            size='large'
                                            maxTagCount='responsive'
                                            defaultValue={c.PAYMENT_METHOD[0].value}
                                            onChange={(value) => setPaymentMethodSelected(value)}
                                        >
                                            {c.PAYMENT_METHOD.map((item) => {
                                                return <Option
                                                    key={item.value}
                                                    value={item.value}
                                                    data={item}
                                                >
                                                    {item.label}
                                                </Option>
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={2} md={2} className="text-right">
                                    <Form.Item
                                        label={' '}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ height: 40, marginTop: 3, width: 40 }}
                                            size="middle"
                                            icon={<PlusOutlined style={{ fontSize: 20 }} />}
                                        />
                                    </Form.Item>
                                </Col>
                                {paymentMethodSelected !== c.PAYMENT_METHOD[2].value && <Col xs={9} md={9} >
                                    <Form.Item
                                        label={lang.invoice_payment_method_money}
                                        name="invoice_payment_method_money"
                                        rules={[
                                            { required: true, message: lang.value_is_required },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (value < 1) {
                                                        return Promise.reject(lang.method_price_greater_than_0);
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            type='number'
                                            min={1}

                                        />
                                    </Form.Item>
                                </Col>}
                                {paymentMethodSelected === c.PAYMENT_METHOD[2].value && <Col xs={12} md={12} >
                                    <InvoiceActionDetailGiftCard isVisible={isVisible} onAdd={(data) => onAdd(data)} />
                                </Col>}

                                {paymentMethodSelected !== c.PAYMENT_METHOD[2].value && <Col xs={3} md={3} className="text-right">
                                    <Form.Item
                                        label={' '}
                                    >
                                        <Button
                                            type="primary"
                                            style={{ height: 40, marginTop: 3 }}
                                            onClick={() => form.submit()}
                                            size="middle">
                                            {lang.add}
                                        </Button>
                                    </Form.Item>
                                </Col>}
                                <Col xs={24} md={24} >
                                    <Table columns={columns} dataSource={invoiceDetail.invoicePaymentMethodList} size="small" pagination={false} />
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} md={24} className="text-right pt-15 mt-15 border-top-1">
                            <Space>
                                <Button
                                    onClick={onClose}
                                    className="w-100"
                                    type="primary"
                                    size="large"
                                    block
                                ><CloseCircleOutlined /> {lang.close}
                                </Button>
                                <Button
                                    className="w-100"
                                    disabled={!invoiceId || invoiceDetail.invoiceItemList.length === 0}
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={onSave}
                                >
                                    <DollarOutlined /> {lang.payment}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
        >
            <Button
                key="submit"
                onClick={onClickPay}
                disabled={!invoiceId || invoiceDetail.invoiceItemList.length === 0}
                type="primary"
                size="large"
                block
            ><DollarOutlined />
                {lang.payment}
            </Button>
        </Popover>
    );
};

export default InvoiceActionDetailPay;
