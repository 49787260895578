/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined, PlusOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Row, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../constants/common';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import EmailSampleSelect from '../../../Common/components/EmailSampleSelect';
import CheckPermission from '../../../Common/components/CheckPermission';
import { setLoading } from '../../../Common/slice';
import * as emailHistoryService from '../../service';
import { clearEmailHistoryId, setEmailCustomerListId } from '../../slice';
import * as emailHistoryThunk from '../../thunk';
import TinyEditor from './../../../Common/components/TinyEditor';
import CustomerSelect from '../../../Common/components/CustomerSelect'
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';

const EmailHistoryAction = (props) => {
  const { isHide } = props
  const queryData = useSelector(state => state.email.emailHistoryQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const emailHistoryId = useSelector(state => state.email.emailHistoryId);
  const emailCustomerListId = useSelector(state => state.email.emailCustomerListId);

  const [actionType, setActionType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  // const branchLocal = JSON.parse(localStorage.getItem('branch'));

  useEffect(() => {
    if (emailCustomerListId) {
      form.setFieldsValue({ customer: emailCustomerListId })
    }
  }, [emailCustomerListId, form])
  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const dataReq = {
      listCustomerId: data.customer,
      email_history_title: data.email_history_title,
      email_history_content: data.email_history_content,
      create_user: userInfo.user_id
    };
    emailHistoryService.emailHistoryCreate(dataReq).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));

        // luu thanh cong
        if (data.email_history_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!emailHistoryId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(emailHistoryThunk.emailHistoryList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    // genCodeByTable();
    dispatch(clearEmailHistoryId(''));
    form.resetFields();
    setEmailCustomerListId([])
  };

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSelectEmailSample = (data) => {
    form.setFieldsValue({ email_history_content: data.email_sample_content })
  }

  /// render/////
  return (
    <span className={`${isHide ? 'd-none' : ''}`}>
      <>
        <CheckPermission permission={['EMAIL_HISTORY_ADD']}>
          <Button type="primary" size="large" id="emailHistoryAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Button className='d-none' type="primary" size="large" id="emailHistoryAction" onClick={showModal} icon={<PlusOutlined />} />
        <Drawer
          title={lang.email_create}
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SendOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.send}
              </Button>
              <Button size="large" icon={<SendOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.send_and_close}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <CustomerSelect
                  label={lang.email_history_to}
                  isMultiple
                  hidePhone
                  allowClear
                  showSearch
                  showSelectAll
                  required
                  showEmail
                />
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.email_history_title}
                  name="email_history_title"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <EmailSampleSelect
                  onSelect={onSelectEmailSample}
                />
                <Form.Item
                  label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_CUSTOMER} /></div>}
                  name="email_history_content"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <TinyEditor />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default EmailHistoryAction;
