import React, { useEffect } from 'react';
import { Menu, Dropdown } from 'antd';
import vi from './../../../../assets/images/vietnam.png';
import en from './../../../../assets/images/united-kingdom.png';

const HeaderRightLanguage = (props) => {
  const language = localStorage.getItem('language')
  const langData = [
    { value: 'vi', label: 'Vietnamese', flat: vi },
    { value: 'en', label: 'English', flat: en }
  ]
  /// ///// useEffect
  useEffect(() => {
    if (!language) {
      localStorage.setItem('language', 'vi')
    }
  }, [])

  /// //funtion/////
  const handleLanguage = (lang) => {
    localStorage.setItem('language', lang)
    window.location.reload();
  }

  const menu = (
    <Menu>
      {
        langData.map((item, index) => {
          return (
            <Menu.Item key={index} onClick={() => handleLanguage(item.value)}>
              <img height={20} width={20} src={item.flat} /> <span style={{ verticalAlign: 'middle' }}>{item.label}</span>
            </Menu.Item>
          )
        })
      }
    </Menu>
  );

  return (
    <Dropdown placement="bottomRight" overlay={menu} className="cursor-pointer" trigger={['click']}>
      <img height={24} width={24} src={language === 'vi' ? vi : en} />
    </Dropdown>
  );
};

export default HeaderRightLanguage;
