import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { ImportExcel } from '../../../../modules/Common/components/ImportExcel';
import { userImport } from '../service'
import * as c from '../../../../constants/common';
import { setLoading } from '../../../../modules/Common/slice';
import * as userThunk from '../thunk';
import { Drawer, Space, Button, Row, Col, Alert, message } from 'antd';
import {
    SaveOutlined,
    CloseCircleOutlined,
    UploadOutlined
} from '@ant-design/icons';

const UserImport = (props) => {
    const queryData = useSelector(state => state.user.userQueryData);
    const [dataImport, setDataImport] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const getFileExportDemo = useSelector(state => state.common.getFileExportDemo);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const refImport = useRef()
    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onSave = () => {
        if (refImport.current.files.length === 0) {
            message.error(lang.please_select_file);
            return;
        }
        if (refImport.current.files.length > 0 && dataImport.length > 0) {
            dispatch(setLoading(true));
            const data = []
            dataImport.map((item) => {
                if (item[0] && item[1] && item[2] && item[3] && item[8]) {
                    return data.push({
                        user_code: item[0],
                        user_fullname: item[1],
                        user_name: item[2],
                        user_password: item[3],
                        user_phone_number: item[4],
                        user_email: item[5],
                        user_birthday: item[6],
                        user_address: item[7],
                        user_note: item[8],
                        province_id: '',
                        district_id: '',
                        ward_id: '',
                        create_user: userInfo.user_id,
                        is_active: item[9] === lang.active ? c.IS_ACTIVE : c.IS_DEACTIVE
                    })
                } else {
                    return null
                }
            })
            userImport({ user_list: data }).then(res => {
                if (res && res.status) {
                    dispatch(userThunk.userList(queryData));
                    message.success(lang.save_success);
                }
            }).finally(() => {
                dispatch(setLoading(false))
            });
        }
    }

    const onClose = () => {
        onClearData()
        setIsModalVisible(false)
    }

    const onClearData = () => {
        refImport.current.value = ''
        setDataImport([])
    }
    /// render/////
    return (
        <span>
            <Button size="large" onClick={showModal} type="primary" icon={<UploadOutlined />} />
            <Drawer
                title={lang.import + ' ' + lang.user.toLowerCase()}
                placement="right"
                width={c.WIDTH_DRAWER.SMALL}
                onClose={onClose}
                visible={isModalVisible}
                footer={
                    <Space className="text-center">
                        <Button icon={<CloseCircleOutlined />} size="large" key="back" onClick={onClose}>
                            {lang.close}
                        </Button>
                        <Button icon={<SaveOutlined />} onClick={onSave} size="large" key="submit" type="primary" >
                            {lang.apply}
                        </Button>
                    </Space>
                }
            >
                <Row >
                    <Col xs={12} md={24} className="mb-15 download-excel-demo">
                        {lang.download_demo}: <a href={getFileExportDemo.file_user_demo}>Excel</a>
                    </Col>
                    <Col xs={24} md={24}>
                        <input
                            id="userImport"
                            style={{ display: 'none' }}
                            onChange={(e) => ImportExcel(e, setDataImport)}
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ref={refImport}
                        />
                        <Button

                            size="large"
                            block
                            onClick={() => refImport.current.click()}
                            icon={<UploadOutlined />}
                        >{lang.choose_file}</Button>
                    </Col>
                    <Col xs={24} md={24}>
                        {refImport.current && refImport.current.files.length > 0 && <Alert
                            message={refImport.current.files[0].name}
                            type="info"
                            action={<Button
                                size="large"
                                icon={<CloseCircleOutlined />}
                                type="text"
                                onClick={onClearData}
                            />}
                        />}
                    </Col>
                </Row>
            </Drawer>
        </span>
    );
};

export default UserImport;
