import CallApi from '../../utils/CallAPI';
import {
  SERVICE_GROUP,
  SERVICE_GROUP_DETAIL
} from './constants/endpoint';

export const serviceGroupList = (data) => {
  return CallApi(SERVICE_GROUP, 'GET', data)
};

export const serviceGroupCreateUpdate = (data, method) => {
  return CallApi(SERVICE_GROUP, method, data)
};

export const serviceGroupDetail = (data) => {
  return CallApi(SERVICE_GROUP_DETAIL, 'GET', data)
};
