/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Col, Form, Image, Row, Input, Button, message } from 'antd';
import lang from '../../../language/language';
import TextArea from 'antd/lib/input/TextArea';
import {
    EditOutlined,
    SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../Common/components/CheckPermission';
import logo from '../../../assets/images/no-image.png'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Common/slice';
import * as giftCardSettingService from './../service';
import { giftCardSettingThunk } from '../thunk';
import ImageGallerySelect from '../../ImageGallery/components/ImageGallerySelect';

const GiftCardSettingGeneral = (props) => {
    const [form] = Form.useForm();
    const giftCardSettingData = useSelector(state => state.giftCardSetting.giftCardSettingData);
    const dispatch = useDispatch();
    const dataImageSelected = useSelector(state => state.imageGallery.dataImageSelected);
    const [typeChangeImage, setTypeChangeImage] = useState(null)
    const [giftCardLogo, setGiftCardLogo] = useState('')
    const [giftCardBackground, setGiftCardBackground] = useState('')

    useEffect(() => {
        form.setFieldsValue({
            gift_card_setting_title: giftCardSettingData.gift_card_setting_title,
            gift_card_setting_description: giftCardSettingData.gift_card_setting_description,
            gift_card_setting_terms_conditions: giftCardSettingData.gift_card_setting_terms_conditions,
            gift_card_setting_logo: giftCardSettingData.gift_card_setting_logo,
            gift_card_setting_allow_send_from_email: giftCardSettingData.gift_card_setting_allow_send_from_email || 2,
            gift_card_setting_allow_send_to_email: giftCardSettingData.gift_card_setting_allow_send_to_email || 2,
            gift_card_setting_from_email_content: giftCardSettingData.gift_card_setting_from_email_content,
            gift_card_setting_to_email_content: giftCardSettingData.gift_card_setting_to_email_content,
            gift_card_setting_from_email_title: giftCardSettingData.gift_card_setting_from_email_title,
            gift_card_setting_to_email_title: giftCardSettingData.gift_card_setting_to_email_title,
            gift_card_setting_background_image: giftCardSettingData.gift_card_setting_background_image
        });
    }, [giftCardSettingData])

    /// function

    const onChangeImage = (type) => {
        document.getElementById('gallerySettingGeneralAction').click()
        setTypeChangeImage(type)
    }

    const onSubmit = (data) => {
        dispatch(setLoading(true));
        const method = giftCardSettingData.gift_card_setting_id ? 'PUT' : 'POST';
        const dataReq = {
            ...giftCardSettingData,
            gift_card_setting_title: data.gift_card_setting_title || '',
            gift_card_setting_description: data.gift_card_setting_description || '',
            gift_card_setting_terms_conditions: data.gift_card_setting_terms_conditions || '',
            gift_card_setting_logo: giftCardLogo || giftCardSettingData.gift_card_setting_logo_image_name,
            gift_card_setting_background_image: giftCardBackground || giftCardSettingData.gift_card_setting_background_image_name
        };
        giftCardSettingService.giftCardSettingCreateUpdate(dataReq, method).then(res => {
            if (res) {
                dispatch(setLoading(false));
                message.success(lang.save_success);
            }
        }).finally(() => {
            dispatch(setLoading(false));
            dispatch(giftCardSettingThunk({}));
        })
    }

    const onSaveChangeImage = () => {
        if (dataImageSelected.length > 0) {
            if (typeChangeImage === 0) {
                form.setFieldsValue({
                    gift_card_setting_logo: dataImageSelected[0].image_gallery_url
                });
                setGiftCardLogo(dataImageSelected[0].image_gallery_name)
            }
            if (typeChangeImage === 1) {
                form.setFieldsValue({
                    gift_card_setting_background_image: dataImageSelected[0].image_gallery_url
                });
                setGiftCardBackground(dataImageSelected[0].image_gallery_name)
            }
        }
    }

    /// render

    return (
        <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
        >
            <ImageGallerySelect id="gallerySettingGeneralAction" onSave={onSaveChangeImage} />
            <Row gutter={24} className="mb-15">
                <Col xs={24} sm={4} md={4}>
                    <Form.Item
                        label="Logo"
                        name="gift_card_setting_logo"
                    >
                        <Image
                            src={form.getFieldValue('gift_card_setting_logo') || logo}
                            className='img-cover img-gift-card-setting'
                            width="100%"
                        />
                        <div className="btn-edit-image">
                            <Button
                                size="middle"
                                icon={<EditOutlined />}
                                key="edit-logo"
                                type="primary"
                                onClick={() => onChangeImage(0)}
                            />
                        </div>
                    </Form.Item>

                </Col>

                <Col xs={24} sm={6} md={6}>
                    <Form.Item
                        label={lang.background_image_default}
                        name="gift_card_setting_background_image"
                    >
                        <Image
                            src={form.getFieldValue('gift_card_setting_background_image') || logo}
                            width="100%"
                            className='img-cover img-gift-card-setting'
                        />
                        <div className="btn-edit-image">
                            <Button
                                size="middle"
                                icon={<EditOutlined />}
                                key="edit-logo"
                                type="primary"
                                onClick={() => onChangeImage(1)}
                            />
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} md={14}>
                    <Row>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={lang.gift_card_setting_title}
                                name="gift_card_setting_title"
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={lang.gift_card_setting_description}
                                name="gift_card_setting_description"
                            >
                                <TextArea size="large" rows={2} maxLength={50} showCount />
                            </Form.Item>
                        </Col>

                    </Row>
                </Col>
                <Col xs={24} md={24} >
                    <Form.Item
                        label={lang.gift_card_setting_terms_conditions}
                        name="gift_card_setting_terms_conditions"
                    >
                        <TextArea size="large" rows={2} maxLength={150} showCount />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24} className="text-center mt-15">
                    <CheckPermission permission={['GIFT_CARD_SETTING_EDIT']}>
                        <Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                            {lang.save}
                        </Button>
                    </CheckPermission>
                </Col>
            </Row>
        </Form>
    );
}

export default GiftCardSettingGeneral;
