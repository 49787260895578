import { createAsyncThunk } from '@reduxjs/toolkit';
import * as serviceService from './service';

export const serviceList = createAsyncThunk(
  'service/serviceList',
  async (data, thunkAPI) => {
    const response = await serviceService.serviceList(data);
    return response.data;
  }
);
export const serviceInvoiceList = createAsyncThunk(
  'service/serviceInvoiceList',
  async (data, thunkAPI) => {
    const response = await serviceService.serviceInvoiceList(data);
    return response.data;
  }
);
