import CallApi from '../../utils/CallAPI';
import {
    TIME_KEEPING,
    TIME_KEEPING_DETAIL,
    TIME_KEEPING_LIST_ID_REMOVE
} from './constants/endpoint';

export const getTimeKeeping = (data) => {
    return CallApi(TIME_KEEPING, 'GET', data)
}

export const checkinTimeKeeping = (data) => {
    return CallApi(TIME_KEEPING, 'POST', data)
}

export const checkoutTimeKeeping = (data) => {
    return CallApi(TIME_KEEPING, 'PUT', data)
}

export const getTimeKeepingDetail = (data) => {
    return CallApi(TIME_KEEPING_DETAIL, 'GET', data)
}

export const getListIdRemove = (data) => {
    return CallApi(TIME_KEEPING_LIST_ID_REMOVE, 'GET', data)
}
