/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import { Row, Col, List, Typography } from 'antd';
import * as c from '../../../../constants/common';
import { useSelector } from 'react-redux';

import { formatCurrency, formatDate, formatIsActive } from '../../../Common/components/FormatData';
const { Text } = Typography;

const CustomerGroupDetailInfo = (props) => {
    const { dataDetail } = props;
    const generalSetting = useSelector(state => state.generalSetting);
    /// /funtion///////

    /// /render///////
    return (
        <Row >
            <Col xs={24} md={24} lg={24}>
                <List
                    size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(dataDetail)
                            .filter(x =>
                                x !== 'customer_id' &&
                                x !== 'key' &&
                                x !== 'customer_group_id' &&
                                x !== 'branch_id' &&
                                x !== 'discount_type'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date'
                                        ? formatDate(dataDetail[item])
                                        : (item === 'is_active')
                                            ? formatIsActive(dataDetail[item])
                                            : (String(dataDetail[item]).includes('{DELETE}'))
                                                ? <Text delete>{String(dataDetail[item]).replace('{DELETE}', '')}</Text>
                                                : item === 'discount'
                                                    ? (dataDetail.discount_type === c.DISCOUNT_TYPE.PERCENT ? dataDetail.discount + '%' : formatCurrency(dataDetail.discount, generalSetting))
                                                    : dataDetail[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    );
};

export default CustomerGroupDetailInfo;
