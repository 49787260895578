/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setStaffTitleId, setStaffTitleQueryData, setStaffTitleListId } from '../slice';
import * as staffThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import StaffTitleDetail from './StaffTitleDetail';
import StaffTitleActionChecked from './StaffTitleAction/StaffTitleActionChecked';
import StaffTitleFilter from './StaffTitleFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import StaffTitleAction from './StaffTitleAction/StaffTitleAction';
import * as c from '../../../constants/common';

const { Search } = Input;

const StaffTitle = (props) => {
  const dispatch = useDispatch();
  const staffTitleQueryData = useSelector(state => state.staffTitle.staffTitleQueryData);
  const staffTitleListId = useSelector(state => state.staffTitle.staffTitleListId);
  const dataList = useSelector(state => state.staffTitle.staffTitleList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setStaffTitleListId([]));
      dispatch(setStaffTitleId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.service));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(staffTitleQueryData);
  }, [staffTitleQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(staffThunk.staffTitleList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setStaffTitleQueryData({ ...staffTitleQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.title}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_staff_title}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <StaffTitleActionChecked />
                <StaffTitleAction />
                <StaffTitleFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={staffTitleQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={staffTitleListId}
            queryData={staffTitleQueryData}
            keyField="staff_title_id"
            clickField="staff_title_name"
            setListIdChecked={setStaffTitleListId}
            setQueryData={setStaffTitleQueryData}
            expandedRowRender={(record) => <StaffTitleDetail data={record} queryData={staffTitleQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StaffTitle;
