/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import {
    DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import { Button, Col, List, message, Popconfirm, Row, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../language/language';
import CheckPermission from '../../../../modules/Common/components/CheckPermission';
import { setBranchId } from '../../../../modules/Setting/Branch/slice';
import { formatDate, formatIsActive, formatPhone, formatBranchMain } from '../../../Common/components/FormatData';
import * as branchService from '../service';
import * as branchThunk from '../thunk';

const { Text } = Typography;

const BranchDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const branchLocal = JSON.parse(localStorage.getItem('branch')) || null;

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        const branchId = data.branch_id
        if (branchId) {
            if (branchId === branchLocal.branch_id) {
                message.error(lang.error_delete_current_branch);
            } else {
                const dataDelete = {
                    list_id: [branchId],
                    update_user: userInfo.user_id,
                    permission: ['BRANCH_DELETE'],
                    table: 'branch',
                    field_name: 'branch_id'
                };
                branchService.branchDelete(dataDelete).then(res => {
                    if (res) {
                        const { data } = res;
                        if (data.is_delete) {
                            dispatch(branchThunk.branchList(queryData));
                            message.success(lang.delete_success);
                        }
                    }
                })
            }
        }
    };

    const onEdit = (row) => {
        if (data.branch_id) {
            document.getElementById('branchAction').click();
            dispatch(setBranchId(data.branch_id));
        }
    };
    /// /render///////
    return (
        <Row >
            <Col xs={24} md={24} lg={24} className="pl-15">
                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'key' &&
                                x !== 'branch_id' &&
                                x !== 'is_main'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    (
                                        item === 'create_date'
                                    )
                                        ? formatDate(data[item])
                                        : (
                                            item === 'branch_phone_number'
                                        )
                                            ? formatPhone(data[item])
                                            : (item === 'is_active')
                                                ? formatIsActive(data[item])
                                                : (item === 'is_main')
                                                    ? formatBranchMain(data[item])
                                                    : (String(data[item]).includes('{DELETE}'))
                                                        ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                        : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['BRANCH_EDIT']}>
                                {data.branch_id &&
                                    <Button
                                        size="large"
                                        type="primary"
                                        onClick={onEdit}
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['BRANCH_DELETE']}>
                                {data.branch_id && data.is_main === 0 &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default BranchDetail;
