import CallApi from '../../utils/CallAPI';
import {
  INVOICE,
  INVOICE_DETAIL,
  INVOICE_COPY,
  INVOICE_ITEM,
  INVOICE_UPDATE_STATUS,
  INVOICE_CUSTOM_PROMOTION,
  INVOICE_PROMOTION,
  INVOICE_SURCHARGE,
  INVOICE_PRINT_TICKET
} from './constants/endpoint';

export const invoiceList = (data) => {
  return CallApi(INVOICE, 'GET', data)
};

export const invoiceCreateUpdate = (data, method) => {
  return CallApi(INVOICE, method, data)
};

export const invoiceDetail = (data) => {
  return CallApi(INVOICE_DETAIL, 'GET', data)
};

export const invoiceCopy = (data) => {
  return CallApi(INVOICE_COPY, 'POST', data)
};

export const invoiceItemCreateUpdate = (data, method = 'POST') => {
  return CallApi(INVOICE_ITEM, method, data)
};

export const invoiceItemDelete = (data) => {
  return CallApi(INVOICE_ITEM, 'DELETE', data)
};

export const invoiceUpdateStatus = (data) => {
  return CallApi(INVOICE_UPDATE_STATUS, 'PUT', data)
};

export const invoiceCustomPromotionCreate = (data) => {
  return CallApi(INVOICE_CUSTOM_PROMOTION, 'POST', data)
};
export const invoicePromotionDelete = (data) => {
  return CallApi(INVOICE_PROMOTION, 'DELETE', data)
};

export const invoiceSurchargeCreate = (data) => {
  return CallApi(INVOICE_SURCHARGE, 'POST', data)
};
export const invoiceSurchargeDelete = (data) => {
  return CallApi(INVOICE_SURCHARGE, 'DELETE', data)
};

export const invoicePrintTicket = (data) => {
  return CallApi(INVOICE_PRINT_TICKET, 'PUT', data)
};
