import { createAsyncThunk } from '@reduxjs/toolkit';
import * as emailService from './service';

export const emailHistoryList = createAsyncThunk(
  'email/emailHistoryList',
  async (data, thunkAPI) => {
    const response = await emailService.emailHistoryList(data);
    return response.data;
  }
);

export const emailSampleList = createAsyncThunk(
  'email/emailSampleList',
  async (data, thunkAPI) => {
    const response = await emailService.emailSampleList(data);
    return response.data;
  }
);
