/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { getAppointment } from '../service';
import { Form, AutoComplete } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { formatPhone } from './FormatData';
import {
  PhoneOutlined,
  BarcodeOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
const { Option } = AutoComplete;

const AppointmentAutoComplete = (props) => {
  const {
    label,
    name,
    isHideLabel,
    onSelect,
    onClear,
    style,
    required,
    allowClear,
    showSearch,
    size,
    onChange,
    className
  } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getAppointment().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const onSelected = (value, option) => {
    if (onSelect) {
      onSelect(option.data)
    }
  }

  const onChangeData = (value) => {
    if (onChange) {
      onChange(value)
    }
  }

  /// render/////
  return (
    <Form.Item
      label={!isHideLabel ? (label || lang.appointment) : ''}
      name={name || 'appointment'}
      style={style}
      rules={[
        { required: required || false, message: lang.value_is_required },
        { pattern: /^[0-9]*$/, message: lang.not_format },
        { min: 10, message: lang.phone_min_max },
        { max: 11, message: lang.phone_min_max }
      ]}
      className="appointment-auto-search"
    >
      <AutoComplete
        style={{ minWidth: 400 }}
        size={size || 'large'}
        showSearch={showSearch || false}
        allowClear={allowClear || false}
        onClear={onClear || null}
        onSelect={onSelected || null}
        maxTagCount='responsive'
        placeholder={label || lang.customer}
        onChange={onChangeData}
        className={className}
        filterOption={(input, option) => {
          const data = option.data.customer_phone_number
          return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {options.filter(e => e.customer_phone_number !== '').map((item) => {
          return <Option key={item.appointment_id} value={item.customer_phone_number} data={item}>
            <div className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}><PhoneOutlined /> {formatPhone(item.customer_phone_number)} - <BarcodeOutlined /> {item.appointment_code} - <ClockCircleOutlined /> {moment(item.appointment_hour, 'hh:mm A').format('LT')}</div>
          </Option>
        })}
      </AutoComplete>
    </Form.Item>
  )
};

export default AppointmentAutoComplete;
