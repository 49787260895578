/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined, PlusOutlined,
  SendOutlined
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, message, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState, useEffect } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../constants/common';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import { setLoading } from '../../../Common/slice';
import * as smsHistoryService from '../../service';
import { clearSMSHistoryId, setSMSCustomerListId } from '../../slice';
import * as smsHistoryThunk from '../../thunk';
import CustomerSelect from '../../../Common/components/CustomerSelect'
import SMSSampleSelect from '../../../Common/components/SMSSampleSelect';
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';

const SMSHistoryAction = (props) => {
  const { isHide } = props
  const generalSetting = useSelector(state => state.generalSetting);
  const queryData = useSelector(state => state.sms.smsHistoryQueryData);
  const smsCustomerListId = useSelector(state => state.sms.smsCustomerListId);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const smsHistoryId = useSelector(state => state.sms.smsHistoryId);
  const [actionType, setActionType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  // const branchLocal = JSON.parse(localStorage.getItem('branch'));

  useEffect(() => {
    if (smsCustomerListId.length > 0) {
      form.setFieldsValue({ customer: smsCustomerListId })
    }
  }, [smsCustomerListId, form])
  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const dataReq = {
      listCustomerId: data.customer,
      general_setting_country: generalSetting.general_setting_country,
      sms_history_content: data.sms_history_content,
      create_user: userInfo.user_id
    };
    smsHistoryService.smsHistoryCreate(dataReq).then(res => {
      if (res) {
        const { data } = res;
        // luu thanh cong
        if (data.sms_history_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!smsHistoryId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(smsHistoryThunk.smsHistoryList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    dispatch(clearSMSHistoryId(''));
    form.resetFields();
    setSMSCustomerListId([])
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onSelectSMSSample = (data) => {
    form.setFieldsValue({ sms_history_content: data.sms_sample_content })
  }

  /// render/////
  return (
    <span className={`${isHide ? 'd-none' : ''}`}>
      <>
        <CheckPermission permission={['SMS_HISTORY_ADD']}>
          <Button type="primary" size="large" id="smsHistoryAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Button className='d-none' type="primary" size="large" id="smsHistoryAction" onClick={showModal} icon={<PlusOutlined />} />
        <Drawer
          title={lang.sms_create}
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SendOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.send}
              </Button>
              <Button size="large" icon={<SendOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.send_and_close}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <CustomerSelect
                  label={lang.sms_history_to}
                  isMultiple
                  allowClear
                  showSearch
                  showSelectAll
                  required
                />
              </Col>
              <Col xs={24} md={24}>
                <SMSSampleSelect
                  onSelect={onSelectSMSSample}
                />
                <Form.Item
                  label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_CUSTOMER} /></div>}
                  name="sms_history_content"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default SMSHistoryAction;
