import React, { useEffect } from 'react';
// import { useSelector } from 'react-redux';
import HeaderRightAccount from './HeaderRightAccount';
import { Row, Col, Space, Button } from 'antd';
import HeaderRightNotification from './HeaderRightNotification';
import HeaderRightLanguage from './HeaderRightLanguage';
import HeaderRightBranch from './HeaderRightBranch';
import lang from './../../../../language/language';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import CheckPermission from '../../../../modules/Common/components/CheckPermission';
import { ROUTE_POS } from '../../../../modules/Invoice/constants/routes';

const HeaderRight = (props) => {
	// const userInfo = useSelector(state => state.user.userInfo)
	const language = localStorage.getItem('language');
	const history = useHistory();
	/// ///// useEffect
	useEffect(() => {
		if (!language) {
			localStorage.setItem('language', 'vi');
		}
	}, []);
	/// //funtion/////

	// const onLogout = () => {
	//   localStorage.clear();
	//   window.location.href = '/';
	// };

	return (
		<Row>
			<Col span={12}>
				<Row type="flex" justify="end">
					<Col>
						<Space size="middle">
							<CheckPermission permission={['INVOICE_ADD_EDIT']}>
								<Button size="middle" onClick={() => history.push(ROUTE_POS)} icon={<DollarCircleOutlined />}>
									{lang.pos}
								</Button>
							</CheckPermission>
							<HeaderRightBranch />
							<HeaderRightLanguage />
							{/* <HeaderRightNotification /> */}
							<HeaderRightAccount />
						</Space>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default HeaderRight;
