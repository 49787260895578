/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined, PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Checkbox, Col, Drawer, Form, Input, message, Row, Space, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../constants/common';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import { RandomCode } from '../../../Common/components/RandomCode';
import { setLoading } from '../../../Common/slice';
import * as staffService from '../../service';
import { clearStaffTypeId } from '../../slice';
import * as staffThunk from '../../thunk';

const StaffTypeAction = (props) => {
  const queryData = useSelector(state => state.staffType.staffTypeQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color)s;
  const userInfo = useSelector(state => state.user.userInfo);
  const staffTypeId = useSelector(state => state.staffType.staffTypeId);
  const [actionType, setActionType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const branchLocal = JSON.parse(localStorage.getItem('branch'));

  /// //////////////////////// Validate /////////////////////////

  useEffect(() => {
    if (staffTypeId) {
      dispatch(setLoading(true));
      staffService.staffTypeDetail({ staff_type_id: staffTypeId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            staff_type_name: data.staff_type_name,
            staff_type_note: data.staff_type_note,
            is_active: data.is_active === c.IS_ACTIVE,
            is_turn_priority: data.is_turn_priority === 1
          });
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [staffTypeId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!staffTypeId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ staff_type_code: RandomCode(), is_active: true });
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = staffTypeId ? 'PUT' : 'POST';
    const dataReq = {
      staff_type_id: staffTypeId || '',
      staff_type_note: data.staff_type_note || '',
      staff_type_name: data.staff_type_name,
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      is_turn_priority: data.is_turn_priority ? 1 : 0
    };
    staffService.staffTypeCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.staff_type_name) {
          message.error(lang.staff_type_name + lang.exist);
        }
        // luu thanh cong
        if (data.staff_type_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!staffTypeId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
            // document.getElementById('closeServiceGroupAction').click();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(staffThunk.staffTypeList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    // genCode();
    dispatch(clearStaffTypeId(''));
    form.resetFields();
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <>
        <CheckPermission permission={['STAFF_TYPE_ADD']}>
          <Button type="primary" size="large" id="staffTypeAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!staffTypeId
            ? lang.add + lang.staff_type.toLowerCase()
            : lang.edit + lang.staff_type.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.SMALL}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.staff_type_name}
                  name="staff_type_name"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.staff_type_note}
                  name="staff_type_note"
                  rules={[
                    { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                  ]}
                >
                  <Input.TextArea size="large" allowClear rows={1} />
                </Form.Item>
              </Col>
              <Col xs={24} md={16} >
                <Form.Item
                  name="is_turn_priority"
                  valuePropName="checked"
                >
                  <Checkbox> {lang.is_turn_priority}</Checkbox>
                </Form.Item>
              </Col>
              <Col xs={24} md={8} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default StaffTypeAction;
