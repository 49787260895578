/* eslint-disable no-unused-expressions */
/* eslint-disable no-sequences */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import lang from '../../../../../language/language';
// import { setUserBranchRole } from '../../slice';
// import AlertValidate from '../../../../Common/components/AlertValidate';
// import CustomSelect from '../../../../../modules/Common/components/CustomSelect';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Button, Space, Row, Col, Select } from 'antd';
const { Option } = Select;

const UserActionBranchRole = (props) => {
    // const { userBranchRoleList } = props
    // const dispatch = useDispatch();
    const branchByUser = useSelector(state => state.branch.branchByUser)
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const roleListAll = useSelector(state => state.role.roleListAll);
    // const branchListAll = useSelector(state => state.branch.branchListAll);
    // const dataBranchRole = useSelector(state => state.user.userBranchRole);
    const [dataSelectBranch, setDataSelectBranch] = useState([]);
    const [branchByUserTemp, setBranchByUserTemp] = useState([]);

    const onSelectBranch = (value, key) => {
        const data = [...dataSelectBranch];
        data.push({ value: value, key })
        setDataSelectBranch(data)
    }

    const onAdd = () => {
        const data = [...branchByUserTemp];
        data.push(branchByUser.filter((i) => !dataSelectBranch.some(x => x.value && x.value === i.branch_id)));
        setBranchByUserTemp(data);
    }

    const onRemove = (key) => {
        const data = [...dataSelectBranch];
        data.filter((i) => i.key !== key)
        setDataSelectBranch(data)
    }

    /// render/////
    return (
        <Row gutter={24}>
            <Form.List
                name="user_branch_role_list">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <Col key={key} xs={24} md={24}>
                                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'branch']}
                                        rules={[
                                            { required: true, message: lang.value_is_required }
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            showSearch
                                            style={{ width: 320 }}
                                            maxTagCount='responsive'
                                            placeholder={lang.branch}
                                            onChange={(value) => onSelectBranch(value, index)}
                                            options={branchByUser.length > 0
                                                ? branchByUser.map((item) => {
                                                    return { value: item.branch_id, label: item.branch_name }
                                                })
                                                : []}
                                        >

                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'role']}
                                        rules={[
                                            { required: true, message: lang.value_is_required }
                                        ]}
                                    >
                                        <Select
                                            size="large"
                                            style={{ width: 320 }}
                                            showSearch
                                            maxTagCount='responsive'
                                            placeholder={lang.role}
                                        >
                                            {
                                                roleListAll.length > 0
                                                    ? roleListAll.map((item) => {
                                                        return <Option key={item.role_id} value={item.role_id}>{item.role_name}</Option>
                                                    })
                                                    : []
                                            }
                                        </Select>
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => { onRemove(index), remove(name) }} />
                                </Space>
                            </Col>
                        ))}
                        {fields.length < branchByUser.length && <Col xs={24} md={24}>
                            <Form.Item>
                                <Button size="large" onClick={() => onAdd(add())} icon={<PlusOutlined />}>
                                    {lang.add}
                                </Button>
                            </Form.Item>
                        </Col>}
                    </>
                )}
            </Form.List>
        </Row>
    );
};

export default UserActionBranchRole;
