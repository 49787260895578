/* eslint-disable array-callback-return */
import React from 'react';
import lang from '../../../../language/language';
import { Row, Col, Form, Input, Checkbox } from 'antd';

const EmailSettingCustom = (props) => {
    return (
        <Row gutter={24} >
            <Col xs={24} md={8}>
                <Form.Item
                    label={lang.email_setting_from}
                    name="email_setting_from"
                    rules={[
                        { required: true, message: lang.value_is_required }
                    ]}
                >
                    <Input placeholder='Name <email>' size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item
                    label={lang.email_setting_smtp_server}
                    name="email_setting_smtp_server"
                    rules={[
                        { required: true, message: lang.value_is_required },
                        {
                            pattern: /^[A-Za-z0-9._]*$/,
                            message: lang.not_allow_special_characters
                        }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item
                    label={lang.email_setting_port}
                    name="email_setting_port"
                    rules={[
                        { required: true, message: lang.value_is_required },
                        {
                            pattern: /^[0-9]*$/,
                            message: lang.not_allow_special_characters
                        }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item
                    label={lang.email_setting_user}
                    name="email_setting_user"
                    rules={[
                        { required: true, message: lang.value_is_required },
                        {
                            pattern: /^[A-Za-z0-9._@]*$/,
                            message: lang.not_allow_special_characters
                        }
                    ]}
                >
                    <Input size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item
                    label={lang.email_setting_password}
                    name="email_setting_password"
                    rules={[
                        { required: true, message: lang.value_is_required }
                    ]}
                >
                    <Input type="password" size="large" />
                </Form.Item>
            </Col>
            <Col xs={24} md={8}>
                <Form.Item
                    label=" "
                    className="mt-5"
                    name="email_setting_secure"
                    valuePropName="checked"
                >
                    <Checkbox
                        defaultChecked={true}
                        size="large"
                    >{lang.email_setting_secure}</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default EmailSettingCustom;
