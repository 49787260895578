/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../language/language';
import * as settingService from '../service';
import { setLoading } from '../../../Common/slice';
import { userDetail } from '../../User/thunk';

const GeneralSettingLeftMenuColor = (props) => {
  const { isQuickSetting } = props
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.userInfo);
  const color = useSelector(state => state.user.userInfo.theme_color);

  /// ///// useEffect

  /// //funtion/////

  const themeColor = [
    { colorClass: 'dark' },
    { colorClass: 'light' },
    { colorClass: color }
  ];

  const updateLeftMenuColor = (colorName) => {
    dispatch(setLoading(true))
    settingService.userUpdateLeftMenuColor({ left_menu_color: colorName, user_id: userInfo.user_id }).then(res => {
      if (res) {
        const { user_id } = res.data;
        if (user_id > 0) {
          dispatch(userDetail({ user_id }));
          dispatch(setLoading(false))
        }
      }
    });
  };

  /// /render
  return (
    <div className="row mt-3 border-bottom">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <h6>{lang.left_menu_color}</h6>
      </div>
      {themeColor.map((item, index) => {
        return (
          <div key={index} className={`${isQuickSetting ? 'col-xs-2 col-sm-2 col-mobile-2 col-md-2 col-lg-2 mb-3' : 'col-xs-1 col-sm-1 col-mobile-1 col-md-1 col-lg-1 mb-3'}`}>
            <button
              type="button"
              className={`btn border border-1 navbar-${item.colorClass} w-100`}
              style={{ height: isQuickSetting ? 30 : 50 }}
              onClick={() => updateLeftMenuColor(item.colorClass)}
            ></button>
          </div>
        );
      })}
    </div>
  );
};

export default GeneralSettingLeftMenuColor;
