/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Popover, Row, Space } from 'antd';
import {
    PlusOutlined,
    CloseCircleOutlined,
    SaveOutlined
} from '@ant-design/icons';
import lang from '../../../language/language';
import { setLoading } from '../../Common/slice';
import { useDispatch, useSelector } from 'react-redux';
import * as giftCardSettingService from '../service';
import { giftCardSettingBackgroundAlbumThunk } from '../thunk';
import * as c from '../../../constants/common';

const GiftCardAlbumAction = (props) => {
    const { data } = props
    const [form] = Form.useForm();
    const [isVisible, setIsvisible] = useState(false)
    const [actionType, setActionType] = useState('');
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const albumId = data ? data.gift_card_background_album_id : ''

    useEffect(() => {
        if (data) {
            form.setFieldsValue({ gift_card_background_album_name: data.gift_card_background_album_name })
        }
    }, [data])

    /// function
    const onSubmit = (data) => {
        dispatch(setLoading(true));
        const method = albumId ? 'PUT' : 'POST';
        const dataReq = {
            gift_card_background_album_id: albumId || '',
            gift_card_background_album_name: data.gift_card_background_album_name,
            create_user: userInfo.user_id
        };
        giftCardSettingService.giftCardBackgroundAlbum(dataReq, method).then(res => {
            if (res) {
                const { data } = res;
                dispatch(setLoading(false));
                // check exist
                if (data.gift_card_background_album_name) {
                    message.error(lang.album_name + lang.exist);
                }
                // luu thanh cong
                if (data.gift_card_background_album_id > 0) {
                    if (actionType === c.SAVE) {
                        message.success(lang.save_success);
                        setActionType('')
                        if (!albumId) {
                            resetData();
                        }
                    } else {
                        message.success(lang.save_success);
                        onClose();
                    }
                }
            }
        }).finally(() => {
            dispatch(setLoading(false));
            dispatch(giftCardSettingBackgroundAlbumThunk({}));
        })
    };

    const onSave = (type) => {
        setActionType(type);
        form.submit()
    }

    const onClose = () => {
        resetData()
    }

    const resetData = () => {
        if (!albumId) {
            form.resetFields()
        }
        setIsvisible(false)
    }

    /// render
    return (
        <Popover
            placement="topLeft"
            trigger="click"
            style={{ width: '100%' }}
            overlayClassName="popover-small"
            visible={isVisible}
            content={
                <Form
                    onFinish={onSubmit}
                    autoComplete="off"
                    form={form}
                    layout="horizontal"
                >
                    <Row>
                        <Col xs={24} md={24} className="border-bottom-1">
                            <h3>{!albumId
                                ? lang.add + lang.album.toLowerCase()
                                : lang.edit + lang.album.toLowerCase()
                            }</h3>
                        </Col>
                        <Col xs={24} md={24} className="pb-10 pt-15">
                            <Form.Item
                                label={lang.album_name}
                                name="gift_card_background_album_name"
                                rules={[
                                    { required: true, message: lang.value_is_required }
                                ]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24} className="text-right border-top-1 pt-15">
                            <Space>
                                <Button
                                    className="w-100"
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={onClose}
                                ><CloseCircleOutlined /> {lang.close}
                                </Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => onSave(c.SAVE)}
                                ><SaveOutlined /> {lang.save}</Button>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => onSave(c.SAVE_CLOSE)}
                                ><SaveOutlined /> {lang.save_and_close}</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
        >
            {albumId ? <a onClick={() => setIsvisible(true)} >{lang.edit} </a> : <Button onClick={() => setIsvisible(true)} size="middle" className="mr-15" icon={<PlusOutlined />} >{lang.add_album}</Button>}
        </Popover>
    );
};

export default GiftCardAlbumAction;
