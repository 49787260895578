/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import { Image } from 'antd';
import {
    PhoneOutlined,
    MailOutlined
} from '@ant-design/icons';
import CustomerDetail from '../../../../Customer/components/CustomerDetail/CustomerDetail'
// import lang from '../../../../language/language';
import { formatPhone } from '../../../../Common/components/FormatData';
// const { Text } = Typography;
import noImage from './../../../../../assets/images/default-user.png'

const AppointmentActionCustomer = (props) => {
    const { data } = props;

    /// /funtion///////

    /// /render///////
    return (
        <div>
            <div className="text-center border-bottom-1 pb-15">
                <Image
                    style={{ width: 60, height: 60 }}
                    className="img-avatar"
                    preview={false}
                    src={(data && data.customer_avatar) || noImage}
                />
                <h3>{data.customer_fullname}</h3>
                {data.customer_phone_number && <div><PhoneOutlined /> {formatPhone(data.customer_phone_number)}</div>}
                {data.customer_email && <div><MailOutlined /> {data.customer_email}</div>}
            </div>
            <CustomerDetail data={data} isView />
        </div>
    );
};

export default AppointmentActionCustomer;
