/* eslint-disable no-new */
import React from 'react';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
// import { setDiscountType } from '../../slice';
import { Row, Col, Form, Input, Switch, Select } from 'antd';
const { Option } = Select;

const CustomerGroupActionInfo = (props) => {
  // const discountType = useSelector(state => state.customerGroup.discountType);

  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  /// render/////
  return (

    <Row gutter={24}>
      <Col xs={24} md={10}>
        <Form.Item
          label={lang.customer_group_name}
          name="customer_group_name"
          rules={[
            { required: true, message: lang.value_is_required },
            {
              pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
              message: lang.not_allow_special_characters
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} md={9}>
        <Form.Item
          label={lang.discount}
          name="discount"
          rules={[
            { required: true, message: lang.value_is_required },
            { pattern: /^[0-9]*$/, message: lang.not_format }
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} md={5}>
        <Form.Item
          label={lang.discount_type}
          name="discount_type"
        >
          <Select size="large" defaultValue={c.DISCOUNT_TYPE.PERCENT}>
            <Option value={c.DISCOUNT_TYPE.PERCENT}>%</Option>
            <Option value={c.DISCOUNT_TYPE.MONEY}>$</Option>
          </Select>
        </Form.Item>
      </Col>

      <Col xs={24} md={24}>
        <Form.Item
          label={lang.customer_group_note}
          name="customer_group_note"
        >
          <Input.TextArea size="large" allowClear rows={3} />
        </Form.Item>
      </Col>
      <Col xs={24} md={24} className="text-right">
        <Form.Item
          name="is_active"
          valuePropName="checked"
        >
          <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CustomerGroupActionInfo;
