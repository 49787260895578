import CallApi from '../../utils/CallAPI';
import {
  CUSTOMER,
  CUSTOMER_DETAIL,
  CUSTOMER_EXPORT,
  CUSTOMER_IMPORT,
  CUSTOMER_APPOINTMENT_HISTORY,
  CUSTOMER_REWARD_POINT_HISTORY,
  CUSTOMER_SPENT_HISTORY,
  CUSTOMER_CHECKIN_HISTORY
} from './constants/endpoint';

export const customerList = (data) => {
  return CallApi(CUSTOMER, 'GET', data)
};

export const customerCreateUpdate = (data, method) => {
  return CallApi(CUSTOMER, method, data)
};

export const customerDetail = (data) => {
  return CallApi(CUSTOMER_DETAIL, 'GET', data)
};

export const customerExport = (data) => {
  return CallApi(CUSTOMER_EXPORT, 'POST', data)
};

export const customerImport = (data) => {
  return CallApi(CUSTOMER_IMPORT, 'POST', data)
};

export const customerAppointmentHistoryList = (data) => {
  return CallApi(CUSTOMER_APPOINTMENT_HISTORY, 'GET', data)
};

export const customerRewardPointHistoryList = (data) => {
  return CallApi(CUSTOMER_REWARD_POINT_HISTORY, 'GET', data)
};

export const customerSpentHistoryList = (data) => {
  return CallApi(CUSTOMER_SPENT_HISTORY, 'GET', data)
};

export const customerCheckinHistoryList = (data) => {
  return CallApi(CUSTOMER_CHECKIN_HISTORY, 'GET', data)
};
