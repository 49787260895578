/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setServiceGroupId, setServiceGroupQueryData, setServiceGroupListId } from '../slice';
import * as serviceGroupThunk from '../thunk';
// import ServiceFilter from './ServiceGroupFilter';
// import ServiceGroupAction from './ServiceGroupAction/ServiceGroupAction';
// import ServiceActionChecked from './ServiceGroupAction/ServiceGroupActionChecked';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import ServiceGroupDetail from './ServiceGroupDetail';
import ServiceGroupActionChecked from './ServiceGroupAction/ServiceGroupActionChecked';
import ServiceGroupFilter from './ServiceGroupFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../../modules/Common/slice';
import AntdTable from './../../Common/components/AntdTable/AntdTable';
import ServiceGroupAction from './ServiceGroupAction/ServiceGroupAction';
import * as c from '../../../constants/common';

const { Search } = Input;

const ServiceGroup = (props) => {
  const dispatch = useDispatch();
  const serviceGroupQueryData = useSelector(state => state.serviceGroup.serviceGroupQueryData);
  const serviceGroupListId = useSelector(state => state.serviceGroup.serviceGroupListId);
  const dataList = useSelector(state => state.serviceGroup.serviceGroupList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setServiceGroupListId([]));
      dispatch(setServiceGroupId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.service));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(serviceGroupQueryData);
  }, [serviceGroupQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(serviceGroupThunk.serviceGroupList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setServiceGroupQueryData({ ...serviceGroupQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.service_group}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_service_group}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <ServiceGroupActionChecked />
                <ServiceGroupAction />
                <ServiceGroupFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={serviceGroupQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={serviceGroupListId}
            queryData={serviceGroupQueryData}
            keyField="service_group_id"
            clickField="service_group_code"
            setListIdChecked={setServiceGroupListId}
            setQueryData={setServiceGroupQueryData}
            expandedRowRender={(record) => <ServiceGroupDetail data={record} queryData={serviceGroupQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ServiceGroup;
