import { createSlice } from '@reduxjs/toolkit';
import * as posSettingThunk from './thunk';

export const posSettingSlice = createSlice({
    name: 'posSetting',
    initialState: {

    },
    reducers: {

    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [posSettingThunk.posSettingThunk.fulfilled]: (state, action) => {
            return action.payload;
        }
    }
});

export default posSettingSlice.reducer;
