/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Col, Form, Row, Input, Button, Checkbox, message } from 'antd';
import lang from '../../../language/language';
import {
    SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../Common/components/CheckPermission';
import TinyEditor from './../../Common/components/TinyEditor';
import * as c from '../../../constants/common';
import ToolTipShortCode from '../../Common/components/ToolTipShortCode';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Common/slice';
import * as giftCardSettingService from './../service';
import { giftCardSettingThunk } from '../thunk';

const GiftCardSettingEmail = (props) => {
    const [form] = Form.useForm();
    const giftCardSettingData = useSelector(state => state.giftCardSetting.giftCardSettingData);
    const dispatch = useDispatch();

    useEffect(() => {
        form.setFieldsValue({
            gift_card_setting_allow_send_from_email: giftCardSettingData.gift_card_setting_allow_send_from_email === c.IS_ACTIVE,
            gift_card_setting_allow_send_to_email: giftCardSettingData.gift_card_setting_allow_send_to_email === c.IS_ACTIVE,
            gift_card_setting_from_email_content: giftCardSettingData.gift_card_setting_from_email_content || lang.gift_card_setting_from_email_content,
            gift_card_setting_to_email_content: giftCardSettingData.gift_card_setting_to_email_content || lang.gift_card_setting_to_email_content,
            gift_card_setting_from_email_title: giftCardSettingData.gift_card_setting_from_email_title || lang.gift_card_setting_from_email_title,
            gift_card_setting_to_email_title: giftCardSettingData.gift_card_setting_to_email_title || lang.gift_card_setting_to_email_title
        });
    }, [giftCardSettingData])

    const onSubmit = (data) => {
        dispatch(setLoading(true));
        const method = giftCardSettingData.gift_card_setting_id ? 'PUT' : 'POST';
        const dataReq = {
            ...giftCardSettingData,
            gift_card_setting_allow_send_from_email: data.gift_card_setting_allow_send_from_email === true ? c.IS_ACTIVE : c.IS_DEACTIVE,
            gift_card_setting_allow_send_to_email: data.gift_card_setting_allow_send_to_email === true ? c.IS_ACTIVE : c.IS_DEACTIVE,
            gift_card_setting_from_email_content: data.gift_card_setting_from_email_content || '',
            gift_card_setting_to_email_content: data.gift_card_setting_to_email_content || '',
            gift_card_setting_from_email_title: data.gift_card_setting_from_email_title || '',
            gift_card_setting_to_email_title: data.gift_card_setting_to_email_title || '',
            gift_card_setting_logo: giftCardSettingData.gift_card_setting_logo_image_name,
            gift_card_setting_background_image: giftCardSettingData.gift_card_setting_background_image_name
        };
        giftCardSettingService.giftCardSettingCreateUpdate(dataReq, method).then(res => {
            if (res) {
                dispatch(setLoading(false));
                message.success(lang.save_success);
            }
        }).finally(() => {
            dispatch(setLoading(false));
            dispatch(giftCardSettingThunk({}));
        })
    }

    return (
        <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
        >
            <Row gutter={24} className="mb-15">
                <Col xs={24} md={12}>
                    <div className="shadow-hide">
                        <Form.Item
                            name="gift_card_setting_allow_send_from_email"
                            valuePropName="checked"
                        >
                            <Checkbox className="f-16">{lang.gift_card_setting_allow_send_from_email}</Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={<div className='cursor-pointer'>{lang.title_label} <ToolTipShortCode data={c.SHORT_CODE_GIFT_CARD} /></div>}
                            name="gift_card_setting_from_email_title"
                            rules={[
                                { required: true, message: lang.value_is_required }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={[...c.SHORT_CODE_GIFT_CARD, { value: giftCardSettingData.gift_card_setting_logo, label: lang.logo_url }]} /></div>}
                            name="gift_card_setting_from_email_content"
                            rules={[
                                { required: true, message: lang.value_is_required }
                            ]}
                        >
                            <TinyEditor toolbar={false} />
                        </Form.Item>
                    </div>
                </Col>
                <Col xs={24} md={12}>
                    <div className="shadow-hide">
                        <Form.Item
                            name="gift_card_setting_allow_send_to_email"
                            valuePropName="checked"
                        >
                            <Checkbox className="f-16">{lang.gift_card_setting_allow_send_to_email}</Checkbox>
                        </Form.Item>
                        <Form.Item
                            label={<div className='cursor-pointer'>{lang.title_label} <ToolTipShortCode data={c.SHORT_CODE_GIFT_CARD} /></div>}
                            name="gift_card_setting_to_email_title"
                            rules={[
                                { required: true, message: lang.value_is_required }
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={[...c.SHORT_CODE_GIFT_CARD, {
                                value: giftCardSettingData.gift_card_setting_logo,
                                label: lang.logo_url
                            }]} /></div>}
                            name="gift_card_setting_to_email_content"
                            rules={[
                                { required: true, message: lang.value_is_required }
                            ]}
                        >
                            <TinyEditor toolbar={false} />
                        </Form.Item>
                    </div>
                </Col>
                <Col xs={24} md={24} className="text-center mt-15">
                    <CheckPermission permission={['GIFT_CARD_SETTING_EDIT']}>
                        <Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                            {lang.save}
                        </Button>
                    </CheckPermission>
                </Col>
            </Row>
        </Form>
    );
};

export default GiftCardSettingEmail;
