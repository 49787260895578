/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Space, Button, Row, Col, List, Avatar, Input, Spin, Collapse, Radio, message } from 'antd';
import {
    CloseCircleOutlined,
    BarcodeOutlined,
    PlusOutlined,
    DollarCircleOutlined,
    ClockCircleOutlined
} from '@ant-design/icons';
import { getStaff, getServiceTree } from '../../../Common/service';
import noImage from '../../../../assets/images/default-user.png';
import { formatCurrency } from '../../../Common/components/FormatData';
import * as c from '../../../../constants/common';
import { setServiceStaffSelect } from '../slice';
import { useSelector, useDispatch } from 'react-redux';

const { Panel } = Collapse;

const { Search } = Input;

const AppointmentSelectServiceStaff = (props) => {
    const { listStaffRemove } = props
    const dispatch = useDispatch();
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const [staffList, setStaffList] = useState([])
    const [serviceListTree, setServiceListTree] = useState([])
    const [searchStaff, setSearchStaff] = useState('')
    const [searchService, setSearchService] = useState('')
    const [loadingService, setLoadingService] = useState(false)
    const [loadingStaff, setLoadingStaff] = useState(false)
    const [onlyActiveServiceGroup, setOnlyActiveServiceGroup] = useState('')
    const [serviceIdSelected, setServiceIdSelected] = useState('')
    const [dataSelectService, setDataSelectService] = useState({})
    const [serviceNameSelected, setServiceNameSelected] = useState('')
    const [staffNameSelected, setStaffNameSelected] = useState('')
    const [staffIdSelected, setStaffIdSelected] = useState('')
    const serviceStaffSelect = useSelector(state => state.appointment.serviceStaffSelect);
    const generalSetting = useSelector(state => state.generalSetting);

    const currentBranch = JSON.parse(localStorage.getItem('branch'))

    useEffect(() => {
        getServiceListTree()
    }, []);

    useEffect(() => {
        if (serviceIdSelected) {
            getStaffList(serviceIdSelected)
        }
    }, [serviceIdSelected]);

    const getStaffList = (serviceId = '') => {
        const reqData = {
            listBranchId: [currentBranch.branch_id],
            search: searchStaff,
            service_id: serviceId
        }
        getStaff(reqData).then(res => {
            if (res.data) {
                const newData = res.data.filter(e => e.is_active === 1 && e.is_technician === c.STAFF_TYPE.TECHNICIAN && e.staff_allow_booking === 1)
                setStaffList(newData)
            }
        }).finally(res => {
            setLoadingStaff(false)
        })
    }

    const getServiceListTree = (staffId = '') => {
        setLoadingService(true)
        const reqData = { staff_id: staffId, search: searchService }
        getServiceTree(reqData).then(res => {
            if (res.data) {
                setServiceListTree(res.data)
            }
        }).finally(res => {
            setLoadingService(false)
        })
    }
    /// ///function////

    const resetData = () => {
        // cho phep edit
        setStaffIdSelected('');
        setServiceIdSelected('');
        setServiceNameSelected('');
        setStaffList([]);
        setSearchStaff('');
        setStaffNameSelected('');
        document.getElementById('btn-close-select-service-staff').click()
    };

    const onSearchStaff = () => {
        if (serviceIdSelected) {
            setLoadingStaff(true)
            getStaffList(serviceIdSelected)
        }
    }

    const onSearchService = () => {
        setLoadingService(true)
        getServiceListTree(serviceIdSelected)
    }

    const onChangeService = (e) => {
        setLoadingStaff(true)
        setServiceIdSelected(e.target.value)
        setServiceNameSelected(e.target.label)
        setDataSelectService(e.target.data)
    }

    const onChangeStaff = (e) => {
        setStaffIdSelected(e.target.value)
        setStaffNameSelected(e.target.label)
    }

    const onSave = (type) => {
        const result = [...serviceStaffSelect]
        if (serviceIdSelected === '') {
            message.error(lang.service_is_required)
            return;
        }
        if (staffIdSelected === '') {
            message.error(lang.staff_is_required)
            return;
        }
        if (!result.some(e => e.service_id === serviceIdSelected && e.staff_id === staffIdSelected)) {
            result.push({
                key: (Math.random() + 1).toString(36).substring(1),
                service_id: serviceIdSelected,
                service_name: serviceNameSelected,
                staff_id: staffIdSelected,
                staff_name: staffNameSelected,
                service_price: dataSelectService.service_price,
                service_duration: dataSelectService.service_duration,
                service_price_discount: dataSelectService.service_price_discount
            })
            dispatch(setServiceStaffSelect(result))
            if (type === c.SAVE_CLOSE) {
                document.getElementById('close-service-staff-select').click()
                resetData()
            }
        } else {
            message.error(lang.service_staff_exist)
        }
    }

    /// render/////
    return (
        <Row gutter={12}>

            <Col
                style={{ minHeight: 600, maxHeight: 600 }}
                className=" pr-15" xs={14} md={14}>
                <h3>{lang.service}</h3>
                <Search
                    size="large"
                    className="mb-15"
                    placeholder={lang.search_service}
                    enterButton
                    onSearch={onSearchService}
                    value={searchService}
                    onChange={(e) => setSearchService(e.target.value)}
                />
                {loadingService
                    ? <div style={{ minHeight: '50vh' }} className="loading-container">
                        <Spin />
                    </div>
                    : <div
                        style={{
                            minHeight: 500, overflowX: 'auto', maxHeight: 500, padding: 5
                        }}
                    >
                        <Radio.Group value={serviceIdSelected} onChange={onChangeService} name="radService" style={{ width: '100%' }}>
                            <Collapse
                                bordered={false}
                                defaultActiveKey={onlyActiveServiceGroup}
                            >

                                {serviceListTree.map((item) => {
                                    return (
                                        <Panel onClick={() => setOnlyActiveServiceGroup(item.value)} header={<span className="f-16">{item.label}</span>} key={item.value}>
                                            <List
                                                itemLayout="horizontal"
                                                className="ml-5"
                                                dataSource={item.children}
                                                renderItem={x => {
                                                    return (
                                                        <List.Item
                                                        >
                                                            <Space direction="vertical">
                                                                <Radio
                                                                    value={x.value}
                                                                    label={x.label}
                                                                    data={x}
                                                                >{x.label}</Radio>
                                                            </Space>
                                                            <div className="f-13">
                                                                <DollarCircleOutlined /> <span className={`${x.service_price_discount && 'line-through limiter'}`}>{formatCurrency(x.service_price, generalSetting)}</span> {x.service_price_discount && <span>{formatCurrency(x.service_price_discount, generalSetting)}</span>} -  <ClockCircleOutlined /> {x.service_duration} {lang.minute}</div>
                                                        </List.Item>
                                                    )
                                                }}
                                            />
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        </Radio.Group>
                    </div>
                }
            </Col>
            <Col
                style={{ minHeight: 600, maxHeight: 600 }}
                className="pl-15 border-left-1" xs={10} md={10}>
                <h3>{lang.staff} {serviceNameSelected}</h3>
                <Search
                    size="large"
                    placeholder={lang.search_staff_code_name}
                    enterButton
                    onSearch={onSearchStaff}
                    value={searchStaff}
                    onChange={(e) => setSearchStaff(e.target.value)}
                />
                {loadingStaff
                    ? <div style={{ minHeight: '50vh' }} className="loading-container">
                        <Spin />
                    </div>
                    : <Radio.Group value={staffIdSelected} onChange={onChangeStaff} name="radStaff" style={{ width: '100%' }}>
                        <List
                            itemLayout="horizontal"
                            className="mt-15"
                            dataSource={staffList.filter(x => !listStaffRemove.includes(x.staff_id))}
                            style={{
                                minHeight: 500, overflowX: 'auto', maxHeight: 500
                            }}
                            renderItem={item => {
                                return (
                                    <List.Item
                                        actions={[<Radio key={item.staff_id} value={item.staff_id} label={item.staff_fullname} />]}
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar size={50} src={item.staff_avatar || noImage} />}
                                            title={<a>{item.staff_fullname}</a>}
                                            description={<div><BarcodeOutlined /> {item.staff_code}</div>}
                                        />
                                    </List.Item>
                                )
                            }}
                        />
                    </Radio.Group>
                }
            </Col>
            <Col className="text-right mt-15" xs={24} md={24}>
                <Space className="text-right">
                    <Button size="large" id="close-service-staff-select" icon={<CloseCircleOutlined />} onClick={resetData} key="close" type="primary" >
                        {lang.close}
                    </Button>
                    <Button size="large" icon={<PlusOutlined />} onClick={() => onSave(c.SAVE)} key="submit1" type="primary" >
                        {lang.add}
                    </Button>
                    <Button size="large" icon={<PlusOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                        {lang.add_close}
                    </Button>
                </Space>
            </Col>
        </Row>

    );
};

export default AppointmentSelectServiceStaff;
