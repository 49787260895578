import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as staffThunk from '../../thunk';
import { setStaffTitleListId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import { Menu, Button, Dropdown, Popconfirm, message } from 'antd';
import {
  SettingOutlined
} from '@ant-design/icons';
import * as c from '../../../../constants/common';

const StaffTitleActionChecked = (props) => {
  const staffTitleListId = useSelector(state => state.staffTitle.staffTitleListId);
  const queryData = useSelector(state => state.staffTitle.staffTitleQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (staffTitleListId.length > 0) {
      const data = {
        list_id: staffTitleListId,
        field_name: 'staff_title_id',
        table: 'staff_title',
        update_user: userInfo.user_id,
        permission: ['STAFF_TITLE_DELETE']
      };
      commonService.commonDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(staffThunk.staffTitleList(queryData));
            dispatch(setStaffTitleListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (staffTitleListId.length > 0) {
      const data = {
        list_id: staffTitleListId,
        field_name: 'staff_title_id',
        table: 'staff_title',
        status,
        update_user: userInfo.user_id,
        permission: ['STAFF_TITLE_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(staffThunk.staffTitleList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TITLE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TITLE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TITLE_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    staffTitleListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default StaffTitleActionChecked;
