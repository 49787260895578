import { createSlice } from '@reduxjs/toolkit';
import * as serviceGroupThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';

export const serviceGroupSlice = createSlice({
  name: 'serviceGroup',
  initialState: {
    serviceGroupList: [],
    serviceGroupInfo: {},
    serviceGroupId: '',
    serviceGroupListId: [],
    serviceGroupListAction: [],
    serviceGroupCondition: [],
    discountType: c.DISCOUNT_TYPE.PERCENT,
    serviceGroupQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearServiceGroupList: (state, action) => {
      state.serviceGroupList = {};
    },
    clearServiceGroupRow: (state, action) => {
      state.serviceGroupList.rows = [];
    },
    setServiceGroupId: (state, action) => {
      state.serviceGroupId = action.payload;
    },
    clearServiceGroupId: (state, action) => {
      state.serviceGroupId = '';
    },
    setServiceGroupQueryData: (state, action) => {
      state.serviceGroupQueryData = action.payload;
    },
    setServiceGroupListId: (state, action) => {
      state.serviceGroupListId = action.payload;
    },
    setServiceGroupCondition: (state, action) => {
      state.serviceGroupCondition = action.payload;
    },
    setDiscountType: (state, action) => {
      state.discountType = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [serviceGroupThunk.serviceGroupList.fulfilled]: (state, action) => {
      state.serviceGroupList = action.payload;
    }
  }
});

export const {
  setCurrentServiceGroup,
  clearServiceGroupList,
  clearServiceGroupRow,
  setServiceGroupId,
  clearServiceGroupId,
  setServiceGroupQueryData,
  setServiceGroupListId,
  setServiceGroupCondition,
  setDiscountType
} = serviceGroupSlice.actions;

export default serviceGroupSlice.reducer;
