import { createSlice } from '@reduxjs/toolkit';
import * as invoiceThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';
const currentBranch = JSON.parse(localStorage.getItem('branch')) || {};

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    invoiceList: [],
    invoiceInfo: {},
    invoiceId: '',
    invoiceListId: [],
    invoiceListAction: [],
    invoiceCondition: [],
    discountType: c.DISCOUNT_TYPE.PERCENT,
    invoiceQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id,
      branchIdList: currentBranch.branch_id ? [currentBranch.branch_id] : []
    },
    invoiceDetail: {
      invoice_temp_money: null,
      invoice_total_promotion: 0,
      invoice_total_surcharge: 0,
      invoice_tax: null,
      invoice_customer_need_to_pay: null,
      invoice_customer_group_discount: null,
      invoice_tip: 0,
      customer_id: null,
      invoicePromotionList: [],
      invoiceSurchargeList: [],
      invoicePaymentMethodList: [],
      invoice_money_received: 0,
      invoice_change: 0,
      invoice_note: '',
      update_user: '',
      giftCardList: [],
      invoice_code: '',
      invoice_status: null,
      invoiceItemList: []
    }
  },
  reducers: {
    clearInvoiceList: (state, action) => {
      state.invoiceList = {};
    },
    clearInvoiceRow: (state, action) => {
      state.invoiceList.rows = [];
    },
    setInvoiceId: (state, action) => {
      state.invoiceId = action.payload;
    },
    clearInvoiceId: (state, action) => {
      state.invoiceId = '';
    },
    setInvoiceQueryData: (state, action) => {
      state.invoiceQueryData = action.payload;
    },
    setInvoiceListId: (state, action) => {
      state.invoiceListId = action.payload;
    },
    setInvoiceCondition: (state, action) => {
      state.invoiceCondition = action.payload;
    },
    setDiscountType: (state, action) => {
      state.discountType = action.payload;
    },
    setInvoiceDetail: (state, action) => {
      state.invoiceDetail = action.payload;
    },
    clearInvoiceDetail: (state, action) => {
      state.invoiceDetail = {
        invoice_temp_money: null,
        invoice_total_promotion: 0,
        invoice_total_surcharge: 0,
        invoice_tax: null,
        invoice_customer_need_to_pay: null,
        invoice_customer_group_discount: null,
        invoice_tip: 0,
        customer_id: null,
        invoicePromotionList: [],
        invoiceSurchargeList: [],
        invoicePaymentMethodList: [],
        invoice_money_received: 0,
        invoice_change: 0,
        invoice_note: '',
        update_user: '',
        giftCardList: [],
        invoice_code: '',
        invoice_status: null,
        invoiceItemList: []
      }
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [invoiceThunk.invoiceList.fulfilled]: (state, action) => {
      state.invoiceList = action.payload;
    },
    [invoiceThunk.getInvoiceDetail.fulfilled]: (state, action) => {
      state.invoiceDetail = action.payload;
    }
  }
});

export const {
  setCurrentInvoice,
  clearInvoiceList,
  clearInvoiceRow,
  setInvoiceId,
  clearInvoiceId,
  setInvoiceQueryData,
  setInvoiceListId,
  setInvoiceCondition,
  setDiscountType,
  setInvoiceItemList,
  setGiftCardList,
  setInvoiceDetail,
  clearInvoiceDetail
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
