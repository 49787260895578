/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Image, List, message, Popconfirm, Row, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../../assets/images/no-image.png';
import lang from '../../../language/language';
import CheckPermission from '../../Common/components/CheckPermission';
import { formatCurrency, formatDate, formatIsActive } from '../../Common/components/FormatData';
// import TinyEditor from '../../Common/components/TinyEditor';
import * as commonService from '../../Common/service';
import { setServiceId } from '../slice';
import * as serviceThunk from '../thunk';
const { Text } = Typography;

const ServiceDetail = (props) => {
	const { data, queryData } = props;
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.user.userInfo);
	const setting = useSelector((state) => state.generalSetting);
	/// /funtion///////

	// click button delete
	const onDelete = () => {
		if (data.service_id) {
			if (data.service_count > 0) {
				message.error(lang.service_group_not_allow_delete);
				return;
			}
			const serviceId = data.service_id;
			const dataDelete = {
				list_id: [serviceId],
				field_name: 'service_id',
				table: 'service',
				update_user: userInfo.user_id,
				permission: ['SERVICE_DELETE'],
			};
			commonService.commonDelete(dataDelete).then((res) => {
				if (res) {
					const { data } = res;
					if (data.is_delete) {
						dispatch(serviceThunk.serviceList(queryData));
						message.success(lang.delete_success);
					}
				}
			});
		}
	};

	const onEdit = (row) => {
		if (data.service_id) {
			document.getElementById('serviceAction').click();
			dispatch(setServiceId(data.service_id));
		}
	};
	/// /render///////
	return (
		<Row>
			<Col xs={12} md={4} lg={4}>
				<Image width="100%" className="mb-15 img-avatar-square" preview={false} src={data.service_avatar || noImage} />
			</Col>
			<Col xs={24} md={19} lg={19} className="ml-15">
				<List
					size="large"
					itemLayout="horizontal"
					grid={{ gutter: 24, column: 1, md: 2, xs: 1 }}
					dataSource={Object.keys(data).filter(
						(x) =>
							x !== 'key' &&
							x !== 'service_id' &&
							x !== 'service_avatar' &&
							x !== 'service_avatar_name' &&
							x !== 'service_group_id' &&
							x !== 'service_description'
					)}
					renderItem={(item) => (
						<List.Item>
							<Row className="border-detail-list">
								<Col xs={12} lg={9}>
									{lang[item]}
								</Col>
								<Col xs={item === 'service_description' ? 24 : 12} lg={item === 'service_description' ? 24 : 15}>
									{item === 'create_date' ? (
										formatDate(data[item], false, setting)
									) : item === 'is_active' ? (
										formatIsActive(data[item])
									) : String(data[item]).includes('{DELETE}') ? (
										<Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
									) : item === 'service_price' || item === 'service_price_discount' ? (
										formatCurrency(data[item], setting)
									) : item === 'service_duration' ? (
										`${data[item]} ${lang.minute}`
									) : (
										data[item]
									)}
								</Col>
							</Row>
						</List.Item>
					)}
				/>
			</Col>
			<Col className="mt-15" xs={24} md={24}>
				<Row type="flex" justify="end">
					<Col>
						<Space>
							<CheckPermission permission={['SERVICE_EDIT']}>
								{data.service_id && (
									<Button type="primary" onClick={onEdit} size="large" icon={<EditOutlined />}>
										{lang.edit}
									</Button>
								)}
							</CheckPermission>
							<CheckPermission permission={['SERVICE_DELETE']}>
								{data.service_id && (
									<Popconfirm
										placement="topRight"
										title={lang.sure_delete}
										onConfirm={onDelete}
										okText={lang.ok}
										cancelText={lang.cancel}
									>
										<Button size="large" icon={<DeleteOutlined />}>
											{lang.delete}
										</Button>
									</Popconfirm>
								)}
							</CheckPermission>
						</Space>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ServiceDetail;
