/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../../language/language';
import { setLoading } from '../../../../Common/slice';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../../constants/common';
import * as appointmentService from '../../service';
import * as appointmentThunk from '../../thunk';
import { getService } from '../../../../Common/service';
import { formatCurrency } from '../../../../Common/components/FormatData';
import {
	Drawer,
	Space,
	Button,
	Row,
	Col,
	Form,
	Input,
	DatePicker,
	Select,
	Popover,
	message,
	Popconfirm,
	Table,
} from 'antd';
import {
	SaveOutlined,
	CloseCircleOutlined,
	MinusCircleOutlined,
	PlusOutlined,
	DollarCircleOutlined,
	ClockCircleOutlined,
} from '@ant-design/icons';
import {
	setIsShowAppointmentAction,
	setDataAddAppointment,
	setAppointmentId,
	setServiceStaffSelect,
} from '../../slice';
import { GetListDateByNumber } from '../../../../Common/components/GetListDateByNumber';
import StaffSelect from '../../../../Common/components/StaffSelect';
import CheckPermission from '../../../../Common/components/CheckPermission';
import { customerList } from '../../../../Customer/service';
import moment from 'moment';
import CustomerQuickAdd from '../../../../Customer/components/CustomerAction/CustomerQuickAdd';
import { setAppointmentIdFromCustomer, setCustomerPhoneQuickAdd } from '../../../../Customer/slice';
import AppointmentActionCustomer from './AppointmentActionCustomer';
import { customerPhoneList } from '../../../../Common/thunk';
import ServiceSelect from '../../../../Common/components/ServiceSelect';
import CustomerAutoComplete from '../../../../Common/components/CustomerAutoComplete';
import AppointmentSelectServiceStaff from '../AppointmentSelectServiceStaff';
import * as smsHistoryService from '../../../../SMS/service';
import * as emailHistoryService from '../../../../Email/service';
import InvoicePrintTicket from '../../../../Invoice/components/InvoicePrintTicket';
import { useReactToPrint } from 'react-to-print';
import { RandomCode } from '../../../../Common/components/RandomCode';
import { dayOffSetting } from '../../../../Setting/BusinessHours/service';

const { Option } = Select;
const { TextArea } = Input;

const AppointmentAction = (props) => {
	const { staffList, fetchAppointmentWaitList } = props;
	const dispatch = useDispatch();
	// const color = useSelector(state => state.user.userInfo.theme_color);
	const appointmentId = useSelector((state) => state.appointment.appointmentId);
	const appointmentIdFromCustomer = useSelector((state) => state.customer.appointmentIdFromCustomer);
	const dataAddAppointment = useSelector((state) => state.appointment.dataAddAppointment);
	const generalSetting = useSelector((state) => state.generalSetting);
	const isShowAppointmentAction = useSelector((state) => state.appointment.isShowAppointmentAction);
	const appointmentSetting = useSelector((state) => state.appointmentSetting);
	const userInfo = useSelector((state) => state.user.userInfo);
	const [actionType, setActionType] = useState('');
	const [cancellationReason, setCancellationReason] = useState('');
	const appointmentQueryData = useSelector((state) => state.appointment.appointmentQueryData);
	const [appointmentDetail, setAppointmentDetail] = useState({});
	const [isVisibleAddCustomer, setIsVisibleAddCustomer] = useState(false);
	const [isVisibleSelectServiceStaff, setIsVisibleSelectServiceStaff] = useState(false);
	const [dataDetailCustomer, setDataDetailCustomer] = useState(null);
	const [appointmentList, setAppointmentList] = useState([]);
	const [staffIdBlock, setStaffIdBlock] = useState(null);
	const [serviceList, setServiceList] = useState([]);
	const customerQueryData = useSelector((state) => state.customer.customerQueryData);
	const branchLocal = JSON.parse(localStorage.getItem('branch'));
	const [form] = Form.useForm();
	const currentTime = moment()
		.add(appointmentSetting.appointment_setting_min_booking_time, 'minutes')
		.format('HH:mm')
		.toString();
	const checkNotExpired = true;
	// appointmentDetail.appointment_date === moment().format('YYYY-MM-DD') &&
	// appointmentDetail.appointment_hour > currentTime;
	const serviceStaffSelect = useSelector((state) => state.appointment.serviceStaffSelect);
	const [listStaffRemove, setListStaffRemove] = useState([]);
	const [listHourByStaffRemove, setListHourByStaffRemove] = useState([]);
	const [invoiceDetail, setInvoiceDetail] = useState({});
	const printTicketRef = useRef();
	const [dayOffList, setDayOffList] = useState([]);
	const getDayOffList = () => {
		dayOffSetting().then((res) => {
			if (res) {
				setDayOffList(res?.data);
			}
		});
	};

	useEffect(() => {
		getDayOffList();
	}, []);
	useEffect(() => {
		getAppointmentList();
	}, [staffList, appointmentDetail, form.getFieldValue('appointment_date'), dataAddAppointment]);

	useEffect(() => {
		if (!appointmentId && !appointmentIdFromCustomer) {
			genCode();
		}
	}, [isShowAppointmentAction]);

	useEffect(() => {
		countEndHour(
			serviceStaffSelect.map((e) => {
				return { service: e.service_id };
			})
		);
	}, [serviceStaffSelect]);

	useEffect(() => {
		if (Object.keys(dataAddAppointment).length > 0) {
			genCode();
			form.setFieldsValue({
				appointment_date: moment(dataAddAppointment.appointment_date),
				appointment_hour: dataAddAppointment.key,
				staff: dataAddAppointment.staff_id && parseInt(dataAddAppointment.staff_id),
			});
			setStaffIdBlock(dataAddAppointment.staff_id);
			getListStaffIdRemove(dataAddAppointment.key, dataAddAppointment.appointment_date);
			if (!appointmentId) {
				getListHourByStaffRemove(dataAddAppointment.staff_id);
			}
		}
	}, [dataAddAppointment]);

	useEffect(() => {
		GetListDateByNumber();
	}, [appointmentSetting]);

	useEffect(() => {
		getServiceList({ staff_id: staffIdBlock });
	}, [staffIdBlock]);

	useEffect(() => {
		if (Object.keys(appointmentDetail).length > 0) {
			dispatch(setIsShowAppointmentAction(true));
			setStaffIdBlock(appointmentDetail.staff_id_block);
		}
	}, [appointmentDetail]);

	useEffect(() => {
		if (dataDetailCustomer && dataDetailCustomer.customer_phone_number) {
			form.setFieldsValue({ customer: dataDetailCustomer.customer_phone_number });
		}
	}, [dataDetailCustomer]);

	useEffect(() => {
		dispatch(customerPhoneList({}));
	}, [dispatch]);

	useEffect(() => {
		if (appointmentId || appointmentIdFromCustomer) {
			getDetailAppointment(appointmentId || appointmentIdFromCustomer);
		}
	}, [appointmentId, appointmentIdFromCustomer]);

	/// //////////////////////// Validate /////////////////////////

	/// ///function////

	const handlePrintTicket = useReactToPrint({
		content: () => printTicketRef.current,
	});

	const getListStaffIdRemove = (appointmentHour, appointmentDate, listIdNotRemove = []) => {
		const data = { appointment_hour: appointmentHour, appointment_date: appointmentDate };
		appointmentService.appointmentListStaffIdRemove(data).then((res) => {
			const resData = res.data;
			if (resData) {
				setListStaffRemove(resData.filter((a) => !listIdNotRemove.includes(parseInt(a))));
			}
		});
	};

	const getListHourByStaffRemove = (staffId, listHourNotRemove = []) => {
		if (staffId) {
			const data = {
				appointment_date: moment(form.getFieldValue('appointment_date')).format('YYYY-MM-DD'),
				staff_id: staffId,
			};
			appointmentService.appointmentHourListByStaffRemove(data).then((res) => {
				const resData = res.data;
				if (resData) {
					setListHourByStaffRemove(resData.filter((a) => !listHourNotRemove.includes(a)));
				}
			});
		}
	};

	const countEndHour = (data = []) => {
		if (data && data.length > 0) {
			let countDuration = 0;
			if (data.length > 0) {
				data.map((x) => {
					const newData = serviceList.filter((e) => e.service_id === x.service);
					countDuration += newData.length > 0 ? newData[0].service_duration : 0;
				});
				form.setFieldsValue({
					appointment_end_hour: moment(form.getFieldValue('appointment_hour'), 'hh:mm')
						.add(countDuration, 'minutes')
						.format('hh:mm A')
						.toString(),
				});
			} else {
				form.setFieldsValue({ appointment_end_hour: '' });
			}
		} else {
			form.setFieldsValue({ appointment_end_hour: '' });
		}
	};

	const getServiceList = (data) => {
		getService(data).then((res) => {
			if (res.data) {
				setServiceList(res.data);
			}
		});
	};
	const getAppointmentList = () => {
		const appointmentDate = moment(form.getFieldValue('appointment_date')).format('YYYY-MM-DD');
		const data = {
			...appointmentQueryData,
			appointment_date: appointmentDate,
		};
		appointmentService.getAppointmentList(data).then((res) => {
			const resData = res.data;
			if (resData) {
				if (appointmentDetail.appointment_id) {
					const result = resData.filter(
						(e) =>
							e.key === appointmentDetail.appointment_hour ||
							(e.key > currentTime && staffList && Object.keys(e).length - 2 !== staffList.length - 1)
					);
					setAppointmentList(result);
				} else if (appointmentDate === moment().format('YYYY-MM-DD') && !appointmentDetail.appointment_id) {
					setAppointmentList(
						resData.filter(
							(e) => e.key > currentTime && staffList && Object.keys(e).length - 2 !== staffList.length - 1
						)
					);
				} else if (!appointmentDetail.appointment_id) {
					setAppointmentList(resData);
				}
			}
		});
	};

	const getDetailAppointment = (appointmentId) => {
		if (appointmentId) {
			dispatch(setLoading(true));
			appointmentService
				.appointmentDetail({ appointment_id: appointmentId })
				.then((res) => {
					if (res) {
						dispatch(setLoading(false));
						const { data } = res;
						const serviceStaffList = data.service_staff_list.map((item) => {
							return { service: item.service.value, staff: item.staff.value };
						});
						setAppointmentDetail(data);
						form.setFieldsValue({
							appointment_code: data.appointment_code,
							appointment_hour: data.appointment_hour,
							appointment_date: moment(data.appointment_date),
							appointment_quantity: data.appointment_quantity,
							appointment_note: data.appointment_note,
							service_staff_list: serviceStaffList || null,
							appointment_end_hour: moment(data.appointment_end_hour, 'hh:mm').format('hh:mm A'),
						});
						// nếu chọn nhân viên mặc định
						if (data.staff_id_block) {
							form.setFieldsValue({
								staff: data.staff_id_block,
							});
							getListStaffIdRemove(data.appointment_hour, data.appointment_date, [data.staff_id_block]);
						}

						// nếu không chọn nhân viên mặc định
						if (!data.staffIdBlock) {
							const list = data.service_staff_list.map((item) => {
								return {
									key: (Math.random() + 1).toString(36).substring(1),
									service_id: item.service.value,
									staff_id: item.staff.value,
									staff_name: item.staff.label,
									service_name: item.service.label,
									service_price: item.service.service_price,
									service_duration: item.service.service_duration,
								};
							});
							dispatch(setServiceStaffSelect(list));
							getListStaffIdRemove(
								data.appointment_hour,
								data.appointment_date,
								list.map((e) => e.staff_id)
							);
						}

						onSelectCustomerPhone(data.customer_phone_number);
					}
				})
				.finally(() => {
					dispatch(setLoading(false));
				});
		} else {
			form.setFieldsValue({
				staff_branch: branchLocal.branch_name,
			});
		}
	};

	const appointmentSendSMS = async (status = '') => {
		if (status) {
			if (
				status === c.APPOINTMENT_STATUS.CONFIRMED &&
				appointmentSetting.appointment_allow_sms_confirm === c.TRUE_FALSE.TRUE &&
				appointmentSetting.appointment_content_sms_confirm
			) {
				const dataReq = {
					listCustomerId: [dataDetailCustomer.customer_id],
					general_setting_country: generalSetting.general_setting_country,
					sms_history_content: appointmentSetting.appointment_content_sms_confirm,
					create_user: -1,
					appointment_id: appointmentDetail.appointment_id,
				};
				await smsHistoryService.smsHistoryCreate(dataReq);
			}

			if (
				status === c.APPOINTMENT_STATUS.CANCELLED &&
				appointmentSetting.appointment_allow_admin_sms_cancel === c.TRUE_FALSE.TRUE &&
				appointmentSetting.appointment_content_admin_sms_cancel
			) {
				const dataReq = {
					listCustomerId: [dataDetailCustomer.customer_id],
					general_setting_country: generalSetting.general_setting_country,
					sms_history_content: appointmentSetting.appointment_content_admin_sms_cancel,
					create_user: -1,
					appointment_id: appointmentDetail.appointment_id,
				};
				await smsHistoryService.smsHistoryCreate(dataReq);
			}
		}
	};

	const appointmentSendEmail = async (status = '') => {
		if (status) {
			if (
				status === c.APPOINTMENT_STATUS.CONFIRMED &&
				appointmentSetting.appointment_allow_email_confirm === c.TRUE_FALSE.TRUE &&
				appointmentSetting.appointment_content_email_confirm &&
				appointmentSetting.appointment_title_email_confirm
			) {
				const dataReq = {
					listCustomerId: [dataDetailCustomer.customer_id],
					email_history_title: appointmentSetting.appointment_title_email_confirm,
					general_setting_country: generalSetting.general_setting_country,
					email_history_content: appointmentSetting.appointment_content_email_confirm,
					create_user: -1,
					appointment_id: appointmentDetail.appointment_id,
				};
				await emailHistoryService.emailHistoryCreate(dataReq);
			}

			if (
				status === c.APPOINTMENT_STATUS.CANCELLED &&
				appointmentSetting.appointment_allow_admin_email_cancel === c.TRUE_FALSE.TRUE &&
				appointmentSetting.appointment_content_admin_email_cancel &&
				appointmentSetting.appointment_title_admin_email_cancel
			) {
				const dataReq = {
					listCustomerId: [dataDetailCustomer.customer_id],
					general_setting_country: generalSetting.general_setting_country,
					email_history_title: appointmentSetting.appointment_title_admin_email_cancel,
					email_history_content: appointmentSetting.appointment_content_admin_email_cancel,
					create_user: -1,
					appointment_id: appointmentDetail.appointment_id,
				};
				await emailHistoryService.emailHistoryCreate(dataReq);
			}
		}
	};

	const appointmentUpdateStatus = (status = '', mes = '', cancellationReason = '') => {
		if (status) {
			dispatch(setLoading(true));
			const data = {
				appointment_status: status,
				appointment_id: appointmentDetail.appointment_id,
				cancellation_reason: cancellationReason,
			};

			appointmentService
				.appointmentUpdateStatus(data)
				.then((res) => {
					if (res) {
						dispatch(setLoading(false));
						const { data } = res;
						if (data.appointment_id && mes) {
							message.success(mes);
						}
					}
				})
				.finally(async () => {
					dispatch(setLoading(false));
					dispatch(appointmentThunk.appointmentGetListThunk(appointmentQueryData));
					dispatch(setIsShowAppointmentAction(false));
					resetData();
					await appointmentSendSMS(status);
					await appointmentSendEmail(status);
				});
		}
	};

	const onSelectCustomerPhone = (value = '') => {
		if (value) {
			dispatch(setLoading(true));
			if (isVisibleAddCustomer) {
				document.getElementById('btn-close-quick-add-customer').click();
			}
			customerList({ ...customerQueryData, search: value }).then((res) => {
				if (res.data) {
					const { rows } = res.data;
					if (rows && rows.length > 0) {
						dispatch(setLoading(false));
						setDataDetailCustomer(rows[0]);
					}
				}
			});
		}
	};

	const genCode = () => {
		form.setFieldsValue({ appointment_code: RandomCode() });
	};

	const onSubmit = (data) => {
		dispatch(setLoading(true));
		if ((!data.service_staff_list || data.service_staff_list.length === 0) && serviceStaffSelect.length === 0) {
			message.error(lang.service_not_empty);
			dispatch(setLoading(false));
			return;
		}

		const method = appointmentId ? 'PUT' : 'POST';
		const dataReq = {
			appointment_id: appointmentId || '',
			appointment_code: data.appointment_code || '',
			appointment_date: moment(data.appointment_date).format('YYYY-MM-DD') || '',
			appointment_hour: data.appointment_hour || '',
			appointment_status: c.APPOINTMENT_STATUS.WAIT_CONFIRM,
			appointment_note: data.appointment_note || '',
			customer_id: dataDetailCustomer.customer_id || '',
			create_by: appointmentDetail.create_by || 'POS',
			appointment_quantity: data.appointment_quantity || 1,
			branch_id: branchLocal.branch_id || '',
			create_user: userInfo.user_id || '',
			staff_id_block: data.staff || null,
			appointment_end_hour: moment(data.appointment_end_hour, 'hh:mm A').format('HH:mm'),
			service_staff_list:
				(data.service_staff_list &&
					data.service_staff_list.map((item) => {
						return { service_id: item.service, staff_id: staffIdBlock || appointmentDetail.staff_id_block };
					})) ||
				serviceStaffSelect.map((e) => {
					return { service_id: e.service_id, staff_id: e.staff_id };
				}) ||
				[],
		};
		appointmentService
			.appointmentCreateUpdate(dataReq, method)
			.then((res) => {
				if (res) {
					const { data } = res;
					dispatch(setLoading(false));
					// check exist
					if (data.appointment_code) {
						message.error(lang.appointment_code + data.appointment_code + lang.exist);
						return;
					} else if (data.appointment_date) {
						message.error(lang.appointment_date + lang.exist);
						return;
					} else if (data.appointment_hour) {
						message.error(lang.appointment_hour + lang.exist);
						return;
					} else if (data.conflict_end_hour) {
						message.error(lang.conflict_end_hour);
						return;
					}
					// luu thanh cong
					if (data.appointment_id > 0) {
						if (actionType === c.SAVE) {
							message.success(lang.save_success);
							setActionType('');
							if (!appointmentId) {
								resetData();
							}
						} else {
							message.success(lang.save_success);
							onClose();
						}
					}
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
				dispatch(appointmentThunk.appointmentGetListThunk(appointmentQueryData));
			});
	};

	const resetData = () => {
		// cho phep edit
		genCode();
		form.resetFields();
		dispatch(setDataAddAppointment({}));
		dispatch(setAppointmentId(''));
		setDataDetailCustomer(null);
		dispatch(setCustomerPhoneQuickAdd(''));
		setAppointmentDetail({});
		setStaffIdBlock(null);
		dispatch(setServiceStaffSelect([]));
		setListHourByStaffRemove([]);
		dispatch(setAppointmentIdFromCustomer(null));
		setCancellationReason('');
	};

	const onClose = () => {
		resetData();
		dispatch(setIsShowAppointmentAction(false));
		setIsVisibleAddCustomer(false);
	};

	const onSave = (type) => {
		setActionType(type);
		form.submit();
	};

	const onQuickAddCustomer = () => {
		setIsVisibleAddCustomer(true);
		dispatch(setCustomerPhoneQuickAdd(form.getFieldValue('customer_phone_number')));
	};

	const appointmentPrintTicket = () => {
		if (appointmentDetail.is_checkin === 1) {
			return null;
		} else {
			const dataPrintTicket = {
				appointment_id: appointmentId,
				invoice_code: RandomCode(),
				create_user: userInfo.user_id,
			};
			appointmentService
				.appointmentPrintTicket(dataPrintTicket)
				.then((res) => {
					if (res.data) {
						setInvoiceDetail(res.data);
						appointmentUpdateStatus(c.APPOINTMENT_STATUS.VISITED, '');
						handlePrintTicket();
						resetData();
						fetchAppointmentWaitList();
					}
				})
				.finally(() => {
					setIsShowAppointmentAction(false);
				});
		}
	};

	const showBtnSave = () => {
		if (
			(checkNotExpired && appointmentDetail.appointment_status === c.APPOINTMENT_STATUS.WAIT_CONFIRM) ||
			Object.keys(appointmentDetail).length === 0
		) {
			return (
				<>
					<Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary">
						{lang.save}
					</Button>
					<Button
						size="large"
						icon={<SaveOutlined />}
						onClick={() => onSave(c.SAVE_CLOSE)}
						key="submit2"
						type="primary"
					>
						{lang.save_and_close}
					</Button>
				</>
			);
		}
	};

	const showBtnConfirm = () => {
		if (appointmentDetail.appointment_status === c.APPOINTMENT_STATUS.WAIT_CONFIRM && checkNotExpired) {
			return (
				<Popconfirm
					placement="topRight"
					title={lang.sure_save}
					onConfirm={() => appointmentUpdateStatus(c.APPOINTMENT_STATUS.CONFIRMED, lang.confirm_appointment_success)}
					okText={lang.ok}
					cancelText={lang.cancel}
				>
					<Button size="large" icon={<SaveOutlined />} key="confirm" type="primary">
						{lang.confirm}
					</Button>
				</Popconfirm>
			);
		}
	};

	const showBtnCancelAppointment = () => {
		const onSubmitCancel = () => {
			if (cancellationReason) {
				appointmentUpdateStatus(c.APPOINTMENT_STATUS.CANCELLED, lang.cancel_appointment_success, cancellationReason);
			} else {
				message.error(lang.cancellation_reason_is_required);
			}
		};
		if (
			Object.keys(appointmentDetail).length > 0 &&
			appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.CANCELLED &&
			appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.DONE &&
			checkNotExpired &&
			!appointmentDetail.is_checkin
		) {
			return (
				<Popover
					placement="topLeft"
					trigger="click"
					style={{ width: 600 }}
					overlayClassName="popover-reason-cancel"
					// visible={isVisibleAddCustomer}
					content={
						<Row>
							<Col xs={24} md={24}>
								<Form.Item
									label={lang.cancellation_reason}
									name="cancellation_reason"
									rules={[{ required: true, message: lang.value_is_required }]}
								>
									<TextArea onChange={(e) => setCancellationReason(e.target.value)} size="large" allowClear rows={1} />
								</Form.Item>
							</Col>
							<Col xs={24} md={24} className="text-center">
								<Popconfirm
									placement="topRight"
									title={lang.sure_cancel}
									onConfirm={onSubmitCancel}
									okText={lang.ok}
									cancelText={lang.cancel}
								>
									<Button size="middle" icon={<SaveOutlined />} key="submitCancel" type="primary">
										{lang.save}
									</Button>
								</Popconfirm>
							</Col>
						</Row>
					}
				>
					<Button size="large" icon={<SaveOutlined />} key="confirm" type="primary">
						{lang.cancel_appointment}
					</Button>
				</Popover>
			);
		}
	};

	const showBtnPrintAppointment = () => {
		if (
			appointmentDetail.appointment_id &&
			appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.CANCELLED &&
			appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.WAIT_CONFIRM &&
			appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.VISITED
		) {
			return (
				<Button size="large" onClick={onPrintTicket} icon={<SaveOutlined />} key="confirm" type="primary">
					{lang.print_ticket}
				</Button>
			);
		}
	};

	const onPrintTicket = () => {
		appointmentPrintTicket();
	};

	const checkDisable = () => {
		let result = false;
		if (
			(!checkNotExpired && appointmentDetail.appointment_id) ||
			(appointmentDetail.appointment_id && appointmentDetail.appointment_status !== c.APPOINTMENT_STATUS.WAIT_CONFIRM)
		) {
			result = true;
		}
		return result;
	};

	const onClearStaff = () => {
		form.setFieldsValue({
			service_staff_list: null,
		});
		setStaffIdBlock(null);
		form.resetFields(['appointment_hour']);
		setListStaffRemove([]);
		setListHourByStaffRemove([]);
	};

	const onRemoveServiceStaffSelected = (data) => {
		const result = [...serviceStaffSelect];
		const res = result.filter((e) => e.key !== data.key);
		dispatch(setServiceStaffSelect(res));
	};

	const columnsServiceStaff = [
		{
			title: lang.service,
			dataIndex: 'service_name',
			key: 'service_name',
			render: (text, record) => {
				return (
					<div>
						<div>{text}</div>
						<div className="f-13">
							<DollarCircleOutlined />{' '}
							<span className={`${record.service_price_discount && 'line-through limiter'}`}>
								{formatCurrency(record.service_price, generalSetting)}
							</span>{' '}
							{record.service_price_discount && (
								<span>{formatCurrency(record.service_price_discount, generalSetting)}</span>
							)}{' '}
							- <ClockCircleOutlined /> {record.service_duration} {lang.minute}
						</div>
					</div>
				);
			},
		},
		{
			title: lang.staff,
			dataIndex: 'staff_name',
			key: 'staff_name',
		},
		{
			title: '',
			key: 'action',
			width: 50,
			align: 'center',
			hidden: !!checkDisable(),
			render: (text, record) =>
				!checkDisable() ? <MinusCircleOutlined onClick={() => onRemoveServiceStaffSelected(record)} /> : '',
		},
	];

	const onSelectStaff = (value) => {
		setStaffIdBlock(value);
		dispatch(setServiceStaffSelect([]));
		form.setFieldsValue({
			service_staff_list: '',
		});
		if (!appointmentId) {
			getListHourByStaffRemove(value);
		}
	};

	const onRemoveService = (remove, name) => {
		remove(name);
		countEndHour(form.getFieldValue('service_staff_list'));
	};

	const onSelectAppointmentHour = (value) => {
		// countEndHour(form.getFieldValue('service_staff_list'))
		// countEndHour(serviceStaffSelect.map(
		//   (e) => { return { service: e.service_id } }
		// ))
		setListStaffRemove([]);
		getListStaffIdRemove(value, moment(form.getFieldValue('appointment_date')).format('YYYY-MM-DD'));
		dispatch(setServiceStaffSelect([]));
		form.resetFields(['service_staff_list']);
	};

	const onChangeAppointmentDate = (value) => {
		getAppointmentList();
		setListStaffRemove([]);
		getListStaffIdRemove(form.getFieldValue('appointment_hour'), moment(value).format('YYYY-MM-DD'));
		form.resetFields(['staff', 'appointment_hour']);
		setStaffIdBlock('');
		dispatch(setServiceStaffSelect([]));
	};

	const onClearHour = (value) => {
		setStaffIdBlock('');
		form.resetFields(['staff']);
		setListStaffRemove([]);
	};
	/// render/////
	return (
		<Drawer
			title={
				!appointmentId && !appointmentIdFromCustomer
					? lang.add + lang.appointment.toLowerCase()
					: appointmentIdFromCustomer
					? lang.appointment
					: lang.edit +
					  lang.appointment.toLowerCase() +
					  ` (${
							Object.keys(appointmentDetail).length > 0 &&
							c.APPOINTMENT_STATUS_LIST.filter((e) => e.value === appointmentDetail.appointment_status)[0].label
					  })`
			}
			placement="right"
			width={c.WIDTH_DRAWER.FULL}
			onClose={onClose}
			visible={isShowAppointmentAction}
			footer={
				<Space className="text-center">
					<Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
						{lang.close}
					</Button>
					{showBtnPrintAppointment()}
					{showBtnCancelAppointment()}
					{showBtnConfirm()}
					{showBtnSave()}
				</Space>
			}
		>
			<InvoicePrintTicket
				dataItem={invoiceDetail.invoiceItemList || []}
				data={invoiceDetail || {}}
				printRef={printTicketRef}
			/>
			<Form onFinish={onSubmit} autoComplete="off" form={form} layout="vertical">
				<Row>
					<Col xs={!appointmentIdFromCustomer ? 15 : 24} md={!appointmentIdFromCustomer ? 15 : 24} className="pr-5">
						<Row gutter={10}>
							<Col xs={24} md={12}>
								<Form.Item
									label={lang.appointment_date}
									name="appointment_date"
									rules={[{ required: true, message: lang.value_is_required }]}
								>
									<DatePicker
										allowClear={false}
										size="large"
										style={{ width: '100%' }}
										disabled={checkDisable()}
										disabledDate={(current) => {
											const listDayOff = dayOffList
												?.filter((x) => x?.day_off_setting_status === 1)
												?.map((a) =>
													moment(new Date().getFullYear() + '-' + a?.day_off_setting_date).format('YYYY-MM-DD')
												);
											const allowDate = GetListDateByNumber(appointmentSetting.appointment_setting_booking_date_limit);
											const filteredAllowDate = allowDate?.filter((date) => !listDayOff.includes(date));
											return !filteredAllowDate?.includes(moment(current).format('YYYY-MM-DD'));
										}}
										format={generalSetting.general_setting_date_format}
										onChange={onChangeAppointmentDate}
									/>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item
									label={lang.appointment_hour}
									name="appointment_hour"
									rules={[{ required: true, message: lang.value_is_required }]}
								>
									<Select
										size="large"
										disabled={checkDisable()}
										placeholder={lang.appointment_hour}
										onSelect={onSelectAppointmentHour}
										allowClear
										onClear={onClearHour}
									>
										{appointmentList.map((item) => {
											if (item?.allowBook) {
												return (
													<Option
														key={item.key}
														value={item.key}
														className={listHourByStaffRemove.includes(String(item.key)) ? 'd-none' : ''}
													>
														{item.appointment_hour}
													</Option>
												);
											}
										})}
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} md={6}>
								<Form.Item label={lang.appointment_end_hour} name="appointment_end_hour">
									<Input disabled={true} size="large" />
								</Form.Item>
							</Col>
							<Col xs={24} md={12}>
								<Form.Item
									label={lang.appointment_code}
									name="appointment_code"
									rules={[
										{ required: true, message: lang.value_is_required },
										// { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
									]}
								>
									<Input disabled size="large" />
									{/* <Input disabled={(appointmentId || appointmentIdFromCustomer) || false} size="large" /> */}
								</Form.Item>
							</Col>
							{/*
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.appointment_quantity}
                  name="appointment_quantity"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[0-9]*$/, message: lang.not_format }
                  ]}
                >
                  <Input disabled={checkDisable()} value={1} size="large" />
                </Form.Item>
              </Col> */}
							<Col xs={24} md={12}>
								<StaffSelect
									disabled={checkDisable()}
									onSelect={onSelectStaff}
									onClear={onClearStaff}
									listIdRemove={listStaffRemove}
									isTechnician
									allowBooking
								/>
							</Col>
							<Col xs={24} md={24}>
								<Form.Item label={lang.note} name="appointment_note">
									<TextArea disabled={checkDisable()} size="large" allowClear rows={1} />
								</Form.Item>
							</Col>
							<Col xs={24} md={24}>
								<label style={{ fontSize: 16 }}>
									{form.getFieldValue('staff') ? lang.service : lang.service_staff}
								</label>
								<Row gutter={24} className="shadow-hide">
									{(staffIdBlock || appointmentDetail.staff_id_block) && form.getFieldValue('staff') && (
										<Form.List name="service_staff_list">
											{(fields, { add, remove }) => {
												return (
													<>
														{fields.map(({ key, name, ...restField }, index) => {
															return (
																<Col key={key} xs={24} md={24}>
																	<Space
																		size="middle"
																		key={key}
																		style={{ display: 'flex', marginBottom: 8 }}
																		align="baseline"
																	>
																		<ServiceSelect
																			required
																			hideLabel
																			disabled={checkDisable()}
																			name={[name, 'service']}
																			style={{ width: 540 }}
																			staffId={staffIdBlock}
																			listIdSelected={form.getFieldValue('service_staff_list') || []}
																			onSelect={() => countEndHour(form.getFieldValue('service_staff_list'))}
																		/>
																		{!checkDisable() && (
																			<MinusCircleOutlined
																				onClick={() => {
																					onRemoveService(remove, name);
																				}}
																			/>
																		)}
																	</Space>
																</Col>
															);
														})}
														{!checkDisable() && fields.length < serviceList.length && (
															<Col xs={24} md={24}>
																<Form.Item>
																	<Button size="middle" onClick={() => add()} icon={<PlusOutlined />}>
																		{lang.add}
																	</Button>
																</Form.Item>
															</Col>
														)}
													</>
												);
											}}
										</Form.List>
									)}
									{!staffIdBlock && !form.getFieldValue('staff') && form.getFieldValue('appointment_hour') && (
										<Col xs={24} md={24}>
											{!checkDisable() && (
												<Popover
													overlayClassName="popover-select-service-staff"
													placement="right"
													trigger="click"
													visible={isVisibleSelectServiceStaff}
													content={<AppointmentSelectServiceStaff listStaffRemove={listStaffRemove} />}
												>
													<Button
														size="middle"
														icon={<PlusOutlined />}
														className="mb-15"
														onClick={() => setIsVisibleSelectServiceStaff(true)}
													>
														{lang.add}
													</Button>
													<Button
														onClick={() => setIsVisibleSelectServiceStaff(false)}
														className="d-none"
														id="btn-close-select-service-staff"
													/>
												</Popover>
											)}

											{serviceStaffSelect.length > 0 && (
												<Table
													className="mb-15"
													bordered
													columns={columnsServiceStaff.filter((x) => !x.hidden)}
													dataSource={serviceStaffSelect}
													pagination={false}
												/>
											)}
										</Col>
									)}
								</Row>
							</Col>
						</Row>
					</Col>
					{!appointmentIdFromCustomer && (
						<Col className="appointment-customer pl-5" xs={9} md={9}>
							<Row gutter={9}>
								<Col xs={24} md={24}>
									<CustomerAutoComplete
										style={{ width: !dataDetailCustomer ? '85%' : '100%', float: 'left' }}
										label={lang.search_customer_phone}
										isHideLabel
										required
										showSearch
										allowClear
										disabled={checkDisable()}
										onClear={() => setDataDetailCustomer(null)}
										onSelect={(data) => onSelectCustomerPhone(data.customer_phone_number)}
										onChange={(data) => dispatch(setCustomerPhoneQuickAdd(data))}
									/>
									{!dataDetailCustomer && (
										<CheckPermission permission={['CUSTOMER_ADD']}>
											<Popover
												placement="bottomRight"
												trigger="click"
												style={{ width: 350 }}
												overlayClassName="popover-quick-add-customer"
												visible={isVisibleAddCustomer}
												content={<CustomerQuickAdd onSelectCustomerPhone={onSelectCustomerPhone} />}
											>
												<Button
													onClick={onQuickAddCustomer}
													style={{ width: 40, height: 40, marginLeft: 5 }}
													size="middle"
													icon={<PlusOutlined />}
													id="btn-quick-add-customer"
												/>
												<Button
													onClick={() => setIsVisibleAddCustomer(false)}
													className="d-none"
													id="btn-close-quick-add-customer"
												/>
											</Popover>
										</CheckPermission>
									)}
								</Col>
								<Col xs={24} md={24}>
									{dataDetailCustomer && <AppointmentActionCustomer data={dataDetailCustomer} />}
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			</Form>
		</Drawer>
	);
};

export default AppointmentAction;
