/* eslint-disable no-return-assign */
import moment from 'moment'

// tính % chênh lệch

export const rateOfChange = (num1, num2) => {
    const percent = (num1 * 100) / num2
    let rate = 0
    if (percent > 100) rate = percent - 100
    else rate = 100 - percent
    return {
        rate: rate === Infinity || NaN ? 100 : rate.toFixed(2),
        increase: percent > 100
    }
}

// tính tổng

export const sumData = (data = [], fieldSum = '') => {
    let result = 0
    if (data.length > 0) {
        data.map(z => {
            return result = result + z[fieldSum]
        })
    }
    return result
}
// tính số ngày giữa 2 date
export const diffInDays = (fromDate = '', toDate = '') => {
    const date1 = moment(fromDate);
    const date2 = moment(toDate);
    const diffInDays = date2.diff(date1, 'days');
    return diffInDays
}
