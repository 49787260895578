import { Select, DatePicker, Row, Col } from 'antd'
import React, { useState } from 'react'
import { OPTIONS_CHART_FILTER } from '../../../constants/common'
import lang from '../../../language/language';
import { customGetDay } from './CustomGetDay'

const { RangePicker } = DatePicker;

const FilterDay = (props) => {
    const [rangePickerOn, setRangePickerOn] = useState(false)
    const {
        onSelect,
        isShowDay,
        size,
        style,
        defaultFilter
    } = props
    const getDate = customGetDay()

    const handleChange = (value) => {
        setRangePickerOn(false)
        if (onSelect) {
            switch (value) {
                case OPTIONS_CHART_FILTER[0].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.today,
                            toDate: getDate.today
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[1].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.yesterday,
                            toDate: getDate.yesterday
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[2].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.thisMonday,
                            toDate: getDate.thisSunday
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[3].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.lastMonday,
                            toDate: getDate.lastSunday
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[4].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.fisrtdayThisMonth,
                            toDate: getDate.lastdayThisMonth
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[5].value: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.fisrtdayLastMonth,
                            toDate: getDate.lastdayLastMonth
                        }
                    )
                    break
                }
                case OPTIONS_CHART_FILTER[6].value: {
                    setRangePickerOn(true)
                    break
                }
                default: {
                    onSelect(
                        {
                            value,
                            fromDate: getDate.today,
                            toDate: getDate.today
                        }
                    )
                    break
                }
            }
        }
    }

    const onChangeRangePicker = (range) => {
        if (range) {
            onSelect(
                {
                    value: OPTIONS_CHART_FILTER[6].value,
                    fromDate: range[0],
                    toDate: range[1]
                }
            )
        } else {
            onSelect(
                {
                    value: OPTIONS_CHART_FILTER[0].value,
                    fromDate: getDate.today,
                    toDate: getDate.today
                }
            )
        }
    }
    return (
        <Row >
            <Col md={!rangePickerOn ? 24 : 12} >
                <Select
                    defaultValue={defaultFilter || 'thisWeek'}
                    size={size || 'middle'}
                    style={style}

                    onChange={handleChange}
                    options={isShowDay ? OPTIONS_CHART_FILTER : OPTIONS_CHART_FILTER.filter(z => z.value !== 'today' && z.value !== 'yesterday')}
                />
            </Col>
            {rangePickerOn &&
                <Col md={12} className="pl-15">
                    <RangePicker
                        size={size || 'middle'}
                        onChange={onChangeRangePicker}
                        placeholder={[lang.from_date, lang.to_date]}
                    />
                </Col>
            }
        </Row>
    )
}
export default FilterDay
