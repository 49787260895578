import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import { ROUTE_HOME } from './../../modules/Common/constants/routes';
import * as c from './../../constants/config';
import * as common from '../../constants/common';

import axios from 'axios';
import lang from '../../language/language';
import { toast } from 'react-toastify';
import { setLoading } from '../../modules/Common/slice';
import { ROUTE_DASH_BOARD } from '../../modules/DashBoard/constants/routes'
import { Form, Input, Button } from 'antd'
import LabelRequired from './Common/LabelRequired';

const Login = (props) => {
  const dispatch = useDispatch();
  // const themeMenu = useSelector(state => state.user.userInfo.theme_menu);
  const ref = useRef();
  const [loginForm] = Form.useForm();

  const onLogin = () => {
    loginForm
      .validateFields()
      .then((res) => {
        if (res) {
          dispatch(setLoading(true));
          const dataLogin = {
            user_name: res.userName,
            password: res.password
          };
          axios({
            method: 'POST',
            url: `${c.API_URL}/login`,
            data: dataLogin,
            headers: {
              'content-type': 'application/json'
            }
          }).then(res => {
            const { data } = res;
            if (data.status === false || data.status_checkin === false || data.user_type === common.USER_TYPE.CHECKIN) {
              dispatch(setLoading(false));
              toast.error(lang.login_not_correct);
              return;
            }

            // check root user
            if (data.is_main === 1) {
              if (data.status && data.accessToken) {
                dispatch(setLoading(false));
                localStorage.setItem('infoUser', JSON.stringify(data));
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                // if (themeMenu === c.MENU_TYPE_LEFT) {
                //   window.location.href = ROUTE_DASH_BOARD;
                // } else {
                //   window.location.href = ROUTE_HOME;
                // }
                window.location.href = ROUTE_DASH_BOARD;
              }
            }
            if (data.branch_list && data.branch_list.length > 0) {
              if (data.status && data.accessToken) {
                dispatch(setLoading(false));
                localStorage.setItem('infoUser', JSON.stringify(data));
                localStorage.setItem('accessToken', data.accessToken);
                localStorage.setItem('refreshToken', data.refreshToken);
                // if (themeMenu === c.MENU_TYPE_LEFT) {
                //   window.location.href = ROUTE_DASH_BOARD;
                // } else {
                //   window.location.href = ROUTE_HOME;
                // }
                window.location.href = ROUTE_DASH_BOARD;
              }
            } else if (data.is_main === 0 && data.branch_list.length === 0) {
              dispatch(setLoading(false));
              toast.error(lang.no_permission);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userNameValidator = (rule, values, callback) => {
    const stringRegex = /^[A-Za-z0-9_]*$/;
    if (stringRegex.test(values)) {
      callback();
    } else {
      /*eslint-disable */
      callback(lang.value_not_allow_special_characters);
    }
  };

  const handleEnterlogin = (event) => {
    // Enter
    if (event.keyCode === 13) {
      ref.current.submit();
    }
  }

  useEffect(() => {
    window.addEventListener("keyup", handleEnterlogin);
    return () => {
      window.removeEventListener("keyup", handleEnterlogin);
    };
  }, []);

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100 p-4 shadow-soft border-light">
          <h3 className='title-main'>{lang.login_main_top}</h3>
          <p className='title-main-1'>{lang.login_main_bottom}</p>
          <Form ref={ref} form={loginForm} id="settingReminderForm" onFinish={onLogin}>
            <LabelRequired title={lang.user_name} />
            <Form.Item
              name="userName"
              rules={[
                {
                  required: true,
                  message: lang.user_fullname_required
                },
                {
                  validator: userNameValidator
                }
              ]}
            >
              <Input size="large" placeholder={lang.user_name} />
            </Form.Item>
            <LabelRequired title={lang.password} />
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: lang.user_password_required
                }
              ]}
            >
              <Input size="large" type="password" placeholder={lang.password} />
            </Form.Item>
          </Form>
          {/* <div className="text-center">
            <a>Lost password?</a>
          </div> */}
          <Button size="large" onClick={onLogin} className="text-center mt-15 mb-15" block>
            {lang.login}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
