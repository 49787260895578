/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Form, Button, message, Tabs, Card } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as c from '../../../../constants/common';
import { useSelector, useDispatch } from 'react-redux';
import { appointmentSettingThunk } from './../thunk';
import * as appointmentSettingService from './../service';
import { setLoading } from '../../../Common/slice';

import AppointmentSettingGeneral from './AppointmentSettingGeneral';
import AppointmentSettingSMS from './AppointmentSettingSMS';
import AppointmentSettingEmail from './AppointmentSettingEmail';

const AppointmentSetting = (props) => {
	const { TabPane } = Tabs;
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const appointmentSetting = useSelector((state) => state.appointmentSetting);

	useEffect(() => {
		dispatch(appointmentSettingThunk({}));
	}, []);

	useEffect(() => {
		form.setFieldsValue({
			appointment_setting_fromtime: appointmentSetting.appointment_setting_fromtime,
			appointment_setting_totime: appointmentSetting.appointment_setting_totime,
			appointment_setting_range: appointmentSetting.appointment_setting_range,
			appointment_setting_booking_date_limit: appointmentSetting.appointment_setting_booking_date_limit,
			appointment_setting_min_booking_time: appointmentSetting.appointment_setting_min_booking_time,
			appointment_setting_time_remind: appointmentSetting.appointment_setting_time_remind,
			appointment_allow_sms_confirm: appointmentSetting.appointment_allow_sms_confirm === c.TRUE_FALSE.TRUE,
			appointment_allow_admin_sms_cancel: appointmentSetting.appointment_allow_admin_sms_cancel === c.TRUE_FALSE.TRUE,
			appointment_allow_customer_sms_cancel:
				appointmentSetting.appointment_allow_customer_sms_cancel === c.TRUE_FALSE.TRUE,
			appointment_allow_sms_remind: appointmentSetting.appointment_allow_sms_remind === c.TRUE_FALSE.TRUE,
			appointment_content_sms_confirm:
				appointmentSetting.appointment_content_sms_confirm || lang.appointment_content_sms_confirm,
			appointment_content_admin_sms_cancel:
				appointmentSetting.appointment_content_admin_sms_cancel || lang.appointment_content_admin_sms_cancel,
			appointment_content_customer_sms_cancel:
				appointmentSetting.appointment_content_customer_sms_cancel || lang.appointment_content_customer_sms_cancel,
			appointment_content_sms_remind:
				appointmentSetting.appointment_content_sms_remind || lang.appointment_content_sms_remind,
			appointment_allow_email_confirm: appointmentSetting.appointment_allow_email_confirm === c.TRUE_FALSE.TRUE,
			appointment_allow_admin_email_cancel:
				appointmentSetting.appointment_allow_admin_email_cancel === c.TRUE_FALSE.TRUE,
			appointment_allow_customer_email_cancel:
				appointmentSetting.appointment_allow_customer_email_cancel === c.TRUE_FALSE.TRUE,
			appointment_allow_email_remind: appointmentSetting.appointment_allow_email_remind === c.TRUE_FALSE.TRUE,
			appointment_content_email_confirm:
				appointmentSetting.appointment_content_email_confirm || lang.appointment_content_email_confirm,
			appointment_content_admin_email_cancel:
				appointmentSetting.appointment_content_admin_email_cancel || lang.appointment_content_admin_email_cancel,
			appointment_content_customer_email_cancel:
				appointmentSetting.appointment_content_customer_email_cancel || lang.appointment_content_customer_email_cancel,
			appointment_content_email_remind:
				appointmentSetting.appointment_content_email_remind || lang.appointment_content_email_remind,
			appointment_title_email_confirm:
				appointmentSetting.appointment_title_email_confirm || lang.appointment_title_email_confirm,
			appointment_title_admin_email_cancel:
				appointmentSetting.appointment_title_admin_email_cancel || lang.appointment_title_admin_email_cancel,
			appointment_title_customer_email_cancel:
				appointmentSetting.appointment_title_customer_email_cancel || lang.appointment_title_customer_email_cancel,
			appointment_title_email_remind:
				appointmentSetting.appointment_title_email_remind || lang.appointment_title_email_remind,
		});
	}, [appointmentSetting]);

	const onSubmit = (data) => {
		dispatch(setLoading(true));
		const dataReq = {
			appointment_setting_fromtime: data.appointment_setting_fromtime,
			appointment_setting_totime: data.appointment_setting_totime,
			appointment_setting_range: data.appointment_setting_range,
			appointment_setting_booking_date_limit: data.appointment_setting_booking_date_limit,
			appointment_setting_id: appointmentSetting.appointment_setting_id || '',
			appointment_setting_min_booking_time: data.appointment_setting_min_booking_time,
			appointment_setting_time_remind: data.appointment_setting_time_remind,
			appointment_allow_sms_confirm: data.appointment_allow_sms_confirm ? c.TRUE_FALSE.TRUE : c.TRUE_FALSE.FALSE,
			appointment_allow_admin_sms_cancel: data.appointment_allow_admin_sms_cancel
				? c.TRUE_FALSE.TRUE
				: c.TRUE_FALSE.FALSE,
			appointment_allow_customer_sms_cancel: data.appointment_allow_customer_sms_cancel
				? c.TRUE_FALSE.TRUE
				: c.TRUE_FALSE.FALSE,
			appointment_allow_sms_remind: data.appointment_allow_sms_remind ? c.TRUE_FALSE.TRUE : c.TRUE_FALSE.FALSE,
			appointment_content_sms_confirm: data.appointment_content_sms_confirm || '',
			appointment_content_admin_sms_cancel: data.appointment_content_admin_sms_cancel || '',
			appointment_content_customer_sms_cancel: data.appointment_content_customer_sms_cancel || '',
			appointment_content_sms_remind: data.appointment_content_sms_remind || '',
			appointment_allow_email_confirm: data.appointment_allow_email_confirm ? c.TRUE_FALSE.TRUE : c.TRUE_FALSE.FALSE,
			appointment_allow_admin_email_cancel: data.appointment_allow_admin_email_cancel
				? c.TRUE_FALSE.TRUE
				: c.TRUE_FALSE.FALSE,
			appointment_allow_customer_email_cancel: data.appointment_allow_customer_email_cancel
				? c.TRUE_FALSE.TRUE
				: c.TRUE_FALSE.FALSE,
			appointment_allow_email_remind: data.appointment_allow_email_remind ? c.TRUE_FALSE.TRUE : c.TRUE_FALSE.FALSE,
			appointment_content_email_confirm: data.appointment_content_email_confirm || '',
			appointment_content_admin_email_cancel: data.appointment_content_admin_email_cancel || '',
			appointment_content_customer_email_cancel: data.appointment_content_customer_email_cancel || '',
			appointment_content_email_remind: data.appointment_content_email_remind || '',
			appointment_title_email_confirm: data.appointment_title_email_confirm || '',
			appointment_title_admin_email_cancel: data.appointment_title_admin_email_cancel || '',
			appointment_title_customer_email_cancel: data.appointment_title_customer_email_cancel || '',
			appointment_title_email_remind: data.appointment_title_email_remind || '',
		};
		appointmentSettingService
			.appointmentSettingUpdate(dataReq)
			.then((res) => {
				if (res) {
					dispatch(setLoading(false));
					message.success(lang.save_success);
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
				dispatch(appointmentSettingThunk({}));
			});
	};

	return (
		<Card>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{APP_TITLE} - {lang.appointment_setting}
				</title>
			</Helmet>
			<Form onFinish={onSubmit} autoComplete="off" form={form} layout="vertical">
				<Tabs tabPosition="left">
					<TabPane tab={lang.setting_general} key="1">
						<AppointmentSettingGeneral form={form} />
					</TabPane>
					<TabPane tab={lang.sms_send} key="2">
						<AppointmentSettingSMS />
					</TabPane>
					<TabPane tab={lang.email_send} key="3">
						<AppointmentSettingEmail />
					</TabPane>
				</Tabs>

				<Row gutter={24}>
					<Col xs={24} md={24} className="text-center mt-15">
						<CheckPermission permission={['APPOINTMENT_SETTING_EDIT']}>
							<Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary">
								{lang.save}
							</Button>
						</CheckPermission>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default AppointmentSetting;
