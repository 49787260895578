import {
  SettingOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Popconfirm } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../../language/language';
import CheckPermission from '../../../../Common/components/CheckPermission';
import * as commonService from '../../../../Common/service';
import * as roleService from '../../service';
import { setRoleListId } from '../../slice';
import * as roleThunk from '../../thunk';
import * as c from '../../../../../constants/common';

const RoleActionChecked = (props) => {
  const roleListId = useSelector(state => state.role.roleListId);
  const queryData = useSelector(state => state.role.roleQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (roleListId.length > 0) {
      const data = {
        list_id: roleListId,
        update_user: userInfo.user_id
      };
      roleService.roleDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(roleThunk.roleList(queryData));
            dispatch(setRoleListId([]));
            message.success(lang.delete_success);
          }
        }
      });
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (roleListId.length > 0) {
      const data = {
        list_id: roleListId,
        field_name: 'role_id',
        table: 'role',
        status,
        update_user: userInfo.user_id,
        permission: ['ROLE_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(roleThunk.roleList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['ROLE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['ROLE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['ROLE_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    roleListId.length > 0 && <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default RoleActionChecked;
