/* eslint-disable no-unmodified-loop-condition */
import moment from 'moment';

export const GetListDateByNumber = (number = 7) => {
    const result = [moment().format('YYYY-MM-DD')];
    for (let i = 0; i < number - 1; i++) {
        result.push(moment(result[i]).add(1, 'days').format('YYYY-MM-DD'))
    }
    return result;
}
