/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import lang from '../../../../../language/language';
import { Row, Col, Button, Popover, Space, Input, Radio, Form, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import {
    SaveOutlined,
    DollarOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
import { formatCurrency } from '../../../../Common/components/FormatData';
import * as c from '../../../../../constants/common';
import { invoiceSurchargeCreate } from '../../../service';
import { setLoading } from '../../../../Common/slice';
import { getInvoiceDetail } from '../../../thunk';
import { getInvoiceTotalSurcharge, updateInvoiceInfo } from '../../../common';

const InvoiceActionDetailSurcharge = (props) => {
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const [isVisible, setIsvisible] = useState(false)
    const [discountType, setDiscountType] = useState(c.DISCOUNT_TYPE.PERCENT)
    const [surchargeList, setSurchargeList] = useState([])
    const [form] = Form.useForm();
    const generalSetting = useSelector(state => state.generalSetting);
    const userInfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch()
    const posSetting = useSelector(state => state.posSetting);

    useEffect(() => {
        setSurchargeList(invoiceDetail.invoiceSurchargeList)
    }, [invoiceDetail.invoiceSurchargeList, isVisible])

    /// /funtion///////s

    const onClose = () => {
        onClearData()
    }

    const onClearData = () => {
        setIsvisible(false)
        form.resetFields()
    }

    const onSave = () => {
        dispatch(setLoading(true))
        invoiceSurchargeCreate({ invoiceSurchargeList: surchargeList }).then(res => {
            if (res) {
                dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                updateInvoiceInfo({
                    ...invoiceDetail,
                    invoiceSurchargeList: surchargeList,
                    invoice_total_surcharge: getInvoiceTotalSurcharge(surchargeList)
                }, dispatch, posSetting)
                onClearData()
            }
        })
    }

    const onChangeDiscountType = (e) => {
        setDiscountType(e.target.value)
    }

    const columns = [

        {
            title: lang.invoice_surcharge_name,
            dataIndex: 'invoice_surcharge_name'
        },
        {
            title: lang.invoice_surcharge_money,
            dataIndex: 'invoice_surcharge_money',
            render: (text, record) => {
                return <div >{formatCurrency(record.invoice_surcharge_money, generalSetting)}</div>
            }
        },
        {
            title: <MinusCircleOutlined onClick={() => onDeleteSurcharge()} className="cursor-pointer" style={{ fontSize: 18 }} />,
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => {
                return <MinusCircleOutlined onClick={() => onDeleteSurcharge(record)} className="cursor-pointer" style={{ fontSize: 18 }} />
            }
        }
    ];

    const onAdd = (data) => {
        const list = [...surchargeList]
        let surchargeMoney = 0
        if (discountType === c.DISCOUNT_TYPE.PERCENT) {
            surchargeMoney = (invoiceDetail.invoice_temp_money * data.invoice_surcharge_money) / 100
        } else {
            surchargeMoney = data.invoice_surcharge_money
        }
        list.push({
            ...data,
            invoice_surcharge_money: surchargeMoney,
            invoice_surcharge_id: list.length + 1,
            invoice_id: invoiceId,
            create_user: userInfo.user_id
        })
        setSurchargeList(list)
        form.resetFields()
    }

    const onDeleteSurcharge = (data = null) => {
        if (data) {
            const list = [...surchargeList]
            setSurchargeList(list.filter(x => x.invoice_surcharge_id !== data.invoice_surcharge_id))
        } else {
            setSurchargeList([])
        }
    }

    /// /render///////
    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            overlayClassName="popover-medium"
            visible={isVisible}
            content={
                <Form
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onAdd}
                    form={form}
                >
                    <Row gutter={12}>
                        <Col xs={24} md={24} className="border-bottom-1 mb-15">
                            <h3>{lang.surcharge}</h3>
                        </Col>
                        <Col xs={24} md={24} >
                            <Form.Item
                                label={lang.invoice_surcharge_name}
                                name="invoice_surcharge_name"
                                rules={[
                                    { required: true, message: lang.value_is_required }
                                ]}
                            >
                                <Input
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={20} >
                            <Form.Item
                                label={lang.invoice_surcharge_money}
                                name="invoice_surcharge_money"
                                rules={[
                                    { required: true, message: lang.value_is_required }
                                ]}
                            >
                                <Input
                                    size="large"
                                    type='number'
                                    addonAfter={
                                        <Radio.Group onChange={onChangeDiscountType} value={discountType}>
                                            <Radio value={c.DISCOUNT_TYPE.PERCENT}>%</Radio>
                                            <Radio value={c.DISCOUNT_TYPE.MONEY}>$</Radio>
                                        </Radio.Group>
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <Form.Item
                                label={' '}
                            >
                                <Button
                                    type="primary"
                                    style={{ height: 40, marginTop: 3 }}
                                    onClick={() => form.submit()}
                                    size="middle">
                                    {lang.add}
                                </Button>
                            </Form.Item>

                        </Col>
                        <Col xs={24} md={24} className="mb-15">
                            <Table columns={columns} dataSource={surchargeList} size="small" pagination={false} />
                        </Col>
                        <Col xs={12} md={12} className="text-center">
                            <h3 className='mb-0'><b>{lang.total_price}: {formatCurrency(invoiceDetail.invoice_temp_money, generalSetting)}</b></h3>
                        </Col>
                        <Col xs={12} md={12} className="text-center">
                            <h3 className='mb-0'><b>{lang.invoice_total_surcharge}: {formatCurrency(getInvoiceTotalSurcharge(surchargeList), generalSetting)}</b></h3>
                        </Col>
                        <Col xs={24} md={24} className="text-right pt-15 mt-15 border-top-1">
                            <Space>
                                <Button
                                    onClick={onClose}
                                    className="w-100"
                                    type="primary"
                                    size="large"
                                    block
                                ><CloseCircleOutlined /> {lang.close}
                                </Button>
                                <Button
                                    className="w-100"
                                    disabled={!invoiceId}
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={onSave}
                                >
                                    <SaveOutlined /> {lang.save}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
        >
            <Button
                type="primary"
                onClick={() => { setIsvisible(true) }}
                size="large"
                block
                disabled={!invoiceId || invoiceDetail.invoiceItemList.length === 0}
            ><DollarOutlined />
                {lang.surcharge}
            </Button>
        </Popover>
    );
};

export default InvoiceActionDetailSurcharge;
