/* eslint-disable no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setLoadingTable } from '../../../Common/slice';
import { Table, Pagination, Typography, Tooltip } from 'antd';
import lang from '../../../../language/language';
import * as c from '../../../../constants/common';
import { formatCurrency, formatDate, formatPhone } from '../../../Common/components/FormatData';
import { StarFilled } from '@ant-design/icons';
import moment from 'moment';
const { Text } = Typography;

const AntdTable = (props) => {
	const {
		data,
		queryData,
		setQueryData,
		listIdChecked,
		setListIdChecked,
		keyField,
		clickField,
		onClickKey,
		expandedRowRender,
		isShowCreateDateHour,
		hideCheckbox,
		setQueryDataLocal,
	} = props;
	const loadingTable = useSelector((state) => state.common.loadingTable);
	const dispatch = useDispatch();
	const [expandedRowKeys, setExpandedRowKeys] = useState([]);
	const generalSetting = useSelector((state) => state.generalSetting);

	useEffect(() => {
		if (data.columns && data.columns.length > 0) {
			dispatch(setLoadingTable(false));
		}
	}, [data.columns]);

	const CustomColumn = (columns = []) => {
		const result = [];
		if (columns.length > 0) {
			columns
				.filter((item) => item.column_hidden === 0)
				.map((item, index) => {
					return result.push({
						title: lang[item.column_field],
						dataIndex: item.column_field,
						key: item.column_field,
						ellipsis: true,
						textWrap: 'word-break',
						fixed: item.column_fixed === 1 ? 'left' : null,
						width: item.column_width || 150,
						sorter: item.column_sort === 1,
						// align: item.column_align,
						showSorterTooltip: false,
						render: (text, record, index) => {
							if (item.column_field === 'is_active') {
								return record.is_active === c.IS_DEACTIVE ? lang.de_active : lang.active;
							} else if (item.column_field === clickField) {
								if (record.is_main === 1) {
									return (
										<span className="cursor-pointer" style={{ color: '#222222', fontWeight: 'bold' }}>
											{text}
											<Tooltip title={lang.is_main}>
												{' '}
												<StarFilled />
											</Tooltip>
										</span>
									);
								} else {
									return (
										<span
											className="cursor-pointer"
											onClick={() => {
												onClickKey ? onClickKey(record) : null;
											}}
											style={{ color: '#222222', fontWeight: 'bold' }}
										>
											{text}
										</span>
									);
								}
							} else if (
								item.column_field === 'customer_birthday' ||
								item.column_field === 'staff_birthday' ||
								item.column_field === 'user_birthday' ||
								item.column_field === 'appointment_date'
							) {
								if (isShowCreateDateHour) {
									return formatDate(text, true, generalSetting);
								} else {
									return formatDate(text, false, generalSetting);
								}
							} else if (item.column_field === 'gift_card_expiry_date') {
								if (!record.gift_card_expiry_date) {
									return lang.not_expiry;
								} else {
									return formatDate(text, true, generalSetting);
								}
							} else if (item.column_field === 'create_date' || item.column_field === 'update_date') {
								return formatDate(text, true, generalSetting);
							}
							if (
								item.column_field === 'service_price' ||
								item.column_field === 'service_price_discount' ||
								item.column_field === 'gift_card_balance' ||
								item.column_field === 'invoice_temp_money' ||
								item.column_field === 'invoice_total_promotion' ||
								item.column_field === 'invoice_tax' ||
								item.column_field === 'invoice_customer_need_to_pay' ||
								item.column_field === 'invoice_money_received' ||
								item.column_field === 'invoice_change' ||
								item.column_field === 'invoice_tip' ||
								item.column_field === 'invoice_total_surcharge' ||
								item.column_field === 'total_payment' ||
								item.column_field === 'income'
							) {
								return formatCurrency(text, generalSetting);
							}
							if (item.column_field === 'discount') {
								if (record.discount_type === c.DISCOUNT_TYPE.PERCENT) {
									return text + '%';
								} else {
									return formatCurrency(text, generalSetting);
								}
							}

							if (
								item.column_field === 'customer_phone_number' ||
								item.column_field === 'customer_phone_number2' ||
								item.column_field === 'staff_phone_number' ||
								item.column_field === 'user_phone_number' ||
								item.column_field === 'sms_history_to' ||
								item.column_field === 'branch_phone_number'
							) {
								return formatPhone(text);
							} else if (String(text).includes('{DELETE}')) {
								return <Text delete>{String(text).replace('{DELETE}', '')}</Text>;
							} else if (item.column_field === 'service_duration') {
								return `${text} ${lang.minute}`;
							} else if (item.column_field === 'appointment_status' && text) {
								return c.APPOINTMENT_STATUS_LIST.filter((e) => e.value === parseInt(text))[0].label;
							} else if (item.column_field === 'invoice_payment_method') {
								if (record.invoice_payment_method && record.invoice_payment_method.length > 0) {
									return record.invoice_payment_method.map((x, index) => {
										const showX = record.invoice_payment_method.length !== index + 1 ? ', ' : '';
										return c.PAYMENT_METHOD.filter((z) => z.value === x.invoice_payment_method)[0].label + showX;
									});
								} else {
									const method = c.PAYMENT_METHOD.filter((z) => z.value === record.invoice_payment_method);
									return method.length > 0 && method[0].label;
								}
							} else if (item.column_field === 'invoice_status') {
								if (text === c.INVOICE_STATUS.CANCEL) {
									return lang.cancel;
								} else if (text === c.INVOICE_STATUS.PAID) {
									return lang.paid;
								} else {
									return lang.unpaid;
								}
							} else if (item.column_field === 'appointment_hour' || item.column_field === 'appointment_end_hour') {
								return moment(text, 'hh:ss').format('hh:ss A');
							} else if (item.column_field === 'gift_card_history_money') {
								if (text > 0) {
									return `+${formatCurrency(text, generalSetting)}`;
								} else {
									return formatCurrency(text, generalSetting);
								}
							} else if (item.column_field === 'customer_reward_point_history') {
								if (text > 0) {
									return `+${text}`;
								} else {
									return text;
								}
							} else if (item.column_field === 'checkin_history_type') {
								const typeHistory = c.CHECKIN_TYPE.filter((x) => x.value === parseInt(text));
								if (typeHistory.length > 0) {
									return typeHistory[0].label;
								}
							} else if (item.column_field === 'create_from') {
								const data = c.CREATE_FROM.filter((x) => x.value === parseInt(text));
								if (data.length > 0) {
									return data[0].label;
								}
							}
							if (item.column_field === 'sms_history_status') {
								return record.sms_history_status === c.SMS_HISTORY_STATUS.SUCCESS ? lang.success : lang.fail;
							} else if (item.column_field === 'create_date_appointment') {
								return formatDate(text, false, generalSetting);
							} else {
								return text;
							}
						},
					});
				});
		}
		return result;
	};

	const onChangePagination = (page, pageSize) => {
		dispatch(setLoadingTable(true));
		if (setQueryDataLocal) {
			setQueryDataLocal({ ...queryData, page, sizePerPage: pageSize });
		} else {
			dispatch(setQueryData({ ...queryData, page, sizePerPage: pageSize }));
		}
	};

	const onChangeTable = (pagination, filters, sorter) => {
		dispatch(setLoadingTable(true));
		const order = sorter.order === 'ascend' ? 'asc' : 'desc';
		if (setQueryDataLocal) {
			setQueryDataLocal({ ...queryData, orderBy: `${sorter.field} ${order}` });
		} else {
			dispatch(setQueryData({ ...queryData, orderBy: `${sorter.field} ${order}` }));
		}
	};

	const rowSelection = {
		fixed: true,
		onChange: (selectedRowKeys, selectedRows) => {
			// console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
		onSelect: (record, selected, selectedRows) => {
			// console.log(record, selected, selectedRows);
			const list = [...listIdChecked];
			if (selected) {
				list.push(record[`${keyField}`]);
				dispatch(setListIdChecked(list));
			} else {
				const filter = list.filter((_item) => _item !== record[`${keyField}`]);
				dispatch(setListIdChecked(filter));
			}
		},
		onSelectAll: (selected, selectedRows, changeRows) => {
			// console.log(selected, selectedRows, changeRows);
			const list = [...listIdChecked];
			if (selected && selectedRows.length > 0) {
				selectedRows.map((item) => {
					return list.push(item[`${keyField}`]);
				});
				dispatch(setListIdChecked(list));
			} else {
				dispatch(setListIdChecked([]));
			}
		},
	};

	// Only open one row at a time
	const onTableRowExpand = (expanded, record) => {
		const keys = [];
		if (expanded) {
			keys.push(record.key);
		}
		setExpandedRowKeys(keys);
	};

	/// /render///
	return (
		<div>
			<Table
				className="mb-15 mt-15 table-spacing"
				dataSource={
					data.rows &&
					data.rows.map((item, index) => {
						return { ...item, key: index };
					})
				}
				columns={CustomColumn(data.columns)}
				scroll={{ x: 500 }}
				bordered
				pagination={false}
				loading={loadingTable}
				size="large"
				onChange={onChangeTable}
				rowSelection={hideCheckbox ? null : { ...rowSelection }}
				expandedRowKeys={expandedRowKeys}
				expandable={{
					expandedRowRender: (record) => (expandedRowRender ? expandedRowRender(record) : null),
					expandRowByClick: !!expandedRowRender,
					expandIconColumnIndex: -1,
				}}
				onExpand={onTableRowExpand}
				onRow={(record, rowIndex) => {
					return {
						onClick: (event) => {}, // click row
						onDoubleClick: (event) => {}, // double click row
						onContextMenu: (event) => {}, // right button click row
						onMouseEnter: (event) => {}, // mouse enter row
						onMouseLeave: (event) => {}, // mouse leave row
					};
				}}
			/>
			<Pagination
				showTotal={(total, range) =>
					`${lang.showing} ${range[0]}-${range[1]} / ${lang.total} ${total} ${
						listIdChecked && listIdChecked.length > 0
							? listIdChecked && '(' + listIdChecked.length + lang.selected + ')'
							: ''
					}`
				}
				pageSizeOptions={[5, 10, 20, 50, 100]}
				total={data.total}
				showSizeChanger
				pageSize={queryData ? queryData.sizePerPage : c.SIZE_PAGE.PAGE}
				current={queryData ? queryData.page : c.SIZE_PAGE.SIZE_PER_PAGE}
				onChange={onChangePagination}
				className="mb-15 align-right"
			/>
		</div>
	);
};
export default AntdTable;
