import { createSlice } from '@reduxjs/toolkit';
import * as reportAppointmentThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';
import moment from 'moment';

export const reportAppointmentSlice = createSlice({
  name: 'reportAppointment',
  initialState: {
    reportAppointmentList: {},
    reportAppointmentId: '',
    reportAppointmentListId: [],
    reportAppointmentQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      orderBy: '',
      userId: getInfoUser.user_id,
      reportType: c.REPORT_APPOINTMENT_TYPE[0].value
    }
  },
  reducers: {
    clearReportAppointmentList: (state, action) => {
      state.reportAppointmentList = {};
    },
    clearReportAppointmentRow: (state, action) => {
      state.reportAppointmentList.rows = [];
    },
    setReportAppointmentId: (state, action) => {
      state.reportAppointmentId = action.payload;
    },
    clearReportAppointmentId: (state, action) => {
      state.reportAppointmentId = '';
    },
    setReportAppointmentQueryData: (state, action) => {
      state.reportAppointmentQueryData = action.payload;
    },
    setReportAppointmentListId: (state, action) => {
      state.reportAppointmentListId = action.payload;
    },
    clearReportQueryData: (state, action) => {
      state.reportAppointmentQueryData = {
        page: c.SIZE_PAGE.PAGE,
        sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
        search: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        orderBy: '',
        userId: getInfoUser.user_id,
        reportType: c.REPORT_APPOINTMENT_TYPE[0].value
      }
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [reportAppointmentThunk.reportAppointmentList.fulfilled]: (state, action) => {
      state.reportAppointmentList = action.payload;
    }
  }
});

export const {
  clearReportAppointmentList,
  clearReportAppointmentRow,
  setReportAppointmentId,
  setReportAppointmentQueryData,
  setReportAppointmentListId,
  clearReportAppointmentId,
  clearReportQueryData
} = reportAppointmentSlice.actions;

export default reportAppointmentSlice.reducer;
