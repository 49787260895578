import React, { useEffect } from 'react';
import lang from '../../../language/language';
import { getInvoiceList } from '../thunk';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setInvoiceId } from '../../Invoice/slice';

const { Option } = Select;

const InvoiceSelect = (props) => {
  const { size, isMultiple, onChange, onSelect, onClear, style } = props;
  const options = useSelector(state => state.common.invoiceList);
  const invoiceId = useSelector(state => state.invoice.invoiceId);
  const loading = useSelector(state => state.common.loading);

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInvoiceList())
  }, [loading]);

  const onChangeData = (data, option) => {
    if (onChange) {
      onChange(option)
    }
  }
  const onSelectData = (data, option) => {
    if (onSelect) {
      onSelect(data)
    }
  }

  const onClearData = () => {
    dispatch(setInvoiceId(null))
    onClear()
  }

  /// render/////
  return (
    <Select
      showSearch
      allowClear
      maxTagCount='responsive'
      placeholder={lang.invoice}
      size={size || 'large'}
      mode={isMultiple ? 'multiple' : ''}
      onChange={onChangeData}
      value={invoiceId || undefined}
      style={style || { width: '100%' }}
      onClear={onClearData}
      onSelect={onSelectData}
      filterOption={(input, option) => {
        const data = option.data.invoice_code
        return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
      }}
    >
      {options.map((item) => {
        return <Option key={item.invoice_id} value={item.invoice_id} data={item}>
          {item.invoice_code}
        </Option>
      })}
    </Select>
  )
};

export default InvoiceSelect;
