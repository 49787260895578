import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { ImportExcel } from '../../../modules/Common/components/ImportExcel';
import { customerImport } from '../service'
import * as c from '../../../constants/common';
import { setLoading } from '../../../modules/Common/slice';
import * as customerThunk from '../thunk';
import {
    SaveOutlined,
    CloseCircleOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Drawer, Space, Button, Row, Col, Alert, message } from 'antd';

const CustomerImport = (props) => {
    const queryData = useSelector(state => state.customer.customerQueryData);
    const [dataImport, setDataImport] = useState([]);
    const userInfo = useSelector(state => state.user.userInfo);
    const getFileExportDemo = useSelector(state => state.common.getFileExportDemo);
    const currentBranch = useSelector(state => state.branch.currentBranch);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const refImport = useRef()
    const dispatch = useDispatch();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onSave = () => {
        if (refImport.current.files.length === 0) {
            message.error(lang.please_select_file);
            return;
        }
        if (refImport.current.files.length > 0 && dataImport.length > 0) {
            const data = []
            dataImport.map((item) => {
                if (item[0] && item[1]) {
                    return data.push({
                        customer_code: item[0],
                        customer_fullname: item[1],
                        customer_phone_number: item[2],
                        customer_phone_number2: item[3],
                        customer_gender: item[4] === '' ? '' : item[4] === lang.male ? c.GENDER.MALE : item[4] === lang.FEMALE ? c.GENDER.FEMALE : c.GENDER.OTHER,
                        customer_type: item[5] === '' ? c.CUSTOMER_TYPE.PERSONAL : item[5] === lang.personal ? c.CUSTOMER_TYPE.PERSONAL : c.CUSTOMER_TYPE.COMPANY,
                        customer_tax_code: item[6],
                        customer_birthday: item[7],
                        customer_email: item[8],
                        customer_facebook: item[9],
                        customer_yelp: item[10],
                        customer_address: item[11],
                        customer_note: item[12],
                        is_active: item[13] === lang.active ? c.IS_ACTIVE : c.IS_DEACTIVE,
                        province_id: '',
                        district_id: '',
                        ward_id: '',
                        branch_id: currentBranch.branch_id,
                        create_user: userInfo.user_id
                    })
                } else {
                    return null
                }
            })
            if (data.length > 0) {
                dispatch(setLoading(true));
                customerImport({ customer_list: data }).then(res => {
                    const resData = res.data
                    if (resData.status) {
                        dispatch(customerThunk.customerList(queryData));
                        message.success(lang.save_success);
                    }
                }).finally(() => {
                    dispatch(setLoading(false))
                });
            }
        }
    }

    const onClose = () => {
        onClearData()
        setIsModalVisible(false)
    }

    const onClearData = () => {
        refImport.current.value = ''
        setDataImport([])
    }

    /// render/////
    return (
        <span>
            <Button size="large" onClick={showModal} type="primary" icon={<UploadOutlined />} />
            <Drawer
                title={lang.import + ' ' + lang.customer.toLowerCase()}
                placement="right"
                width={c.WIDTH_DRAWER.SMALL}
                onClose={onClose}
                visible={isModalVisible}
                footer={
                    <Space className="text-center">
                        <Button icon={<CloseCircleOutlined />} size="large" key="back" onClick={onClose}>
                            {lang.close}
                        </Button>
                        <Button icon={<SaveOutlined />} onClick={onSave} size="large" key="submit" type="primary" >
                            {lang.apply}
                        </Button>
                    </Space>
                }
            >

                <Row >
                    <Col xs={24} md={24} className="mb-15 download-excel-demo">
                        {lang.download_demo}: <a href={getFileExportDemo.file_customer_demo}>Excel</a>
                    </Col>
                    <Col xs={24} md={24}>
                        <Col>
                            <input
                                id="customerImport"
                                style={{ display: 'none' }}
                                onChange={(e) => ImportExcel(e, setDataImport)}
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={refImport}
                            />
                            <Button
                                size="large"

                                block
                                onClick={() => refImport.current.click()}
                                icon={<UploadOutlined />}
                            >{lang.choose_file}</Button>
                        </Col>
                    </Col>
                    <Col xs={24} md={24}>
                        {refImport.current && refImport.current.files.length > 0 && <Alert
                            message={refImport.current.files[0].name}
                            type="info"
                            action={<Button
                                size="small"
                                icon={<CloseCircleOutlined />}
                                type="text"
                                onClick={onClearData}
                            />}
                        />}
                    </Col>
                </Row>
            </Drawer>
        </span>
    );
};

export default CustomerImport;
