import { createSlice } from '@reduxjs/toolkit';
import * as roleThunk from './thunk';
import { getInfoUser } from './../../Common/components/GetInfoUser';
import * as c from '../../../constants/common';

export const roleSlice = createSlice({
  name: 'role',
  initialState: {
    getRoleByUser: [],
    roleList: {},
    roleListAll: [],
    roleListByAppDeActive: [],
    roleInfo: {},
    roleId: '',
    roleListId: [],
    roleListAction: [],
    roleQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearRoleList: (state, action) => {
      state.roleList = {};
    },
    clearRoleRow: (state, action) => {
      state.roleList.rows = [];
    },
    setRoleId: (state, action) => {
      state.roleId = action.payload;
    },
    clearRoleId: (state, action) => {
      state.roleId = '';
    },
    setRoleQueryData: (state, action) => {
      state.roleQueryData = action.payload;
    },
    setRoleListId: (state, action) => {
      state.roleListId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [roleThunk.getRoleByUser.fulfilled]: (state, action) => {
      state.getRoleByUser = action.payload;
    },
    [roleThunk.roleList.fulfilled]: (state, action) => {
      state.roleList = action.payload;
    },
    [roleThunk.roleListAction.fulfilled]: (state, action) => {
      state.roleListAction = action.payload;
    },
    [roleThunk.roleListAll.fulfilled]: (state, action) => {
      state.roleListAll = action.payload;
    },
    [roleThunk.getRoleListByAppDeActive.fulfilled]: (state, action) => {
      state.roleListByAppDeActive = action.payload;
    }
  }
});

export const {
  clearRoleList,
  clearRoleRow,
  setRoleId,
  clearRoleId,
  setRoleQueryData,
  setRoleListId
} = roleSlice.actions;

export default roleSlice.reducer;
