/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import { Row, Col } from 'antd';
import * as c from '../../../../constants/common';
import * as customerService from '../../service';
import { getInfoUser } from '../../../Common/components/GetInfoUser';
import CustomerDetailCheckinHistoryFilter from './CustomerDetailCheckinHistoryFilter';

const CustomerDetailCheckinHistory = (props) => {
  const { data } = props
  const [dataList, setDataList] = useState({})
  const [queryData, setQueryData] = useState({
    page: c.SIZE_PAGE.PAGE,
    sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_DETAIL,
    search: '',
    fromDate: '',
    toDate: '',
    orderBy: '',
    userId: getInfoUser.user_id,
    branchId: null,
    customer_id: null,
    status: ''
  })

  useEffect(() => {
    if (data.customer_id) {
      setDataList({})
      setQueryData({
        page: c.SIZE_PAGE.PAGE,
        sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_DETAIL,
        search: '',
        fromDate: '',
        toDate: '',
        orderBy: '',
        userId: getInfoUser.user_id,
        branchId: null,
        customer_id: data.customer_id,
        status: ''
      })
    }
  }, [data.customer_id]);

  useEffect(() => {
    if (queryData.customer_id) {
      fetchDataList()
    }
  }, [queryData]);

  /// /funtion///////
  const fetchDataList = () => {
    customerService.customerCheckinHistoryList(queryData).then(res => {
      if (res) {
        setDataList(res.data)
      }
    })
  };

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.customer_list}</title>
      </Helmet>
      <Row className="table-detail pl-10 pr-10">
        <Col lg={24} md={24} xs={24}>
          <Row type="flex" justify="end">
            <Col>
              <CustomerDetailCheckinHistoryFilter queryData={queryData} setQueryData={setQueryData} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            queryData={queryData}
            keyField="checkin_history_id"
            clickField="checkin_history_id"
            hideCheckbox
            setQueryDataLocal={setQueryData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomerDetailCheckinHistory;
