/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { clearServiceGroupRow, setServiceGroupQueryData } from '../slice';
import DateSelect from '../../Common/components/DateSelect';
import StatusSelect from '../../Common/components/StatusSelect';
import {
  FilterOutlined,
  ClearOutlined,
  SaveOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { Drawer, Space, Button, Row, Col, Form, Badge } from 'antd';
import { setLoadingTable } from '../../../modules/Common/slice';
import moment from 'moment';
import * as c from '../../../constants/common';

const ServiceGroupFilter = (props) => {
  const queryData = useSelector(state => state.serviceGroup.serviceGroupQueryData);
  const dispatch = useDispatch();
  const [countFilter, setCountFilter] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [form] = Form.useForm();

  const count = (obj = {}) => {
    const data = Object.keys(obj);
    let count = 0;
    if (data.length > 0) {
      data.map((item) => {
        if (!obj[item] || obj[item].length === 0) {
          return 0
        } else {
          count++
        }
      })
    }
    setCountFilter(count)
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit()
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (dataFilter) => {
    setIsModalVisible(false)
    dispatch(clearServiceGroupRow([]));
    const query = Object.assign({ ...queryData, page: 1 }, {
      isActive: dataFilter.status ? dataFilter.status : null,
      fromDate: dataFilter.create_date ? moment(dataFilter.create_date[0]).format('YYYY-MM-DD') : null,
      toDate: dataFilter.create_date ? moment(dataFilter.create_date[1]).format('YYYY-MM-DD') : null,
      branchId: dataFilter.branch ? dataFilter.branch.map((item) => item) : []
    });
    count(dataFilter)
    dispatch(setLoadingTable(true));
    dispatch(setServiceGroupQueryData(query));
    document.getElementById('closeRoleFilter').click();
  };

  const onClearData = () => {
    form.resetFields();
  };

  /// render/////
  return (
    <>
      <Badge count={countFilter} overflowCount={9} size="small">
        <Button size="large" type="primary" onClick={showModal} icon={<FilterOutlined />} />
      </Badge>
      <Drawer
        title={lang.filter}
        placement="right"
        width={c.WIDTH_DRAWER.SMALL}
        onClose={handleCancel}
        visible={isModalVisible}
        footer={
          <Space className="text-center">
            <Button icon={<CloseCircleOutlined />} key="back" size="large" onClick={handleCancel}>
              {lang.close}
            </Button>
            <Button icon={<ClearOutlined />} key="reset" size="large" type="primary" onClick={onClearData}>
              {lang.reset}
            </Button>
            <Button icon={<SaveOutlined />} onClick={handleOk} size="large" key="submit" type="primary" >
              {lang.apply}
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col xs={24} md={24}>
              <DateSelect />
            </Col>
            <Col xs={24} md={24}>
              <StatusSelect />
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default ServiceGroupFilter;
