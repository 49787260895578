/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setServiceId, setServiceQueryData, setServiceListId } from '../slice';
import * as serviceThunk from '../thunk';
// import ServiceFilter from './ServiceFilter';
// import ServiceAction from './ServiceAction/ServiceAction';
// import ServiceActionChecked from './ServiceAction/ServiceActionChecked';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import ServiceDetail from './ServiceDetail';
import ServiceActionChecked from './ServiceAction/ServiceActionChecked';
import ServiceFilter from './ServiceFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import ServiceAction from './ServiceAction/ServiceAction';
import * as c from '../../../constants/common';

const { Search } = Input;

const Service = (props) => {
  const dispatch = useDispatch();
  const serviceQueryData = useSelector(state => state.service.serviceQueryData);
  const serviceListId = useSelector(state => state.service.serviceListId);
  const dataList = useSelector(state => state.service.serviceList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setServiceListId([]));
      dispatch(setServiceId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.service));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(serviceQueryData);
  }, [serviceQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(serviceThunk.serviceList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setServiceQueryData({ ...serviceQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.service}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_service}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <ServiceActionChecked />
                <ServiceAction />
                <ServiceFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={serviceQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={serviceListId}
            queryData={serviceQueryData}
            keyField="service_id"
            clickField="service_code"
            setListIdChecked={setServiceListId}
            setQueryData={setServiceQueryData}
            expandedRowRender={(record) => <ServiceDetail data={record} queryData={serviceQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Service;
