/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { updateTitle, setLoadingTable, setLoading } from '../../../../modules/Common/slice';
import { setUserId, setUserQueryData, setUserListId } from './../slice';
import * as userThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import AntdTable from './../../../Common/components/AntdTable/AntdTable';
import { Row, Col, Input, Space, Button } from 'antd';
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import UserFilter from './UserFilter';
import { userExport } from './../service';
import UserImport from './UserImport';
import UserActionChecked from './UserAction/UserActionChecked';
import UserAction from './UserAction/UserAction';
import UserDetail from './UserDetail/UserDetail';
import {
  DownloadOutlined
} from '@ant-design/icons';
import * as c from '../../../../constants/common';

const { Search } = Input;

const User = (props) => {
  const dispatch = useDispatch();
  const userQueryData = useSelector(state => state.user.userQueryData);
  const userListId = useSelector(state => state.user.userListId);
  const dataList = useSelector(state => state.user.userList);
  const [listNoSelect, setListNoSelect] = useState([]);

  useEffect(() => {
    return function cleanup() {
      dispatch(setUserListId([]));
      dispatch(setUserId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.setting));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(userQueryData);
  }, [userQueryData]);

  useEffect(() => {
    if (dataList.rows && dataList.rows.length > 0) {
      const data = [...listNoSelect]
      dataList.rows.map((item) => {
        if (item.is_main === 1) {
          data.push(item.user_id)
        }
      })
      setListNoSelect(data)
    }
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    dispatch(userThunk.userList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setUserQueryData({ ...userQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  const onExportData = () => {
    dispatch(setLoading(true));
    const data = { user_id: [] }
    userExport(data).then(res => {
      if (res && res.data) {
        dispatch(setLoading(false));
        window.location.href = res.data.file;
      }
    }).finally(() => {
      dispatch(setLoading(false))
    });
  }
  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.user}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_user}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <UserAction />
                <UserActionChecked />
                <UserFilter />
                <Button size="large" onClick={onExportData} type="primary" icon={<DownloadOutlined />} />
                <UserImport />
                <AntdToggleColumn
                  data={dataList}
                  queryData={userQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={userListId}
            queryData={userQueryData}
            keyField="user_id"
            clickField="user_code"
            setListIdChecked={setUserListId}
            setQueryData={setUserQueryData}
            expandedRowRender={(record) => <UserDetail data={record} queryData={userQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default User;
