/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined, PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Row, Space, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../constants/common';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import { setLoading } from '../../../Common/slice';
import * as smsService from '../../service';
import { clearSMSSampleId } from '../../slice';
import * as smsSampleThunk from '../../thunk';
import TextArea from 'antd/lib/input/TextArea';
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';
import { RandomCode } from '../../../Common/components/RandomCode';

const SMSSampleAction = (props) => {
  const queryData = useSelector(state => state.sms.smsSampleQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const smsSampleId = useSelector(state => state.sms.smsSampleId);
  const [actionType, setActionType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const branchLocal = JSON.parse(localStorage.getItem('branch'));

  /// //////////////////////// Validate /////////////////////////

  useEffect(() => {
    if (smsSampleId) {
      dispatch(setLoading(true));
      smsService.smsSampleDetail({ sms_sample_id: smsSampleId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            sms_sample_title: data.sms_sample_title,
            is_active: data.is_active === c.IS_ACTIVE,
            sms_sample_content: data.sms_sample_content
          });
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [smsSampleId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!smsSampleId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ smsSample_code: RandomCode(), is_active: true });
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = smsSampleId ? 'PUT' : 'POST';
    const dataReq = {
      sms_sample_id: smsSampleId || '',
      sms_sample_title: data.sms_sample_title,
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      sms_sample_content: data.sms_sample_content
    };
    smsService.smsSampleCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.sms_sample_title) {
          message.error(lang.sms_sample_title + lang.exist);
        }
        // luu thanh cong
        if (data.sms_sample_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!smsSampleId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(smsSampleThunk.smsSampleList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    // genCode();
    dispatch(clearSMSSampleId(''));
    form.resetFields();
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <>
        <CheckPermission permission={['TITLE_ADD']}>
          <Button type="primary" size="large" id="smsSampleAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!smsSampleId
            ? lang.add + lang.sms_sample.toLowerCase()
            : lang.edit + lang.sms_sample.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          // initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.sms_sample_title}
                  name="sms_sample_title"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_CUSTOMER} /></div>}
                  name="sms_sample_content"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <TextArea />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default SMSSampleAction;
