/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import lang from '../../../language/language';
import { Row, Col, Button, Popover, Space, message, Popconfirm, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../Common/slice';
import {
    SaveOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import * as c from '../../../constants/common';
import TextArea from 'antd/lib/input/TextArea';
import * as invoiceService from '../service';
import * as invoiceThunk from '../thunk';

const InvoiceDetailCancel = (props) => {
    const { data } = props
    const [isVisible, setIsvisible] = useState(false)
    const [cancellationReason, setCancellationReason] = useState('');
    const userInfo = useSelector(state => state.user.userInfo);
    const invoiceQueryData = useSelector(state => state.invoice.invoiceQueryData);

    const dispatch = useDispatch()

    /// /funtion///////
    const onSubmitCancel = () => {
        if (cancellationReason) {
            if (data.invoice_id) {
                dispatch(setLoading(true));
                const dataReq = {
                    invoice_id: data.invoice_id,
                    invoice_status: c.INVOICE_STATUS.CANCEL,
                    invoice_note: data.invoice_note || '',
                    invoice_cancellation_reason: cancellationReason || '',
                    update_user: userInfo.user_id
                }
                invoiceService.invoiceUpdateStatus(dataReq).then(res => {
                    if (res.data) {
                        if (res.data.invoice_id > 0) {
                            message.success(lang.save_success)
                        }
                    }
                }).finally(x => {
                    dispatch(setLoading(false));
                    dispatch(invoiceThunk.invoiceList(invoiceQueryData));
                })
            }
        } else {
            message.error(lang.cancellation_reason_is_required)
        }
    }

    const onCancel = () => {
        setIsvisible(true)
    }
    const onClose = () => {
        setIsvisible(false)
        setCancellationReason('')
    }
    /// /render///////

    return (
        <Popover
            placement="topLeft"
            trigger="click"
            style={{ width: '100%' }}
            overlayClassName="popover-pos"
            visible={isVisible}
            content={
                <Row>
                    <Col xs={24} md={24} className="border-bottom-1">
                        <h3>{lang.cancellation_invoice}</h3>
                    </Col>
                    <Col xs={24} md={24} className="pb-10 pt-15">
                        <Form.Item
                            label={lang.cancellation_reason}
                            name="cancellation_reason"
                        >
                            <TextArea onChange={(e) => setCancellationReason(e.target.value)} size="large" allowClear rows={1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} className="text-right border-top-1 pt-15">
                        <Space>
                            <Button
                                className="w-100"
                                disabled={!data.invoice_id}
                                onClick={onClose}
                                type="primary"
                                size="large"
                                block
                            ><CloseCircleOutlined /> {lang.close}
                            </Button>
                            <Popconfirm
                                placement="topRight"
                                title={lang.sure_cancel}
                                onConfirm={onSubmitCancel}
                                okText={lang.ok}
                                cancelText={lang.cancel}
                                className="popover-reason-cancel"
                            >
                                <Button
                                    onClick={() => onCancel(c.INVOICE_STATUS.CANCEL)}
                                    disabled={!data.invoice_id}
                                    type="primary"
                                    size="large"
                                ><SaveOutlined /> {lang.save}</Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            }
        >
            <Button
                onClick={() => onCancel(c.INVOICE_STATUS.CANCEL)}
                className="w-100"
                disabled={!data.invoice_id}
                size="large"
                icon={<CloseCircleOutlined

                />} key="confirm" type="primary" >
                {lang.cancel}
            </Button>
        </Popover>
    );
};

export default InvoiceDetailCancel;
