/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { setSMSHistoryId, setSMSHistoryQueryData, setSMSHistoryListId } from '../../slice';
import * as smsHistoryThunk from '../../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import SMSHistoryDetail from './SMSHistoryDetail';
import SMSHistoryFilter from './SMSHistoryFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../../Common/slice';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import SMSHistoryAction from './SMSHistoryAction';
import * as c from '../../../../constants/common';

const { Search } = Input;

const SMSHistory = (props) => {
  const dispatch = useDispatch();
  const smsHistoryQueryData = useSelector(state => state.sms.smsHistoryQueryData);
  const smsHistoryListId = useSelector(state => state.sms.smsHistoryListId);
  const dataList = useSelector(state => state.sms.smsHistoryList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setSMSHistoryListId([]));
      dispatch(setSMSHistoryId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.sms_history));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(smsHistoryQueryData);
  }, [smsHistoryQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(smsHistoryThunk.smsHistoryList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setSMSHistoryQueryData({ ...smsHistoryQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.sms_history}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_sms_history}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <SMSHistoryAction />
                <SMSHistoryFilter />
                <AntdToggleColumn
                  data={dataList}
                  queryData={smsHistoryQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            hideCheckbox
            listIdChecked={smsHistoryListId}
            queryData={smsHistoryQueryData}
            keyField="sms_history_content"
            clickField="sms_history_content"
            isShowCreateDateHour
            setListIdChecked={setSMSHistoryListId}
            setQueryData={setSMSHistoryQueryData}
            expandedRowRender={(record) => <SMSHistoryDetail data={record} queryData={smsHistoryQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SMSHistory;
