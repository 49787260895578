import { createSlice } from '@reduxjs/toolkit';
import * as customerThunk from './thunk';
import { getInfoUser } from './../Common/components/GetInfoUser';
import * as c from '../../constants/common';

export const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    customerList: {},
    customerAppointmentHistoryList: {},
    customerInfo: {},
    customerId: '',
    customerListId: [],
    customerListAction: [],
    customerPhoneQuickAdd: '',
    customerQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id,
      branchId: null,
      customerGender: null,
      customerType: null,
      customerGroup: [],
      userIdList: null
    },
    customerAppointmentHistoryQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      orderBy: '',
      userId: getInfoUser.user_id,
      branchId: null,
      customer_id: null
    },
    appointmentIdFromCustomer: null
  },
  reducers: {
    clearCustomerList: (state, action) => {
      state.customerList = {};
    },
    clearCustomerRow: (state, action) => {
      state.customerList.rows = [];
    },
    setCustomerId: (state, action) => {
      state.customerId = action.payload;
    },
    clearCustomerId: (state, action) => {
      state.customerId = '';
    },
    setCustomerQueryData: (state, action) => {
      state.customerQueryData = action.payload;
    },
    setCustomerListId: (state, action) => {
      state.customerListId = action.payload;
    },
    setCustomerPhoneQuickAdd: (state, action) => {
      state.customerPhoneQuickAdd = action.payload;
    },
    setCustomerAppointmentHistoryQueryData: (state, action) => {
      state.customerAppointmentHistoryQueryData = action.payload;
    },
    setAppointmentIdFromCustomer: (state, action) => {
      state.appointmentIdFromCustomer = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [customerThunk.customerList.fulfilled]: (state, action) => {
      state.customerList = action.payload;
    },
    [customerThunk.customerAppointmentHistoryList.fulfilled]: (state, action) => {
      state.customerAppointmentHistoryList = action.payload;
    }
  }
});

export const {
  setCurrentCustomer,
  clearCustomerList,
  clearCustomerRow,
  setCustomerId,
  clearCustomerId,
  setCustomerQueryData,
  setCustomerListId,
  setCustomerPhoneQuickAdd,
  setCustomerAppointmentHistoryQueryData,
  setAppointmentIdFromCustomer
} = customerSlice.actions;

export default customerSlice.reducer;
