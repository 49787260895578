/* eslint-disable array-callback-return */
/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined,
  PlusOutlined,
  SaveOutlined,
  EditOutlined
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, Input, message, Row, Space, Switch, Radio, Select, Image, DatePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../constants/common';
import * as con from '../../../../constants/config';
import lang from '../../../../language/language';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import { formatCurrency } from '../../../Common/components/FormatData';
import { setLoading } from '../../../Common/slice';
import * as giftCardService from '../../service';
import { clearGiftCardId } from '../../slice';
import * as giftCardThunk from '../../thunk';
import { setCustomerPhoneQuickAdd } from '../../../Customer/slice';
import TextArea from 'antd/lib/input/TextArea';
import { getGiftCardMoneyList } from '../../../Common/service';
import GiftCardBackgroundSelect from './GiftCardBackgroundSelect';
import { setGiftCardSettingSelectBackgroundImage } from '../../../GiftCardSetting/slice';
import moment from 'moment';
import GiftCardPrint from '../GiftCardPrint';
import { useReactToPrint } from 'react-to-print';
import { giftCardSendEmail } from '../../common';
const { Option } = Select;

const GiftCardAction = (props) => {
  const queryData = useSelector(state => state.giftCard.giftCardQueryData);
  const dispatch = useDispatch();
  const dataImageSelected = useSelector(state => state.giftCardSetting.giftCardSettingSelectBackgroundImage);
  const userInfo = useSelector(state => state.user.userInfo);
  const [actionType, setActionType] = useState('');
  const [giftCardMoneyList, setGiftCardMoneyList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [giftCardMoney, setGiftCardMoney] = useState(5)
  const [dataAction, setDataAction] = useState([])
  const [giftCardExpiryDate, setGiftCardExpiryDate] = useState('')
  const [moneyCustom, setMoneyCustom] = useState(false)
  const [backGroundImage, setBackGroundImage] = useState('')
  const [form] = Form.useForm();
  const generalSetting = useSelector(state => state.generalSetting);
  const giftCardSettingData = useSelector(state => state.giftCardSetting.giftCardSettingData);
  const [giftCardType, setGiftCardType] = useState(c.GIFT_CARD_TYPE[0].value)
  const printRef = useRef();

  useEffect(() => {
    getGiftCardMoneyList({}).then(res => {
      if (res.data) {
        setGiftCardMoneyList(res.data)
      }
    })
  }, [form])

  useEffect(() => {
    setBackGroundImage(giftCardSettingData.gift_card_setting_background_image_name)
    form.setFieldsValue({
      gift_card_setting_background_image: giftCardSettingData.gift_card_setting_background_image
    });
  }, [giftCardSettingData])

  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const handlePrint = useReactToPrint({
    content: () => printRef.current
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const dataReq = {
      gift_card_balance: giftCardMoney || data.gift_card_money,
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      gift_card_from_name: data.gift_card_from_name || '',
      gift_card_from_email: data.gift_card_from_email || '',
      gift_card_to_name: data.gift_card_to_name || '',
      gift_card_to_email: data.gift_card_to_email || '',
      gift_card_message: data.gift_card_message || '',
      gift_card_type: data.gift_card_type || c.GIFT_CARD_TYPE[0].value,
      gift_card_quantity: data.gift_card_quantity || 1,
      gift_card_background_image_name: backGroundImage,
      gift_card_expiry_date: (giftCardExpiryDate === -1 ? moment(data.gift_card_expiry_date_custom).tz(con.TIME_ZONE).format('YYYY-MM-DD HH:mm:ss') : data.gift_card_expiry_date) || ''
    };
    giftCardService.giftCardCreate(dataReq, 'POST').then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));

        // luu thanh cong
        if (data.gift_card_id > 0) {
          // send email
          const newData = data.list_gift_card_code.map((item) => {
            return {
              ...dataReq,
              gift_card_code: item,
              gift_card_background_image_url: `${con.ROOT_IMAGE}/${dataReq.gift_card_background_image_name}`
            }
          })
          setTimeout(() => {
            giftCardSendEmail(newData)
          }, 1000)
          setDataAction(newData)
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            resetData();
          } else if (actionType === c.SAVE_PRINT) {
            message.success(lang.save_success);
            onClose();
            setTimeout(() => {
              handlePrint()
            }, 1000)
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(async () => {
      dispatch(setLoading(false));
      dispatch(giftCardThunk.giftCardList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    dispatch(clearGiftCardId(''));
    dispatch(setCustomerPhoneQuickAdd(null))
    setMoneyCustom(false)
    setGiftCardType(c.GIFT_CARD_TYPE[0].value)
    setGiftCardMoney(5)
    form.resetFields();
    dispatch(setGiftCardSettingSelectBackgroundImage([]))
    setGiftCardExpiryDate('')
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onClickMoney = (value) => {
    setGiftCardMoney(value)
    setMoneyCustom(false)
    form.setFieldsValue({ gift_card_money: null })
  }

  const onClickMoneyCustom = () => {
    setGiftCardMoney(null)
    setMoneyCustom(true)
  }

  const onSaveChangeImage = () => {
    if (dataImageSelected.length > 0) {
      form.setFieldsValue({
        gift_card_setting_background_image: dataImageSelected[0].gift_card_background_image_url
      });
      setBackGroundImage(dataImageSelected[0].gift_card_background_image_name)
    }
  }

  /// render/////
  return (
    <span>
      <GiftCardPrint data={dataAction || []} printRef={printRef} />
      <>
        <CheckPermission permission={['GIFT_CARD_ADD']}>
          <Button type="primary" size="large" id="giftCardAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={lang.add + lang.gift_card.toLowerCase()}
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_PRINT)} key="submit3" type="primary" >
                {lang.save_and_print}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
            initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.background_image}
                  name="gift_card_setting_background_image"
                >
                  <GiftCardBackgroundSelect id="galleryGiftCardAction" onSave={onSaveChangeImage} />
                  <Image
                    src={form.getFieldValue('gift_card_setting_background_image') || giftCardSettingData.gift_card_setting_background_image}
                    width="100%"
                    className='img-cover'
                    style={{ maxHeight: 210 }}
                  />
                  <div className="btn-edit-image">
                    <Button
                      size="small"
                      icon={<EditOutlined />}
                      key="edit-logo"
                      onClick={() => document.getElementById('galleryGiftCardAction').click()}
                      type="primary"
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <span className="text-danger">* </span><label className="f-16">{lang.gift_card_money}</label>
                <Row gutter={12} className="mt-10">
                  {giftCardMoneyList.map((item, index) => {
                    return (
                      <Col key={index} xs={8} md={8} className="pb-10">
                        <Button
                          className={`${item === giftCardMoney && !moneyCustom ? 'active-payment-method' : ''} p-5 text-center`}
                          onClick={() => onClickMoney(item)}
                          type="primary"
                          size="large"
                          block
                        >{formatCurrency(item, generalSetting)}</Button>
                      </Col>
                    )
                  })}
                  <Col xs={8} md={8} className="pb-10">
                    <Button
                      className={moneyCustom && !giftCardMoney ? 'active-payment-method' : ''}
                      onClick={onClickMoneyCustom}
                      type="primary"
                      size="large"
                      block
                    >{lang.other}</Button>
                  </Col>
                </Row>
                {moneyCustom &&
                  <Form.Item
                    label={null}
                    name="gift_card_money"
                    rules={[
                      {
                        pattern: /^[0-9]+$/,
                        message: lang.only_allow_number
                      },
                      { required: true, message: lang.value_is_required }
                    ]}
                  >
                    <Input size="large" />
                  </Form.Item>
                }
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_type}
                  name="gift_card_type"
                >
                  <Radio.Group defaultValue={1} onChange={(e) => setGiftCardType(e.target.value)}>
                    {c.GIFT_CARD_TYPE.map((item, index) => {
                      return <Radio key={index} value={item.value}>{item.label}</Radio>
                    })}
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.quantity}
                  name="gift_card_quantity"
                >
                  <Select
                    size="large"
                    defaultValue={c.GIFT_CARD_QUANTITY[0]}
                  >
                    {c.GIFT_CARD_QUANTITY.map((item, index) => {
                      return <Option key={item} value={item}>{item}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {giftCardType === c.GIFT_CARD_TYPE[0].value && <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_from_name}
                  name="gift_card_from_name"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>}
              {giftCardType === c.GIFT_CARD_TYPE[0].value && <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_from_email}
                  name="gift_card_from_email"
                  rules={[{ type: 'email', message: lang.email_not_format }]}
                >
                  <Input size="large" type="email" />
                </Form.Item>
              </Col>}
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_to_name}
                  name="gift_card_to_name"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_to_email}
                  name="gift_card_to_email"
                  rules={[{ type: 'email', message: lang.email_not_format }]}
                >
                  <Input size="large" type="email" />
                </Form.Item>
              </Col>
              <Col xs={24} md={giftCardExpiryDate === -1 ? 12 : 24}>
                <Form.Item
                  label={lang.gift_card_expiry_date}
                  name="gift_card_expiry_date"
                >
                  <Select size='large' defaultValue="" onChange={(value) => setGiftCardExpiryDate(value)}>
                    {c.GIFT_CARD_EXPIRY.map((item, index) => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              {giftCardExpiryDate === -1 && <Col xs={24} md={12}>
                <Form.Item
                  label={lang.gift_card_expiry_date}
                  name="gift_card_expiry_date_custom"
                  rules={[
                    { required: giftCardExpiryDate === -1, message: lang.value_is_required }
                  ]}
                >
                  <DatePicker
                    allowClear={false}
                    size="large"
                    style={{ marginTop: 3, width: '100%' }}
                    format={generalSetting.general_setting_date_format}
                    placeholder={lang.gift_card_expiry_date}
                    disabledDate={(current) => {
                      return moment(new Date()).format('YYYY-MM-DD') > moment(current).format('YYYY-MM-DD')
                    }}
                  />
                </Form.Item>
              </Col>}

              {giftCardType === c.GIFT_CARD_TYPE[0].value && <Col xs={24} md={24}>
                <Form.Item
                  label={lang.gift_card_message}
                  name="gift_card_message"
                >
                  <TextArea size="large" maxLength={150} showCount />
                </Form.Item>
              </Col>}

              <Col xs={24} md={24} className="text-right mt-15">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch defaultChecked={true} checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default GiftCardAction;
