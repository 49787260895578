import CallApi from '../../utils/CallAPI';
import {
  SERVICE,
  SERVICE_DETAIL,
  SERVICE_POS
} from './constants/endpoint';

export const serviceList = (data) => {
  return CallApi(SERVICE, 'GET', data)
};

export const serviceCreateUpdate = (data, method) => {
  return CallApi(SERVICE, method, data)
};

export const serviceDetail = (data) => {
  return CallApi(SERVICE_DETAIL, 'GET', data)
};

export const serviceInvoiceList = (data) => {
  return CallApi(SERVICE_POS, 'GET', data)
};
