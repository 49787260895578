import React, { useEffect } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Form, Button, message, Input, Switch, Card } from 'antd';
import { setLoading } from '../../../Common/slice';
import { useSelector, useDispatch } from 'react-redux';
import * as posSettingService from '../service';
import { posSettingThunk } from '../thunk'
import {
  SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as c from '../../../../constants/common';

const POSSetting = (props) => {
  const [form] = Form.useForm();
  const posSetting = useSelector(state => state.posSetting);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      auto_apply_discount: posSetting.auto_apply_discount === c.IS_ACTIVE,
      auto_payment_print: posSetting.auto_payment_print === c.IS_ACTIVE,
      tax_percent: posSetting.tax_percent,
      allow_apply_tax: posSetting.allow_apply_tax === c.IS_ACTIVE,
      allow_accumulate_reward_point: posSetting.allow_accumulate_reward_point === c.IS_ACTIVE,
      accumulate_reward_point_percent: posSetting.accumulate_reward_point_percent
    });
  }, [posSetting]);

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = posSetting.pos_setting_id ? 'PUT' : 'POST';
    const dataReq = {
      pos_setting_id: posSetting.pos_setting_id || null,
      auto_apply_discount: data.auto_apply_discount === true ? c.IS_ACTIVE : c.IS_DEACTIVE,
      auto_payment_print: data.auto_payment_print === true ? c.IS_ACTIVE : c.IS_DEACTIVE,
      tax_percent: data.tax_percent || 0,
      allow_apply_tax: data.allow_apply_tax === true ? c.IS_ACTIVE : c.IS_DEACTIVE,
      accumulate_reward_point_percent: data.accumulate_reward_point_percent || 0,
      allow_accumulate_reward_point: data.allow_accumulate_reward_point === true ? c.IS_ACTIVE : c.IS_DEACTIVE
    };

    posSettingService.posSettingCreateUpdate(dataReq, method).then(res => {
      if (res) {
        dispatch(setLoading(false));
        message.success(lang.save_success);
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(posSettingThunk({}));
    })
  }

  return (
    <Card>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.pos_setting}</title>
      </Helmet>
      <Form
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
      >
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.tax_percent}
              name="tax_percent"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: lang.only_allow_number
                }
              ]}
            >
              <Input width={200} size="large" addonAfter={
                <Form.Item
                  name="allow_apply_tax"
                  valuePropName="checked"
                  className='mb-0'
                >
                  <Switch
                    checkedChildren={lang.on}
                    unCheckedChildren={lang.off}
                  />
                </Form.Item>
              } />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.accumulate_reward_point_percent}
              name="accumulate_reward_point_percent"
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: lang.only_allow_number
                }
              ]}
            >
              <Input size="large" addonAfter={
                <Form.Item
                  name="allow_accumulate_reward_point"
                  valuePropName="checked"
                  className='mb-0'
                >
                  <Switch
                    checkedChildren={lang.on}
                    unCheckedChildren={lang.off}
                  />
                </Form.Item>
              } />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.auto_apply_discount}
              name="auto_apply_discount"
              valuePropName="checked"
            >
              <Switch checkedChildren={lang.on} unCheckedChildren={lang.off} />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.auto_payment_print}
              name="auto_payment_print"
              valuePropName="checked"
            >
              <Switch checkedChildren={lang.on} unCheckedChildren={lang.off} />
            </Form.Item>
          </Col>
          <Col xs={24} md={24} className="text-center">
            <CheckPermission permission={['POS_SETTING_EDIT']}>
              <Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                {lang.save}
              </Button>
            </CheckPermission>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default POSSetting;
