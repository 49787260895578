/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../../language/language';
import { formatDate, formatPhone, formatIsActive } from '../../../../Common/components/FormatData';
import noImage from '../../../../../assets/images/default-user.png'
import { List, Row, Col, Typography, Image } from 'antd';
const { Text } = Typography;

const UserDetailInfo = (props) => {
    const { dataDetail } = props;

    /// /funtion///////

    /// /render///////
    return (
        <Row >
            <Col xs={8} md={4} lg={4}>
                <Image
                    width="100%"
                    className="mb-15 img-avatar img-avatar-detail"
                    preview={false}
                    src={dataDetail.user_avatar || noImage}
                />
            </Col>
            <Col xs={24} md={20} lg={20} className="pl-15">
                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(dataDetail)
                            .filter(x =>
                                x !== 'user_id' &&
                                x !== 'key' &&
                                x !== 'branch' &&
                                x !== 'is_main' &&
                                x !== 'user_avatar'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    (
                                        item === 'create_date' ||
                                        item === 'user_birthday'
                                    )
                                        ? formatDate(dataDetail[item])
                                        : (
                                            item === 'user_phone_number'
                                        )
                                            ? formatPhone(dataDetail[item])
                                            : (item === 'is_active')
                                                ? formatIsActive(dataDetail[item])
                                                : (String(dataDetail[item]).includes('{DELETE}'))
                                                    ? <Text delete>{String(dataDetail[item]).replace('{DELETE}', '')}</Text>
                                                    : dataDetail[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>

        </Row>
    );
};

export default UserDetailInfo;
