import React from 'react';
import lang from '../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import { Card, Tabs } from 'antd';
import GiftCardSettingGeneral from './GiftCardSettingGeneral'
import GiftCardSettingBackgroundList from './GiftCardSettingBackgroundList';
import GiftCardSettingEmail from './GiftCardSettingEmail';

const GiftCardSetting = (props) => {
  const { TabPane } = Tabs;
  /// /funtion///////

  /// /render///////
  return (
    <Card name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.gift_card_setting}</title>
      </Helmet>

      <Tabs destroyInactiveTabPane tabPosition="left">
        <TabPane forceRender tab={lang.setting_general} key="1">
          <GiftCardSettingGeneral />
        </TabPane>
        <TabPane forceRender tab={lang.background_list} key="2">
          <GiftCardSettingBackgroundList />
        </TabPane>
        <TabPane forceRender tab={lang.email_send} key="3">
          <GiftCardSettingEmail />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default GiftCardSetting;
