/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setReportAppointmentId, setReportAppointmentQueryData, setReportAppointmentListId, clearReportQueryData } from '../slice';
import * as staffThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import { Row, Col, Form, Select, Card } from 'antd'
import { setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import * as c from '../../../constants/common';
import FilterDay from '../../Common/components/FilterDay';
import BranchSelect from '../../Common/components/BranchSelect';
import moment from 'moment';

const ReportAppointment = (props) => {
  const dispatch = useDispatch();
  const reportAppointmentQueryData = useSelector(state => state.reportAppointment.reportAppointmentQueryData);
  const reportAppointmentListId = useSelector(state => state.reportAppointment.reportAppointmentListId);
  const dataList = useSelector(state => state.reportAppointment.reportAppointmentList);

  const { Option } = Select;

  useEffect(() => {
    return function cleanup() {
      dispatch(setReportAppointmentListId([]));
      dispatch(setReportAppointmentId(''));
      dispatch(clearReportQueryData())
    };
  }, []);

  useEffect(() => {
    fetchDataList(reportAppointmentQueryData);
  }, [reportAppointmentQueryData]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    dispatch(setLoadingTable(true));
    dispatch(staffThunk.reportAppointmentList(queryData));
  };

  const onSelectFilterDay = (data) => {
    dispatch(setReportAppointmentQueryData({
      ...reportAppointmentQueryData,
      fromDate: moment(data.fromDate).format('YYYY-MM-DD'),
      toDate: moment(data.toDate).format('YYYY-MM-DD')
    }))
  }

  const onChangeBranch = (data) => {
    dispatch(setReportAppointmentQueryData({
      ...reportAppointmentQueryData,
      branchIdList: data
    }))
  }
  const onChangeType = (data) => {
    dispatch(setReportAppointmentQueryData({
      ...reportAppointmentQueryData,
      reportType: data
    }))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.report_appointment}</title>
      </Helmet>
      <Form
        autoComplete="off"
        layout="vertical"
      >
        <Row >
          <Col md={24}>
            <Card>
              <Row gutter={24}>
                <Col md={8}>
                  <Form.Item
                    label={lang.report_type}
                    name="report_type"
                  >
                    <Select
                      size="large"
                      style={{ width: '100%' }}
                      defaultValue={c.REPORT_APPOINTMENT_TYPE[0].value}
                      onChange={onChangeType}
                    >
                      {c.REPORT_APPOINTMENT_TYPE.map((item) => {
                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={8}>
                  <BranchSelect
                    isMultiple
                    onChange={onChangeBranch}
                  />
                </Col>
                <Col md={8}>
                  <Form.Item
                    label={lang.time_select}
                    name="time_select"
                  >
                    <FilterDay
                      style={{ width: '100%' }}
                      size="large"
                      isShowDay
                      defaultFilter="today"
                      onSelect={onSelectFilterDay}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24}>
            <AntdTable
              data={dataList}
              listIdChecked={reportAppointmentListId}
              queryData={reportAppointmentQueryData}
              keyField="service_id"
              // clickField="staff_title_name"
              setListIdChecked={setReportAppointmentListId}
              setQueryData={setReportAppointmentQueryData}
              // expandedRowRender={(record) => <div></div>}
              hideCheckbox
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ReportAppointment;
