/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  CloseCircleOutlined,
  PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Drawer, Space } from 'antd';
import React, { useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../language/language';
import { clearImageGalleryList, setDataImageSelected, setImageGalleryQueryData } from '../slice';
import ImageGallery from './ImageGallery';
import * as c from '../../../constants/common';

const ImageGallerySelect = (props) => {
  const { onSave, isMultiple, id } = props
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dataImageSelected = useSelector(state => state.imageGallery.dataImageSelected);

  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onClose = () => {
    setIsModalVisible(false);
    dispatch(clearImageGalleryList())
    dispatch(setDataImageSelected([]))
    dispatch(setImageGalleryQueryData({
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_IMAGE_GALLERY,
      fromDate: '',
      toDate: ''
    }))
  }

  const onSaveData = () => {
    if (onSave) {
      onSave(dataImageSelected)
    }
    onClose()
  }

  /// render/////
  return (
    <>
      <Button className='d-none' type="primary" size="large" id={id || 'galleryAction'} onClick={showModal} icon={<PlusOutlined />} />
      <Drawer
        title={lang.image_gallery}
        placement="top"
        onClose={onClose}
        height="100%"
        visible={isModalVisible}
        footer={
          <Space className="text-center">
            <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
              {lang.close}
            </Button>
            <Button
              size="large"
              icon={<SaveOutlined />}
              onClick={() => onSaveData()}
              key="submit2"
              type="primary"
              disabled={!(dataImageSelected.length > 0)}
            >
              {lang.save}
            </Button>
          </Space>
        }
      >
        <ImageGallery isMultiple={isMultiple} />
      </Drawer>
    </>
  );
};

export default ImageGallerySelect;
