/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as serviceService from '../../service';
import { setLoading, setIsClearImage } from '../../../Common/slice';
import { clearServiceId } from '../../slice';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
import * as serviceThunk from '../../thunk';
import TinyEditor from '../../../Common/components/TinyEditor';
import noImage from '../../../../assets/images/no-image.png';
import { Drawer, Space, Button, Row, Col, Form, Input, Switch, message, Image, Select } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CameraOutlined
} from '@ant-design/icons';
import ServiceGroupSelect from '../../../Common/components/ServiceGroupSelect';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import { RandomCode } from '../../../Common/components/RandomCode';
const Option = Select.Option;

const ServiceAction = (props) => {
  const queryData = useSelector(state => state.service.serviceQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const serviceId = useSelector(state => state.service.serviceId);
  const [actionType, setActionType] = useState('');
  const [description, setDescription] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dataAvatar, setDataAvatar] = useState({});

  const branchLocal = JSON.parse(localStorage.getItem('branch'));

  /// //////////////////////// Validate /////////////////////////

  useEffect(() => {
    if (serviceId) {
      dispatch(setLoading(true));
      serviceService.serviceDetail({ service_id: serviceId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            service_name: data.service_name,
            service_code: data.service_code,
            service_price: data.service_price,
            service_price_discount: data.service_price_discount,
            service_group: data.service_group_id,
            is_active: data.is_active === c.IS_ACTIVE,
            service_duration: data.service_duration
          });
          setDataAvatar({ image_gallery_name: data.service_avatar_name, image_gallery_url: data.service_avatar })
          setDescription(data.service_description)
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [serviceId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!serviceId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ service_code: RandomCode(), is_active: true });
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = serviceId ? 'PUT' : 'POST';
    const dataReq = {
      service_id: serviceId || '',
      service_code: c.CODE_TYPE.SERVICE + data.service_code.replace('SER', ''),
      service_name: data.service_name,
      service_price: data.service_price,
      service_price_discount: data.service_price_discount || null,
      service_group_id: data.service_group,
      service_avatar: dataAvatar.image_gallery_name || '',
      service_description: description,
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      service_duration: data.service_duration || null
    };
    serviceService.serviceCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.service_name) {
          message.error(lang.service_name + lang.exist);
        }
        if (data.service_code) {
          message.error(lang.service_code + lang.exist);
        }
        // luu thanh cong
        if (data.service_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!serviceId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(serviceThunk.serviceList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    genCode();
    dispatch(clearServiceId(''));
    form.resetFields();
    dispatch(setIsClearImage(true))
    setDescription('')
    setDataAvatar({})
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const validatePrice = (rule, value, getFieldValue) => {
    const priceDiscount = getFieldValue('service_price_discount');
    if (value && priceDiscount && Number(value) <= Number(priceDiscount)) {
      return Promise.reject(new Error(lang.service_price_not_greater_discount));
    }
    return Promise.resolve();
  };
  const validatePriceDiscount = (rule, value, getFieldValue) => {
    const price = getFieldValue('service_price');
    if (value && price && Number(value) >= Number(price)) {
      return Promise.reject(new Error(lang.discount_not_greater_service_price));
    }
    return Promise.resolve();
  }

  const onSaveChangeImage = (data) => {
    if (data.length > 0) {
      setDataAvatar(data[0])
    }
  }
  /// render/////
  return (
    <span>
      <ImageGallerySelect id="galleryServiceAction" onSave={onSaveChangeImage} />
      <>
        <CheckPermission permission={['SERVICE_ADD']}>
          <Button type="primary" size="large" id="serviceAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!serviceId
            ? lang.add + lang.service.toLowerCase()
            : lang.edit + lang.service.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          // initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24} className="mb-15">
                <div className="text-center">

                  <Image
                    width="100%"
                    style={{ margin: 'auto' }}
                    className="img-avatar-square"
                    preview={false}
                    src={dataAvatar.image_gallery_url || noImage}
                  />
                </div>
                <div className="text-center pt-10">
                  <Button icon={<CameraOutlined />}
                    onClick={() => document.getElementById('galleryServiceAction').click()}
                    key="update_service_avatar"
                    type="primary"
                    size="middle"
                  >
                    {lang.choose_image}
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_code}
                  name="service_code"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_name}
                  name="service_name"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_price}
                  name="service_price"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[0-9]+$/,
                      message: lang.only_allow_number
                    },
                    ({ getFieldValue }) => ({
                      validator: (rule, value) => validatePrice(rule, value, getFieldValue)
                    })
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_price_discount}
                  name="service_price_discount"
                  rules={[
                    {
                      pattern: /^[0-9]+$/,
                      message: lang.only_allow_number
                    },
                    ({ getFieldValue }) => ({
                      validator: (rule, value) => validatePriceDiscount(rule, value, getFieldValue)
                    })
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_duration}
                  name="service_duration"
                  rules={[
                    { required: true, message: lang.value_is_required }
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={lang.service_duration}
                  >
                    {c.SERVICE_DURATION.map((item) => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <ServiceGroupSelect required={true} />
              </Col>
              <Col xs={24} md={24}>
                <label style={{ fontSize: 16 }}>{lang.service_description}</label>
                <TinyEditor
                  value={description}
                  onChange={(value) => setDescription(value)}
                />
              </Col>
              <Col xs={24} md={24} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default ServiceAction;
