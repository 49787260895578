import { createAsyncThunk } from '@reduxjs/toolkit';
import * as smsService from './service';

export const smsHistoryList = createAsyncThunk(
  'sms/smsHistoryList',
  async (data, thunkAPI) => {
    const response = await smsService.smsHistoryList(data);
    return response.data;
  }
);

export const smsSampleList = createAsyncThunk(
  'sms/smsSampleList',
  async (data, thunkAPI) => {
    const response = await smsService.smsSampleList(data);
    return response.data;
  }
);
