import { createSlice } from '@reduxjs/toolkit';
import * as staffThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';

const currentBranch = JSON.parse(localStorage.getItem('branch'))

export const staffSlice = createSlice({
  name: 'staff',
  initialState: {
    staffList: {},
    staffInfo: {},
    staffId: '',
    staffListId: [],
    staffListAction: [],
    serviceListAction: [],
    staffQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id,
      branchIdList: currentBranch ? [currentBranch.branch_id] : [],
      staffGender: null,
      staffType: null,
      userIdList: null
    }
  },
  reducers: {
    clearStaffList: (state, action) => {
      state.staffList = {};
    },
    clearStaffRow: (state, action) => {
      state.staffList.rows = [];
    },
    setStaffId: (state, action) => {
      state.staffId = action.payload;
    },
    clearStaffId: (state, action) => {
      state.staffId = '';
    },
    setStaffQueryData: (state, action) => {
      state.staffQueryData = action.payload;
    },
    setStaffListId: (state, action) => {
      state.staffListId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [staffThunk.staffList.fulfilled]: (state, action) => {
      state.staffList = action.payload;
    },
    [staffThunk.serviceListAction.fulfilled]: (state, action) => {
      state.serviceListAction = action.payload;
    }

  }
});

export const {
  clearStaffList,
  clearStaffRow,
  setStaffId,
  clearStaffId,
  setStaffQueryData,
  setStaffListId
} = staffSlice.actions;

export default staffSlice.reducer;
