import { createAsyncThunk } from '@reduxjs/toolkit';
import * as serviceGroupService from './service';

export const serviceGroupList = createAsyncThunk(
  'serviceGroup/serviceGroupList',
  async (data, thunkAPI) => {
    const response = await serviceGroupService.serviceGroupList(data);
    return response.data;
  }
);
