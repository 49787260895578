import React from 'react';
import lang from '../../../language/language';
import { Form, DatePicker } from 'antd';
import * as c from '../../../constants/common';
const { RangePicker } = DatePicker;

const DateSelect = (props) => {
  /// render/////
  return (
    <Form.Item
      label={lang.create_date}
      name="create_date"
    >
      <RangePicker size="large" style={{ width: '100%' }} format={c.FORMAT_DATE} placeholder={[lang.from_date, lang.to_date]} />
    </Form.Item>
  );
};

export default DateSelect;
