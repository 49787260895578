import { Bar } from '@ant-design/plots';
import { Card, Col, Row, Select, Space } from 'antd';
import moment from 'moment';
// import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { OPTIONS_CHART_FILTER } from '../../../constants/common';
import lang from '../../../language/language';
import { customGetDay } from '../../Common/components/CustomGetDay';
import FilterDay from '../../Common/components/FilterDay';
import { formatDate } from '../../Common/components/FormatData';
import { setLoading } from '../../Common/slice';
import { getTopTenService } from '../service';

const DashBoardTopTenService = () => {
    const getDate = customGetDay()

    const filterTopTen = [
        {
            value: 1,
            label: lang.used_times
        },
        {
            value: 2,
            label: lang.income
        }
    ]
    const [filtered, setFiltered] = useState(lang.this_week)
    const [filterType, setFilterType] = useState(filterTopTen[0].value)
    const [dateDate, setDataDate] = useState({
        fromDate: moment(getDate.thisMonday).format('YYYY-MM-DD'),
        toDate: moment(getDate.thisSunday).format('YYYY-MM-DD')
    })
    const branchByUser = useSelector(state => state.branch.branchByUser)
    const generalSetting = useSelector(state => state.generalSetting)
    const dispatch = useDispatch()
    const [dataReport, setDataReport] = useState([])

    useEffect(() => {
        getNewTopTenService()
    }, [filterType, dateDate])

    const getNewTopTenService = () => {
        dispatch(setLoading(true))
        const reqData = {
            fromDate: dateDate.fromDate,
            toDate: dateDate.toDate,
            branch_list_id: branchByUser
                ? branchByUser.map(item => {
                    return item.branch_id
                })
                : [],
            filter_by: filterType
        }
        getTopTenService(reqData).then(res => {
            if (res.data) {
                const newData = res.data
                setDataReport(newData)
            }
        }).finally(x => {
            dispatch(setLoading(false))
        }
        )
    }
    // config report
    const config = {
        data: dataReport || [],
        xField: 'value',
        yField: 'services',
        seriesField: 'branch',
        legend: {
            position: 'top-left'
        },
        yAxis: {
            label: {
                autoRotate: false
            }
        }
    }

    const onSelectFilterDay = (data) => {
        if (data.value === OPTIONS_CHART_FILTER[6].value) {
            const titleFilter = `${lang.from_day.toLowerCase()} ${formatDate(data.fromDate, false, generalSetting)} ${lang.to_day.toLowerCase()} ${formatDate(data.toDate, false, generalSetting)}`
            setFiltered(titleFilter)
        } else { setFiltered(OPTIONS_CHART_FILTER.filter(x => x.value === data.value)[0].label) }
        setDataDate({ fromDate: moment(data.fromDate).format('YYYY-MM-DD'), toDate: moment(data.toDate).format('YYYY-MM-DD') })
    }

    const handleChangeFilterType = (value) => {
        setFilterType(value)
    }

    return (
        <Card className='mb-15'>
            <Row>
                <Col xs={24} md={10} className="font-weight-bold">
                    {lang.top_ten_service} {filtered.toLowerCase()}
                </Col>
                <Col xs={24} md={14} className="text-right">
                    <Space>
                        <Select
                            style={{ width: '100%' }}
                            defaultValue={filterTopTen[0].value}
                            size='middle'
                            onChange={handleChangeFilterType}
                            options={filterTopTen}
                        />
                        <FilterDay onSelect={onSelectFilterDay} />
                    </Space>
                </Col>
            </Row>
            <Bar {...config} />
        </Card>
    )
}

export default DashBoardTopTenService;
