import { configureStore } from '@reduxjs/toolkit';
import userReducer from './modules/Setting/User/slice';
import roleReducer from './modules/Setting/Role/slice';
import commonReducer from './modules/Common/slice';
import branchReducer from './modules/Setting/Branch/slice';
import customerReducer from './modules/Customer/slice';
import customerGroupReducer from './modules/CustomerGroup/slice';
import serviceGroupReducer from './modules/ServiceGroup/slice';
import serviceReducer from './modules/Service/slice';
import staffReducer from './modules/Staff/slice';
import generalSettingReducer from './modules/Setting/GeneralSetting/slice';
import appointmentSettingReducer from './modules/Appointment/AppointmentSetting/slice';
import appointmentReducer from './modules/Appointment/AppointmentList/slice';
import emailReducer from './modules/Email/slice';
import smsReducer from './modules/SMS/slice';
import posSettingReducer from './modules/Setting/POSSetting/slice';
import giftCardReducer from './modules/GiftCard/slice';
import giftCardSettingReducer from './modules/GiftCardSetting/slice';
import imageGalleryReducer from './modules/ImageGallery/slice';
import invoiceReducer from './modules/Invoice/slice';
import checkinReducer from './modules/Checkin/slice';
import dashboardReducer from './modules/DashBoard/slice'
import staffTitleReducer from './modules/StaffTitle/slice';
import staffTypeReducer from './modules/StaffType/slice';
import timeKeepingReducer from './modules/TimeKeeping/slice'
import turnSettingReducer from './modules/TurnSetting/slice'
import reportIncomeReducer from './modules/ReportIncome/slice'
import reportAppointmentReducer from './modules/ReportAppointment/slice'

export default configureStore({
  reducer: {
    user: userReducer,
    role: roleReducer,
    common: commonReducer,
    branch: branchReducer,
    customer: customerReducer,
    customerGroup: customerGroupReducer,
    serviceGroup: serviceGroupReducer,
    service: serviceReducer,
    staff: staffReducer,
    generalSetting: generalSettingReducer,
    appointmentSetting: appointmentSettingReducer,
    appointment: appointmentReducer,
    email: emailReducer,
    sms: smsReducer,
    posSetting: posSettingReducer,
    giftCard: giftCardReducer,
    giftCardSetting: giftCardSettingReducer,
    imageGallery: imageGalleryReducer,
    invoice: invoiceReducer,
    checkin: checkinReducer,
    dashboard: dashboardReducer,
    staffTitle: staffTitleReducer,
    staffType: staffTypeReducer,
    timeKeeping: timeKeepingReducer,
    turnSetting: turnSettingReducer,
    reportIncome: reportIncomeReducer,
    reportAppointment: reportAppointmentReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});
