import { createSlice } from '@reduxjs/toolkit';
import * as userThunk from './thunk';
import { getInfoUser } from './../../Common/components/GetInfoUser';
import * as c from '../../../constants/common';
const currentBranch = JSON.parse(localStorage.getItem('branch')) || {};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userList: {},
    userInfo: {},
    userBranchRole: [],
    userId: '',
    userQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id,
      test: '',
      branchIdList: currentBranch.branch_id ? [currentBranch.branch_id] : []
    },
    userListId: []
  },
  reducers: {
    clearUserList: (state, action) => {
      state.userList = {};
    },
    clearUserRow: (state, action) => {
      state.userList.rows = [];
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    clearUserId: (state, action) => {
      state.userId = '';
    },
    setUserQueryData: (state, action) => {
      state.userQueryData = action.payload;
    },
    setUserListId: (state, action) => {
      state.userListId = action.payload;
    },
    setUserBranchRole: (state, action) => {
      state.userBranchRole = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [userThunk.userList.fulfilled]: (state, action) => {
      state.userList = action.payload;
    },
    [userThunk.userDetail.fulfilled]: (state, action) => {
      state.userInfo = action.payload;
    }
  }
});
export const {
  clearUserList,
  clearUserRow,
  setUserId,
  clearUserId,
  setUserQueryData,
  setUserListId,
  setUserBranchRole
} = userSlice.actions;

export default userSlice.reducer;
