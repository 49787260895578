import CallApi from '../../utils/CallAPI';
import {
  GIFT_CARD,
  GIFT_CARD_DETAIL,
  GIFT_CARD_HISTORY,
  GIFT_CARD_DEPOSIT,
  GIFT_CARD_RESEND_EMAIL,
  GIFT_CARD_SEND_EMAIL
} from './constants/endpoint';

export const giftCardList = (data) => {
  return CallApi(GIFT_CARD, 'GET', data)
};

export const giftCardCreate = (data, method) => {
  return CallApi(GIFT_CARD, method, data)
};

export const giftCardDetail = (data) => {
  return CallApi(GIFT_CARD_DETAIL, 'GET', data)
};

export const giftCardHistoryList = (data) => {
  return CallApi(GIFT_CARD_HISTORY, 'GET', data)
};

export const giftCardDeposit = (data) => {
  return CallApi(GIFT_CARD_DEPOSIT, 'PUT', data)
};

export const giftCardHistoryCreate = (data) => {
  return CallApi(GIFT_CARD_HISTORY, 'POST', data)
};

export const giftCardResendEmail = (data) => {
  return CallApi(GIFT_CARD_RESEND_EMAIL, 'POST', data)
};

export const giftCardSendEmail = (data) => {
  return CallApi(GIFT_CARD_SEND_EMAIL, 'POST', data)
};
