import React from 'react';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { Select } from 'antd';
const { Option } = Select;
// import {
//     SortAscendingOutlined
// } from '@ant-design/icons';

const SortSelect = (props) => {
    const { onChange } = props

    const onChangeData = (data) => {
        if (onChange) {
            onChange(data)
        }
    }
    /// render/////
    return (
        <Select
            className='select-sort'
            size="middle"
            allowClear
            placeholder={lang.sort_data}
            onChange={onChangeData}
        >
            {c.SORT_SERVICE.map((item) => {
                return <Option key={item.value} value={item.value}>{item.label}</Option>
            })}
        </Select>
    );
};

export default SortSelect;
