import React, { useEffect } from 'react';
import randomString from 'random-string';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setDataImage, setUrlImage, setIsClearImage, setLoading } from '../slice';
import * as commonService from '../service';
import lang from '../../../language/language';

const UploadImage = (props) => {
    const { multiple, id, name } = props
    const dispatch = useDispatch();
    const isClearImage = useSelector(state => state.common.isClearImage);

    useEffect(() => {
        if (isClearImage) {
            clearData()
            dispatch(setIsClearImage(false))
        }
    }, [isClearImage]);

    const clearData = (e) => {
        document.getElementById(`${id}`).value = ''
        dispatch(setDataImage([]))
        dispatch(setUrlImage([]))
    }

    const onChooseImage = (e) => {
        dispatch(setLoading(true))
        const arrayNameImage = []
        const arrayUrlImage = []
        const data = new FormData()
        const { files } = e.target
        const image = files
        for (let i = 0; i < image.length; i++) {
            const imageType = image[i].type
            const imageSize = image[i].size
            if (imageSize > (2 * 1024 * 1024)) {
                toast.error(lang.file_max_size);
                clearData(e)
                return
            }
            if (imageType.indexOf('image') === 0) {
                const imageName = randomString({ length: 50 }) + '.' + imageType.replace('image/', '').replace('e', '')
                arrayNameImage.push(imageName)
                arrayUrlImage.push(URL.createObjectURL(image[i]))
                data.append('images', image[i], imageName)
            } else {
                toast.error(lang.file_not_image);
                clearData(e)
            }
        }
        dispatch(setDataImage(arrayNameImage))
        dispatch(setUrlImage(arrayUrlImage))
        commonService.uploadImage(data).then(res => {
            if (res) {
                dispatch(setLoading(false))
            }
        })
    }
    /// render/////
    return (
        <input
            className="d-none"
            type="file"
            id={id}
            name={name}
            multiple={multiple}
            accept='image/*'
            onChange={onChooseImage}
        />
    );
};

export default UploadImage;
