/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Form, Button, Input, message } from 'antd';
import {
    SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import TextArea from 'antd/lib/input/TextArea';
import { checkinSetting } from '../../service';
import { setLoading } from '../../../Common/slice';
import { useDispatch } from 'react-redux';
import { getInfoUser } from '../../../Common/components/GetInfoUser';
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';
import TinyEditor from '../../../Common/components/TinyEditor';

const CheckinSettingGeneral = (props) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [checkinSettingData, setCheckinSettingData] = useState({})

    useEffect(() => {
        checkinSetting({}).then(res => {
            if (res.data) {
                form.setFieldsValue({
                    checkin_setting_welcome: res.data.checkin_setting_welcome,
                    checkin_setting_description: res.data.checkin_setting_description,
                    checkin_setting_policies: res.data.checkin_setting_policies
                });
                setCheckinSettingData(res.data)
            }
        })
    }, []);

    const onSubmit = (data) => {
        dispatch(setLoading(true));
        const dataReq = {
            checkin_setting_welcome: data.checkin_setting_welcome,
            checkin_setting_description: data.checkin_setting_description,
            checkin_setting_id: checkinSettingData.checkin_setting_id || null,
            create_user: getInfoUser.user_id,
            checkin_setting_policies: data.checkin_setting_policies
        };
        checkinSetting(dataReq, 'POST').then(res => {
            if (res.data) {
                message.success(lang.save_success);
            }
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_TITLE} - {lang.setting_general}</title>
            </Helmet>
            <Form
                onFinish={onSubmit}
                autoComplete="off"
                form={form}
                layout="vertical"
            >

                <Row gutter={24}>
                    <Col xs={24} md={12}>
                        <Row>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    label={<div className='cursor-pointer'>{lang.checkin_setting_welcome} <ToolTipShortCode data={[{ value: '[customer_fullname]', label: lang.customer_fullname }]} /> </div>}
                                    name="checkin_setting_welcome"
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    label={<div className='cursor-pointer'>{lang.checkin_setting_description}</div>}
                                    name="checkin_setting_description"
                                >
                                    <TextArea rows={10} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<div className='cursor-pointer'>{lang.checkin_setting_policies}</div>}
                            name="checkin_setting_policies"
                        >
                            <TinyEditor toolbar={false} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} className="text-center mt-15 mb-15">
                        <CheckPermission permission={['CHECKIN_SETTING_EDIT']}>
                            <Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                                {lang.save}
                            </Button>
                        </CheckPermission>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default CheckinSettingGeneral;
