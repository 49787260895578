/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { setSMSSampleId, setSMSSampleQueryData, setSMSSampleListId } from '../../slice';
import * as smsThunk from '../../thunk';
import SMSSampleAction from './SMSSampleAction';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import SMSSampleDetail from './SMSSampleDetail';
import SMSSampleFilter from './SMSSampleFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../../Common/slice';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import * as c from '../../../../constants/common';
import SMSSampleActionChecked from './SMSSampleActionChecked';

const { Search } = Input;

const SMSSample = (props) => {
  const dispatch = useDispatch();
  const smsSampleQueryData = useSelector(state => state.sms.smsSampleQueryData);
  const smsSampleListId = useSelector(state => state.sms.smsSampleListId);
  const dataList = useSelector(state => state.sms.smsSampleList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setSMSSampleListId([]));
      dispatch(setSMSSampleId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.sms_sample));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(smsSampleQueryData);
  }, [smsSampleQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(smsThunk.smsSampleList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setSMSSampleQueryData({ ...smsSampleQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.sms_sample}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_sms_sample}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <SMSSampleActionChecked />
                <SMSSampleAction />
                <SMSSampleFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={smsSampleQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={smsSampleListId}
            queryData={smsSampleQueryData}
            keyField="sms_sample_id"
            clickField="sms_sample_title"
            setListIdChecked={setSMSSampleListId}
            setQueryData={setSMSSampleQueryData}
            expandedRowRender={(record) => <SMSSampleDetail data={record} queryData={smsSampleQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SMSSample;
