import { createSlice } from '@reduxjs/toolkit';
import * as customerGroupThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';

export const customerGroupSlice = createSlice({
  name: 'customerGroup',
  initialState: {
    customerGroupList: {},
    customerGroupInfo: {},
    customerGroupId: '',
    customerGroupListId: [],
    customerGroupListAction: [],
    customerGroupCondition: [],
    discountType: c.DISCOUNT_TYPE.PERCENT,
    customerGroupQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearCustomerGroupList: (state, action) => {
      state.customerGroupList = {};
    },
    clearCustomerGroupRow: (state, action) => {
      state.customerGroupList.rows = [];
    },
    setCustomerGroupId: (state, action) => {
      state.customerGroupId = action.payload;
    },
    clearCustomerGroupId: (state, action) => {
      state.customerGroupId = '';
    },
    setCustomerGroupQueryData: (state, action) => {
      state.customerGroupQueryData = action.payload;
    },
    setCustomerGroupListId: (state, action) => {
      state.customerGroupListId = action.payload;
    },
    setCustomerGroupCondition: (state, action) => {
      state.customerGroupCondition = action.payload;
    },
    setDiscountType: (state, action) => {
      state.discountType = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [customerGroupThunk.customerGroupList.fulfilled]: (state, action) => {
      state.customerGroupList = action.payload;
    }
  }
});

export const {
  setCurrentCustomerGroup,
  clearCustomerGroupList,
  clearCustomerGroupRow,
  setCustomerGroupId,
  clearCustomerGroupId,
  setCustomerGroupQueryData,
  setCustomerGroupListId,
  setCustomerGroupCondition,
  setDiscountType
} = customerGroupSlice.actions;

export default customerGroupSlice.reducer;
