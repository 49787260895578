import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as customerThunk from '../../thunk';
import { setCustomerListId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import { Menu, Button, Dropdown, Popconfirm, message } from 'antd';
import { setLoading } from '../../../../modules/Common/slice';
import { customerExport } from '../../service';
import { setEmailCustomerListId } from '../../../Email/slice';
import { setSMSCustomerListId } from '../../../SMS/slice';
import * as c from '../../../../constants/common';

import { SettingOutlined } from '@ant-design/icons';
const CustomerActionChecked = (props) => {
  const customerListId = useSelector(state => state.customer.customerListId);
  const queryData = useSelector(state => state.customer.customerQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (customerListId.length > 0) {
      const data = {
        list_id: customerListId,
        field_name: 'customer_id',
        table: 'customer',
        update_user: userInfo.user_id,
        permission: ['CUSTOMER_DELETE']
      };
      commonService.commonDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(customerThunk.customerList(queryData));
            dispatch(setCustomerListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (customerListId.length > 0) {
      const data = {
        list_id: customerListId,
        field_name: 'customer_id',
        table: 'customer',
        status,
        update_user: userInfo.user_id,
        permission: ['CUSTOMER_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(customerThunk.customerList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const onExportData = () => {
    dispatch(setLoading(true));
    const data = { customer_id: customerListId }
    customerExport(data).then(res => {
      if (res && res.data) {
        dispatch(setLoading(false));
        window.location.href = res.data.file;
      }
    }).finally(() => {
      dispatch(setLoading(false))
    });
  }

  const onSendEmail = () => {
    dispatch(setEmailCustomerListId(customerListId))
    document.getElementById('emailHistoryAction').click()
  }
  const onSendSMS = () => {
    dispatch(setSMSCustomerListId(customerListId))
    document.getElementById('smsHistoryAction').click()
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_SEND_EMAIL']}>
          <a onClick={onSendEmail}>
            {lang.email_send}
          </a>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_SEND_SMS']}>
          <a onClick={onSendSMS}>
            {lang.sms_send}
          </a>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_EXPORT']}>
          <a onClick={onExportData}>
            {lang.export_excel}
          </a>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    customerListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default CustomerActionChecked;
