/* eslint-disable camelcase */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as c from '../../../../constants/config';
import { ROUTE_HOME } from '../../../../modules/Common/constants/routes';
import { useHistory } from 'react-router-dom';
import themeGridImage from '../../../../assets/images/theme-grid.jpg';
import themeLeftImage from '../../../../assets/images/theme-left.jpg';
import themeDropdownImage from '../../../../assets/images/theme-dropdown-menu.png';

import * as settingService from '../service';
import { userDetail } from '../../../../modules/Setting/User/thunk';
import { setLoading } from '../../../Common/slice';
import { ROUTE_DASH_BOARD } from '../../../DashBoard/constants/routes';

const GeneralSettingTheme = (props) => {
  const { isQuickSetting } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const themeMenu = useSelector(state => state.user.userInfo.theme_menu);
  const userInfo = useSelector(state => state.user.userInfo);
  const color = useSelector(state => state.user.userInfo.theme_color);

  const changeTheme = (theme) => {
    dispatch(setLoading(true))

    settingService.userUpdateThemeMenu({ theme_menu: theme, user_id: userInfo.user_id }).then(res => {
      if (res) {
        const { user_id } = res.data;
        if (user_id > 0) {
          dispatch(setLoading(false))
          dispatch(userDetail({ user_id }));
          if (theme === c.MENU_TYPE_GRID || theme === c.MENU_TYPE_DROPDOWN) {
            document.body.classList.add('sidebar-collapse');
          }
          if (theme === c.MENU_TYPE_DROPDOWN || theme === c.MENU_TYPE_LEFT) {
            history.push(ROUTE_DASH_BOARD);
          }
          if (theme === c.MENU_TYPE_GRID) {
            history.push(ROUTE_HOME);
          }
        }
      }
    });
  };
  return (
    <div className="row mt-3">
      <div className="col-sm-12 col-md-12 col-lg-12">
            <h6 >{lang.theme}</h6>
            <div className="row">
              <div className={`mb-3 ${isQuickSetting ? 'col-sm-6 col-md-6 col-lg-6 col-mobile' : 'col-sm-6 col-md-4 col-lg-3 col-mobile'}`}>
                <div
                  className="card mb-2 bg-dark text-white shadow-1-strong cursor-pointer"
                >
                  <img height={`${isQuickSetting ? '80' : '150'}`} src={themeGridImage} className="card-img" alt="..." />
                  <div className="card-img-overlay">
                    <div className="card-title">
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    onClick={() => changeTheme(c.MENU_TYPE_GRID)}
                    type="button"
                    disabled={themeMenu === c.MENU_TYPE_GRID}
                    className={`btn navbar-${themeMenu === c.MENU_TYPE_GRID ? 'secondary' : color} btn-sm`}
                  >{themeMenu === c.MENU_TYPE_GRID ? lang.actived : lang.active}</button>
                </div>
              </div>
              <div className={`mb-3 ${isQuickSetting ? 'col-sm-6 col-md-6 col-lg-6 col-mobile' : 'col-sm-6 col-md-4 col-lg-3 col-mobile'}`}>
                <div
                  className="card mb-2 bg-dark text-white shadow-1-strong cursor-pointer"
                >
                  <img height={`${isQuickSetting ? '80' : '150'}`} src={themeLeftImage} className="card-img" alt="..." />
                  <div className="card-img-overlay" >
                    <div className="card-title">
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    disabled={themeMenu === c.MENU_TYPE_LEFT}
                    className={`btn navbar-${themeMenu === c.MENU_TYPE_LEFT ? 'secondary' : color} btn-sm`}
                    onClick={() => changeTheme(c.MENU_TYPE_LEFT)}
                  >{themeMenu === c.MENU_TYPE_LEFT ? lang.actived : lang.active}</button>
                </div>
              </div>
              <div className={`mb-3 ${isQuickSetting ? 'col-sm-6 col-md-6 col-lg-6 col-mobile' : 'col-sm-6 col-md-4 col-lg-3 col-mobile'}`}>
                <div
                  className="card mb-2 bg-dark text-white shadow-1-strong cursor-pointer"
                >
                  <img height={`${isQuickSetting ? '80' : '150'}`} src={themeDropdownImage} className="card-img" alt="..." />
                  <div className="card-img-overlay" >
                    <div className="card-title">
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="button"
                    disabled={themeMenu === c.MENU_TYPE_DROPDOWN}
                    className={`btn navbar-${themeMenu === c.MENU_TYPE_DROPDOWN ? 'secondary' : color} btn-sm`}
                    onClick={() => changeTheme(c.MENU_TYPE_DROPDOWN)}
                  >{themeMenu === c.MENU_TYPE_DROPDOWN ? lang.actived : lang.active}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default GeneralSettingTheme;
