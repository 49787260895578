import CallApi from '../../../utils/CallAPI';
import {
  USER,
  USER_DETAIL,
  USER_CHANGE_PASSWORD,
  USER_EXPORT,
  USER_IMPORT,
  USER_LOGOUT
} from './constants/endpoint';

export const userList = (data) => {
  return CallApi(USER, 'GET', data)
};

export const userDetail = (data) => {
  return CallApi(USER_DETAIL, 'GET', data)
};

export const userCreateUpdate = (data, method) => {
  return CallApi(USER, method, data)
};

export const userChangePassword = (data) => {
  return CallApi(USER_CHANGE_PASSWORD, 'PUT', data)
};

export const userExport = (data) => {
  return CallApi(USER_EXPORT, 'POST', data)
};

export const userImport = (data) => {
  return CallApi(USER_IMPORT, 'POST', data)
};

export const userLogout = (data) => {
  return CallApi(USER_LOGOUT, 'PUT', data)
};
