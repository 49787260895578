import { createSlice } from '@reduxjs/toolkit';
import * as branchThunk from './thunk';
import { getInfoUser } from './../../Common/components/GetInfoUser';
import * as c from '../../../constants/common';

export const branchSlice = createSlice({
  name: 'branch',
  initialState: {
    branchByUser: [],
    branchListAll: [],
    currentBranch: {},
    branchList: {},
    branchInfo: {},
    branchId: '',
    branchListId: [],
    branchListAction: [],
    branchQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    setCurrentBranch: (state, action) => {
      state.currentBranch = action.payload;
    },
    clearBranchList: (state, action) => {
      state.branchList = {};
    },
    clearBranchRow: (state, action) => {
      state.branchList.rows = [];
    },
    setBranchId: (state, action) => {
      state.branchId = action.payload;
    },
    clearBranchId: (state, action) => {
      state.branchId = '';
    },
    setBranchQueryData: (state, action) => {
      state.branchQueryData = action.payload;
    },
    setBranchListId: (state, action) => {
      state.branchListId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [branchThunk.branchByUser.fulfilled]: (state, action) => {
      state.branchByUser = action.payload;
    },
    [branchThunk.branchListAll.fulfilled]: (state, action) => {
      state.branchListAll = action.payload;
    },
    [branchThunk.branchList.fulfilled]: (state, action) => {
      state.branchList = action.payload;
    }
  }
});

export const {
  setCurrentBranch,
  clearBranchList,
  clearBranchRow,
  setBranchId,
  clearBranchId,
  setBranchQueryData,
  setBranchListId
} = branchSlice.actions;

export default branchSlice.reducer;
