import CallApi from '../../utils/CallAPI';
import {
  STAFF_TYPE,
  STAFF_TYPE_DETAIL
} from './constants/endpoint';

export const staffTypeList = (data) => {
  return CallApi(STAFF_TYPE, 'GET', data)
};

export const staffTypeCreateUpdate = (data, method) => {
  return CallApi(STAFF_TYPE, method, data)
};

export const staffTypeDetail = (data) => {
  return CallApi(STAFF_TYPE_DETAIL, 'GET', data)
};
