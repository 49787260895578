import {
  SettingOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Popconfirm } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../../language/language';
import CheckPermission from '../../../../Common/components/CheckPermission';
import * as commonService from '../../../../Common/service';
import * as branchService from '../../service';
import { setBranchListId } from '../../slice';
import * as branchThunk from '../../thunk';
import * as c from '../../../../../constants/common';

const BranchActionChecked = (props) => {
  const branchListId = useSelector(state => state.branch.branchListId);
  const queryData = useSelector(state => state.branch.branchQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (branchListId.length > 0) {
      const data = {
        list_id: branchListId,
        update_user: userInfo.user_id
      };
      branchService.branchDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(branchThunk.branchList(queryData));
            dispatch(setBranchListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (branchListId.length > 0) {
      const data = {
        list_id: branchListId,
        field_name: 'branch_id',
        table: 'branch',
        status,
        update_user: userInfo.user_id,
        permission: ['BRANCH_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(branchThunk.branchList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['BRANCH_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['BRANCH_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    branchListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default BranchActionChecked;
