import { createAsyncThunk } from '@reduxjs/toolkit';
import * as userService from './service';

export const userList = createAsyncThunk(
  'user/getList',
  async (data, thunkAPI) => {
    const response = await userService.userList(data);
    return response.data;
  }
);

export const userDetail = createAsyncThunk(
  'user/getInfo',
  async (data, thunkAPI) => {
    const response = await userService.userDetail(data);
    return response.data;
  }
);
