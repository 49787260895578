/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import lang from '../../../../language/language';
import { Row, Col, Form, Select } from 'antd';
import { getListHourAppointment } from '../../../Common/components/GetListHourAppointment';
import * as c from '../../../../constants/common';
import { useDispatch } from 'react-redux';
import { appointmentSettingThunk } from './../thunk';
import moment from 'moment';

const AppointmentSettingGeneral = ({ form }) => {
	const { Option } = Select;
	const dispatch = useDispatch();
	const [hourList, setHourList] = useState([]);

	useEffect(() => {
		gethourList();
		dispatch(appointmentSettingThunk({}));
	}, []);

	const gethourList = () => {
		const hours = getListHourAppointment('01:00', '23:30', 30);
		setHourList(hours);
	};

	// Hàm kiểm tra from_time < to_time
	const validateFromTimeToTime = (form, itemValue) => ({
		validator(_, toTime) {
			const fromTime = form.getFieldValue(itemValue);

			if (!fromTime || !toTime) {
				return Promise.resolve();
			}
			if (moment(fromTime, 'HH:mm').isAfter(moment(toTime, 'HH:mm'))) {
				return Promise.reject(new Error('From time must be before To time'));
			}

			return Promise.resolve();
		},
	});

	return (
		<Row gutter={24}>
			<Col xs={24} md={8}>
				<Form.Item
					label={lang.appointment_setting_fromtime}
					name="appointment_setting_fromtime"
					rules={[{ required: true, message: lang.value_is_required }]}
				>
					<Select size="large" placeholder={lang.appointment_setting_fromtime}>
						{hourList.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} md={8}>
				<Form.Item
					label={lang.appointment_setting_totime}
					name="appointment_setting_totime"
					rules={[
						{ required: true, message: lang.value_is_required },
						validateFromTimeToTime(form, `appointment_setting_fromtime`),
					]}
				>
					<Select size="large" placeholder={lang.appointment_setting_totime}>
						{hourList.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} md={8}>
				<Form.Item
					label={lang.appointment_setting_range}
					name="appointment_setting_range"
					rules={[{ required: true, message: lang.value_is_required }]}
				>
					<Select size="large" placeholder={lang.appointment_setting_range}>
						{c.APPOINTMENT_SETTING_RANGE.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} md={8}>
				<Form.Item
					label={lang.appointment_setting_booking_date_limit}
					name="appointment_setting_booking_date_limit"
					rules={[{ required: true, message: lang.value_is_required }]}
				>
					<Select size="large" placeholder={lang.appointment_setting_booking_date_limit}>
						{c.APPOINTMENT_SETTING_BOOKING_DATE_LIMIT.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} md={8}>
				<Form.Item
					label={lang.appointment_setting_min_booking_time}
					name="appointment_setting_min_booking_time"
					rules={[{ required: true, message: lang.value_is_required }]}
				>
					<Select size="large" placeholder={lang.appointment_setting_min_booking_time}>
						{c.APPOINTMENT_SETTING_MIN_BOOKING_TIME.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
			<Col xs={24} md={8}>
				<Form.Item label={lang.appointment_setting_time_remind} name="appointment_setting_time_remind">
					<Select size="large" placeholder={lang.appointment_setting_time_remind}>
						{c.APPOINTMENT_SETTING_TIME_REMIND.map((item) => {
							return (
								<Option key={item.value} value={item.value}>
									{item.label}
								</Option>
							);
						})}
					</Select>
				</Form.Item>
			</Col>
		</Row>
	);
};

export default AppointmentSettingGeneral;
