import React, { useEffect, useState } from 'react';
import { getUserList } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';

const { Option } = Select;

const UserSelect = (props) => {
  const { isMultiple, label, name, showSelectSystem, listIdRemove } = props;
  const [options, setOptions] = useState([])
  useEffect(() => {
    getUserList().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  // const validateUserSelect = (rule, value, getFieldValue) => {
  //   if (value) {
  //     if (!isMultiple) {
  //       const res = options.find((i) => i.user_id === value);
  //       if (value && res.is_active === c.IS_DEACTIVE) {
  //         return Promise.reject(new Error(`${res.user_fullname} ${lang.option_deactive_validation}`));
  //       }
  //       return Promise.resolve();
  //     } else {
  //       if (value) {
  //         const arr = options.filter(i => value.includes(i.user_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
  //         const text = arr.map((y) => y.user_fullname)
  //         if (arr.length > 0 && value.length > 0) {
  //           return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
  //         }
  //         return Promise.resolve();
  //       }
  //     }
  //   } else return Promise.resolve();
  // }

  /// render/////
  return (
    <Form.Item
      label={label || lang.create_user}
      name={name || 'create_user'}
    // rules={[
    //   ({ getFieldValue }) => ({
    //     validator: (rule, value) => validateUserSelect(rule, value, getFieldValue)
    //   })
    // ]}
    >
      <Select
        size="large"
        showSearch
        allowClear
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={label || lang.create_user}
        filterOption={(input, option) => {
          const data = option.data.user_fullname
          return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {showSelectSystem && <Option key={-1} value={-1} data={{ user_id: '-1', user_phone_number: '', user_fullname: lang.system }}>
          {lang.system}
        </Option>}
        {options.map((item) => {
          return <Option
            className={listIdRemove && listIdRemove.length > 0 && listIdRemove.filter(e => e !== undefined).includes(item.user_id) ? 'd-none' : ''}
            key={item.user_id} value={item.user_id}
            data={item}
          >
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.user_fullname}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default UserSelect;
