/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import lang from '../../../../../language/language';
import { Row, Col, Button, Popover, Space, Input, Radio, message, Select, Form, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import {
    SaveOutlined,
    DollarOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
import { formatCurrency, formatPrice } from '../../../../Common/components/FormatData';
import * as c from '../../../../../constants/common';
// import { invoiceCustomPromotionCreate } from '../../../service';
import { setLoading } from '../../../../Common/slice';
// import { getInvoiceDetail } from '../../../thunk';
import { setInvoiceDetail } from '../../../slice';
import { getInvoiceTotalPromotion, updateInvoiceInfo } from '../../../common';

const InvoiceActionDetailPromotion = (props) => {
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const [isVisible, setIsvisible] = useState(false)
    const [moneyDiscount, setMoneyDiscount] = useState(0)
    const [invoiceTotalPromotion, setInvoiceTotalPromotion] = useState(0)
    const [discountType, setDiscountType] = useState(c.DISCOUNT_TYPE.PERCENT)
    const [form] = Form.useForm();
    const generalSetting = useSelector(state => state.generalSetting);
    const { Option } = Select;
    const userInfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch()
    const posSetting = useSelector(state => state.posSetting);

    useEffect(() => {
        updateTotalPromotion()
    }, [invoiceDetail.invoicePromotionList])

    useEffect(() => {
        if (invoiceDetail.invoicePromotionList) {
            dispatch(setInvoiceDetail({ ...invoiceDetail, invoicePromotionList: invoiceDetail.invoicePromotionList }))
        }
    }, [invoiceDetail.invoicePromotionList, isVisible])
    /// /funtion///////

    const onClose = () => {
        onClearData()
    }

    const onClearData = () => {
        setMoneyDiscount(null)
        setInvoiceTotalPromotion(0)
        setDiscountType(c.DISCOUNT_TYPE.PERCENT)
        setIsvisible(false)
        form.resetFields()
    }

    const onSave = () => {
        // tổng tiền chưa giảm giá
        const totalPriceNeedToPay = invoiceDetail.invoice_customer_need_to_pay
        // tổng tiền sau khi giảm giá

        if (invoiceTotalPromotion > totalPriceNeedToPay) {
            message.error(lang.promotion_invalid)
        } else {
            dispatch(setLoading(true))
            updateInvoiceInfo({
                ...invoiceDetail,
                invoicePromotionList: invoiceDetail.invoicePromotionList,
                invoice_total_promotion: getInvoiceTotalPromotion(invoiceDetail.invoicePromotionList)
            }, dispatch, posSetting)
            onClearData()
        }
    }

    const updateTotalPromotion = () => {
        let total = 0
        invoiceDetail.invoicePromotionList.map((item) => {
            total = total + parseFloat(item.invoice_promotion_money)
        })
        setInvoiceTotalPromotion(total)
    }

    const onChangeMoneyDiscount = (e) => {
        setMoneyDiscount(formatPrice(e.target.value))
    }

    const onChangeDiscountType = (e) => {
        setDiscountType(e.target.value)
    }

    const columns = [

        {
            title: lang.invoice_promotion_name,
            dataIndex: 'invoice_promotion_name'
        },
        {
            title: lang.invoice_promotion_money,
            dataIndex: 'invoice_promotion_money',
            render: (text, record) => {
                return <div >{formatCurrency(record.invoice_promotion_money, generalSetting)}</div>
            }
        },
        {
            title: <MinusCircleOutlined onClick={() => dispatch(setInvoiceDetail({ ...invoiceDetail, invoicePromotionList: [] }))
            } className="cursor-pointer" style={{ fontSize: 18 }} />,
            dataIndex: 'action',
            align: 'center',
            render: (text, record) => {
                return <MinusCircleOutlined onClick={() => onDeletePromotion(record.invoice_promotion_id)} className="cursor-pointer" style={{ fontSize: 18 }} />
            }
        }
    ];

    const onAdd = (data) => {
        const list = [...invoiceDetail.invoicePromotionList]
        let promotionMoney = 0
        if (discountType === c.DISCOUNT_TYPE.PERCENT) {
            promotionMoney = (invoiceDetail.invoice_temp_money * data.invoice_promotion_money) / 100
        } else {
            promotionMoney = data.invoice_promotion_money
        }
        list.push({
            ...data,
            invoice_promotion_money: promotionMoney,
            invoice_promotion_id: list.length + 1,
            invoice_id: invoiceId,
            create_user: userInfo.user_id
        })
        dispatch(setInvoiceDetail({ ...invoiceDetail, invoicePromotionList: list }))
        form.resetFields()
    }

    const onDeletePromotion = (invoicePromotionId) => {
        const list = [...invoiceDetail.invoicePromotionList]
        dispatch(setInvoiceDetail({ ...invoiceDetail, invoicePromotionList: list.filter(x => x.invoice_promotion_id !== invoicePromotionId) }))
    }

    /// /render///////
    return (
        <Popover
            placement="bottomRight"
            trigger="click"
            overlayClassName="popover-medium"
            visible={isVisible}
            content={
                <Form
                    autoComplete="off"
                    layout="vertical"
                    onFinish={onAdd}
                    form={form}
                    initialValues={{ invoice_promotion_type: c.PROMOTION_TYPE[0].value }}
                >
                    <Row gutter={12}>
                        <Col xs={24} md={24} className="border-bottom-1 mb-15">
                            <h3>{lang.promotion}</h3>
                        </Col>
                        <Col xs={12} md={12} >
                            <Form.Item
                                name="invoice_promotion_type"
                                label={lang.invoice_promotion_type}
                            >
                                <Select
                                    size='large'
                                    maxTagCount='responsive'
                                    defaultValue={c.PROMOTION_TYPE[0].value}
                                >
                                    {c.PROMOTION_TYPE.map((item) => {
                                        return <Option
                                            key={item.value}
                                            value={item.value}
                                            data={item}
                                        >
                                            {item.label}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12} md={12} >
                            <Form.Item
                                label={lang.invoice_promotion_name}
                                name="invoice_promotion_name"
                                rules={[
                                    { required: true, message: lang.value_is_required }
                                ]}
                            >
                                <Input
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={20} md={20} >
                            <Form.Item
                                label={lang.invoice_promotion_money}
                                name="invoice_promotion_money"
                                rules={[
                                    { required: true, message: lang.value_is_required }
                                ]}
                            >
                                <Input
                                    onChange={onChangeMoneyDiscount}
                                    size="large"
                                    value={moneyDiscount}
                                    type='number'
                                    addonAfter={
                                        <Radio.Group onChange={onChangeDiscountType} value={discountType}>
                                            <Radio value={c.DISCOUNT_TYPE.PERCENT}>%</Radio>
                                            <Radio value={c.DISCOUNT_TYPE.MONEY}>$</Radio>
                                        </Radio.Group>
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <Form.Item
                                label={' '}
                            >
                                <Button
                                    type="primary"
                                    style={{ height: 40, marginTop: 3 }}
                                    onClick={() => form.submit()}
                                    size="middle">
                                    {lang.add}
                                </Button>
                            </Form.Item>

                        </Col>
                        <Col xs={24} md={24} className="mb-15">
                            <Table columns={columns} dataSource={invoiceDetail.invoicePromotionList} size="small" pagination={false} />
                        </Col>
                        <Col xs={12} md={12} className="text-center">
                            <h3 className='mb-0'><b>{lang.total_price}: {formatCurrency(invoiceDetail.invoice_temp_money, generalSetting)}</b></h3>
                        </Col>
                        <Col xs={12} md={12} className="text-center">
                            <h3 className='mb-0'><b>{lang.invoice_total_promotion}: {formatCurrency(invoiceTotalPromotion)}</b></h3>
                        </Col>
                        <Col xs={24} md={24} className="text-right pt-15 mt-15 border-top-1">
                            <Space>
                                <Button
                                    onClick={onClose}
                                    className="w-100"
                                    type="primary"
                                    size="large"
                                    block
                                ><CloseCircleOutlined /> {lang.close}
                                </Button>
                                <Button
                                    className="w-100"
                                    disabled={!invoiceId}
                                    type="primary"
                                    size="large"
                                    block
                                    onClick={onSave}
                                >
                                    <SaveOutlined /> {lang.save}
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            }
        >
            <Button
                type="primary"
                onClick={() => { setIsvisible(true) }}
                size="large"
                block
                disabled={!invoiceId || invoiceDetail.invoiceItemList.length === 0}
            ><DollarOutlined />
                {lang.promotion}
            </Button>
        </Popover>
    );
};

export default InvoiceActionDetailPromotion;
