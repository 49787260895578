/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { setEmailSampleId, setEmailSampleQueryData, setEmailSampleListId } from '../../slice';
import * as emailThunk from '../../thunk';
import EmailSampleAction from './EmailSampleAction';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import EmailSampleDetail from './EmailSampleDetail';
import EmailSampleFilter from './EmailSampleFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../../Common/slice';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import * as c from '../../../../constants/common';
import EmailSampleActionChecked from './EmailSampleActionChecked';

const { Search } = Input;

const EmailSample = (props) => {
  const dispatch = useDispatch();
  const emailSampleQueryData = useSelector(state => state.email.emailSampleQueryData);
  const emailSampleListId = useSelector(state => state.email.emailSampleListId);
  const dataList = useSelector(state => state.email.emailSampleList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setEmailSampleListId([]));
      dispatch(setEmailSampleId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.email_sample));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(emailSampleQueryData);
  }, [emailSampleQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(emailThunk.emailSampleList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setEmailSampleQueryData({ ...emailSampleQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.email_sample}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_email_sample}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <EmailSampleActionChecked />
                <EmailSampleAction />
                <EmailSampleFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={emailSampleQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={emailSampleListId}
            queryData={emailSampleQueryData}
            keyField="email_sample_id"
            clickField="email_sample_title"
            setListIdChecked={setEmailSampleListId}
            setQueryData={setEmailSampleQueryData}
            expandedRowRender={(record) => <EmailSampleDetail data={record} queryData={emailSampleQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailSample;
