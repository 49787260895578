module.exports = {
	setting: 'Setting',
	not_found: 'Not found',
	dash_board: 'Dashboard',
	setting_general: 'General setting',
	actived: 'Activating...',
	theme: 'Theme',
	quick_setting: 'Setting shortcuts',
	view_all_setting: 'Preferences and Settings',
	theme_color: 'Theme Colour',
	search: 'Search',
	filter: 'Filter',
	status: 'Status',
	close: 'Close',
	apply: 'Apply',
	search_users_placeholder: "Search for User's code and profile, ...",
	not_found_page: 'Page not found',
	home: 'Home page',
	user: 'User',
	user_code: "User's code",
	user_fullname: "User's full name",
	user_name: 'Username',
	user_phone_number: 'Phone number',
	user_email: 'Email',
	user_birthday: 'Date of birth',
	create_date: 'Date',
	active: 'Activate',
	de_active: 'Suspended',
	user_address: 'Address',
	user_note: 'Note',
	from_date: 'From date',
	to_date: 'Till date',
	all: 'All',
	re_new: 'Refresh',
	cancel: 'Cancel',
	save_and_close: 'Save and exit',
	save: 'Save',
	password: 'Password',
	province: 'Province/ City',
	ward: 'Ward/ Commune',
	district: 'District',
	address: 'Address',
	province_required: 'Please enter your Province/ City',
	ward_required: 'Please enter your Ward/ Commune',
	district_required: 'Please enter your district',
	user_code_required: "Please enter your User's code",
	user_name_required: 'Please enter your Username',
	user_password_required: 'Please enter your password',
	user_fullname_required: 'Please enter your full name',
	phone: 'Phone number',
	email: 'Email',
	birthday: 'Date of birth',
	note: 'Note',
	description: 'Description',
	search_user: "Search for User's code, Username or phone number, ...",
	save_success: 'Saved!',
	not_allow_special_characters: 'Special characters are not allowed',
	phone_not_format: 'Please enter a valid phone number',
	phone_min_max: 'Phone number must include 10-11 digits',
	email_not_format: 'Please enter a valid email',
	password_min_6: 'Password must meet complexity requirements (6 degits)',
	no_data: 'No data found!',
	exist: 'Data existed!',
	delete: 'Delete',
	confirm: 'Confirm',
	ok: 'OK',
	sure_delete: 'Are you sure you want to delete?',
	sure_save: 'Save?',
	delete_success: 'Delete successfully!',
	edit: 'Edit',
	import: 'Import',
	export: 'Export',
	showing: 'Display',
	from: 'From',
	to: 'To',
	data: 'Data',
	total: 'Total',
	is_active: 'Status',
	login: 'Login',
	forgot_password: 'Forgot password',
	login_not_correct: 'Your login information was incorrect',
	add: 'Add',
	info_person: 'Personal information',
	change_password: 'Change password',
	logout: 'Log out',
	password_old: 'Old password',
	password_new: 'New password',
	password_new_confirm: 'Confirm your new password',
	user_password_new_required: 'Please enter a new password',
	user_password_new_confirm_required: 'Please enter and confirm new password',
	password_old_not_match: 'Old password was incorrect!',
	password_new_not_match: 'New password was unmatched!',
	role: 'Role',
	choose_file: 'Select file',
	please_select_file: 'Please select a file',
	role_name: 'Position:',
	role_note: 'Note',
	search_role: 'Search for a job position...',
	role_name_required: 'Please enter a job position',
	branch_and_role: 'Branches and roles',
	branch: 'Branch',
	user_info: 'User information',
	sure_save_update_status_role:
		'If you deactivate this role, it will not be displayed while selecting a new role for users. However, other users can still use it normally',
	sure_delete_role: 'If you delete this role, you will have to re-grant new permissions to all users who are affected',
	branch_code: 'Branch code',
	branch_name: 'Branch name',
	branch_address: 'Branch address',
	branch_note: 'Note',
	search_branch: 'Search for branch code, name or address...',
	branch_code_required: 'Please enter branch code',
	branch_name_required: 'Please enter branch name',
	server_error: 'Server error!',
	sure_delete_branch:
		'If you delete this branch, you will have to re-grant a new branch for all users of the old branch',
	branch_phone_number: 'Branch phone number',
	sure_save_update_status_branch:
		'If this branch is deactivated, it will not be displayed when granting permissions to users. However, the users who are already belonged to this branch can still use it normally',
	main: 'Main',
	customer: 'Customer',
	customer_code: 'Customer code',
	customer_fullname: "Customer's full name",
	customer_phone_number: "Customer's phone number",
	customer_email: "Customer's email",
	customer_address: "Customer's address",
	customer_note: "Customer's note",
	customer_birthday: "Customer's date of birth",
	search_customer: "Search for Customer's code, full name or phone number, ...",
	customer_code_required: "Please enter Customer's code",
	customer_fullname_required: "Please enter Customer's full name",
	customer_list: 'Customer list',
	customer_group: 'Customer group',
	customer_group_name: "Customer group's name",
	discount: 'Discount',
	discount_type: 'Discount type',
	customer_group_note: "Customer group's note",
	customer_group_info: "Customer group's information",
	condition: 'Condition',
	discount_required: 'Discount is required!',
	customer_group_name_required: 'Please enter a name for customer group',
	only_allow_number: 'Only numbers are allowed to be typed',
	search_customer_group: 'Search for customer group...',
	error_delete_current_branch: 'You cannot delete the currently using branch',
	condition_type: 'Condition type',
	condition_value: 'Value',
	info_customer_is_required: 'Please enter customer information',
	value_is_required: 'Please enter value',
	customer_phone_number2: 'Secondary phone number',
	customer_gender: 'Gender',
	gender: 'Gender',
	male: 'Male',
	female: 'Female',
	tax_code: 'Tax code',
	personal: 'Personal',
	company: 'Company',
	company_name: 'Company name',
	choose_image: 'Select image',
	customer_facebook: 'Facebook',
	customer_yelp: 'Yelp',
	customer_type: 'Customer type',
	customer_tax_code: "Customer's tax code",
	customer_company_name: "Customer's company name",
	phone2: 'Secondary phone number',
	phone_no_duplicate: 'Phone number must not be duplicated',
	phone_number: 'Phone number',
	file_not_image: 'The file must be an image',
	file_max_size: 'Uploaded file size must not be larger than 2 MB',
	create_user: 'Creator',
	no_permission:
		'We are sorry, but you do not have access to this service. Please contact your administrator to gain access',
	left_menu_color: 'Menu colour',
	branch_role_is_required: 'Please enter branch role',
	condition_is_required: 'Please enter condition',
	service_group: 'Service group',
	service_group_code: 'Service group code',
	service_group_name: 'Service group name',
	service_group_avatar: 'Avatar',
	service_group_description: 'Service group description',
	search_service_group: 'Search for service group...',
	service: 'Service',
	service_code: 'Service code',
	service_name: 'Service name',
	service_avatar: 'Avatar',
	service_description: 'Service description',
	service_price: 'Price',
	service_price_discount: 'Discount',
	search_service: 'Search for service code or name...',
	value_not_allow_special_characters: 'Special characters are not allowed',
	reset: 'Refresh',
	export_excel: 'Export Excel file',
	download_demo: 'Download demo file',
	not_format: 'File format is not valid',
	login_main_top: 'Welcome to Hexcore!',
	login_main_bottom: 'Please login to experience the best service',
	is_main: 'Main',
	not_main: 'Extra',
	branch_not_duplicate: 'Branch must not be duplicated',
	selected: 'Selected',
	select_all: 'Select all',
	unselect_all: 'Deselect all',
	infomation: 'Information',
	condition_not_duplicate: 'Condition must not be duplicated',
	service_count: 'Service count',
	service_group_not_allow_delete: 'This service group cannot be deleted because it already in use',
	service_price_not_greater_discount: 'The service price must not be less than discount! ',
	discount_not_greater_service_price: 'The discount must not be greater than the service price',
	other: 'Other',
	title: 'Title',
	search_title: 'Search title',
	title_name: 'Title name',
	title_note: 'Note',
	title_not_allow_delete: 'Title cannot be deleted because it already in use',
	staff_list: 'Staff list',
	staff_code: 'Staff code',
	staff_fullname: "Staff's full name",
	staff_birthday: "Staff's date of birth",
	staff_email: "Staff's email",
	staff_phone_number: "Staff's phone number",
	staff_id_card: "Staff's ID",
	staff_gender: 'Gender',
	staff_address: "Staff's address",
	staff_note: 'Note',
	staff_facebook: 'Facebook',
	search_staff: "Search for staff's code, name, phone   number, ID or email...",
	staff: 'Staff',
	staff_info: "Staff's information",
	branch_and_title: 'Branch and title',
	option_deactive_validation: 'This service is currently suspended',
	setting_currency: 'Currency',
	setting_date_format: 'Date format',
	appointment: 'Appointment',
	time: 'Time',
	branch_title_is_required: 'Please enter a branch title',
	appointment_setting: 'Appointment setting',
	appointment_setting_fromtime: 'From',
	appointment_setting_totime: 'To',
	appointment_setting_range: 'Appointment range',
	appointment_setting_booking_date_limit: 'Booking date limit',
	day: 'Day',
	minute: 'Minute',
	alert_exit_without_save: 'Changes have not been saved, are you sure you want to exit?',
	yes: 'Yes',
	no: 'No',
	wait_confirm: 'Waiting for confirmation',
	confirmed: 'Confirm',
	done: 'Done!',
	cancelled: 'Cancelled',
	appointment_code: 'Appointment code',
	appointment_date: 'Appointment date',
	appointment_hour: 'Appointment time',
	service_staff: 'Services and staffs',
	appointment_quantity: 'Quantity',
	min_quantity_1: 'Quantity must be greater than 1',
	search_customer_phone: "Search for customer's phone number",
	spent: 'Spent',
	visit: 'Visit',
	point: 'Point',
	service_duration: 'Service duration',
	time_expired: 'Time expired',
	appointment_setting_min_booking_time: 'Minimum booking time',
	search_appointment_by_customer_phone: "Please enter customer's phone number to search for booked appointment",
	cancel_appointment: 'Cancel an appointment',
	print_ticket: 'Print',
	cancel_appointment_success: 'Cancelled successfully!',
	confirm_appointment_success: 'Appointment confirmed successfully!',
	sure_cancel: 'Are you sure you want to cancel?',
	search_staff_code_name: "Search for staff's code, name...",
	add_close: 'Add and close',
	of: 'Of',
	service_not_empty: 'Service must not be null or empty',
	service_staff_exist: 'Service and staff already existed',
	service_is_required: 'Please select a service',
	staff_is_required: 'Please select a staff',
	appointment_end_hour: 'Estimated finish time',
	conflict_end_hour: 'Time limit exceeded for the next appointment',
	staff_type: 'Staff type',
	technician: 'Technician',
	staff_normal: 'Normal staff',
	wait_appointment: 'Waiting for your turn',
	email_history_title: 'Email title',
	email_history_content: 'Email content',
	email_history: 'Sent emails',
	search_email_history: 'Search for email title, content,...',
	email_sample: 'Email templates',
	email_sample_title: 'Title',
	email_sample_content: 'Content',
	search_email_sample: 'Search for email templates title, content,...',
	email_setting: 'Email setting',
	email_setting_smtp_server: 'SMTP Server',
	email_setting_port: 'Port',
	email_setting_from: 'Email sender',
	email_setting_user: 'Account',
	email_setting_password: 'Password',
	email_setting_secure: 'Use SSL',
	email_setting_default: 'Default Email',
	email_setting_custom: 'Custom Email',
	email_history_to: 'Recipient',
	email_create: 'Send email',
	send: 'Send',
	send_and_close: 'Send and close',
	sms_history: 'Sent SMS',
	sms_history_content: 'SMS content',
	sms_history_to: 'Recipient',
	search_sms_history: 'Search for SMS history, content, recipient,...',
	sms_create: 'Send SMS',
	setting_country: 'Country',
	system: 'System',
	sms_sample: 'SMS templates',
	sms_sample_title: 'Title',
	sms_sample_content: 'Content',
	search_sms_sample: 'Search for SMS templates title, content,...',
	sms_send: 'Send SMS',
	email_send: 'Send email',
	appointment_confirm: 'Confirm appointment',
	appointment_admin_cancel: 'Appointment cancelled by salon',
	appointment_customer_cancel: 'Appointment cancelled by customers',
	appointment_remind: 'Appointment reminder',
	appointment_content_confirm: 'Confirm appointment content',
	appointment_content_admin_cancel: 'Appointment cancellation content by salon',
	appointment_content_customer_cancel: 'Appointment cancellation content by customers',
	appointment_content_remind: 'Appointment cancellation content',
	appointment_setting_time_remind: 'Appoinment reminder',
	title_label: 'Title label',
	content: 'Content',
	hour: 'Hour',
	appointment_history: 'Appointment history',
	appointment_status: 'Appointment status',
	branch_website: 'Website',
	cancellation_reason: 'Reason for cancellation',
	cancellation_reason_is_required: 'Please enter a reason for cancellation',
	pos: 'POS',
	product: 'Product',
	sort_data: 'Sort',
	name_a_z: 'From A-Z',
	name_z_a: 'From Z-A',
	price_decrease: 'Price decrease',
	price_ascending: 'Price increase',
	invoice_item_price: 'Item price',
	invoice_item_name: 'Service / Item',
	invoice_item_quantity: 'Quantity',
	invoice_item_code: 'Item code',
	invoice_item_total_price: 'Total',
	other_cost: 'Other cost ',
	gift_card: 'Gift card',
	tax: 'Tax',
	customer_need_to_pay: 'Pay amount',
	money_received: 'Received',
	change: 'Change',
	payment_method: 'Payment method',
	cash: 'Cash',
	payment: 'Payment',
	invoice: 'Invoice',
	total_price: 'Total',
	credit: 'Credit card',
	check: 'Check',
	cancellation_invoice: 'Invoice cancellation',
	pos_setting: 'POS setting',
	on: 'On',
	off: 'Off',
	auto_apply_discount: 'Apply discounts',
	auto_payment_print: 'Print invoice when purchased',
	tax_percent: 'Tax (%)',
	apply_discount_customer_group: 'Apply discounts to customers group',
	discount_invalid: 'Discount must not be greater than total purchase',
	promotion_invalid: 'Promotion must not be greater than total purchase',
	money_received_invalid: 'Money paid by customers must not be less than total purchase',
	gift_card_balance: 'Gift card balance',
	gift_card_code: 'Gift code',
	search_gift_card: 'Search for gift card...',
	deposit: 'Deposit',
	gift_card_money: 'Gift card money',
	gift_card_history_money: 'Gift card history',
	invoice_code: 'Invoice code',
	transaction_history: 'Transaction history',
	gift_card_code_required: 'Please enter giftcode',
	gift_card_code_invalid: 'Please enter a valid giftcode',
	print_barcode: 'Print barcode',
	cash_gift_card: 'Cash/Gift card',
	cash_credit_card: 'Cash/Credit card',
	cash_check: 'Cash/Check',
	credit_card_gift_card: 'Credit card/Gift card',
	credit_card_check: 'Credit card/Check',
	gift_card_check: 'Gift card/Check',
	accumulate_reward_point_percent: 'Points accumulation (%)',
	quantity_error: 'Quantity must be greater than 1',
	gift_card_from_name: 'Gift card sender',
	gift_card_to_name: 'Recipient name',
	gift_card_message: 'Message',
	max_150: ' (Max 150 characters)',
	gift_card_from_email: 'Sender',
	gift_card_to_email: 'Recipient',
	gift_card_type: 'Purpose',
	someone_else: 'For others',
	your_self: 'For myself',
	quantity: 'Quantity',
	print: 'Print',
	string_length_limit: 'Character length limit exceeded',
	gift_card_list: 'Gift card list',
	on_check: 'Check',
	input_money: 'Amount',
	gift_card_setting: 'Gift card setting',
	background_list: 'Background image list',
	gift_card_setting_message_max_length: 'Maximum characters',
	gift_card_setting_terms_conditions: 'Terms and conditions',
	gift_card_setting_title: 'Title for gift card',
	gift_card_setting_description: 'Description',
	btn_change: 'Change',
	gift_card_setting_allow_send_from_email: 'Send mail to buyer',
	gift_card_setting_allow_send_to_email: 'Send mail to recipient',
	background_image_default: 'Default background image',
	appointment_content_sms_confirm:
		'Dear [customer_fullname]. This is a confirmation that you have booked [service]. We are waiting you at our salon on [appointment_date] at [appointment_hour]. Thank you for choosing us.',
	appointment_content_admin_sms_cancel:
		'Dear [customer_fullname]. Your booking of [service] on [appointment_date] at [appointment_hour] has been rejected. Reason: [cancellation_reason]. Thank you for choosing us.',
	appointment_content_customer_sms_cancel:
		'Dear [customer_fullname]. You have cancelled your booking of [service] on [appointment_date] at [appointment_hour]. Thank you for choosing us.',
	appointment_content_sms_remind:
		'Dear [customer_fullname]. We would like to remind you that you have booked [service] on [appointment_date] at [appointment_hour]. We are waiting for you at [branch_address]. Thank you for choosing us.',
	appointment_content_email_confirm: `<p>Dear [customer_fullname].</p>
                                              <p>This is a confirmation that you have booked [service].</p>
                                              <p>We are waiting you at our salon on [appointment_date] at [appointment_hour].</p>
                                              <p>Thank you for choosing us.</p>
                                              <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_admin_email_cancel: `<p>Dear [customer_fullname].</p>
                                                  <p>Your booking of [service] on [appointment_date] at [appointment_hour] has been rejected.</p>
                                                  <p>Reason: [cancellation_reason]</p>
                                                  <p>Thank you for choosing us.</p>
                                                  <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_customer_email_cancel: `<p>Dear [customer_fullname].</p>
                                                      <p>You have cancelled your booking of [service] on [appointment_date] at [appointment_hour].</p>
                                                      <p>Thank you for choosing us.</p>
                                                      <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_email_remind: `<p>Dear [customer_fullname].</p>
                                            <p>We would like to remind you that you have booked [service] on [appointment_date] at [appointment_hour]. We are waiting for you at [branch_address].</p>
                                            <p>Thank you for choosing us.</p>
                                            <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_title_email_confirm: 'Confirm appointment',
	appointment_title_admin_email_cancel: 'Appointment cancellation notification by salon',
	appointment_title_customer_email_cancel: 'Appointment cancellation notification by customers',
	appointment_title_email_remind: 'Appointment reminder',
	gift_card_setting_from_email_title: 'Gift card payment confirmation',
	gift_card_setting_from_email_content: 'Gift card message',
	gift_card_setting_to_email_title: 'You received a gift card!',
	gift_card_setting_to_email_content: 'Gift card message',
	add_album: 'Add album',
	delete_gift_card_error: 'Unable to delete Album with images inside',
	album_name: 'Album name',
	album: 'album',
	image_gallery: 'Gallery',
	background_image: 'Background image',
	gift_card_send_email_from: 'Sender',
	gift_card_send_email_to: 'Recipient',
	send_success: 'Sent successfully',
	gift_card_expiry_date: 'Expiration date',
	not_expiry: 'Unlimited duration',
	one_month: '1 month',
	three_month: '3 months',
	six_month: '6 months',
	nine_month: '9 months',
	one_year: '1 year',
	custom_date: 'Select date',
	save_and_print: 'Save and print',
	logo_url: 'LOGO url',
	gift_card_exist: 'Gift card existed!',
	gift_card_balance_total: 'Gift card total balance',
	gift_card_not_active: 'This gift card has been suspended',
	gift_card_expiry: 'This gift card has expired',
	invoice_total_price: 'Total',
	invoice_other_cost: 'Other cost',
	invoice_discount: 'Discount',
	invoice_tax: 'Tax',
	invoice_customer_need_to_pay: 'Need to purchase',
	invoice_payment_method: 'Payment method',
	invoice_status: 'Invoice status',
	paid: 'Purchased',
	unpaid: 'Not purchased yet!',
	search_invoice: 'Search for invoice...',
	invoice_money_received: 'Received',
	invoice_change: 'Change',
	invoice_note: 'Note',
	update_date: 'Update date',
	invoice_cancellation_reason: 'Cancellation reason',
	invoice_customer_group_discount: 'Customer group discount',
	copy: 'Copy',
	invoice_payment: 'INVOICE',
	invoice_tip: 'Tips',
	tip: 'Tips',
	service_ticket: 'Service ticket',
	print_ticket_note: 'Please return this ticket to the counter after using the service',
	visited: 'Visited',
	surcharge: 'Surcharge',
	promotion: 'Promotion',
	custom: 'Custom',
	voucher: 'Voucher',
	invoice_total_promotion: 'Total promotion',
	invoice_promotion_type: 'Promotion type',
	from_program: 'From program',
	invoice_promotion_name: 'Promotion name',
	invoice_promotion_money: 'Amount reduced',
	invoice_total_surcharge: 'Total surcharge',
	invoice_surcharge_name: 'Surcharge name',
	invoice_surcharge_money: 'Surcharge',
	invoice_temp_money: 'Provisional calculation',
	invoice_payment_method_money: 'Amount of money',
	method_duplicate: 'Payment method already existed!',
	method_price_greater_than_0: 'Price must be greater than 0',
	pay_success: 'Purchased successfully!',
	invoice_money_received_error: 'Received amount must not be less than total amount',
	please_select_customer: 'Please select a customer',
	gift_card_max_use: 'The payment amount has been exceeded',
	gift_card_use: 'Use',
	gift_card_use_than_total: 'Amount used exceeds total card amount',
	gift_card_money_use: 'Amount used',
	description_btn_tip: 'Click Tip to tip all the change',
	description_percent_tip: 'Percentage is calculated by the paid amount',
	percent_tip_error: 'Tips must not be greater than change!',
	invoice_not_invalid: 'Invalid invoice!',
	money_than_0: 'Amount must be greater than 0',
	money_than_balance: 'Amount is greater than remaining balance',
	code_not_found: 'Code not found!',
	barcode_actived: 'Code has been activated successfully!',
	reward_point_history: 'Reward point history',
	visit_history: 'Visit history',
	customer_reward_point_history: 'Point',
	spent_history: 'Spent history',
	total_payment: 'Total payment',
	checkin_app: 'App check-in',
	checkin_history_type: 'Check-in history',
	create_from: 'Create from',
	website_client: 'Website',
	app_client: 'App client',
	web_admin: 'Admin',
	checkin_user: 'Users check-in',
	search_checkin_user: "Search for User's code, name, phone number, ...",
	income: 'Income',
	today: 'Today',
	yesterday: 'Yesterday',
	this_week: 'This week',
	last_week: 'Last week',
	this_month: 'This month',
	last_month: 'Last month',
	checkin_setting: 'Check-in setting',
	slider: 'Slider',
	top_ten_service: 'Top 10 services',
	used_times: 'Used times',
	booking: 'Booking',
	your_service_pack: 'Service pack',
	sms_left: 'SMS left',
	time_left: 'Time left',
	checkin_setting_welcome: 'Greetings',
	checkin_setting_description: 'Description',
	report_statistic: 'Daily statistics',
	report_service_pack: 'Service pack information',
	from_day: 'From date',
	to_day: 'Till date',
	pls_choose_range_time: 'Please choose a range of time',
	recent_activity: 'Recent activity',
	load_more_end: 'All data has been downloaded',
	has_been: 'Has been',
	at: 'At',
	sure_logout: 'Are you sure you want to logout?',
	logout_success: 'Logout successfully',
	staff_title: 'Staff title',
	search_staff_title: 'Search for staff title...',
	staff_title_name: 'Staff title',
	staff_title_note: 'Note',
	staff_title_not_allow_delete: 'Cannot delete due to already used title',
	report_customer: 'Visitor statistics',
	report_rated: 'Rating Statistics',
	// da dich
	email_contains_invalid_characters: 'Email contains invalid characters',
	business_hours: 'Business hours',
};
