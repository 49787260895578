import { createSlice } from '@reduxjs/toolkit';
import * as giftCardThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';

export const giftCardSlice = createSlice({
  name: 'giftCard',
  initialState: {
    giftCardList: {},
    giftCardInfo: {},
    giftCardId: '',
    giftCardListId: [],
    giftCardListAction: [],
    giftCardCondition: [],
    giftCardQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearGiftCardList: (state, action) => {
      state.giftCardList = {};
    },
    clearGiftCardRow: (state, action) => {
      state.giftCardList.rows = [];
    },
    clearGiftCardHistoryRow: (state, action) => {
      state.giftCardHistoryList.rows = [];
    },
    setGiftCardId: (state, action) => {
      state.giftCardId = action.payload;
    },
    clearGiftCardId: (state, action) => {
      state.giftCardId = '';
    },
    setGiftCardQueryData: (state, action) => {
      state.giftCardQueryData = action.payload;
    },
    setGiftCardListId: (state, action) => {
      state.giftCardListId = action.payload;
    },
    setGiftCardCondition: (state, action) => {
      state.giftCardCondition = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [giftCardThunk.giftCardList.fulfilled]: (state, action) => {
      state.giftCardList = action.payload;
    }
  }
});

export const {
  setCurrentGiftCard,
  clearGiftCardList,
  clearGiftCardRow,
  setGiftCardId,
  clearGiftCardId,
  setGiftCardQueryData,
  setGiftCardListId,
  setGiftCardCondition,
  clearGiftCardHistoryRow
} = giftCardSlice.actions;

export default giftCardSlice.reducer;
