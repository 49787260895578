/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import { Tabs, Row, Col, Button, Space, Popconfirm, message } from 'antd';
// import lang from '../../../../language/language';
import lang from '../../../../language/language';
import CustomerDetailInfo from './CustomerDetailInfo';
import {
    EditOutlined,
    DeleteOutlined,
    SendOutlined
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import { useSelector, useDispatch } from 'react-redux';
import * as customerThunk from '../../thunk';
import { setCustomerId } from '../../slice';
import * as commonService from '../../../Common/service';
import { setEmailCustomerListId } from '../../../Email/slice';
import { setSMSCustomerListId } from '../../../SMS/slice';
import CustomerDetailAppointmentHistory from './CustomerDetailAppointmentHistory';
import CustomerDetailRewardPointHistory from './CustomerDetailRewardPointHistory';
import CustomerDetailSpentHistory from './CustomerDetailSpentHistory';
import CustomerDetailCheckinHistory from './CustomerDetailCheckinHistory';

// const { Text } = Typography;
const { TabPane } = Tabs;

const CustomerDetail = (props) => {
    const { data, queryData, isView, classContentTab } = props;
    const dispatch = useDispatch();
    const role = useSelector(state => state.role)
    const userInfo = useSelector(state => state.user.userInfo);
    /// /funtion///////
    // click button delete
    const onDelete = () => {
        if (data.customer_id) {
            const customerId = data.customer_id
            const dataDelete = {
                list_id: [customerId],
                field_name: 'customer_id',
                table: 'customer',
                update_user: userInfo.user_id,
                permission: ['CUSTOMER_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(customerThunk.customerList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.customer_id) {
            document.getElementById('customerAction').click();
            dispatch(setCustomerId(data.customer_id));
        }
    };
    const onSendEmail = () => {
        dispatch(setEmailCustomerListId([data.customer_id]))
        document.getElementById('emailHistoryAction').click()
    }
    const onSendSMS = () => {
        dispatch(setSMSCustomerListId([data.customer_id]))
        document.getElementById('smsHistoryAction').click()
    }
    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24}>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab={lang.infomation} key="1">
                        <div className={classContentTab}>
                            <CustomerDetailInfo isView={isView} data={data} queryData={queryData} />
                        </div>
                    </TabPane>
                    {(userInfo.is_main === 1 || role.getRoleByUser.some(i => ['CUSTOMER_REWARD_POINT_HISTORY_VIEW'].indexOf(i.permission_code) >= 0)) && <TabPane tab={lang.reward_point_history} key="4">
                        <div className={classContentTab}>
                            <CustomerDetailRewardPointHistory data={data} />
                        </div>
                    </TabPane>}
                    {
                        (userInfo.is_main === 1 || ['CUSTOMER_APPOINTMENT_HISTORY_VIEW', 'APPOINTMENT_VIEW'].every(val => !role.roleListByAppDeActive.includes(val))) &&
                        <TabPane tab={lang.appointment_history} key="3">
                            <div className={classContentTab}>
                                <CustomerDetailAppointmentHistory data={data} />
                            </div>
                        </TabPane>
                    }
                    {(userInfo.is_main === 1 || role.getRoleByUser.some(i => ['CUSTOMER_SPENT_HISTORY_VIEW'].indexOf(i.permission_code) >= 0)) && <TabPane tab={lang.spent_history} key="2">
                        <div className={classContentTab}>
                            <CustomerDetailSpentHistory data={data} />
                        </div>
                    </TabPane>}
                    {(userInfo.is_main === 1 || role.getRoleByUser.some(i => ['CUSTOMER_CHECKIN_HISTORY_VIEW'].indexOf(i.permission_code) >= 0)) && <TabPane tab={lang.visit_history} key="5">
                        <div className={classContentTab} data={data}>
                            {<CustomerDetailCheckinHistory data={data} />}
                        </div>
                    </TabPane>}
                </Tabs>
            </Col>
            {!isView && <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['CUSTOMER_SEND_EMAIL', 'EMAIL_HISTORY_VIEW', 'EMAIL_HISTORY_ADD']}>
                                {data && data.customer_id && data.customer_email &&
                                    <Button
                                        type="primary"
                                        onClick={onSendEmail}
                                        size="large"
                                        icon={<SendOutlined />}
                                    >{lang.email_send}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CUSTOMER_SEND_SMS', 'SMS_HISTORY_ADD', 'SMS_HISTORY_VIEW']}>
                                {data && data.customer_id && data.customer_phone_number &&
                                    <Button
                                        type="primary"
                                        onClick={onSendSMS}
                                        size="large"
                                        icon={<SendOutlined />}
                                    >{lang.sms_send}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CUSTOMER_EDIT']}>
                                {data && data.customer_id &&
                                    <Button
                                        type="primary"
                                        onClick={onEdit}
                                        size="large"
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CUSTOMER_DELETE']}>
                                {data && data.customer_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>}
        </Row>
    );
};

export default CustomerDetail;
