/* eslint-disable array-callback-return */

import React from 'react';
import GiftCardTemplate from './GiftCardTemplate';

const GiftCardPrint = (props) => {
    const { printRef, data } = props;

    const showContent = (data) => {
        let result = <div></div>
        if (data.length > 0) {
            result = data.map((item, index) => {
                return (
                    <GiftCardTemplate key={index} data={item} />
                )
            })
        }
        return <div style={{ position: 'absolute', bottom: 1000, zIndex: 0, right: 0 }}>
            <div id="gift-card" className='bg-white section-print' ref={printRef}>
                {result}
            </div>
        </div>
    }

    return (
        showContent(data)
    )
    /// /render///////
};

export default GiftCardPrint;
