/* eslint-disable no-useless-escape */
import React from 'react';
import lang from '../../../../../language/language';
// import Address from '../../../../Common/components/Address';
import { useSelector } from 'react-redux';
import noImage from '../../../../../assets/images/default-user.png';
import * as c from '../../../../../constants/common';
import {
  Button, Row, Col, Form, Input, DatePicker, Switch, Image
} from 'antd';
import {
  CameraOutlined
} from '@ant-design/icons';
import ImageGallerySelect from '../../../../ImageGallery/components/ImageGallerySelect';
const { TextArea } = Input;

const UserActionInfo = (props) => {
  const { dataAvatar, setDataAvatar } = props;
  const userId = useSelector(state => state.user.userId);
  const userInfo = useSelector(state => state.user.userInfo);

  /// //////////////////////// Validate /////////////////////////

  const onSaveChangeImage = (data) => {
    if (data.length > 0) {
      setDataAvatar(data[0])
    }
  }
  /// render/////
  return (
    <Row gutter={24}>
      <ImageGallerySelect id="galleryUserAction" onSave={onSaveChangeImage} />
      <Col xs={24} md={24} className="mb-15">
        <div className="text-center">
          <Image
            width="100%"
            style={{ margin: 'auto' }}
            className="img-avatar"
            preview={false}
            src={dataAvatar.image_gallery_url || noImage}
          />
        </div>
        <div className="text-center pt-10">
          <Button icon={<CameraOutlined />}
            onClick={() => document.getElementById('galleryUserAction').click()}
            key="update_user_avatar"
            type="primary"
            size="middle"
          >
            {lang.choose_image}
          </Button>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_code}
          name="user_code"
          rules={[
            { required: true, message: lang.value_is_required },
            { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_name}
          name="user_name"
          rules={[
            { required: true, message: lang.value_is_required },
            { pattern: /^[A-Za-z0-9_]*$/, message: lang.not_allow_special_characters }
          ]}
        >
          <Input
            addonBefore={!userId ? `${userInfo.company_code}_` : ''} size="large"
            disabled={!!userId}
            />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={lang.password}
          name="user_password"
          rules={[
            { required: !userId, message: lang.value_is_required },
            { min: 6, message: lang.password_min_6 }
          ]}
        >
          <Input type="password" size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_fullname}
          name="user_fullname"
          rules={[
            { required: true, message: lang.value_is_required },
            {
              pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
              message: lang.not_allow_special_characters
            }
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_phone_number}
          name="user_phone_number"
          rules={[
            { pattern: /^[0-9]*$/, message: lang.not_format },
            { min: 10, message: lang.phone_min_max },
            { max: 11, message: lang.phone_min_max }
          ]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label="Email"
          name="user_email"
          rules={[{ type: 'email', message: lang.email_not_format }]}
        >
          <Input size="large" />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_birthday}
          name="user_birthday"
        >
          <DatePicker format={c.FORMAT_DATE} placeholder={lang.user_birthday} style={{ width: '100%' }} size="large" inputReadOnly />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item
          label={lang.user_address}
          name="user_address"
        >
          <Input size="large" />
        </Form.Item>
      </Col>

      {/* <Address
        dataEdit={dataEdit}
        clearAddress={clearAddress}
        countSubmit={countSubmit}
      /> */}

      <Col xs={24} md={12}>
        <Form.Item
          label={lang.note}
          name="user_note"
        >
          <TextArea allowClear rows={1} size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} md={24} className="text-right">
        <Form.Item
          name="is_active"
          valuePropName="checked"
        >
          <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default UserActionInfo;
