import { createSlice } from '@reduxjs/toolkit';
import * as turnSettingThunk from './thunk';

export const turnSettingSlice = createSlice({
    name: 'turnSetting',
    initialState: {

    },
    reducers: {

    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [turnSettingThunk.turnSettingThunk.fulfilled]: (state, action) => {
            return action.payload;
        }
    }
});

export default turnSettingSlice.reducer;
