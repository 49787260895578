import React, { useEffect, useState } from 'react';
import { getEmailSample } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
const { Option } = Select;

const EmailSampleSelect = (props) => {
  const {
    isMultiple,
    label,
    name,
    showSearch,
    allowClear,
    hideLabel,
    required,
    style,
    disabled,
    listIdSelected,
    onChange,
    onSelect
  } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getEmailSample().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const onSelected = (data, options) => {
    if (onSelect) {
      if (options.data.is_active === c.IS_ACTIVE) {
        onSelect(options.data)
      }
    }
  }
  const validateEmailSampleSelect = (rule, value, getFieldValue) => {
    if (value) {
      if (!isMultiple) {
        const res = options.find((i) => i.email_sample_id === value);
        if (value && res.is_active === c.IS_DEACTIVE) {
          return Promise.reject(new Error(`${res.email_sample_title} ${lang.option_deactive_validation}`));
        }
        return Promise.resolve();
      } else {
        if (value) {
          const arr = options.filter(i => value.includes(i.email_sample_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
          const text = arr.map((y) => y.email_sample_title)
          if (arr.length > 0 && value.length > 0) {
            return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
          }
          return Promise.resolve();
        }
      }
    } else return Promise.resolve();
  }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : (label || lang.email_sample)}
      name={name || 'email_sample'}
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateEmailSampleSelect(rule, value, getFieldValue)
        })
      ]}
    >
      <Select
        style={style}
        size="large"
        showSearch={showSearch || true}
        allowClear={allowClear || true}
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={label || lang.email_sample}
        disabled={disabled || false}
        onChange={onChange}
        onSelect={onSelected}
      >
        {options.map((item) => {
          return <Option
            key={item.email_sample_id}
            value={item.email_sample_id}
            data={item}
            className={listIdSelected && listIdSelected.length > 0 && listIdSelected.filter(e => e !== undefined).map(a => a.email_sample).includes(item.email_sample_id) ? 'd-none' : ''}
          >
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.email_sample_title} </p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default EmailSampleSelect;
