import { createAsyncThunk } from '@reduxjs/toolkit';
import * as customerGroupService from './service';

export const customerGroupList = createAsyncThunk(
  'customerGroup/customerGroupList',
  async (data, thunkAPI) => {
    const response = await customerGroupService.customerGroupList(data);
    return response.data;
  }
);
