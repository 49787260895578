/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import { setCustomerGroupId, setCustomerGroupQueryData, setCustomerGroupListId } from '../slice';
import * as customerGroupThunk from '../thunk';
import CustomerGroupFilter from './CustomerGroupFilter';
import CustomerActionChecked from './CustomerGroupAction/CustomerGroupActionChecked';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import CustomerGroupDetail from './CustomerGroupDetail/CustomerGroupDetail';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import { Row, Col, Space, Input } from 'antd'
import * as c from '../../../constants/common';

import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import CustomerGroupAction from './CustomerGroupAction/CustomerGroupAction';

const { Search } = Input;

const CustomerGroup = (props) => {
  const dispatch = useDispatch();
  const customerGroupQueryData = useSelector(state => state.customerGroup.customerGroupQueryData);
  const customerGroupListId = useSelector(state => state.customerGroup.customerGroupListId);
  const dataList = useSelector(state => state.customerGroup.customerGroupList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setCustomerGroupListId([]));
      dispatch(setCustomerGroupId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.customer));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(customerGroupQueryData);
  }, [customerGroupQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(customerGroupThunk.customerGroupList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setCustomerGroupQueryData({ ...customerGroupQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.customer_group}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_customer_group}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <CustomerActionChecked />
                <CustomerGroupAction />
                <CustomerGroupFilter />
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={customerGroupQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={customerGroupListId}
            queryData={customerGroupQueryData}
            keyField="customer_group_id"
            clickField="customer_group_name"
            setListIdChecked={setCustomerGroupListId}
            setQueryData={setCustomerGroupQueryData}
            expandedRowRender={(record) => <CustomerGroupDetail data={record} queryData={customerGroupQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CustomerGroup;
