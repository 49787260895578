/* eslint-disable camelcase */
/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Col, Pagination, Row, Button, Space, message, Popconfirm } from 'antd';
import {
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import lang from '../../../language/language';
import { setLoading } from '../../Common/slice';
import { useDispatch, useSelector } from 'react-redux';
import * as commonService from '../../Common/service'
import ImageGallerySelect from '../../ImageGallery/components/ImageGallerySelect';
import { setBackgroundQueryData, setGiftCardSettingSelectBackgroundImage } from '../slice';
import { giftCardBackground } from '../service';
import { giftCardSettingBackgroundThunk } from '../thunk';
import CustomImage from '../../Common/components/CustomImage';

const GiftCardSettingBackgroundImage = (props) => {
    const { isHideAdd, isMultiple } = props
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const dataImageSelected = useSelector(state => state.imageGallery.dataImageSelected);
    const backgroundQueryData = useSelector(state => state.giftCardSetting.backgroundQueryData);
    const giftCardSettingBackground = useSelector(state => state.giftCardSetting.giftCardSettingBackground);
    const giftCardSettingSelectBackgroundImage = useSelector(state => state.giftCardSetting.giftCardSettingSelectBackgroundImage);

    useEffect(() => {
        dispatch(setLoading(false))
    }, [giftCardSettingBackground.data])

    // click button delete
    const onDelete = (listId = []) => {
        if (listId.length > 0) {
            const dataDelete = {
                list_id: listId,
                field_name: 'gift_card_background_id',
                table: 'gift_card_background',
                update_user: userInfo.user_id,
                permission: ['GIFT_CARD_SETTING_EDIT']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(giftCardSettingBackgroundThunk(backgroundQueryData))
                        message.success(lang.delete_success);
                        dispatch(setGiftCardSettingSelectBackgroundImage([]))
                    }
                }
            })
        }
    };

    const onSelectImage = (data) => {
        if (isMultiple) {
            const result = [...giftCardSettingSelectBackgroundImage]
            if (!giftCardSettingSelectBackgroundImage.includes(data)) {
                result.push(data)
                dispatch(setGiftCardSettingSelectBackgroundImage(result))
            } else {
                const newResult = result.filter(x => x !== data)
                dispatch(setGiftCardSettingSelectBackgroundImage(newResult))
            }
        } else {
            if (!giftCardSettingSelectBackgroundImage.includes(data)) {
                dispatch(setGiftCardSettingSelectBackgroundImage([data]))
            } else {
                dispatch(setGiftCardSettingSelectBackgroundImage([]))
            }
        }
    }

    const onChangePagination = (page, pageSize) => {
        dispatch(setBackgroundQueryData({ ...backgroundQueryData, page }))
    }

    const onSave = () => {
        dispatch(setLoading(true))
        const background_image_list = dataImageSelected.map((item) => {
            return {
                ...item,
                gift_card_background_image_name: item.image_gallery_name,
                create_user: userInfo.user_id,
                gift_card_background_album_id: backgroundQueryData.gift_card_background_album_id
            }
        })
        giftCardBackground({ background_image_list }, 'POST').then(res => {
            if (res.data) {
                message.success(lang.save_success)
            }
        }).finally(x => {
            dispatch(setLoading(false))
            dispatch(giftCardSettingBackgroundThunk(backgroundQueryData))
        })
    }

    // render

    return (
        <Row gutter={24} className="mt-5">
            {!isHideAdd && <Col className="mb-15" xs={24} md={4} sm={6}>
                <Button
                    style={{ minHeight: giftCardSettingBackground.data && giftCardSettingBackground.data.length > 0 ? 0 : 135 }}
                    className="btn-add-image"
                    type="primary" icon={<PlusOutlined />}
                    block
                    onClick={() => document.getElementById('galleryAction').click()}
                />
                <ImageGallerySelect onSave={onSave} isMultiple />
            </Col>}
            {giftCardSettingBackground.data && giftCardSettingBackground.data.length > 0 && giftCardSettingBackground.data && giftCardSettingBackground.data.map((item, index) => {
                return (
                    <Col className="mb-15" key={index} xs={24} md={4} sm={6}>
                        <CustomImage
                            onSelectImage={onSelectImage}
                            selectList={giftCardSettingSelectBackgroundImage}
                            onDelete={(data) => onDelete([data.gift_card_background_id])}
                            className="img-cover img-background-list"
                            item={item}
                            src={item.gift_card_background_image_url}
                        />
                    </Col>
                )
            })}
            {
                giftCardSettingBackground.data && giftCardSettingBackground.data.length > 0 && <Col className="text-right" xs={24} md={24} sm={24}>
                    <Space>
                        {giftCardSettingSelectBackgroundImage.length > 0 &&
                            <Popconfirm
                                placement="top"
                                title={lang.sure_delete}
                                onConfirm={() => onDelete(giftCardSettingSelectBackgroundImage.map(x => x.gift_card_background_id))}
                                okText={lang.ok}
                                cancelText={lang.cancel}
                            >
                                <Button
                                    size="middle"
                                    icon={<DeleteOutlined />}
                                    style={{ marginBottom: 20 }} >
                                </Button>
                            </Popconfirm>
                        }
                        <Pagination
                            showTotal={(total, range) => `${lang.showing} ${range[0]}-${range[1]} / ${lang.total} ${total} ${giftCardSettingSelectBackgroundImage && giftCardSettingSelectBackgroundImage.length > 0 ? giftCardSettingSelectBackgroundImage && '(' + giftCardSettingSelectBackgroundImage.length + lang.selected + ')' : ''}`} total={giftCardSettingBackground.total || 0}
                            pageSize={isHideAdd ? 12 : backgroundQueryData.sizePerPage}
                            current={backgroundQueryData.page}
                            onChange={onChangePagination}
                            className="mb-15 align-right"
                        />
                    </Space>
                </Col>
            }
        </Row>
    );
};

export default GiftCardSettingBackgroundImage;
