/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Popover, Form, message, Steps } from 'antd';
import InvoiceSelect from '../../../../Common/components/InvoiceSelect';
import { useDispatch, useSelector } from 'react-redux';
import {
	UserOutlined,
	ClockCircleOutlined,
	PlusOutlined,
	PrinterOutlined,
	CloseCircleOutlined,
	MinusCircleOutlined,
	BarcodeOutlined,
} from '@ant-design/icons';
import CustomerSelect from '../../../../Common/components/CustomerSelect';
import lang from '../../../../../language/language';
import CheckPermission from '../../../../Common/components/CheckPermission';
import CustomerQuickAdd from '../../../../Customer/components/CustomerAction/CustomerQuickAdd';
import { setLoading } from '../../../../Common/slice';
import { customerList } from '../../../../Customer/service';
import * as invoiceService from '../../../service';
import moment from 'moment-timezone';
import { setCustomerPhoneQuickAdd } from '../../../../Customer/slice';
import InvoiceActionInfoCustomer from '../InvoiceActionInfoCustomer';
import TextArea from 'antd/lib/input/TextArea';
import { clearInvoiceDetail, setInvoiceDetail, setInvoiceId } from '../../../slice';
import { formatCurrency, formatPhone } from '../../../../Common/components/FormatData';
import * as c from '../../../../../constants/common';
import { updateInvoiceInfo, getInvoiceTotalSurcharge } from '../../../common';
import InvoiceActionDetailCancel from './InvoiceActionDetailCancel';
import InvoiceActionDetailCustomerGroupDiscount from './InvoiceActionDetailCustomerGroupDiscount';
import InvoicePrint from '../../../../Invoice/components/InvoicePrint';
import { useReactToPrint } from 'react-to-print';
import InvoicePrintTicket from '../../InvoicePrintTicket';
import InvoiceActionDetailPromotion from './InvoiceActionDetailPromotion';
import { getInvoiceDetail } from '../../../thunk';
import InvoiceActionDetailSurcharge from './InvoiceActionDetailSurcharge';
import InvoiceActionDetailPay from './InvoiceActionDetailPay';
import { getInvoiceList } from '../../../../Common/thunk';

const InvoiceActionDetail = (props) => {
	const [dataDetailCustomer, setDataDetailCustomer] = useState(null);
	const [dataPrint, setDataPrint] = useState({});
	const [isVisibleAddCustomer, setIsVisibleAddCustomer] = useState(false);
	const customerQueryData = useSelector((state) => state.customer.customerQueryData);
	const invoiceId = useSelector((state) => state.invoice.invoiceId);
	const invoiceDetail = useSelector((state) => state.invoice.invoiceDetail);
	const generalSetting = useSelector((state) => state.generalSetting);
	const posSetting = useSelector((state) => state.posSetting);
	const [form] = Form.useForm();
	const printRef = useRef();
	const printTicketRef = useRef();
	const dispatch = useDispatch();
	const userInfo = useSelector((state) => state.user.userInfo);

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});
	const handlePrintTicket = useReactToPrint({
		content: () => printTicketRef.current,
	});

	useEffect(() => {
		if (invoiceId) {
			dispatch(setLoading(true));
			dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
		} else {
			dispatch(clearInvoiceDetail());
		}
	}, [invoiceId]);

	useEffect(() => {
		return () => {
			resetData();
		};
	}, []);

	useEffect(() => {
		if (invoiceDetail.invoice_id) {
			if (invoiceDetail.invoice_status !== c.INVOICE_STATUS.UNPAID) {
				dispatch(setInvoiceId(null));
				resetData();
				dispatch(getInvoiceList());
				message.error(lang.invoice_not_invalid);
			}
			dispatch(setLoading(false));
		}
	}, [invoiceDetail]);

	useEffect(() => {
		if (invoiceId && invoiceDetail.invoice_customer_need_to_pay && invoiceDetail.invoice_temp_money) {
			invoiceService.invoiceCreateUpdate(
				{
					...invoiceDetail,
					invoice_customer_need_to_pay: invoiceDetail.invoice_customer_need_to_pay,
					invoice_temp_money: invoiceDetail.invoice_temp_money,
					invoice_tax: invoiceDetail.invoice_tax,
					invoice_customer_group_discount: invoiceDetail.invoice_customer_group_discount,
				},
				'PUT'
			);
		}
	}, [
		invoiceDetail.invoice_customer_need_to_pay,
		invoiceDetail.invoice_temp_money,
		invoiceDetail.invoice_tax,
		invoiceDetail.invoice_customer_group_discount,
	]);

	useEffect(() => {
		if (Object.keys(dataPrint).length > 0) {
			handlePrint();
		}
	}, [dataPrint]);

	useEffect(() => {
		if (invoiceDetail.customer_id) {
			form.setFieldsValue({
				customer: `${formatPhone(invoiceDetail.customer_phone_number)} - ${invoiceDetail.customer_fullname}`,
			});
			customerList({ ...customerQueryData, search: invoiceDetail.customer_phone_number }).then((res) => {
				if (res.data) {
					const { rows } = res.data;
					if (rows && rows.length > 0) {
						setDataDetailCustomer(rows[0]);
					}
				}
			});
		}
	}, [invoiceDetail.customer_id]);

	useEffect(() => {
		updateInvoiceInfo(
			{
				...invoiceDetail,
				invoiceSurchargeList: invoiceDetail.invoiceSurchargeList,
				invoice_total_surcharge: getInvoiceTotalSurcharge(invoiceDetail.invoiceSurchargeList),
			},
			dispatch,
			posSetting
		);
	}, [invoiceDetail.invoiceItemList]);

	const onSelectCustomerPhone = (value = '') => {
		if (value) {
			dispatch(setLoading(true));
			if (isVisibleAddCustomer) {
				document.getElementById('btn-close-quick-add-customer').click();
			}
			customerList({ ...customerQueryData, search: value }).then((res) => {
				if (res.data) {
					const { rows } = res.data;
					if (rows && rows.length > 0) {
						setDataDetailCustomer(rows[0]);
						invoiceService
							.invoiceCreateUpdate(
								{
									...invoiceDetail,
									customer_id: rows[0].customer_id,
								},
								'PUT'
							)
							.then((x) => {
								if (x.data) {
									dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
								}
							});
					}
				}
			});
		}
	};

	const onQuickAddCustomer = () => {
		setIsVisibleAddCustomer(true);
	};

	const onSelectInvoice = (data) => {
		if (data !== invoiceId) {
			dispatch(setInvoiceId(data));
			setDataDetailCustomer(null);
			form.resetFields();
			dispatch(clearInvoiceDetail());
		}
	};

	const onClearCustomer = () => {
		dispatch(setLoading(true));
		setDataDetailCustomer(null);

		invoiceService
			.invoiceCreateUpdate(
				{
					...invoiceDetail,
					customer_id: null,
				},
				'PUT'
			)
			.then((res) => {
				if (res.data) {
					dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
				}
			});
	};

	const resetData = () => {
		setDataDetailCustomer(null);
		dispatch(setCustomerPhoneQuickAdd(null));
		dispatch(clearInvoiceDetail());
		form.resetFields();
		dispatch(setInvoiceId(null));
		setDataPrint({});
		dispatch(clearInvoiceDetail());
	};

	const onPromotionDelete = (data) => {
		if (data.invoice_id) {
			updateInvoiceInfo(
				{
					...invoiceDetail,
					invoicePromotionList: [],
					invoice_total_promotion: 0,
				},
				dispatch,
				posSetting
			);
		} else {
			updateInvoiceInfo(
				{
					...invoiceDetail,
					invoicePromotionList: invoiceDetail.invoicePromotionList.filter(
						(x) => x.invoice_promotion_id !== data.invoice_promotion_id
					),
					invoice_total_promotion: invoiceDetail.invoice_total_promotion - data.invoice_promotion_money,
				},
				dispatch,
				posSetting
			);
		}
	};

	const onSurchargeDelete = (data) => {
		dispatch(setLoading(true));
		invoiceService.invoiceSurchargeDelete(data).then((res) => {
			if (res.data) {
				dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
				if (data.invoice_id) {
					updateInvoiceInfo(
						{
							...invoiceDetail,
							invoiceSurchargeList: [],
							invoice_total_surcharge: 0,
						},
						dispatch,
						posSetting
					);
				} else {
					updateInvoiceInfo(
						{
							...invoiceDetail,
							invoice_total_surcharge: invoiceDetail.invoice_total_surcharge - data.invoice_surcharge_money,
							invoiceSurchargeList: invoiceDetail.invoiceSurchargeList.filter(
								(x) => x.invoice_surcharge_id !== data.invoice_surcharge_id
							),
						},
						dispatch,
						posSetting
					);
				}
			}
		});
	};

	const invoicePrintTicket = () => {
		const dataPrintTicket = {
			invoice_id: invoiceId,
			customer_id: invoiceDetail.customer_id,
			create_user: userInfo.user_id,
		};
		invoiceService.invoicePrintTicket(dataPrintTicket).then((res) => {
			if (res.data) {
				dispatch(getInvoiceDetail({ invoice_id: invoiceId }));
				setTimeout(() => {
					handlePrintTicket();
				}, 500);
			}
		});
	};

	/// /render///////
	return (
		<Row>
			<Col xs={24} md={24} style={{ marginBottom: 10 }}>
				<InvoicePrint dataItem={dataPrint.invoiceItemList || []} data={dataPrint || {}} printRef={printRef} />
				<InvoicePrintTicket
					dataItem={invoiceDetail.invoiceItemList || []}
					data={invoiceDetail || {}}
					printRef={printTicketRef}
				/>
				<InvoiceSelect size="large" onSelect={onSelectInvoice} onClear={resetData} style={{ width: '90%' }} />
				<span style={{ width: '9%', float: 'right' }}>
					<Button className="w-100 pl-5 pr-5 pt-5 pb-0" style={{ height: 40 }}>
						<BarcodeOutlined
							onClick={() => {
								message.success(lang.barcode_actived);
							}}
							style={{ fontSize: 22 }}
						/>
					</Button>
				</span>
			</Col>
			<Col xs={24} className="ant-card pos-info p-10" md={24} style={{ marginBottom: 7 }}>
				<Form
					form={form}
					// onFinish={onSubmit}
					autoComplete="off"
				>
					<Row>
						{invoiceId && (
							<Col xs={24} md={24}>
								<Row className="border-bottom-1 pb-10">
									<Col xs={11} md={11} className="pos-user">
										<div>
											<UserOutlined /> {invoiceDetail.create_user}
										</div>
									</Col>
									<Col xs={13} md={13} className="text-right invoice-date-time">
										<div>
											<ClockCircleOutlined />{' '}
											{moment(invoiceDetail.create_date).format(
												generalSetting.general_setting_date_format + ' hh:mm A'
											)}
										</div>
									</Col>
								</Row>
								<Row className="pt-15">
									<Col xs={24} md={24}>
										<Row>
											<Col xs={24} md={24}>
												<CustomerSelect
													style={{ width: !dataDetailCustomer ? '85%' : '85%', float: 'left' }}
													label={lang.search_customer_phone}
													isHideLabel
													required
													showSearch
													allowClear
													onClear={onClearCustomer}
													onSelect={(data) => onSelectCustomerPhone(data.customer_phone_number)}
													onInput={(data) => dispatch(setCustomerPhoneQuickAdd(data))}
												/>
												{dataDetailCustomer && <InvoiceActionInfoCustomer data={dataDetailCustomer} />}
												{!dataDetailCustomer && (
													<CheckPermission permission={['CUSTOMER_ADD']}>
														<Popover
															placement="bottomRight"
															trigger="click"
															style={{ width: 350 }}
															overlayClassName="popover-quick-add-customer"
															visible={isVisibleAddCustomer}
															content={<CustomerQuickAdd onSelectCustomerPhone={onSelectCustomerPhone} />}
														>
															<Button
																onClick={onQuickAddCustomer}
																style={{ width: 40, height: 40, marginLeft: 5 }}
																size="middle"
																className="float-right"
																icon={<PlusOutlined />}
																id="btn-quick-add-customer"
															/>
															<Button
																onClick={() => setIsVisibleAddCustomer(false)}
																className="d-none"
																id="btn-close-quick-add-customer"
															/>
														</Popover>
													</CheckPermission>
												)}
											</Col>
										</Row>
									</Col>
								</Row>
								<div className="invoice-action-container">
									<Row className="pt-5 border-bottom-1 font-weight-bold">
										<Col xs={10} md={10}>
											<div>{lang.invoice_temp_money}</div>
										</Col>
										<Col xs={14} md={14} className="text-right">
											<div>{formatCurrency(invoiceDetail.invoice_temp_money, generalSetting)}</div>
										</Col>
									</Row>
									<Row className="pt-15 border-bottom-1">
										<Col xs={10} md={10}>
											<div>
												{lang.tax} ({`${posSetting.allow_apply_tax === c.IS_ACTIVE ? posSetting.tax_percent : 0}%`})
											</div>
										</Col>
										<Col xs={14} md={14} className="text-right">
											<div>{formatCurrency(invoiceDetail.invoice_tax, generalSetting)}</div>
										</Col>
									</Row>

									{invoiceDetail.invoiceSurchargeList && invoiceDetail.invoiceSurchargeList.length > 1 && (
										<Row className="pt-15 border-bottom-1">
											<Col xs={10} md={10}>
												<div>
													<MinusCircleOutlined
														onClick={() => onSurchargeDelete({ invoice_id: invoiceId })}
														className="f-14 cursor-pointer"
													/>{' '}
													{lang.surcharge}
												</div>
											</Col>
											<Col xs={14} md={14} className="text-right">
												<div>
													{formatCurrency(getInvoiceTotalSurcharge(invoiceDetail.invoiceSurchargeList), generalSetting)}
												</div>
											</Col>
											<Col xs={24} md={24} className="text-left">
												<Row className="f-12 pl-15">
													{invoiceDetail.invoiceSurchargeList &&
														invoiceDetail.invoiceSurchargeList.map((x, index) => {
															return (
																<>
																	<Col xs={10} md={10}>
																		<MinusCircleOutlined
																			onClick={() =>
																				onSurchargeDelete({
																					invoice_surcharge_id: x.invoice_surcharge_id,
																					invoice_surcharge_money: x.invoice_surcharge_money,
																				})
																			}
																			className="cursor-pointer"
																		/>{' '}
																		{x.invoice_surcharge_name}
																	</Col>
																	<Col className="text-right" xs={14} md={14}>
																		{formatCurrency(x.invoice_surcharge_money, generalSetting)}
																	</Col>
																</>
															);
														})}
												</Row>
											</Col>
										</Row>
									)}

									{invoiceDetail.invoiceSurchargeList && invoiceDetail.invoiceSurchargeList.length === 1 && (
										<Row className="pt-15 border-bottom-1">
											<Col xs={10} md={10}>
												<div>
													<MinusCircleOutlined
														onClick={() => onSurchargeDelete({ invoice_id: invoiceId })}
														className="f-14 cursor-pointer"
													/>{' '}
													{invoiceDetail.invoiceSurchargeList[0].invoice_surcharge_name}
												</div>
											</Col>
											<Col xs={14} md={14} className="text-right">
												<div>
													{formatCurrency(getInvoiceTotalSurcharge(invoiceDetail.invoiceSurchargeList), generalSetting)}
												</div>
											</Col>
										</Row>
									)}

									{invoiceDetail.invoicePromotionList && invoiceDetail.invoicePromotionList.length > 1 && (
										<Row className="pt-15 border-bottom-1">
											<Col xs={10} md={10}>
												<div>
													<MinusCircleOutlined
														onClick={() => onPromotionDelete({ invoice_id: invoiceId })}
														className="f-14 cursor-pointer"
													/>{' '}
													{lang.promotion}
												</div>
											</Col>
											<Col xs={14} md={14} className="text-right">
												<div>-{formatCurrency(invoiceDetail.invoice_total_promotion, generalSetting)}</div>
											</Col>
											<Col xs={24} md={24} className="text-left">
												<Row className="f-12 pl-15">
													{invoiceDetail.invoicePromotionList &&
														invoiceDetail.invoicePromotionList.map((x, index) => {
															return (
																<>
																	<Col xs={10} md={10}>
																		<MinusCircleOutlined
																			onClick={() =>
																				onPromotionDelete({
																					invoice_promotion_id: x.invoice_promotion_id,
																					invoice_promotion_money: x.invoice_promotion_money,
																				})
																			}
																			className="cursor-pointer"
																		/>{' '}
																		{x.invoice_promotion_name}
																	</Col>
																	<Col className="text-right" xs={14} md={14}>
																		-{formatCurrency(x.invoice_promotion_money, generalSetting)}
																	</Col>
																</>
															);
														})}
												</Row>
											</Col>
										</Row>
									)}

									{invoiceDetail.invoicePromotionList && invoiceDetail.invoicePromotionList.length === 1 && (
										<Row className="pt-15 border-bottom-1">
											<Col xs={10} md={10}>
												<div>
													<MinusCircleOutlined
														onClick={() => onPromotionDelete({ invoice_id: invoiceId })}
														className="f-14 cursor-pointer"
													/>{' '}
													{invoiceDetail.invoicePromotionList[0].invoice_promotion_name}
												</div>
											</Col>
											<Col xs={14} md={14} className="text-right">
												<div>-{formatCurrency(invoiceDetail.invoice_total_promotion, generalSetting)}</div>
											</Col>
										</Row>
									)}

									<InvoiceActionDetailCustomerGroupDiscount dataCustomer={dataDetailCustomer} />

									<Row className="pt-15 border-bottom-1 font-weight-bold">
										<Col xs={10} md={10}>
											<div>{lang.customer_need_to_pay}</div>
										</Col>
										<Col xs={14} md={14} className="text-right">
											<div>{formatCurrency(invoiceDetail.invoice_customer_need_to_pay, generalSetting)}</div>
										</Col>
									</Row>
									<Form.Item label={null} className="pt-15" name="invoice_note">
										<TextArea
											onChange={(e) => dispatch(setInvoiceDetail({ ...invoiceDetail, invoice_note: e.target.value }))}
											placeholder={lang.note}
											size="middle"
											allowClear
											rows={1}
										/>
									</Form.Item>
								</div>
							</Col>
						)}
						<Col xs={24} md={24} className="btn-pos">
							<Row gutter={8}>
								<Col xs={12} md={12}>
									<Button
										onClick={invoicePrintTicket}
										disabled={!invoiceId || invoiceDetail.invoiceItemList.length === 0}
										type="primary"
										size="large"
										block
									>
										<PrinterOutlined /> {lang.print_ticket}
									</Button>
								</Col>
								<Col xs={12} md={12}>
									<InvoiceActionDetailPromotion />
								</Col>
								<Col xs={12} md={12} className="pt-10">
									<InvoiceActionDetailSurcharge />
								</Col>
								<Col xs={12} md={12} className="pt-10">
									{!invoiceId ? (
										<Button
											className="w-100"
											disabled={true}
											size="large"
											icon={<CloseCircleOutlined />}
											type="primary"
										>
											{lang.cancel}
										</Button>
									) : (
										<InvoiceActionDetailCancel resetData={resetData} form={form} />
									)}
								</Col>
								<Col xs={24} md={24} className="pt-10">
									<InvoiceActionDetailPay resetData={resetData} />
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	);
};

export default InvoiceActionDetail;
