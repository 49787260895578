import CallApi from '../../../utils/CallAPI';
import {
  THEME_MENU,
  THEME_COLOR,
  LEFT_MENU_COLOR,
  GENERAL_SETTING
} from './constants/endpoint';

export const userUpdateThemeMenu = (data) => {
  return CallApi(THEME_MENU, 'PUT', data)
};

export const userUpdateThemeColor = (data) => {
  return CallApi(THEME_COLOR, 'PUT', data)
};
export const userUpdateLeftMenuColor = (data) => {
  return CallApi(LEFT_MENU_COLOR, 'PUT', data)
};
export const generalSetting = (data) => {
  return CallApi(GENERAL_SETTING, 'GET', data)
};

export const generalSettingCreateUpdate = (data, method) => {
  return CallApi(GENERAL_SETTING, method, data)
};
