import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as customerThunk from '../../thunk';
import { setCustomerGroupListId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import { Menu, Button, Dropdown, Popconfirm, message } from 'antd';
import {
  SettingOutlined
} from '@ant-design/icons';
import * as c from '../../../../constants/common';

const CustomerGroupActionChecked = (props) => {
  const customerGroupListId = useSelector(state => state.customerGroup.customerGroupListId);
  const queryData = useSelector(state => state.customerGroup.customerGroupQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (customerGroupListId.length > 0) {
      const data = {
        list_id: customerGroupListId,
        field_name: 'customer_group_id',
        table: 'customer_group',
        update_user: userInfo.user_id,
        permission: ['CUSTOMER_GROUP_DELETE']
      };
      commonService.commonDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(customerThunk.customerGroupList(queryData));
            dispatch(setCustomerGroupListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (customerGroupListId.length > 0) {
      const data = {
        list_id: customerGroupListId,
        field_name: 'customer_group_id',
        table: 'customer_group',
        status,
        update_user: userInfo.user_id,
        permission: ['CUSTOMER_GROUP_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(customerThunk.customerGroupList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_GROUP_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_GROUP_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['CUSTOMER_GROUP_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    customerGroupListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default CustomerGroupActionChecked;
