import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as customerService from '../../service';
import { setLoading, setIsClearImage } from '../../../Common/slice';
import { clearCustomerId, setCustomerPhoneQuickAdd } from '../../slice';
import * as commonThunk from '../../../Common/thunk';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import Address from '../../../Common/components/Address';
import * as c from '../../../../constants/common';
import * as customerThunk from '../../thunk';
import moment from 'moment';
import { Space, Button, Row, Col, Form, Input, message } from 'antd';
import {
    SaveOutlined
} from '@ant-design/icons';
import { RandomCode } from '../../../Common/components/RandomCode';

const CustomerQuickAdd = (props) => {
    const { onSelectCustomerPhone } = props
    const dispatch = useDispatch();
    const queryData = useSelector(state => state.customer.customerQueryData);
    // const color = useSelector(state => state.user.userInfo.theme_color);
    const userInfo = useSelector(state => state.user.userInfo);
    const customerId = useSelector(state => state.customer.customerId);
    const customerPhoneQuickAdd = useSelector(state => state.customer.customerPhoneQuickAdd);
    const [actionType, setActionType] = useState('');
    const [branchId, setBranchId] = useState('');
    const [form] = Form.useForm();

    const branchLocal = JSON.parse(localStorage.getItem('branch'));

    useEffect(() => {
        genCode()
    }, []);

    useEffect(() => {
        if (customerPhoneQuickAdd) {
            form.setFieldsValue({ customer_phone_number: customerPhoneQuickAdd })
        }
    }, [customerPhoneQuickAdd]);

    /// ///function////

    const genCode = () => {
        form.setFieldsValue({ customer_code: RandomCode() });
    };

    const onSubmit = (data) => {
        if (
            !!data.customer_phone_number && !!data.customer_phone_number2 &&
            data.customer_phone_number === data.customer_phone_number2) {
            message.error(lang.phone_no_duplicate);
            return;
        }
        // submit > 1 để validate address
        dispatch(setLoading(true));
        // setCountSubmit(1);
        const method = 'POST';
        const dataReq = {
            customer_id: '',
            customer_code: data.customer_code || '',
            customer_fullname: data.customer_fullname || '',
            customer_phone_number: data.customer_phone_number || '',
            customer_note: data.customer_note || '',
            customer_address: data.customer_address || '',
            customer_email: data.customer_email || '',
            customer_birthday: data.customer_birthday && data.customer_birthday !== 'Invalid date' ? moment(data.customer_birthday).format('YYYY-MM-DD') : '',
            province_id: data.province || '',
            district_id: data.district || '',
            ward_id: data.ward || '',
            branch_id: customerId ? branchId : branchLocal.branch_id || '',
            create_user: userInfo.user_id || '',
            is_active: c.IS_ACTIVE,
            customer_phone_number2: data.customer_phone_number2 || '',
            customer_facebook: data.customer_facebook || '',
            customer_yelp: data.customer_yelp || '',
            customer_company_name: data.customer_company_name || '',
            customer_tax_code: data.customer_tax_code || '',
            customer_gender: data.customer_gender || null,
            customer_type: data.customer_type || c.CUSTOMER_TYPE.PERSONAL,
            customer_avatar: '',
            customer_group_id_list: data.customer_group ? data.customer_group.map((item) => parseInt(item)) : []
        };
        customerService.customerCreateUpdate(dataReq, method).then(res => {
            if (res) {
                const { data } = res;
                dispatch(setLoading(false));
                // check exist
                if (data.customer_phone_number) {
                    message.error(lang.phone_number + data.customer_phone_number + lang.exist);
                    return
                } else if (data.customer_code) {
                    message.error(lang.customer_code + lang.exist);
                    return
                } else if (data.customer_fullname) {
                    message.error(lang.customer_fullname + lang.exist);
                    return
                } else if (data.customer_birthday) {
                    message.error(lang.customer_birthday + lang.exist);
                    return
                } else if (data.customer_email) {
                    message.error(lang.email + lang.exist);
                    return
                } else if (data.customer_phone_number2) {
                    message.error(lang.phone_number + data.customer_phone_number2 + lang.exist);
                    return
                }
                // luu thanh cong
                if (data.customer_id > 0) {
                    if (actionType === c.SAVE) {
                        message.success(lang.save_success);
                        if (!customerId) {
                            resetData();
                        }
                    } else {
                        message.success(lang.save_success);
                        resetData();
                    }
                }
            }
        }).finally(() => {
            dispatch(setLoading(false));
            onSelectCustomerPhone(data.customer_phone_number);
            dispatch(customerThunk.customerList(queryData));
            dispatch(commonThunk.customerPhoneList({}));
        })
    };

    const resetData = () => {
        // cho phep edit
        dispatch(clearCustomerId(''));
        // setClearAddress(Date.parse(new Date()));
        // setCountSubmit(0);
        // setDataEdit({});
        form.resetFields();
        setBranchId('')
        dispatch(setIsClearImage(true))
        document.getElementById('btn-close-quick-add-customer').click()
        dispatch(setCustomerPhoneQuickAdd(''))
        genCode();
    };

    const onSave = (type) => {
        setActionType(type);
        form.submit()
    }

    /// render/////
    return (
        <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
        >
            <Row gutter={24}>
                <Col xs={24} md={24}>
                    <Form.Item
                        label={lang.customer_code}
                        name="customer_code"
                        rules={[
                            { required: true, message: lang.value_is_required }
                            // { required: true, message: lang.value_is_required },
                            // { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                        ]}
                    >
                        <Input disabled size="large" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label={lang.customer_fullname}
                        name="customer_fullname"
                        rules={[
                            { required: true, message: lang.value_is_required },
                            {
                                pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                                message: lang.not_allow_special_characters
                            }
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label={lang.customer_phone_number}
                        name="customer_phone_number"
                        rules={[
                            { required: true, message: lang.value_is_required },
                            { pattern: /^[0-9]*$/, message: lang.not_format },
                            { min: 10, message: lang.phone_min_max },
                            { max: 11, message: lang.phone_min_max }
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </Col>
                <Col xs={24} md={24}>
                    <Form.Item
                        label="Email"
                        name="customer_email"
                        rules={[
                            { type: 'email', message: lang.email_not_format },
                            { pattern: /^[A-Za-z0-9_.]*$/, message: lang.email_contains_invalid_characters }
                        ]}
                    >
                        <Input size="large" />
                    </Form.Item>
                </Col>

                <Col className="text-center" xs={24} md={24}>
                    <Space className="text-center">
                        <Button size="large" icon={<SaveOutlined />} onClick={resetData} key="close" type="primary" >
                            {lang.close}
                        </Button>
                        <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                            {lang.save}
                        </Button>
                    </Space>
                </Col>

            </Row>

        </Form>

    );
};

export default CustomerQuickAdd;
