/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as userService from '../../../Setting/User/service';
import { setLoading, setIsClearImage } from '../../../Common/slice';
import * as checkinUserThunk from '../../thunk';
import moment from 'moment';
import { clearCheckinUserId } from '../../slice';
import * as c from '../../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import { Drawer, Space, Button, Form, message, Row, Col, Image, Input, Switch } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CameraOutlined
} from '@ant-design/icons';
import { RandomCode } from '../../../Common/components/RandomCode';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import noImage from '../../../../assets/images/default-user.png';
import TextArea from 'antd/lib/input/TextArea';
import BranchSelect from '../../../Common/components/BranchSelect';

const CheckinUserAction = (props) => {
  const queryData = useSelector(state => state.user.userQueryData);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.userInfo);
  const checkinUserId = useSelector(state => state.checkin.checkinUserId);
  const [actionType, setActionType] = useState('');
  const dataBranchRole = useSelector(state => state.user.userBranchRole);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dataAvatar, setDataAvatar] = useState({});

  const onSaveChangeImage = (data) => {
    if (data.length > 0) {
      setDataAvatar(data[0])
    }
  }

  useEffect(() => {
    if (checkinUserId) {
      dispatch(setLoading(true));
      userService.userDetail({ user_id: checkinUserId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            user_code: data.user_code,
            user_name: data.user_name,
            user_password: data.user_password,
            user_fullname: data.user_fullname,
            user_phone_number: data.user_phone_number,
            user_email: data.user_email,
            user_birthday: data.user_birthday ? moment(data.user_birthday, 'YYYY-MM-DD') : null,
            user_note: data.user_note,
            user_address: data.user_address,
            is_active: data.is_active === c.IS_ACTIVE,
            province: data.province_id || null,
            district: data.district_id || null,
            ward: data.ward_id || null,
            branch: data.branch_id || null
          });
          setDataAvatar({ image_gallery_name: data.user_avatar_name, image_gallery_url: data.user_avatar })
        }
      }).finally(() => {
        dispatch(setLoading(false))
      });
    }
  }, [checkinUserId]);

  const showModal = () => {
    setIsModalVisible(true);
    if (!checkinUserId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ user_code: RandomCode() });
  };

  const onSubmit = (data) => {
    if (dataBranchRole.length > 0) {
      const hasMatch = JSON.stringify(dataBranchRole).match(/:null[\},]/) !== null;
      if (hasMatch) {
        message.error(lang.branch_role_is_required)
        return;
      }
    }
    const method = checkinUserId ? 'PUT' : 'POST';
    dispatch(setLoading(true));
    const dataReq = {
      user_id: checkinUserId || '',
      user_code: data.user_code || '',
      user_branch_role_id: '1',
      user_name: checkinUserId ? data.user_name : userInfo.company_code + '_' + data.user_name || '',
      user_password: data.user_password || '',
      user_fullname: data.user_fullname || '',
      user_phone_number: data.user_phone_number || '',
      user_email: data.user_email || '',
      user_birthday: data.user_birthday && data.user_birthday !== 'Invalid date' ? moment(data.user_birthday).format('YYYY-MM-DD') : '',
      user_note: data.user_note || '',
      user_address: data.user_address || '',
      province_id: data.province || '',
      district_id: data.district || '',
      ward_id: data.ward || '',
      create_user: userInfo.user_id || '',
      user_avatar: dataAvatar.image_gallery_name || '',
      user_type: c.USER_TYPE.CHECKIN,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      user_branch_role_list: [{ role_id: null, branch_id: data.branch }]
    };

    // check exist branch
    const uniqueBranch = new Set(data.user_branch_role_list && data.user_branch_role_list.map(v => v.branch));
    if (uniqueBranch.size < (data.user_branch_role_list && data.user_branch_role_list.length)) {
      message.error(lang.branch_not_duplicate);
      dispatch(setLoading(false));
      return;
    }

    userService.userCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const resData = res.data;
        dispatch(setLoading(false));
        // check exist

        if (resData.user_phone_number) {
          message.error(lang.phone + lang.exist);
        } else if (resData.user_code) {
          message.error(lang.user_code + lang.exist);
        } else if (resData.user_name) {
          message.error(lang.user_name + lang.exist);
        } else if (resData.user_email) {
          message.error(lang.user_email + lang.exist);
        }

        // luu thanh cong
        if (resData.user_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!checkinUserId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false))
      dispatch(checkinUserThunk.checkinUserList(queryData));
    });
  };

  const resetData = () => {
    // lấy lại code
    genCode();
    dispatch(clearCheckinUserId(''));
    dispatch(clearCheckinUserId(''));
    form.resetFields();
    dispatch(setIsClearImage(true))
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <CheckPermission permission={['CHECKIN_USER_ADD']}>
        <Button size="large" type="primary" id="userAction" onClick={showModal} icon={<PlusOutlined />} />
      </CheckPermission>
      <Drawer
        title={!checkinUserId
          ? lang.add + lang.user.toLowerCase()
          : lang.edit + lang.user.toLowerCase()
        }
        placement="right"
        width={c.WIDTH_DRAWER.LARGE}
        onClose={onClose}
        visible={isModalVisible}
        footer={
          <Space className="text-center">
            <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
              {lang.close}
            </Button>
            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
              {lang.save}
            </Button>
            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
              {lang.save_and_close}
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onSubmit}
          autoComplete="off"
          form={form}
          layout="vertical"
          initialValues={{ is_active: c.IS_ACTIVE }}
        >
          <Row gutter={24}>
            <ImageGallerySelect id="galleryUserAction" onSave={onSaveChangeImage} />
            <Col xs={24} md={24} className="mb-15">
              <div className="text-center">
                <Image
                  width="100%"
                  style={{ margin: 'auto' }}
                  className="img-avatar"
                  preview={false}
                  src={dataAvatar.image_gallery_url || noImage}
                />
              </div>
              <div className="text-center pt-10">
                <Button icon={<CameraOutlined />}
                  onClick={() => document.getElementById('galleryUserAction').click()}
                  key="update_user_avatar"
                  type="primary"
                  size="middle"
                >
                  {lang.choose_image}
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={lang.user_code}
                name="user_code"
                rules={[
                  { required: true, message: lang.value_is_required },
                  { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={lang.user_name}
                name="user_name"
                rules={[
                  { required: true, message: lang.value_is_required },
                  { pattern: /^[A-Za-z0-9_]*$/, message: lang.not_allow_special_characters }
                ]}
              >
                <Input
                  size="large"
                  addonBefore={!checkinUserId ? `${userInfo.company_code}_` : ''}
                  disabled={!!checkinUserId}
                  />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={lang.password}
                name="user_password"
                rules={[
                  { required: !checkinUserId, message: lang.value_is_required },
                  { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters },
                  { min: 6, message: lang.password_min_6 }
                ]}
              >
                <Input type="password" size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={lang.user_fullname}
                name="user_fullname"
                rules={[
                  { required: true, message: lang.value_is_required },
                  {
                    pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                    message: lang.not_allow_special_characters
                  }
                ]}
              >
                <Input size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <BranchSelect
                required
              />
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label={lang.note}
                name="user_note"
              >
                <TextArea allowClear rows={1} size="large" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24} className="text-right">
              <Form.Item
                name="is_active"
                valuePropName="checked"
              >
                <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </span>
  );
};

export default CheckinUserAction;
