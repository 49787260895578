/* eslint-disable array-callback-return */
import React from 'react';
import lang from '../../../../language/language';
import { Col, Form, Input } from 'antd';

const EmailSettingDefault = (props) => {
	return (
		<Col xs={24} md={24} style={{ minHeight: 100 }}>
			<Form.Item
				label={lang.email_setting_from}
				name="email_setting_from"
				rules={[{ required: true, message: lang.value_is_required }]}
			>
				<Input placeholder="Name <email>" size="large" />
			</Form.Item>
		</Col>
	);
};

export default EmailSettingDefault;
