/* eslint-disable multiline-ternary */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as customerGroupService from '../../service';
import { setLoading } from '../../../Common/slice';
import { clearCustomerGroupId, setCustomerGroupCondition, setDiscountType } from '../../slice';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
import * as customerGroupThunk from '../../thunk';
import CustomerGroupActionInfo from './CustomerGroupActionInfo';
import CustomerGroupActionCondition from './CustomerGroupActionCondition';
import { Space, Button, Form, message, Tabs, Drawer } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import PopConfirm from '../../../Common/components/PopConfirm'
const { TabPane } = Tabs;
const CustomerGroupAction = (props) => {
  const queryData = useSelector(state => state.customerGroup.customerGroupQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const loading = useSelector(state => state.common.loading);
  const customerGroupId = useSelector(state => state.customerGroup.customerGroupId);
  const [actionType, setActionType] = useState('');
  // const discountType = useSelector(state => state.customerGroup.discountType);
  const branchLocal = JSON.parse(localStorage.getItem('branch'));
  const [dataEdit, setDataEdit] = useState({});
  const [branchId, setBranchId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [initialValue, setInitialValue] = useState({});
  const [showConfirm, setShowConfirm] = useState(true);
  const [form] = Form.useForm();
  /// //////////////////////// Validate /////////////////////////

  useEffect(() => {
    if (customerGroupId) {
      dispatch(setLoading(true));
      customerGroupService.customerGroupDetail({ customer_group_id: customerGroupId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          // setDataEdit(data);
          form.setFieldsValue({
            customer_group_name: data.customer_group_name,
            discount: data.discount,
            discount_type: data.discount_type,
            customer_group_note: data.customer_group_note,
            is_active: data.is_active === c.IS_ACTIVE,
            customer_group_condition: data.customer_group_condition.map((item) => {
              return {
                condition_type: item.condition_type.value,
                condition_compare: item.condition_compare.value,
                condition_value: item.condition_value
              }
            }) || null
          });
          dispatch(setCustomerGroupCondition(data.customer_group_condition));
          dispatch(setDiscountType(data.discount_type))
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [customerGroupId]);

  useEffect(() => {
    if (isModalVisible) {
      setInitialValue(form.getFieldsValue())
    }
  }, [isModalVisible, customerGroupId, loading])

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = customerGroupId ? 'PUT' : 'POST';
    const dataReq = {
      customer_group_id: customerGroupId || '',
      customer_group_name: data.customer_group_name,
      discount: data.discount,
      discount_type: data.discount_type || c.DISCOUNT_TYPE.PERCENT,
      customer_group_note: data.customer_group_note || '',
      customer_group_condition: data.customer_group_condition && data.customer_group_condition.length > 0 ? data.customer_group_condition.map((item) => {
        return {
          condition_type_id: item.condition_type,
          condition_compare: item.condition_compare,
          condition_value: item.condition_value
        }
      }) : [],
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      branch_id: customerGroupId ? branchId : branchLocal.branch_id || ''
    };

    // check exist condition

    const uniqueCondition = new Set(data.customer_group_condition && data.customer_group_condition.map(v => v.condition_type));
    if (uniqueCondition.size < (data.customer_group_condition && data.customer_group_condition.length)) {
      message.error(lang.condition_not_duplicate);
      dispatch(setLoading(false));
      return;
    }

    customerGroupService.customerGroupCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const dataRes = res.data;
        dispatch(setLoading(false));
        // check exist
        if (dataRes.customer_group_name) {
          message.error(lang.customer_group_name + lang.exist);
        }
        // luu thanh cong
        if (dataRes.customer_group_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!customerGroupId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(customerGroupThunk.customerGroupList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    dispatch(clearCustomerGroupId(''));
    dispatch(clearCustomerGroupId(''));
    setDataEdit({});
    form.resetFields();
    setBranchId('')
    setShowConfirm(true);
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onValuesChange = (changedValues, allValues) => {
    const res = Object.entries(allValues).toString() === Object.entries(initialValue).toString();
    setShowConfirm(res)
  }

  /// render/////
  return (
    <span>
      <CheckPermission permission={['CUSTOMER_GROUP_ADD']}>
        <Button size="large" type="primary" id="customerGroupAction" onClick={showModal} icon={<PlusOutlined />} />
      </CheckPermission>
      <Drawer
        title={!customerGroupId
          ? lang.add + lang.customer_group.toLowerCase()
          : lang.edit + lang.customer_group.toLowerCase()
        }
        placement="right"
        width={c.WIDTH_DRAWER.LARGE}
        onClose={onClose}
        visible={isModalVisible}
        footer={
          <Space className="text-center">
            {
              !showConfirm ? (<PopConfirm onConfirm={onClose}>
                <Button size="large" icon={<CloseCircleOutlined />} key="back">
                  {lang.close}
                </Button>
              </PopConfirm>) : (<Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>)
            }

            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
              {lang.save}
            </Button>
            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
              {lang.save_and_close}
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onSubmit}
          autoComplete="off"
          form={form}
          layout="vertical"
          initialValues={{ is_active: c.IS_ACTIVE }}
          onValuesChange={onValuesChange}
        >
          <Tabs style={{ paddingTop: 0 }} defaultActiveKey={`1${dataEdit.user_id}`} >
            <TabPane forceRender style={{ paddingTop: 0 }} tab={lang.customer_group_info} key={`1${dataEdit.user_id}`}>
              <CustomerGroupActionInfo />
            </TabPane>
            <TabPane forceRender tab={lang.condition} key={`2${dataEdit.user_id}`}>
              <CustomerGroupActionCondition />
            </TabPane>
          </Tabs>

        </Form>
      </Drawer>
    </span>
  );
};

export default CustomerGroupAction;
