/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as c from '../../../constants/config';
import { userDetail } from '../../../modules/Setting/User/thunk';
import { getRoleListByAppDeActive, roleListAll } from '../../../modules/Setting/Role/thunk';
import { branchListAll } from '../../../modules/Setting/Branch/thunk';
import { setLoading } from '../../../modules/Common/slice';
import { getFileExportDemo, appList } from '../../../modules/Common/thunk';
import { Space } from 'antd';
import HeaderRight from './HeaderRight/HeaderRight';
import { generalSettingThunk } from '../../../modules/Setting/GeneralSetting/thunk';
import { appointmentSettingThunk } from '../../../modules/Appointment/AppointmentSetting/thunk';
import { posSettingThunk } from '../../../modules/Setting/POSSetting/thunk';
import { giftCardSettingThunk } from '../../../modules/GiftCardSetting/thunk';
import { turnSettingThunk } from '../../../modules/TurnSetting/thunk';

const HeaderApp = (props) => {
  const dispatch = useDispatch();
  const themeMenu = useSelector(state => state.user.userInfo.theme_menu);
  const color = useSelector(state => state.user.userInfo.theme_color);
  const branchList = useSelector(state => state.branch.branchList);
  const roleList = useSelector(state => state.role.roleList);
  const currentBranch = useSelector(state => state.branch.currentBranch);
  const branchByUser = useSelector(state => state.branch.branchByUser);

  const infoUser = JSON.parse(localStorage.getItem('infoUser'));
  const { user_id } = infoUser && infoUser;

  /// ///// useEffect

  useEffect(() => {
    if (user_id) {
      dispatch(userDetail({ user_id }));
      dispatch(getFileExportDemo({}))
      dispatch(roleListAll({}))
      dispatch(getRoleListByAppDeActive({}))
      dispatch(branchListAll({}))
      dispatch(giftCardSettingThunk({}))
      dispatch(turnSettingThunk({}))
    }
  }, [user_id, dispatch, currentBranch.branch_id]);

  useEffect(() => {
    if (currentBranch.branch_id) {
      dispatch(appList({ user_id, branch_id: currentBranch.branch_id }))
    }
  }, [currentBranch.branch_id]);

  useEffect(() => {
    if (branchByUser.length > 0) {
      dispatch(appList({ user_id, branch_id: branchByUser[0].branch_id }))
    }
  }, [branchByUser]);

  useEffect(() => {
    dispatch(branchListAll({}))
  }, [branchList]);

  useEffect(() => {
    dispatch(roleListAll({}))
  }, [roleList]);

  useEffect(() => {
    dispatch(generalSettingThunk({}))
    dispatch(posSettingThunk({}))
    dispatch(appointmentSettingThunk({}))
  }, [currentBranch]);

  useEffect(() => {
    if (color) {
      dispatch(setLoading(false));
    }
  }, [color]);

  useEffect(() => {
    if (themeMenu === c.MENU_TYPE_GRID) {
      document.body.classList.add('sidebar-collapse');
    }
  }, []);
  /// //funtion/////

  return (
    <div style={{ float: 'right' }}>
      <Space size="large">
        <HeaderRight />
      </Space>
    </div>
  );
};

export default HeaderApp;
