import React, { } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Menu, Dropdown, Avatar } from 'antd';
import { LogoutOutlined, UserOutlined, KeyOutlined } from '@ant-design/icons';
import lang from '../../../../language/language';
import { setUserIdAccount } from '../../../../modules/Common/slice';

const HeaderRightAccount = (props) => {
    const userInfo = useSelector(state => state.user.userInfo)
    const dispatch = useDispatch();

    /// //funtion/////

    const onLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    const onInfo = () => {
        document.getElementById('accountInfoModal').click()
        dispatch(setUserIdAccount(userInfo.user_id))
    }

    const menu = (
        <Menu>
            <Menu.Item onClick={onInfo} key="1" icon={<UserOutlined />}>
                {lang.info_person}
            </Menu.Item>
            <Menu.Item onClick={() => document.getElementById('accountChangePasswordModal').click()} key="2" icon={<KeyOutlined />}>
                {lang.change_password}
            </Menu.Item>
            <Menu.Item onClick={onLogout} key="3" icon={<LogoutOutlined />}>
                {lang.logout}
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown className="cursor-pointer" overlay={menu} trigger={['click']}>
            <Avatar icon={userInfo.user_avatar ? <img src={userInfo.user_avatar} /> : <UserOutlined />} />
        </Dropdown>
    );
};

export default HeaderRightAccount;
