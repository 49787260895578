/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../../language/language';
import { Row, Col } from 'antd'
import { useSelector } from 'react-redux';

import { formatCurrency } from '../../../../Common/components/FormatData';

const InvoiceActionDetailCustomerGroupDiscount = (props) => {
    const { dataCustomer } = props;
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const generalSetting = useSelector(state => state.generalSetting);

    /// /funtion///////

    /// /render///////
    return (
        <Row className="pt-15 border-bottom-1">
            <Col xs={14} md={14}>
                <div>{lang.customer_group} {dataCustomer && dataCustomer.customer_group && `(${dataCustomer.customer_group})`}</div>
            </Col>
            <Col xs={10} md={10} className="text-right">
                <div>{invoiceDetail.invoice_customer_group_discount > 0 && '-'}{formatCurrency(invoiceDetail.invoice_customer_group_discount, generalSetting)}</div>
            </Col>
        </Row>
    );
};

export default InvoiceActionDetailCustomerGroupDiscount;
