import React, { useEffect } from 'react';
import lang from '../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import { Row, Col, Form, Select, Button, message, Card, Checkbox } from 'antd';
import * as c from '../../../constants/common';
import { setLoading } from '../../Common/slice';
import { useSelector, useDispatch } from 'react-redux';
import * as turnService from '../service';
import { turnSettingThunk } from '../thunk'
import {
  SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../Common/components/CheckPermission';

const { Option } = Select;

const TurnSetting = (props) => {
  const [form] = Form.useForm();
  const turnSetting = useSelector(state => state.turnSetting);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      turn_setting_allow_auto: turnSetting.turn_setting_allow_auto === 1,
      turn_setting_prioritize: turnSetting.turn_setting_prioritize
    });
  }, [turnSetting]);

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = turnSetting.turn_setting_id ? 'PUT' : 'POST';
    const dataReq = {
      turn_setting_allow_auto: data.turn_setting_allow_auto === true ? 1 : 0,
      turn_setting_prioritize: data.turn_setting_prioritize || null,
      turn_setting_id: turnSetting.turn_setting_id
    };

    turnService.turnSetting(dataReq, method).then(res => {
      if (res) {
        dispatch(setLoading(false));
        message.success(lang.save_success);
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(turnSettingThunk({}));
    })
  }

  return (
    <Card className='pb-15'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.turn_setting}</title>
      </Helmet>
      <Form
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <Form.Item
              label={' '}
              name="turn_setting_allow_auto"
              valuePropName="checked"
            >
              <Checkbox> {lang.auto_turn_by_timekeeping}</Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={' '}
              name="turn_setting_prioritize"
            >
              <Select
                size="large"
                value={turnSetting.turn_setting_prioritize}
                placeholder={lang.turn_prioritize}
                allowClear
              >
                {c.TURN_PRIORITIZE.map((item) => {
                  return <Option key={item.value} value={item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6} >
            <CheckPermission permission={['TURN_SETTING_EDIT']}>
              <Button size="middle" style={{ height: 40, marginTop: 30 }} icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                {lang.save}
              </Button>
            </CheckPermission>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default TurnSetting;
