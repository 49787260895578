import { createAsyncThunk } from '@reduxjs/toolkit';
import * as branchService from '../Branch/service';

export const branchByUser = createAsyncThunk(
  'branch/branchByUser',
  async (data, thunkAPI) => {
    const response = await branchService.branchByUser(data);
    return response.data;
  }
);

export const branchListAll = createAsyncThunk(
  'branch/all',
  async (data, thunkAPI) => {
    const response = await branchService.branchListAll(data);
    return response.data;
  }
);

export const branchList = createAsyncThunk(
  'branch/getList',
  async (data, thunkAPI) => {
    const response = await branchService.branchList(data);
    return response.data;
  }
);
