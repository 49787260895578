import { createSlice } from '@reduxjs/toolkit';
import * as reportIncomeThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';
import moment from 'moment';

export const reportIncomeSlice = createSlice({
  name: 'reportIncome',
  initialState: {
    reportIncomeList: {},
    reportIncomeId: '',
    reportIncomeListId: [],
    reportIncomeQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      orderBy: '',
      userId: getInfoUser.user_id,
      reportType: c.REPORT_INCOME_TYPE[0].value
    }
  },
  reducers: {
    clearReportIncomeList: (state, action) => {
      state.reportIncomeList = {};
    },
    clearReportIncomeRow: (state, action) => {
      state.reportIncomeList.rows = [];
    },
    setReportIncomeId: (state, action) => {
      state.reportIncomeId = action.payload;
    },
    clearReportIncomeId: (state, action) => {
      state.reportIncomeId = '';
    },
    setReportIncomeQueryData: (state, action) => {
      state.reportIncomeQueryData = action.payload;
    },
    setReportIncomeListId: (state, action) => {
      state.reportIncomeListId = action.payload;
    },
    clearReportQueryData: (state, action) => {
      state.reportIncomeQueryData = {
        page: c.SIZE_PAGE.PAGE,
        sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
        search: '',
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        orderBy: '',
        userId: getInfoUser.user_id,
        reportType: c.REPORT_INCOME_TYPE[0].value
      }
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [reportIncomeThunk.reportIncomeList.fulfilled]: (state, action) => {
      state.reportIncomeList = action.payload;
    }
  }
});

export const {
  clearReportIncomeList,
  clearReportIncomeRow,
  setReportIncomeId,
  setReportIncomeQueryData,
  setReportIncomeListId,
  clearReportIncomeId,
  clearReportQueryData
} = reportIncomeSlice.actions;

export default reportIncomeSlice.reducer;
