import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row } from 'antd';
import { Column } from '@ant-design/plots';

import { formatCurrency, formatDate } from '../../Common/components/FormatData';
import lang from '../../../language/language';
import { OPTIONS_CHART_FILTER } from '../../../constants/common';
import { customGetDay } from '../../Common/components/CustomGetDay';
import moment from 'moment';
import { setLoading } from '../../Common/slice';
import { getReportIncome } from '../service';
import FilterDay from '../../Common/components/FilterDay';
import { sumData } from '../../Common/components/Caculator';
// import DateSelect from '../../Common/components/DateSelect';

const DashBoardReportIncome = () => {
    const dispatch = useDispatch()
    const [dataReport, setDataReport] = useState([])
    const generalSetting = useSelector(state => state.generalSetting)
    const branchByUser = useSelector(state => state.branch.branchByUser)
    const [filtered, setFiltered] = useState(lang.this_week)
    const getDate = customGetDay()

    useEffect(() => {
        getNewReportIncome()
    }, [branchByUser])

    const onSelectFilter = (data) => {
        if (data.value === OPTIONS_CHART_FILTER[6].value) {
            const titleFilter = `${lang.from_day.toLowerCase()} ${formatDate(data.fromDate, false, generalSetting)} ${lang.to_day.toLowerCase()} ${formatDate(data.toDate, false, generalSetting)}`
            setFiltered(titleFilter)
        } else { setFiltered(OPTIONS_CHART_FILTER.filter(x => x.value === data.value)[0].label) }

        getNewReportIncome(moment(data.fromDate).format('YYYY-MM-DD'), moment(data.toDate).format('YYYY-MM-DD'))
    }

    const getNewReportIncome = (fromDate = moment(getDate.thisMonday).format('YYYY-MM-DD'), toDate = moment(getDate.thisSunday).format('YYYY-MM-DD')) => {
        dispatch(setLoading(true))
        const reqData = {
            fromDate: fromDate,
            toDate: toDate,
            branch_list_id: branchByUser.length > 0
                ? branchByUser.map(item => {
                    return item.branch_id
                })
                : []
        }
        getReportIncome(reqData).then(res => {
            if (res.data) {
                const newReport = res.data.map(z => {
                    return {
                        ...z,
                        date: formatDate(z.date, false, generalSetting),
                        date_show: moment(z.date).format('DD')
                    }
                })
                setDataReport(newReport)
            }
        }).finally(x => {
            dispatch(setLoading(false))
        }
        )
    }

    // mẫu config report
    const config = {
        data: dataReport || [],
        xField: 'date_show',
        yField: 'income',
        seriesField: 'branch',
        isGroup: true,
        isStack: true,
        groupField: 'date',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false
            }
        },
        meta: {
            date: {
                alias: lang.date
            },
            income: {
                alias: lang.income
            },
            branch: {
                alias: lang.income
            }
        },
        legend: {
            layout: 'horizontal',
            position: 'top-left'
        },
        minColumnWidth: 20,
        maxColumnWidth: 20
    }

    return (
        <Card className='mb-15'>
            <Row>
                <Col xs={24} md={16} className="font-weight-bold">{lang.income} {filtered.toLowerCase()}: {formatCurrency(sumData(dataReport, 'income'), generalSetting)}</Col>
                <Col xs={24} md={8} className="text-right">
                    {/* Filter */}
                    <FilterDay onSelect={onSelectFilter} />
                </Col>
            </Row>
            <Column {...config} />
        </Card>
    )
}

export default DashBoardReportIncome;
