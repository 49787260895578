/* eslint-disable camelcase */
/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { Card, Col, message, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    ImportOutlined,
    ExportOutlined
} from '@ant-design/icons';
import lang from '../../../language/language'
import { getStaff } from '../../Common/service'
import TimeKeepingAction from './TimeKeepingAction';
import { setCurrentStaffTimeKeeping, setIsShowTimeKeepingAction, setTimeKeepingId } from '../slice';
import moment from 'moment'
import { setLoading, setLoadingTable } from '../../Common/slice'
import * as timeKeepingThunk from '../thunk';
import CheckPermission from '../../Common/components/CheckPermission';
import { formatHour } from '../../Common/components/FormatData';

const TimeKeeping = () => {
    const loadingTable = useSelector(state => state.appointment.loadingTable)
    const timeKeepingQueryData = useSelector(state => state.timeKeeping.timeKeepingQueryData);
    const currentTime = moment().format('YYYY-MM-DD').toString()
    const timeKeepingListData = useSelector(state => state.timeKeeping.timeKeepingListData);
    const generalSetting = useSelector(state => state.generalSetting);
    const currentBranch = useSelector(state => state.branch.currentBranch);

    const dispatch = useDispatch();

    const [staffList, setStaffList] = useState(
        [{
            title: lang.day + ' / ' + lang.staff,
            dataIndex: 'time_keeping_date',
            key: 'time_keeping_date',
            width: 175,
            fixed: 'left',
            align: 'center',
            ellipsis: true
        }])

    useEffect(() => {
        setStaffList([{
            title: lang.day + ' / ' + lang.staff,
            dataIndex: 'time_keeping_date',
            key: 'time_keeping_date',
            width: 175,
            fixed: 'left',
            align: 'center',
            ellipsis: true
        }])
        if (currentBranch.branch_id) {
            getStaffList()
        }
    }, [currentBranch]);

    useEffect(() => {
        fetchDataList(timeKeepingQueryData);
    }, [timeKeepingQueryData]);

    /// /funtion///////

    const getStaffList = () => {
        dispatch(setLoading(true));
        const reqData = { listBranchId: [currentBranch.branch_id] }
        getStaff(reqData).then(res => {
            if (res.data) {
                const result = [...staffList]
                const { data } = res;
                if (data.length > 0) {
                    data.filter(e => e.is_active === 1).map((item) => {
                        result.push({
                            title: item.staff_fullname,
                            dataIndex: item.staff_id,
                            key: String(item.staff_id),
                            width: 200,
                            align: 'center'
                        })
                    })
                }
                setStaffList(result)
            }
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }

    const fetchDataList = (queryData) => {
        setLoadingTable(true);
        dispatch(timeKeepingThunk.timeKeepinglist(queryData));
    };

    const onEditTimeKeeping = (data) => {
        dispatch(setIsShowTimeKeepingAction(true))
        dispatch(setTimeKeepingId(data.time_keeping_id))
    }

    const onAdd = (data) => {
        dispatch(setIsShowTimeKeepingAction(true))
        dispatch(setCurrentStaffTimeKeeping(data))
    }

    const customColumn = (columns = []) => {
        const result = []

        if (columns.length > 0) {
            columns.map((item) => {
                return result.push(
                    {
                        title: item.title,
                        dataIndex: item.dataIndex,
                        key: item.key,
                        width: item.width,
                        align: item.align,
                        fixed: item.key === 'time_keeping_date' ? 'left' : null,
                        render: (text, record, index) => {
                            const timeKeeping = record[item.key]
                            if (item.key === 'time_keeping_date') {
                                return <div className="font-weight-bold">{moment(text).format('DD').toString()}</div>
                            } else if (timeKeeping) {
                                // khi có dữ liệu chấm công
                                return (
                                    <div
                                        className="cell-time-keeping-edit"
                                        onClick={() => {
                                            dispatch(setCurrentStaffTimeKeeping(item.dataIndex))
                                            onEditTimeKeeping(timeKeeping)
                                        }}
                                    >

                                        <div>
                                            <ImportOutlined /> {formatHour(timeKeeping.time_keeping_time_in, generalSetting)} {timeKeeping.time_keeping_time_out && ' - '} {timeKeeping.time_keeping_time_out && <ExportOutlined />}
                                            {timeKeeping.time_keeping_time_out && <span className='pl-5'>{formatHour(timeKeeping.time_keeping_time_out, generalSetting)}</span>}
                                        </div>
                                    </div>
                                )
                            } else if (record.time_keeping_date !== currentTime) {
                                // khi hết ngày
                                return (
                                    <div onClick={() => message.warning(lang.time_keeping_out_of_time)} className="cell-time-keeping-expired">
                                    </div>
                                )
                            } else {
                                return (
                                    <CheckPermission permission={['TIME_KEEPING_ADD']}>
                                        <div
                                            className="cell-time-keeping-add cursor-pointer"
                                            onClick={() => onAdd(item.dataIndex)}
                                        >
                                        </div>
                                    </CheckPermission>
                                )
                            }
                        }
                    })
            }

            )
            return result
        }
    }
    /// /render///////
    return (
        <Card className='mb-15'>
            <Row gutter={12}>
                <Col className="font-weight-bold" xs={24} md={14}>
                    {lang.staff_timekeeping + ' ' + lang.month + ' ' + moment().format('MM/YYYY').toLowerCase()}
                </Col>
                <Col className="mt-5" xs={24} md={10}>
                    <Row type="flex" justify="end">
                        <TimeKeepingAction
                            staffList={staffList}
                        />
                    </Row>
                </Col>
                <Col className="mt-5" xs={24} md={24}>
                    <Table
                        className="table-appointment"
                        dataSource={timeKeepingListData || null}
                        columns={customColumn(staffList)}
                        pagination={false}
                        bordered
                        loading={loadingTable}
                        scroll={{ y: '73vh' }}
                    />
                </Col>
            </Row>
        </Card>
    )
}

export default TimeKeeping
