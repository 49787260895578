/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-key */
/* eslint-disable react/react-in-jsx-scope */
import { Button, Dropdown, Menu, Checkbox, Row, Col } from 'antd';
import lang from '../../../../language/language';
import React, { useState } from 'react';
import { toggleColumn } from '../../service';
import { useSelector, useDispatch } from 'react-redux';
import { setLoadingTable } from '../../slice';
import { ProfileOutlined } from '@ant-design/icons';
const AntdToggleColumn = (props) => {
    const {
        data,
        queryData,
        reloadData
    } = props;
    const [visible, setVisible] = useState(false);
    const userInfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch();

    const onChange = (e, column) => {
        const status = e.target.checked;
        const data = {
            column_type_code: column.column_type_code,
            status: !status ? 1 : 0,
            column_field: column.column_field,
            user_id: userInfo.user_id
        };
        toggleColumn(data).then(res => {
            if (res.data) {
                dispatch(setLoadingTable(true));
                reloadData(queryData);
            }
        });
    }

    const menu = (
        <Menu>
            <Row className="toggle-column">
                {data.columns && data.columns.length > 0 && data.columns.map((item, index) => {
                    const field = lang[item.column_field];
                    if (field) {
                        return (
                            <Menu.Item key={index} style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Checkbox
                                        checked={item.column_hidden === 0}
                                        onChange={(e) => onChange(e, item)}
                                    >{field}
                                    </Checkbox>
                                </Col>
                            </Menu.Item>

                        )
                    }
                })}
            </Row>
        </Menu>
    )
    /// /render///
    return (
        <Dropdown visible={visible} onVisibleChange={(value) => { setVisible(value) }} overlay={menu} placement="bottomRight" arrow trigger={['click']}>
            <Button size="large" type="primary" icon={<ProfileOutlined />} />
        </Dropdown>
    )
}
export default AntdToggleColumn;
