/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { Col, Input, Row, Space } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { APP_TITLE } from '../../../../constants/config';
import lang from '../../../../language/language';
import { setLoadingTable, updateTitle } from '../../../../modules/Common/slice';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { setRoleId, setRoleListId, setRoleQueryData } from '../slice';
import * as roleThunk from '../thunk';
import RoleAction from './RoleAction/RoleAction';
import RoleActionChecked from './RoleAction/RoleActionChecked';
import RoleDetail from './RoleDetail';
import RoleFilter from './RoleFilter';
import * as c from '../../../../constants/common';

const { Search } = Input;

const Role = (props) => {
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const roleQueryData = useSelector(state => state.role.roleQueryData);
  const roleListId = useSelector(state => state.role.roleListId);
  const dataList = useSelector(state => state.role.roleList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setRoleListId([]));
      dispatch(setRoleId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.setting));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(roleQueryData);
  }, [roleQueryData]);

  /// /funtion///////
  const fetchDataList = (roleQueryData) => {
    setLoadingTable(true);
    dispatch(roleThunk.roleList(roleQueryData));
  };

  const onSearch = (value, e) => {
    dispatch(setRoleQueryData({ ...roleQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.role}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_customer}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <RoleActionChecked />
                <RoleAction />
                <RoleFilter />
                <AntdToggleColumn
                  data={dataList}
                  queryData={roleQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={roleListId}
            queryData={roleQueryData}
            keyField="role_id"
            clickField="role_name"
            setListIdChecked={setRoleListId}
            setQueryData={setRoleQueryData}
            expandedRowRender={(record) => <RoleDetail data={record} queryData={roleQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Role;
