import CallApi from '../../utils/CallAPI';
import {
    DASHBOARD_REPORT_STATISTIC,
    DASHBOARD_REPORT_PACK,
    DASHBOARD_REPORT_INCOME,
    DASHBOARD_REPORT_TOP_TEN_SERVICE,
    DASHBOARD_ACTIVITY_HISTORY,
    DASHBOARD_REPORT_CUSTOMER_VISIT,
    DASHBOARD_REPORT_RATING
} from './constants/endpoint';

export const getReportStatistic = (data) => {
    return CallApi(DASHBOARD_REPORT_STATISTIC, 'GET', data)
};

export const getReportPack = (data) => {
    return CallApi(DASHBOARD_REPORT_PACK, 'GET', data)
};

export const getReportIncome = (data) => {
    return CallApi(DASHBOARD_REPORT_INCOME, 'GET', data)
};

export const getTopTenService = (data) => {
    return CallApi(DASHBOARD_REPORT_TOP_TEN_SERVICE, 'GET', data)
};

export const getActivityHistoryList = (data) => {
    return CallApi(DASHBOARD_ACTIVITY_HISTORY, 'GET', data)
};

export const getReportCustomerVisit = (data) => {
    return CallApi(DASHBOARD_REPORT_CUSTOMER_VISIT, 'GET', data)
};

export const getReportRating = (data) => {
    return CallApi(DASHBOARD_REPORT_RATING, 'GET', data)
};
