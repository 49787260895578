/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';

import noImage from './../../../../assets/images/default-user.png'
import { List, Row, Col, Typography, Image } from 'antd';
import * as c from '../../../../constants/common';

import { formatDate, formatPhone, formatIsActive } from '../../../Common/components/FormatData';
const { Text } = Typography;

const CustomerDetailInfo = (props) => {
    const { data, isView } = props;

    // const color = useSelector(state => state.user.userInfo.theme_color);

    /// /funtion///////

    // click button delete

    /// /render///////
    if (data) {
        return (
            <Row >
                {!isView && <Col xs={8} md={4} lg={4}>
                    <Image
                        width="100%"
                        className="mb-15 img-avatar"
                        preview={false}
                        src={(data && data.customer_avatar) || noImage}
                    />
                </Col>
                }
                <Col xs={24} md={isView ? 24 : 20} lg={isView ? 24 : 20} className={isView ? '' : 'pl-15'}>
                    <List
                        itemLayout="horizontal"
                        grid={{ gutter: 24, column: isView ? 1 : 2, md: 2, xs: 1 }}
                        dataSource={
                            Object.keys(data)
                                .filter(x =>
                                    x !== 'customer_id' &&
                                    x !== 'key' &&
                                    x !== 'customer_group_id' &&
                                    x !== 'branch_id' &&
                                    x !== 'customer_avatar' &&
                                    x !== 'customer_group_id_list' &&
                                    x !== 'ward_id' &&
                                    x !== 'ward_name' &&
                                    x !== 'province_name' &&
                                    x !== 'province_id' &&
                                    x !== 'district_id' &&
                                    x !== 'district_name' &&
                                    x !== 'user_fullname'
                                )}
                        renderItem={item => (
                            <List.Item >
                                <Row className="border-detail-list">
                                    <Col xs={13} lg={10}>{lang[item]}</Col>
                                    <Col xs={11} lg={14}>{
                                        (
                                            item === 'create_date' ||
                                            item === 'customer_birthday'
                                        )
                                            ? formatDate(data[item])
                                            : (
                                                item === 'customer_phone_number' ||
                                                item === 'customer_phone_number2'
                                            )
                                                ? formatPhone(data[item])
                                                : (item === 'is_active')
                                                    ? formatIsActive(data[item])
                                                    : (String(data[item]).includes('{DELETE}'))
                                                        ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                        : (item === 'create_from') ? c.CREATE_FROM.filter(x => x.value === data[item])[0]?.label : data[item]
                                    }</Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        );
    } else {
        return <div></div>
    }
};

export default CustomerDetailInfo;
