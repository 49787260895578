import { createAsyncThunk } from '@reduxjs/toolkit';
import * as invoiceService from './service';

export const invoiceList = createAsyncThunk(
  'invoice/invoiceList',
  async (data, thunkAPI) => {
    const response = await invoiceService.invoiceList(data);
    return response.data;
  }
);

export const getInvoiceDetail = createAsyncThunk(
  'invoice/detail',
  async (data, thunkAPI) => {
    const response = await invoiceService.invoiceDetail(data);
    return response.data;
  }
);
