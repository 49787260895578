import { createAsyncThunk } from '@reduxjs/toolkit';
import * as staffService from './service';

export const staffList = createAsyncThunk(
  'staff/staffList',
  async (data, thunkAPI) => {
    const response = await staffService.staffList(data);
    return response.data;
  }
);

export const serviceListAction = createAsyncThunk(
  'role/list-action',
  async (data, thunkAPI) => {
    const response = await staffService.serviceListAction(data);
    return response.data;
  }
);
