import moment from 'moment';

export const customGetDay = () => {
    const thisMonth = moment().format('YYYY-MM')

    return {
        today: moment(),
        yesterday: moment().subtract(1, 'days'),
        lastMonday: moment().day(-6),
        lastSunday: moment().day(0),
        thisMonday: moment().day(1),
        thisSunday: moment().day(7),
        fisrtdayLastMonth: moment().date(-(moment(moment().subtract(1, 'months')).daysInMonth() - 1)),
        lastdayLastMonth: moment().date(0),
        fisrtdayThisMonth: moment().date(1),
        lastdayThisMonth: moment().date(moment(thisMonth).daysInMonth())
    }
}
