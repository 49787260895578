/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../../language/language';
import * as userService from '../../service';
import { setLoading, setIsClearImage } from '../../../../Common/slice';
import * as userThunk from '../../thunk';
import moment from 'moment';
import { clearUserId, setUserBranchRole } from '../../slice';
import * as c from '../../../../../constants/common';
import CheckPermission from '../../../../Common/components/CheckPermission';
import { Drawer, Space, Button, Form, message, Tabs } from 'antd';
import UserActionInfo from './UserActionInfo';
import UserActionBranchRole from './UserActionBranchRole';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import { RandomCode } from '../../../../Common/components/RandomCode';
const { TabPane } = Tabs;

const UserAction = (props) => {
  const queryData = useSelector(state => state.user.userQueryData);
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.userInfo);
  const userId = useSelector(state => state.user.userId);
  const [dataEdit, setDataEdit] = useState({});
  const [countSubmit, setCountSubmit] = useState(0);
  // const [addressData, setAddressData] = useState({});
  const [actionType, setActionType] = useState('');
  const [clearAddress, setClearAddress] = useState('');
  // const dataBranchRole = useSelector(state => state.user.userBranchRole);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const branchByUser = useSelector(state => state.branch.branchByUser)
  const [dataAvatar, setDataAvatar] = useState({});

  useEffect(() => {
    if (userId) {
      dispatch(setLoading(true));
      userService.userDetail({ user_id: userId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          setDataEdit(data);
          dispatch(setUserBranchRole(data.user_branch_role_list));
          form.setFieldsValue({
            user_code: data.user_code,
            user_name: data.user_name,
            user_password: data.user_password,
            user_fullname: data.user_fullname,
            user_phone_number: data.user_phone_number,
            user_email: data.user_email,
            user_birthday: data.user_birthday ? moment(data.user_birthday, 'YYYY-MM-DD') : null,
            user_note: data.user_note,
            user_address: data.user_address,
            is_active: data.is_active === c.IS_ACTIVE,
            province: data.province_id || null,
            district: data.district_id || null,
            ward: data.ward_id || null,
            user_branch_role_list: data.user_branch_role_list.filter(e => branchByUser.some(i => e.branch.value === i.branch_id)).map((item) => {
              return { role: item.role.value, branch: item.branch.value }
            }) || null
          });
          setDataAvatar({ image_gallery_name: data.user_avatar_name, image_gallery_url: data.user_avatar })
        }
      }).finally(() => {
        dispatch(setLoading(false))
      });
    }
  }, [userId]);

  useEffect(() => {
    if (!userId) {
      genCode();
    }
  }, [userId])

  const showModal = () => {
    setIsModalVisible(true);
  };

  const genCode = () => {
    form.setFieldsValue({ user_code: RandomCode() });
  };

  // nhận địa chỉ từ address trả về

  const onSubmit = (data) => {
    // submit > 1 để validate address
    setCountSubmit(1);
    if (!data.user_branch_role_list || (data.user_branch_role_list && data.user_branch_role_list.length === 0)) {
      return message.error(lang.branch_role_is_required)
    }
    const method = userId ? 'PUT' : 'POST';
    dispatch(setLoading(true));
    const dataReq = {
      user_id: userId || '',
      user_code: data.user_code || '',
      user_branch_role_id: '1',
      user_name: userId ? data.user_name : userInfo.company_code + '_' + data.user_name || '',
      user_password: data.user_password || '',
      user_fullname: data.user_fullname || '',
      user_phone_number: data.user_phone_number || '',
      user_email: data.user_email || '',
      user_birthday: data.user_birthday && data.user_birthday !== 'Invalid date' ? moment(data.user_birthday).format('YYYY-MM-DD') : '',
      user_note: data.user_note || '',
      user_address: data.user_address || '',
      province_id: data.province || '',
      district_id: data.district || '',
      ward_id: data.ward || '',
      create_user: userInfo.user_id || '',
      user_avatar: dataAvatar.image_gallery_name || '',
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      user_branch_role_list: data.user_branch_role_list
        ? data.user_branch_role_list.map((item) => {
          return { role_id: item.role, branch_id: item.branch }
        })
        : []
    };

    // check exist branch
    const uniqueBranch = new Set(data.user_branch_role_list && data.user_branch_role_list.map(v => v.branch));
    if (uniqueBranch.size < (data.user_branch_role_list && data.user_branch_role_list.length)) {
      message.error(lang.branch_not_duplicate);
      dispatch(setLoading(false));
      return;
    }

    userService.userCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const resData = res.data;
        dispatch(setLoading(false));
        // check exist

        if (resData.user_phone_number) {
          message.error(lang.phone + lang.exist);
        } else if (resData.user_code) {
          message.error(lang.user_code + lang.exist);
        } else if (resData.user_name) {
          message.error(lang.user_name + lang.exist);
        } else if (resData.user_email) {
          message.error(lang.user_email + lang.exist);
        }

        // luu thanh cong
        if (resData.user_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!userId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false))
      dispatch(userThunk.userList(queryData));
    });
  };

  const resetData = () => {
    // lấy lại code
    genCode();
    dispatch(clearUserId(''));
    setClearAddress(Date.parse(new Date()));
    setCountSubmit(0);
    dispatch(clearUserId(''));
    setDataEdit({});
    form.resetFields();
    dispatch(setIsClearImage(true))
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <CheckPermission permission={['USER_ADD']}>
        <Button size="large" type="primary" id="userAction" onClick={showModal} icon={<PlusOutlined />} />
      </CheckPermission>
      <Drawer
        title={!userId
          ? lang.add + lang.user.toLowerCase()
          : lang.edit + lang.user.toLowerCase()
        }
        placement="right"
        width={c.WIDTH_DRAWER.LARGE}
        onClose={onClose}
        visible={isModalVisible}
        footer={
          <Space className="text-center">
            <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
              {lang.close}
            </Button>
            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
              {lang.save}
            </Button>
            <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
              {lang.save_and_close}
            </Button>
          </Space>
        }
      >
        <Form
          onFinish={onSubmit}
          autoComplete="off"
          form={form}
          layout="vertical"
        >
          <Tabs style={{ paddingTop: 0 }} defaultActiveKey={`1${dataEdit.user_id}`} >
            <TabPane forceRender style={{ paddingTop: 0 }} tab={lang.user_info} key={`1${dataEdit.user_id}`}>
              <UserActionInfo
                countSubmit={countSubmit}
                clearAddress={clearAddress}
                dataEdit={dataEdit}
                dataAvatar={dataAvatar}
                setDataAvatar={setDataAvatar}
              />
            </TabPane>
            <TabPane forceRender tab={lang.branch_and_role} key={`2${dataEdit.user_id}`}>
              <UserActionBranchRole
                dataEdit={dataEdit}
                countSubmit={countSubmit}
                userBranchRoleList={dataEdit.user_branch_role_list}
              />
            </TabPane>
          </Tabs>
        </Form>
      </Drawer>
    </span>
  );
};

export default UserAction;
