import { createSlice } from '@reduxjs/toolkit';
import * as imageGalleryThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';

export const imageGallerySlice = createSlice({
  name: 'imageGallery',
  initialState: {
    imageGalleryList: {},
    imageGalleryQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_IMAGE_GALLERY,
      fromDate: '',
      toDate: '',
      userId: getInfoUser.user_id
    },
    dataImageSelected: []
  },
  reducers: {
    clearImageGalleryList: (state, action) => {
      state.imageGalleryList = {};
    },
    clearImageGalleryRow: (state, action) => {
      state.imageGalleryList.rows = [];
    },
    setImageGalleryId: (state, action) => {
      state.imageGalleryId = action.payload;
    },
    clearImageGalleryId: (state, action) => {
      state.imageGalleryId = '';
    },
    setImageGalleryQueryData: (state, action) => {
      state.imageGalleryQueryData = action.payload;
    },
    setImageGalleryListId: (state, action) => {
      state.imageGalleryListId = action.payload;
    },
    setDataImageSelected: (state, action) => {
      state.dataImageSelected = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [imageGalleryThunk.imageGalleryListThunk.fulfilled]: (state, action) => {
      state.imageGalleryList = action.payload;
    }
  }
});

export const {
  clearImageGalleryList,
  clearImageGalleryRow,
  setImageGalleryId,
  clearImageGalleryId,
  setImageGalleryQueryData,
  setImageGalleryListId,
  setDataImageSelected
} = imageGallerySlice.actions;

export default imageGallerySlice.reducer;
