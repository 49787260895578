import { createAsyncThunk } from '@reduxjs/toolkit';
import * as customerService from './service';

export const customerList = createAsyncThunk(
  'customer/customerList',
  async (data, thunkAPI) => {
    const response = await customerService.customerList(data);
    return response.data;
  }
);

export const customerAppointmentHistoryList = createAsyncThunk(
  'customer/customerAppointmentHistoryList',
  async (data, thunkAPI) => {
    const response = await customerService.customerAppointmentHistoryList(data);
    return response.data;
  }
);
