/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import {
    DeleteOutlined,
    PoweroffOutlined,
    PrinterOutlined,
    MailOutlined
} from '@ant-design/icons';
import { Button, Col, message, Popconfirm, Popover, Row, Space, Tabs } from 'antd';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../language/language';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import * as giftCardThunk from '../../thunk';
import * as c from '../../../../constants/common';
import GiftCardDetailInfo from './GiftCardDetailInfo';
import GiftCardDetailHistory from './GiftCardDetailHistory';
import { useReactToPrint } from 'react-to-print';
import GiftCardPrint from '../GiftCardPrint';
import GiftCardDetailDeposit from './GiftCardDetailDeposit';
import { giftCardSendEmail } from '../../common';
const { TabPane } = Tabs;

const GiftCardDetail = (props) => {
    const { data, queryData, classContentTab } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const role = useSelector(state => state.role)
    const printRef = useRef();

    /// /funtion///////
    const handlePrintBarcode = useReactToPrint({
        content: () => printRef.current
    });

    // click button delete
    const onDelete = () => {
        if (data.gift_card_id) {
            const giftCardId = data.gift_card_id
            const dataDelete = {
                list_id: [giftCardId],
                field_name: 'gift_card_id',
                table: 'gift_card',
                update_user: userInfo.user_id,
                permission: ['GIFT_CARD_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(giftCardThunk.giftCardList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onUpdateActiveStatus = (status) => {
        if (data.gift_card_id) {
            const dataEdit = {
                list_id: data.gift_card_id,
                field_name: 'gift_card_id',
                table: 'gift_card',
                status,
                update_user: userInfo.user_id,
                permission: ['GIFT_CARD_EDIT']
            };
            commonService.updateActiveStatus(dataEdit).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_update) {
                        dispatch(giftCardThunk.giftCardList(queryData));
                        message.success(lang.save_success);
                    }
                }
            });
        }
    }

    const onResendEmail = (sendType) => {
        const reqData = {
            ...data,
            gift_card_from_email: sendType === c.GIFT_CARD_SEND_MAIL_TYPE.FROM_EMAIL ? data.gift_card_from_email : '',
            gift_card_to_email: sendType === c.GIFT_CARD_SEND_MAIL_TYPE.TO_EMAIL ? data.gift_card_to_email : ''
        }
        giftCardSendEmail([reqData])
        message.success(lang.send_success)
    }

    /// /render///////
    return (
        <Row>
            <GiftCardPrint data={[data]} printRef={printRef} />
            <Col xs={24} md={24}>
                <Tabs defaultActiveKey="1" >
                    <TabPane tab={lang.infomation} key="1">
                        <div className={classContentTab}>
                            <GiftCardDetailInfo data={data} queryData={queryData} />
                        </div>
                    </TabPane>
                    {(userInfo.is_main === 1 || role.getRoleByUser.some(i => ['GIFT_CARD_HISTORY_VIEW'].indexOf(i.permission_code) >= 0)) &&
                        <TabPane tab={lang.transaction_history} key="2">
                            <div className={classContentTab}>
                                <GiftCardDetailHistory data={data} />
                            </div>
                        </TabPane>
                    }
                </Tabs>
            </Col>

            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <Button
                                size="large"
                                icon={<PrinterOutlined />}
                                onClick={handlePrintBarcode}
                            >{lang.print}
                            </Button>
                            {(data.gift_card_from_email || data.gift_card_to_email) && <Popover
                                placement="topRight"
                                trigger="click"
                                overlayClassName="popover-pos"
                                content={
                                    <Space>
                                        {data.gift_card_from_email &&
                                            <Button
                                                size="large"
                                                icon={<MailOutlined />}
                                                onClick={() => onResendEmail(c.GIFT_CARD_SEND_MAIL_TYPE.FROM_EMAIL)}
                                            >{lang.gift_card_send_email_from}
                                            </Button>
                                        }
                                        {data.gift_card_to_email &&
                                            <Button
                                                size="large"
                                                icon={<MailOutlined />}
                                                onClick={() => onResendEmail(c.GIFT_CARD_SEND_MAIL_TYPE.TO_EMAIL)}
                                            >{lang.gift_card_send_email_to}
                                            </Button>
                                        }
                                    </Space>

                                }>
                                <Button
                                    size="large"
                                    icon={<MailOutlined />}
                                >{lang.email_send}</Button>
                            </Popover>
                            }
                            <CheckPermission permission={['GIFT_CARD_DEPOSIT']}>
                                <GiftCardDetailDeposit data={data} />
                            </CheckPermission>
                            <CheckPermission permission={['GIFT_CARD_EDIT']}>
                                {data.gift_card_id && data.is_active === c.IS_DEACTIVE &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_save}
                                        onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"
                                            icon={<PoweroffOutlined />}
                                        >{lang.active}</Button>
                                    </Popconfirm>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['GIFT_CARD_EDIT']}>
                                {data.gift_card_id && data.is_active === c.IS_ACTIVE &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_save}
                                        onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"
                                            icon={<PoweroffOutlined />}
                                        >{lang.de_active}</Button>
                                    </Popconfirm>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['GIFT_CARD_DELETE']}>
                                {data.gift_card_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button

                                            size="large"
                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default GiftCardDetail;
