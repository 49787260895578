import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as customerService from '../../service';
import { setLoading } from '../../../Common/slice';
import { clearCustomerId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
// import Address from '../../../Common/components/Address';
import * as c from '../../../../constants/common';
import * as customerThunk from '../../thunk';
import moment from 'moment';
import CustomerGroupSelect from '../../../Common/components/CustomerGroupSelect';
import noImage from '../../../../assets/images/default-user.png';
import { Drawer, Space, Button, Row, Col, Form, Input, Radio, DatePicker, Switch, message, Image } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CameraOutlined
} from '@ant-design/icons';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import { RandomCode } from '../../../Common/components/RandomCode';
const { TextArea } = Input;

const CustomerAction = (props) => {
  const dispatch = useDispatch();
  const queryData = useSelector(state => state.customer.customerQueryData);
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const customerId = useSelector(state => state.customer.customerId);
  // const [clearAddress, setClearAddress] = useState('');
  // const [countSubmit, setCountSubmit] = useState(0);
  const [actionType, setActionType] = useState('');
  const [dataAvatar, setDataAvatar] = useState({});
  const [branchId, setBranchId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const branchLocal = JSON.parse(localStorage.getItem('branch'));
  const [yearEnough18YearOld, setYearEnough18YearOld] = useState('');

  // const watchCustomerType = watch('customer_type')

  useEffect(() => { getYearEnough18YearOld() }, [])
  useEffect(() => {
    if (customerId) {
      dispatch(setLoading(true));
      customerService.customerDetail({ customer_id: customerId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            province: data.province_id || null,
            district: data.district_id || null,
            ward: data.ward_id || null,
            customer_code: data.customer_code,
            customer_fullname: data.customer_fullname,
            customer_phone_number: data.customer_phone_number,
            customer_phone_number2: data.customer_phone_number2,
            customer_email: data.customer_email,
            customer_note: data.customer_note,
            customer_address: data.customer_address,
            customer_type: data.customer_type,
            is_active: data.is_active === c.IS_ACTIVE,
            customer_birthday: data.customer_birthday ? moment(data.customer_birthday, 'YYYY-MM-DD') : null,
            customer_branch: data.branch_name,
            customer_facebook: data.customer_facebook,
            customer_yelp: data.customer_yelp,
            customer_tax_code: data.customer_tax_code,
            customer_company_name: data.customer_company_name,
            customer_gender: data.customer_gender,
            customer_group: data.customer_group_id_list ? data.customer_group_id_list.map((item) => item.value) : []
          });
          setBranchId(data.branch_id)
          setDataAvatar({ image_gallery_name: data.customer_avatar_name, image_gallery_url: data.customer_avatar })
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [customerId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!customerId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ customer_code: RandomCode() });
  };

  const onSubmit = (data) => {
    if (
      !!data.customer_phone_number && !!data.customer_phone_number2 &&
      data.customer_phone_number === data.customer_phone_number2) {
      message.error(lang.phone_no_duplicate);
      return;
    }
    dispatch(setLoading(true));
    const method = customerId ? 'PUT' : 'POST';
    const dataReq = {
      customer_id: customerId || '',
      customer_code: data.customer_code || '',
      customer_fullname: data.customer_fullname || '',
      customer_phone_number: data.customer_phone_number || '',
      customer_note: data.customer_note || '',
      customer_address: data.customer_address || '',
      customer_email: data.customer_email || '',
      customer_birthday: data.customer_birthday && data.customer_birthday !== 'Invalid date' ? moment(data.customer_birthday).format('YYYY-MM-DD') : '',
      province_id: data.province || '',
      district_id: data.district || '',
      ward_id: data.ward || '',
      branch_id: customerId ? branchId : branchLocal.branch_id || '',
      create_user: userInfo.user_id || '',
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      customer_phone_number2: data.customer_phone_number2 || '',
      customer_facebook: data.customer_facebook || '',
      customer_yelp: data.customer_yelp || '',
      customer_company_name: data.customer_company_name || '',
      customer_tax_code: data.customer_tax_code || '',
      customer_gender: data.customer_gender || null,
      customer_type: data.customer_type || c.CUSTOMER_TYPE.PERSONAL,
      customer_avatar: dataAvatar.image_gallery_name || '',
      customer_group_id_list: data.customer_group ? data.customer_group.map((item) => parseInt(item)) : []
    };
    customerService.customerCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.customer_phone_number) {
          message.error(lang.phone_number + data.customer_phone_number + lang.exist);
        } else if (data.customer_code) {
          message.error(lang.customer_code + lang.exist);
        } else if (data.customer_fullname) {
          message.error(lang.customer_fullname + lang.exist);
        } else if (data.customer_birthday) {
          message.error(lang.customer_birthday + lang.exist);
        } else if (data.customer_email) {
          message.error(lang.email + lang.exist);
        } else if (data.customer_phone_number2) {
          message.error(lang.phone_number + data.customer_phone_number2 + lang.exist);
        }
        // luu thanh cong
        if (data.customer_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!customerId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose()
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(customerThunk.customerList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    genCode();
    dispatch(clearCustomerId(''));
    dispatch(clearCustomerId(''));
    form.resetFields();
    setBranchId('')
    setDataAvatar({})
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onSaveChangeImage = (data) => {
    if (data.length > 0) {
      setDataAvatar(data[0])
    }
  }

  const today = new Date()
  const target = today.getFullYear() - 18
  const getYearEnough18YearOld = () => {
    console.log(target + '-12-31')
    setYearEnough18YearOld(target + '-12-31')
  }
  /// render/////
  return (
    <span>
      <ImageGallerySelect id="galleryCustomerAction" onSave={onSaveChangeImage} />
      <>
        <CheckPermission permission={['CUSTOMER_ADD']}>
          <Button size="large" type="primary" id="customerAction" onClick={showModal} icon={<PlusOutlined st />} />
        </CheckPermission>
        <Drawer
          title={!customerId
            ? lang.add + lang.customer.toLowerCase()
            : lang.edit + lang.customer.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
            initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24} className="mb-15 ">
                <div className="text-center">
                  <Image
                    width="100%"
                    className="img-avatar"
                    preview={false}
                    src={dataAvatar.image_gallery_url || noImage}
                  />
                </div>
                <div className="text-center pt-10">
                  <Button size="middle" icon={<CameraOutlined />}
                    onClick={() => document.getElementById('galleryCustomerAction').click()}
                    key="update_customer_avatar"
                    type="primary"
                  >
                    {lang.choose_image}
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_code}
                  name="customer_code"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_fullname}
                  name="customer_fullname"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Email"
                  name="customer_email"
                  rules={[{ type: 'email', message: lang.email_not_format }]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_phone_number}
                  name="customer_phone_number"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[0-9]*$/, message: lang.not_format },
                    { min: 10, message: lang.phone_min_max },
                    { max: 11, message: lang.phone_min_max }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_phone_number2}
                  name="customer_phone_number2"
                  rules={[
                    { pattern: /^[0-9]*$/, message: lang.not_format },
                    { min: 10, message: lang.phone_min_max },
                    { max: 11, message: lang.phone_min_max }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_birthday}
                  name="customer_birthday"
                >
                  <DatePicker size="large" format={c.FORMAT_DATE} placeholder={lang.customer_birthday} style={{ width: '100%' }} inputReadOnly disabledDate={d => !d || d.isAfter(yearEnough18YearOld)} />
                </Form.Item>
              </Col>

              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_gender}
                  name="customer_gender"
                >
                  <Radio.Group size="large">
                    <Radio value={c.GENDER.MALE}>{lang.male}</Radio>
                    <Radio value={c.GENDER.FEMALE}>{lang.female}</Radio>
                    <Radio value={c.GENDER.OTHER}>{lang.other}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_type}
                  name="customer_type"
                >
                  <Radio.Group size="large" defaultValue={c.CUSTOMER_TYPE.PERSONAL}>
                    <Radio value={c.CUSTOMER_TYPE.PERSONAL}>{lang.personal}</Radio>
                    <Radio value={c.CUSTOMER_TYPE.COMPANY}>{lang.company}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_tax_code}
                  name="customer_tax_code"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => prevValues.customer_type !== currentValues.customer_type}
                >
                  {({ getFieldValue }) =>
                    <Form.Item
                      label={lang.customer_company_name}
                      name="customer_company_name"
                      rules={[
                        {
                          pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                          message: lang.not_allow_special_characters
                        }
                      ]}
                    >
                      <Input size="large" disabled={getFieldValue('customer_type') !== c.CUSTOMER_TYPE.COMPANY} />
                    </Form.Item>
                  }
                </Form.Item>

              </Col>
              <Col xs={24} md={12}>
                <CustomerGroupSelect />
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.customer_address}
                  name="customer_address"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              {/* <Address
                dataEdit={dataEdit}
                clearAddress={clearAddress}
                countSubmit={countSubmit}
              /> */}

              <Col xs={24} md={12}>
                <Form.Item
                  label="Facebook"
                  name="customer_facebook"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label="Yelp"
                  name="customer_yelp"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.note}
                  name="customer_note"
                >
                  <TextArea size="large" allowClear rows={1} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default CustomerAction;
