/* eslint-disable no-unmodified-loop-condition */
import moment from 'moment';

export const getListHourAppointment = (startTime = '', endTime = '', interval = 0) => {
    const start = new Date();
    start.setHours(moment(startTime, 'hh:mm').hours(), moment(startTime, 'hh:mm').minutes())
    const end = new Date();
    end.setHours(moment(endTime, 'hh:mm').hours(), moment(endTime, 'hh:mm').minutes())
    const result = [];
    for (let d = start; d <= end; d.setMinutes(d.getMinutes() + interval)) {
        result.push({ label: moment(d).format('hh:mm A'), value: moment(d).format('HH:mm') });
    }
    return result;
}
