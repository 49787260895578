import CallApi from '../../../utils/CallAPI';
import {
  APPOINTMENT_SETTING
} from './constants/endpoint';

export const appointmentSetting = (data) => {
  return CallApi(APPOINTMENT_SETTING, 'GET', data)
};
export const appointmentSettingUpdate = (data) => {
  return CallApi(APPOINTMENT_SETTING, 'PUT', data)
};
