/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React, { useRef } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Editor } from '@tinymce/tinymce-react';

const TinyEditor = (props) => {
    const { onChange, value, height, toolbar } = props;
    const editorRef = useRef(null);

    /// ///function////

    /// render/////
    return (
        <div>
            <Editor
                apiKey="9hik7znl29tbm05kofmzi7yafbcquojxh1sbatr88rec2wzg"
                onInit={(evt, editor) => editorRef.current = editor}
                value={value}
                onEditorChange={onChange}
                init={{
                    // content_style: 'body {  background-color: #e6e7ee !important;}, header {  background-color: #e6e7ee !important;}',
                    height: height || 300,
                    menubar: true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                        'searchreplace visualblocks visualchars code fullscreen',
                        'insertdatetime media nonbreaking save table contextmenu directionality paste wordcount textcolor colorpicker',
                        'emoticons template paste textcolor colorpicker textpattern imagetools'
                    ],
                    toolbar: toolbar === false ? toolbar : 'bold italic underline | alignleft aligncenter alignright alignjustify | fontsizeselect | formatselect | fontselect'
                    // toolbar: 'bold italic underline | alignleft aligncenter alignright alignjustify fontsizeselect'

                    // toolbar: 'formatselect fontselect fontsizeselect | ' +
                    //     'bold italic underline superscript Strikethrough forecolor backcolor | charmap emoticons hr | alignleft aligncenter ' +
                    //     'alignright alignjustify | table image media link | lineheight bullist numlist outdent indent | ' +
                    //     'code print preview insertdatetime removeformat searchreplace fullscreen',
                    // toolbar2: 'formatselect fontselect fontsizeselect | ' +
                    //     'bold italic underline superscript Strikethrough forecolor backcolor | charmap emoticons hr | alignleft aligncenter ' +
                    //     'alignright alignjustify | table image media link | lineheight bullist numlist outdent indent | ' +
                    //     'code print preview insertdatetime removeformat searchreplace fullscreen',
                    // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </div>
    );
};

export default TinyEditor;
