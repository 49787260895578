/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Image, Input, Pagination, message, Button } from 'antd'
import * as serviceThunk from '../../../Service/thunk';
import noImage from '../../../../assets/images/no-image.png';
import { formatCurrency } from '../../../Common/components/FormatData'
import lang from '../../../../language/language';
import ServiceGroupSelect from '../../../Common/components/ServiceGroupSelect'
import { setInvoiceDetail, setInvoiceId } from '../../slice';
import { setServiceInvoiceQueryData } from '../../../Service/slice'
import { setLoading } from '../../../Common/slice';
import * as c from '../../../../constants/common';
import * as invoiceService from '../../service';
import SortSelect from '../../../Common/components/SortSelect';
import { getInvoiceList } from '../../../Common/thunk';
import { RandomCode } from '../../../Common/components/RandomCode';
import { getInvoiceDetail } from '../../thunk';
import { updateInvoiceInfo } from '../../common';
import { serviceList } from '../../../Service/service';
import BarcodeReader from 'react-barcode-reader';
import {
    BarcodeOutlined
} from '@ant-design/icons';
import { getStaffAutoTurn } from '../../../Common/service';
import moment from 'moment';

const { Search } = Input;

const InvoiceActionServiceList = (props) => {
    const dispatch = useDispatch();
    const serviceInvoiceQueryData = useSelector(state => state.service.serviceInvoiceQueryData);
    const dataList = useSelector(state => state.service.serviceInvoiceList);
    const generalSetting = useSelector(state => state.generalSetting);
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const userInfo = useSelector(state => state.user.userInfo);
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const posSetting = useSelector(state => state.posSetting);

    useEffect(() => {
        fetchDataList(serviceInvoiceQueryData);
    }, [serviceInvoiceQueryData]);

    useEffect(() => {
        dispatch(setLoading(false))
    }, [dataList.rows]);

    /// /funtion///////

    const getStaffAuto = async () => {
        const resData = await getStaffAutoTurn({ date: moment().format('YYYY-MM-DD') })
        return resData.data.staff_id
    }

    const onAddItemList = async (data) => {
        dispatch(setLoading(true));
        const autoStaff = await getStaffAuto() || null
        let result = [...invoiceDetail.invoiceItemList]
        if (invoiceId) {
            // nếu đã có hoá đơn
            if (!result.map(x => x.invoice_item_code).includes(data.service_code)) {
                const totalPrice = data.service_price_discount ? data.service_price_discount : data.service_price
                // nếu ko tồn tại item
                const dataReq = {
                    invoice_item_code: data.service_code,
                    invoice_item_name: data.service_name,
                    invoice_item_quantity: 1,
                    invoice_item_price: data.service_price,
                    invoice_item_price_discount: data.service_price_discount,
                    invoice_item_total_price: totalPrice,
                    invoice_item_type: c.CART_ITEM_TYPE.SERVICE,
                    invoice_id: invoiceId,
                    staff_id: autoStaff,
                    service_id: data.service_id
                }
                invoiceService.invoiceItemCreateUpdate(dataReq).then(res => {
                    if (res.data) {
                        const invoiceReq = {
                            ...invoiceDetail,
                            invoice_temp_money: (invoiceDetail.invoice_temp_money + data.service_price),
                            invoice_id: invoiceId,
                            invoice_total_promotion: 0,
                            invoicePromotionList: [],
                            invoice_total_surcharge: 0,
                            invoiceSurchargeList: []
                        }
                        updateInvoiceInfo(invoiceReq, dispatch, posSetting)
                        invoiceService.invoiceSurchargeDelete({ invoice_id: invoiceId }).then(res => {
                            dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                        })
                    }
                }).finally(x => {
                    dispatch(setLoading(false));
                    dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                })
            } else if (result.map(x => x.invoice_item_code).includes(data.service_code)) {
                // nếu tồn tại item
                let itemFilter = result.filter(x => x.invoice_item_code === data.service_code)
                const totalPrice = data.service_price_discount ? data.service_price_discount * (itemFilter[0].invoice_item_quantity + 1) : data.service_price * (itemFilter[0].invoice_item_quantity + 1)

                const dataReq = {
                    invoice_item_quantity: itemFilter[0].invoice_item_quantity + 1,
                    invoice_item_price: data.service_price,
                    invoice_item_price_discount: data.service_price_discount,
                    invoice_item_total_price: totalPrice,
                    invoice_id: invoiceId,
                    invoice_item_id: itemFilter[0].invoice_item_id,
                    invoice_item_code: data.service_code,
                    invoice_item_name: data.service_name,
                    invoice_item_type: c.CART_ITEM_TYPE.SERVICE,
                    staff_id: itemFilter[0].staff_id,
                    service_id: data.service_id
                }
                invoiceService.invoiceItemCreateUpdate(dataReq, 'PUT').then(res => {
                    if (res.data) {
                        const invoiceReq = {
                            ...invoiceDetail,
                            invoice_temp_money: (invoiceDetail.invoice_temp_money + data.service_price),
                            invoice_id: invoiceId,
                            invoice_total_promotion: 0,
                            invoicePromotionList: [],
                            invoice_total_surcharge: 0,
                            invoiceSurchargeList: []
                        }
                        updateInvoiceInfo(invoiceReq, dispatch, posSetting)
                        invoiceService.invoiceSurchargeDelete({ invoice_id: invoiceId }).then(res => {
                            dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                        })
                    }
                }).finally(x => {
                    dispatch(setLoading(false));
                    dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                })
            }
        } else {
            // nếu chưa có hoá đơn
            // const tax = (data.service_price * posSetting.tax_percent) / 100
            const invoiceReq = {
                invoice_code: RandomCode(c.CODE_TYPE.INVOICE),
                create_user: userInfo.user_id,
                invoice_temp_money: 0,
                invoice_customer_need_to_pay: 0,
                invoice_status: c.INVOICE_STATUS.UNPAID,
                invoice_money_received: 0,
                invoice_change: 0,
                invoice_tax: 0,
                invoice_customer_group_discount: 0,
                invoice_note: '',
                invoice_tip: 0,
                invoice_id: null
            }
            // tạo hoá đơn
            invoiceService.invoiceCreateUpdate(invoiceReq, 'POST').then(res => {
                if (res.data) {
                    const idInvoice = res.data.invoice_id
                    if (idInvoice > 0) {
                        // thêm item vào hoá đơn
                        const totalPrice = data.service_price_discount ? data.service_price_discount : data.service_price

                        const itemReq = {
                            invoice_item_code: data.service_code,
                            invoice_item_name: data.service_name,
                            invoice_item_quantity: 1,
                            invoice_item_price: data.service_price,
                            invoice_item_price_discount: data.service_price_discount,
                            invoice_item_total_price: totalPrice,
                            invoice_item_type: c.CART_ITEM_TYPE.SERVICE,
                            invoice_id: idInvoice,
                            staff_id: autoStaff,
                            service_id: data.service_id
                        }
                        invoiceService.invoiceItemCreateUpdate(itemReq, 'POST').then(res => {
                            if (res.data) {
                                dispatch(setInvoiceId(idInvoice))
                                dispatch(getInvoiceList())
                            }
                        }).finally(x => {
                            dispatch(setLoading(false));
                            dispatch(getInvoiceDetail({ invoice_id: idInvoice }))
                        })
                    }
                }
            })
        }

        dispatch(dispatch(setInvoiceDetail({ ...invoiceDetail, invoiceItemList: result })))
    }
    const fetchDataList = (queryData) => {
        dispatch(setLoading(true))
        dispatch(serviceThunk.serviceInvoiceList(queryData));
    };

    const onChangeGroupService = (data) => {
        dispatch(setServiceInvoiceQueryData({ ...serviceInvoiceQueryData, serviceGroupId: data.map((item) => item.value) }))
    }

    const onSearch = (value, e) => {
        dispatch(setServiceInvoiceQueryData({ ...serviceInvoiceQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    }

    const onChangePagination = (page, pageSize) => {
        dispatch(setServiceInvoiceQueryData({ ...serviceInvoiceQueryData, page: page }))
    }

    const onChangeSort = (data) => {
        dispatch(setServiceInvoiceQueryData({ ...serviceInvoiceQueryData, orderBy: data }))
    }

    const onScan = (codeScan) => {
        if (codeScan) {
            const code = codeScan.slice(0, 3)
            if (code === c.CODE_TYPE.SERVICE) {
                serviceList({ ...serviceInvoiceQueryData, page: c.SIZE_PAGE.PAGE, search: codeScan }).then(res => {
                    if (res.data && res.data.rows) {
                        const dataService = res.data.rows;
                        if (dataService.length > 0) {
                            onAddItemList(dataService[0])
                        }
                    } else {
                        message.error(lang.code_not_found)
                    }
                })
            }
        }
    }

    const onScanError = (err) => {
        console.log(err)
    }
    /// /render///////
    return (
        <Row>
            <BarcodeReader
                onError={onScanError}
                onScan={onScan}
            />
            <Col md={11} className="pr-15 pb-15">
                <Search
                    size="middle"
                    placeholder={lang.search_service}
                    enterButton
                    onSearch={onSearch}
                />

            </Col>
            <Col md={6} className="pb-15">
                <ServiceGroupSelect
                    size="middle"
                    label={null}
                    className="mb-0"
                    isMultiple
                    onChange={onChangeGroupService}
                />

            </Col>
            <Col md={1} className="pb-15 pl-5">
                <Button size='middle' className='pl-5 pr-5'>
                    <BarcodeOutlined onClick={() => { message.success(lang.barcode_actived) }} style={{ fontSize: 22 }} />
                </Button>
            </Col>
            <Col md={6} className="pb-15 pr-10 text-right">
                <SortSelect
                    onChange={onChangeSort}
                />
            </Col>
            <Col md={24}>
                <Row gutter={6} className="pos-service-list pl-10 pr-10 pb-10 pt-10">
                    {
                        dataList.rows && dataList.rows.length > 0 && dataList.rows.map((item, index) => {
                            return (
                                <Col onClick={() => onAddItemList(item)} key={index} xs={3} md={6} lg={4} className="col-service-pos pt-5 pb-5 box-hover">
                                    <div className="text-center">
                                        <Image
                                            preview={false}
                                            className="clear-shadow img-item-pos"
                                            src={item.service_avatar || noImage}
                                        />
                                    </div>
                                    <div className='text-center'>
                                        <div className='f-11'>{item.service_name}</div>
                                        <div className='font-weight-bold f-13'><span className={`${item.service_price_discount && 'line-through limiter'}`}>{formatCurrency(item.service_price, generalSetting)}</span> {item.service_price_discount && <span>{formatCurrency(item.service_price_discount, generalSetting)}</span>} - {item.service_duration} {lang.minute}</div>
                                    </div>
                                </Col>
                            )
                        })
                    }
                </Row>
            </Col>
            <Col xs={24} md={24} lg={24} className="text-right pt-5 pb-10 pr-5">
                <Pagination pageSize={serviceInvoiceQueryData.sizePerPage} size="small" onChange={onChangePagination} current={serviceInvoiceQueryData.page} total={dataList.total} />
            </Col>
        </Row>

    );
};

export default InvoiceActionServiceList;
