/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { setEmailHistoryId, setEmailHistoryQueryData, setEmailHistoryListId } from '../../slice';
import * as emailHistoryThunk from '../../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import EmailHistoryDetail from './EmailHistoryDetail';
import EmailHistoryFilter from './EmailHistoryFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../../Common/slice';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import EmailHistoryAction from './EmailHistoryAction';
import * as c from '../../../../constants/common';

const { Search } = Input;

const EmailHistory = (props) => {
  const dispatch = useDispatch();
  const emailHistoryQueryData = useSelector(state => state.email.emailHistoryQueryData);
  const emailHistoryListId = useSelector(state => state.email.emailHistoryListId);
  const dataList = useSelector(state => state.email.emailHistoryList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setEmailHistoryListId([]));
      dispatch(setEmailHistoryId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.email_history));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(emailHistoryQueryData);
  }, [emailHistoryQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(emailHistoryThunk.emailHistoryList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setEmailHistoryQueryData({ ...emailHistoryQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.email_history}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_email_history}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <EmailHistoryAction />
                <EmailHistoryFilter />
                <AntdToggleColumn
                  data={dataList}
                  queryData={emailHistoryQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            hideCheckbox
            listIdChecked={emailHistoryListId}
            queryData={emailHistoryQueryData}
            keyField="email_history_id"
            clickField="email_history_title"
            isShowCreateDateHour
            setListIdChecked={setEmailHistoryListId}
            setQueryData={setEmailHistoryQueryData}
            expandedRowRender={(record) => <EmailHistoryDetail data={record} queryData={emailHistoryQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailHistory;
