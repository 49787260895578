import CallApi from '../../../utils/CallAPI';
import {
  POS_SETTING
} from './constants/endpoint';

export const posSetting = (data) => {
  return CallApi(POS_SETTING, 'GET', data)
};

export const posSettingCreateUpdate = (data, method) => {
  return CallApi(POS_SETTING, method, data)
};
