/* eslint-disable array-callback-return */

import { Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import lang from '../../../language/language';
import { formatCurrency, formatDate, formatPhone } from '../../Common/components/FormatData';
import * as c from '../../../constants/common';
import Barcode from 'react-barcode/lib/react-barcode';
import { getInvoiceTotalPromotion, getInvoiceTotalSurcharge } from '../common';

const InvoicePrint = (props) => {
    const { printRef, data, dataItem } = props;
    const generalSetting = useSelector(state => state.generalSetting);
    const posSetting = useSelector(state => state.posSetting);

    const showContent = (data) => {
        return <div style={{ position: 'absolute', bottom: 10000, zIndex: 0, right: 0 }}>
            <div id="invoice-print" className='bg-white section-print invoice-print' ref={printRef}>
                <Row gutter={12} className="mb-15 pt-15 pb-15 bg-white page-break" >
                    <Col md={24} xs={24} className="text-center border-bottom-2 pb-15 mb-15">
                        <b className="text-uppercase f-20">{data.branch}</b>
                        <div>{data.branch_address}</div>
                        <div>{formatPhone(data.branch_phone_number)}</div>
                    </Col>
                    <Col md={24} xs={24} className="pb-10 f-12 border-bottom-2">
                        <div className="text-center pb-10 f-14"><b>{lang.invoice_payment}</b></div>
                        <Row >
                            <Col md={8} xs={9}>
                                <div>{lang.invoice_code}</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{data.invoice_code}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.create_date}</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{formatDate(data.create_date, true, generalSetting)}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.create_user}</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{data.create_user}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.customer}</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{data.customer || data.customer_fullname}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.phone}</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{formatPhone(data.customer_phone_number)}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} xs={24} className="pb-10 table-white pt-10">
                        <table width="100%" className='text-left'>
                            <tr className="border-bottom-2 pb-10 f-13">
                                <th >{lang.invoice_item_name}</th>
                                <th>{lang.total_price}</th>
                            </tr>
                            {dataItem && dataItem.map((item, index) => {
                                return (
                                    <tr className="border-bottom-2 f-12" key={index}>
                                        <td>
                                            <div>
                                                {item.invoice_item_name}
                                            </div>
                                            <div>
                                                {item.invoice_item_quantity} x <span className={`${item.invoice_item_price_discount && 'line-through limiter'}`}>{formatCurrency(item.invoice_item_price, generalSetting)}</span>
                                                {item.invoice_item_price_discount && <span> {formatCurrency(item.invoice_item_price_discount, generalSetting)}</span>}
                                            </div>
                                        </td>
                                        <td>{formatCurrency(item.invoice_item_total_price, generalSetting)}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </Col>
                    <Col md={24} xs={24} className="pb-10 f-12 ">
                        <Row className="font-weight-bold f-13 pt-5 pb-5">
                            <Col md={13} xs={13}>
                                <div >{lang.invoice_temp_money}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(data.invoice_temp_money, generalSetting)}</div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.tax} ({`${posSetting.allow_apply_tax === c.IS_ACTIVE ? posSetting.tax_percent : 0}%`})</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(data.invoice_tax, generalSetting)}</div>
                            </Col>
                        </Row>

                        {data.invoiceSurchargeList && data.invoiceSurchargeList.length > 1 && <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.surcharge}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(getInvoiceTotalSurcharge(data.invoiceSurchargeList), generalSetting)}</div>
                            </Col>
                            <Col xs={24} md={24}>
                                <Row className="f-10 pl-15">
                                    {data.invoiceSurchargeList && data.invoiceSurchargeList.map((x, index) => {
                                        return <>
                                            <Col xs={13} md={13}>
                                                {x.invoice_surcharge_name}
                                            </Col>
                                            <Col className='text-right' xs={11} md={11}>
                                                {formatCurrency(x.invoice_surcharge_money, generalSetting)}
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            </Col>
                        </Row>}

                        {data.invoiceSurchargeList && data.invoiceSurchargeList.length === 1 && <Row>
                            <Col md={13} xs={13}>
                                <div>{data.invoiceSurchargeList[0].invoice_surcharge_name}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(getInvoiceTotalSurcharge(data.invoiceSurchargeList), generalSetting)}</div>
                            </Col>
                        </Row>}

                        {data.invoicePromotionList && data.invoicePromotionList.length > 1 && <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.promotion}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>-{formatCurrency(getInvoiceTotalPromotion(data.invoicePromotionList), generalSetting)}</div>
                            </Col>
                            <Col xs={24} md={24}>
                                <Row className="f-10 pl-15">
                                    {data.invoicePromotionList && data.invoicePromotionList.map((x, index) => {
                                        return <>
                                            <Col xs={13} md={13}>
                                                {x.invoice_promotion_name}
                                            </Col>
                                            <Col className='text-right' xs={11} md={11}>
                                                -{formatCurrency(x.invoice_promotion_money, generalSetting)}
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            </Col>
                        </Row>}

                        {data.invoicePromotionList && data.invoicePromotionList.length === 1 && <Row>
                            <Col md={13} xs={13}>
                                <div>{data.invoicePromotionList[0].invoice_promotion_name}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>-{formatCurrency(getInvoiceTotalPromotion(data.invoicePromotionList), generalSetting)}</div>
                            </Col>
                        </Row>}

                        <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.customer_group}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{data.invoice_customer_group_discount > 0 && '-'}{formatCurrency(data.invoice_customer_group_discount, generalSetting)}</div>
                            </Col>
                        </Row>

                        <Row className="font-weight-bold f-13 pt-5 pb-5">
                            <Col md={13} xs={13}>
                                <div>{lang.customer_need_to_pay}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(data.invoice_customer_need_to_pay, generalSetting)}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={15} xs={15}>
                                <div>{lang.money_received} {`${data.invoicePaymentMethod && data.invoicePaymentMethod.length === 1 ? '(' + c.PAYMENT_METHOD.filter(z => z.value === data.invoicePaymentMethod[0].invoice_payment_method)[0].label + ')' : ''}`}</div>
                            </Col>
                            <Col md={9} xs={9} className="text-right">
                                {formatCurrency(data.invoice_money_received, generalSetting)}
                            </Col>
                            {data.invoicePaymentMethod && data.invoicePaymentMethod.length > 1 && <Col xs={24} md={24}>
                                <Row className="f-10 pl-15">
                                    {data.invoicePaymentMethod && data.invoicePaymentMethod.map((x, index) => {
                                        return <>
                                            <Col xs={13} md={13}>
                                                {c.PAYMENT_METHOD.filter(z => z.value === x.invoice_payment_method)[0].label}
                                            </Col>
                                            <Col className='text-right' xs={11} md={11}>
                                                {formatCurrency(x.invoice_payment_method_money, generalSetting)}
                                            </Col>
                                        </>
                                    })}
                                </Row>
                            </Col>}
                        </Row>
                        <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.change}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(data.invoice_change, generalSetting)}</div>
                            </Col>
                        </Row>
                        {data.invoice_tip > 0 && <Row>
                            <Col md={13} xs={13}>
                                <div>{lang.invoice_tip}</div>
                            </Col>
                            <Col md={11} xs={11} className="text-right">
                                <div>{formatCurrency(data.invoice_tip, generalSetting)}</div>
                            </Col>
                        </Row>}
                        {data.invoice_note && <Row className="border-top-1 pt-10 text-left">
                            <Col md={7} xs={7}>
                                <div>{lang.invoice_note}</div>
                            </Col>
                            <Col md={17} xs={17} className="text-left">
                                <div>
                                    {data.invoice_note}
                                </div>
                            </Col>
                        </Row>}
                    </Col>
                    <Col md={24} xs={24} className="text-center">
                        <Barcode
                            value={data.invoice_code}
                            width={1.5}
                            height={40}
                            fontSize={20}
                            displayValue={false}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    }

    return (
        showContent(data)
    )
    /// /render///////
};

export default InvoicePrint;
