import { createAsyncThunk } from '@reduxjs/toolkit';
import * as generalSettingService from './service';

export const generalSettingThunk = createAsyncThunk(
    'generalSetting/get',
    async (data, thunkAPI) => {
        const response = await generalSettingService.generalSetting(data);
        return response.data;
    }
);
