/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Tooltip, List } from 'antd';
import {
    InfoCircleOutlined
} from '@ant-design/icons';

const ToolTipShortCode = (props) => {
    const { data } = props;

    /// ///function////

    const tooltipList = (data) => {
        return (
            <List
                dataSource={data}
                renderItem={item => (
                    <List.Item>
                        <div style={{ width: '65%' }} className='text-left pr-15'>{item.value}</div>
                        <div style={{ width: '35%' }} className='text-left'>{item.label}</div>
                    </List.Item>
                )}
            />
        )
    }

    /// render/////
    if (data.length > 0) {
        return (
            <Tooltip trigger="click" placement="topLeft" title={tooltipList(data)} >
                <InfoCircleOutlined />
            </Tooltip>
        )
    } else {
        return null
    }
}

export default ToolTipShortCode;
