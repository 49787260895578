import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import * as c from '../../../../constants/common';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { getConditionType } from '../../../Common/service';
import { Form, Button, Space, Row, Col, Select, Input } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { Option } = Select;

const CustomerGroupActionCondition = (props) => {
  const [conditionTypeList, setConditionTypeList] = useState([]);
  /// effect

  useEffect(() => {
    getConditionType().then(res => {
      if (res) {
        setConditionTypeList(res.data)
      }
    })
  }, []);

  /// ///function////
  // const onChangeConditionType = (item, index) => {
  //   const data = [...customerGroupCondition]
  //   const newData = {
  //     condition_type: item,
  //     condition_compare: data[index].condition_compare,
  //     condition_value: data[index].condition_value
  //   }
  //   data[index] = newData
  //   dispatch(setCustomerGroupCondition(data))
  // }

  // const onChangeConditionCompare = (item, index) => {
  //   const data = [...customerGroupCondition]
  //   const newData = {
  //     condition_type: data[index].condition_type,
  //     condition_compare: item,
  //     condition_value: data[index].condition_value
  //   }
  //   data[index] = newData
  //   dispatch(setCustomerGroupCondition(data))
  // }

  // const onChangeConditionValue = (item, index) => {
  //   const { value } = item.target
  //   const data = [...customerGroupCondition]
  //   const newData = {
  //     condition_type: data[index].condition_type,
  //     condition_compare: data[index].condition_compare,
  //     condition_value: value
  //   }
  //   data[index] = newData
  //   dispatch(setCustomerGroupCondition(data))
  // }

  // const showList = () => {
  //   let result = null
  //   if (customerGroupCondition.length > 0) {
  //     result = customerGroupCondition.map((item, index) => {
  //       return (
  //         <tr key={index}>
  //           <td style={{ width: '30%' }}>
  //             <CustomSelect
  //               options={conditionTypeList.map((item) => {
  //                 return { value: item.condition_type_id, label: item.condition_type_name }
  //               }).filter((i) => !customerGroupCondition.some(x => x.condition_type && x.condition_type.value === i.value))}
  //               value={customerGroupCondition[index].condition_type}
  //               placeholder={''}
  //               onChange={(data) => onChangeConditionType(data, index)}
  //               isClearable
  //             />
  //             {countSubmit > 0 && !customerGroupCondition[index].condition_type && AlertValidate(lang.value_is_required)}
  //           </td>
  //           <td style={{ width: '25%' }}>
  //             <CustomSelect
  //               options={c.CONDITION_COMPARE.map((item) => {
  //                 return { value: item.value, label: item.label }
  //               })}
  //               onChange={(data) => onChangeConditionCompare(data, index)}
  //               value={customerGroupCondition[index].condition_compare}
  //               placeholder={''}
  //             />
  //             {countSubmit > 0 && !customerGroupCondition[index].condition_compare && AlertValidate(lang.value_is_required)}
  //           </td>
  //           <td>
  //             <input
  //               className="form-control"
  //               type='number'
  //               name={`condition_value${index}`}
  //               value={customerGroupCondition[index].condition_value}
  //               onChange={(data) => onChangeConditionValue(data, index)}
  //               placeholder={lang.condition_value}
  //             />
  //             {countSubmit > 0 && !customerGroupCondition[index].condition_value && AlertValidate(lang.value_is_required)}
  //           </td>
  //           <td style={{ width: 20 }} className="text-center">
  //             <i className="fas fa-trash cursor-pointer text-danger pt-2" onClick={() => onDelete(item)} />
  //           </td>
  //         </tr>
  //       )
  //     })
  //   }
  //   return result
  // }

  /// render/////
  return (
    <Row gutter={24}>
      <Form.List
        name="customer_group_condition">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Col key={key} xs={24} md={24}>
                <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Form.Item
                    {...restField}
                    name={[name, 'condition_type']}
                    rules={[
                      { required: true, message: lang.value_is_required }
                    ]}
                  >
                    <Select
                      size="large"
                      showSearch
                      style={{ width: 210 }}
                      maxTagCount='responsive'
                      placeholder={lang.condition_type}
                      // onChange={(value) => onSelectBranch(value, index)}
                      options={conditionTypeList.length > 0
                        ? conditionTypeList.map((item) => {
                          return { value: item.condition_type_id, label: item.condition_type_name }
                        })
                        : []}
                    >

                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'condition_compare']}
                    rules={[
                      { required: true, message: lang.value_is_required }
                    ]}
                  >
                    <Select
                      size="large"
                      style={{ width: 210 }}
                      showSearch
                      maxTagCount='responsive'
                      placeholder={lang.condition}
                    >
                      {
                        c.CONDITION_COMPARE.length > 0
                          ? c.CONDITION_COMPARE.map((item) => {
                            return <Option key={item.value} value={item.value}>{item.label}</Option>
                          })
                          : []
                      }
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'condition_value']}
                    rules={[
                      { required: true, message: lang.value_is_required },
                      { pattern: /^[0-9]*$/, message: lang.not_format }
                    ]}
                  >
                    <Input style={{ width: 210 }} size="large" placeholder={lang.condition_value} />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              </Col>
            ))}
            {fields.length < conditionTypeList.length && <Col xs={24} md={24}>
              <Form.Item>
                <Button size="large" onClick={() => add()} icon={<PlusOutlined />}>
                  {lang.add}
                </Button>
              </Form.Item>
            </Col>}
          </>
        )}
      </Form.List>
    </Row>

  );
};

export default CustomerGroupActionCondition;
