import CallApi from '../../../utils/CallAPI';
import {
  APPOINTMENT_LIST,
  APPOINTMENT,
  APPOINTMENT_DETAIL,
  APPOINTMENT_UPDATE_STATUS,
  APPOINTMENT_LIST_STAFF_ID_REMOVE,
  APPOINTMENT_HOUR_LIST_BY_STAFF_REMOVE,
  APPOINTMENT_WAIT_LIST,
  APPOINTMENT_CHECKIN,
  APPOINTMENT_PRINT_TICKET
} from './constants/endpoint';

export const getAppointmentList = (data) => {
  return CallApi(APPOINTMENT_LIST, 'GET', data)
};
export const appointmentCreateUpdate = (data, method) => {
  return CallApi(APPOINTMENT, method, data)
};
export const appointmentDetail = (data) => {
  return CallApi(APPOINTMENT_DETAIL, 'GET', data)
};

export const appointmentUpdateStatus = (data) => {
  return CallApi(APPOINTMENT_UPDATE_STATUS, 'PUT', data)
};
export const appointmentListStaffIdRemove = (data) => {
  return CallApi(APPOINTMENT_LIST_STAFF_ID_REMOVE, 'GET', data)
};
export const appointmentHourListByStaffRemove = (data) => {
  return CallApi(APPOINTMENT_HOUR_LIST_BY_STAFF_REMOVE, 'GET', data)
};
export const getAppointmentWaitList = (data) => {
  return CallApi(APPOINTMENT_WAIT_LIST, 'GET', data)
};

export const appointmentCheckin = (data) => {
  return CallApi(APPOINTMENT_CHECKIN, 'PUT', data)
};

export const appointmentPrintTicket = (data) => {
  return CallApi(APPOINTMENT_PRINT_TICKET, 'POST', data)
};
