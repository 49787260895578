/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { Col, List, Row, Typography, Button, Space, Popconfirm, message } from 'antd';
import React from 'react';
import lang from '../../../../language/language';
import { formatDate, formatIsActive } from '../../../Common/components/FormatData';
import CheckPermission from '../../../Common/components/CheckPermission';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { setSMSSampleId } from '../../slice';
import { useDispatch, useSelector } from 'react-redux';
import * as commonService from '../../../Common/service';
import * as smsThunk from '../../thunk';
const { Text } = Typography;

const SMSSampleDetail = (props) => {
    const { data, queryData } = props;
    const userInfo = useSelector(state => state.user.userInfo);
    const dispatch = useDispatch();

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.sms_sample_id) {
            const smsSampleId = data.sms_sample_id
            const dataDelete = {
                list_id: [smsSampleId],
                field_name: 'sms_sample_id',
                table: 'sms_sample',
                update_user: userInfo.user_id,
                permission: ['SMS_SAMPLE_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(smsThunk.smsSampleList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.sms_sample_id) {
            document.getElementById('smsSampleAction').click();
            dispatch(setSMSSampleId(data.sms_sample_id));
        }
    };

    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24} lg={24} className="ml-15">
                <List
                    size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'key' &&
                                x !== 'sms_sample_id' &&
                                x !== 'sms_sample_content'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date'
                                        ? formatDate(data[item])
                                        : (item === 'is_active')
                                            ? formatIsActive(data[item])
                                            : (String(data[item]).includes('{DELETE}'))
                                                ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['SMS_SAMPLE_EDIT']}>
                                {data.sms_sample_id &&
                                    <Button
                                        type="primary"
                                        onClick={onEdit}
                                        size="large"
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['SMS_SAMPLE_DELETE']}>
                                {data.sms_sample_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"
                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default SMSSampleDetail;
