import React from 'react';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { Form, Select } from 'antd';
const { Option } = Select;

const StatusSelect = (props) => {
  const { data } = props;
  const options = [
    { value: c.IS_ACTIVE, label: lang.active },
    { value: c.IS_DEACTIVE, label: lang.de_active }
  ];

  /// render/////
  return (
    <Form.Item
      label={lang.status}
      name="status"
    >
      <Select
        size="large"
        allowClear
        placeholder={lang.status}
      >
        {data && data.length > 0
          ? data.map((item) => {
            return <Option key={item.value} value={item.value}>{item.label}</Option>
          })
          : options.map((item) => {
            return <Option key={item.value} value={item.value}>{item.label}</Option>
          })}
      </Select>
    </Form.Item>
  );
};

export default StatusSelect;
