/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import lang from '../../../../language/language';
import CheckPermission from '../../../Common/components/CheckPermission';
import { useSelector, useDispatch } from 'react-redux';
import * as customerThunk from '../../thunk';
import * as customerGroupService from '../../service';
import { setCustomerGroupId } from '../../slice';
import * as commonService from '../../../Common/service';
import CustomerGroupDetailInfo from './CustomerGroupDetailInfo';
import CustomerGroupDetailCondition from './CustomerGroupDetailCondition';
import { Row, Col, Button, Space, Popconfirm, message, Tabs } from 'antd';
import {
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
const { TabPane } = Tabs;

const CustomerGroupDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const loading = useSelector(state => state.common.loading);
    const [dataDetail, setDataDetail] = useState({});

    useEffect(() => {
        if (data.customer_group_id) {
            customerGroupService.customerGroupDetail({ customer_group_id: data.customer_group_id }).then(res => {
                if (res) {
                    const { data } = res;
                    setDataDetail(data)
                }
            })
        }
    }, [data.customer_group_id, loading]);

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.customer_group_id) {
            const customerGroupId = data.customer_group_id
            const dataDelete = {
                list_id: [customerGroupId],
                field_name: 'customer_group_id',
                table: 'customer_group',
                update_user: userInfo.user_id,
                permission: ['CUSTOMER_GROUP_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(customerThunk.customerGroupList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.customer_group_id) {
            dispatch(setCustomerGroupId(data.customer_group_id));
            document.getElementById('customerGroupAction').click();
        }
    };
    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24}>
                <Tabs defaultActiveKey={`1${data.customer_group_id}`} >
                    <TabPane tab={lang.customer_group_info} key={`1${data.customer_group_id}`}>
                        <CustomerGroupDetailInfo dataDetail={data} />
                    </TabPane>
                    <TabPane tab={lang.condition} key={`2${data.customer_group_id}`}>
                        <CustomerGroupDetailCondition dataDetail={dataDetail} />
                    </TabPane>
                </Tabs>
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['CUSTOMER_GROUP_EDIT']}>
                                {data.customer_group_id &&
                                    <Button
                                        type="primary"
                                        onClick={onEdit}
                                        size="large"
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['CUSTOMER_GROUP_DELETE']}>
                                {data.customer_group_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button

                                            size="large"
                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default CustomerGroupDetail;
