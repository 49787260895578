import React from 'react';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { Form, Select } from 'antd';
const { Option } = Select;

const CustomerTypeSelect = (props) => {
  const options = [
    { value: c.CUSTOMER_TYPE.PERSONAL, label: lang.personal },
    { value: c.CUSTOMER_TYPE.COMPANY, label: lang.company }
  ];
  /// render/////
  return (
    <Form.Item
      label={lang.customer_type}
      name="customer_type"
    >
      <Select
        size="large"
        showSearch
        allowClear
        defaultValue={null}
        placeholder={lang.customer_type}
      >
        {options.map((item) => {
          return <Option key={item.value} value={item.value}>{item.label}</Option>
        })}
      </Select>
    </Form.Item>
  );
};

export default CustomerTypeSelect;
