import React from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Card, Tabs } from 'antd';
import CheckinSettingGeneral from './CheckinSettingGeneral';
import CheckinSettingSlider from './CheckinSettingSlider';

const CheckinSetting = (props) => {
    const { TabPane } = Tabs;
    /// /funtion///////

    /// /render///////
    return (
        <Card name="content" className="content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{APP_TITLE} - {lang.checkin_setting}</title>
            </Helmet>

            <Tabs destroyInactiveTabPane tabPosition="left">
                <TabPane forceRender tab={lang.setting_general} key="1">
                    <CheckinSettingGeneral />
                </TabPane>
                <TabPane forceRender tab={lang.slider} key="2">
                    <CheckinSettingSlider />
                </TabPane>
            </Tabs>
        </Card>
    );
};

export default CheckinSetting;
