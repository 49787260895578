import { createSlice } from '@reduxjs/toolkit';
import * as staffThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';

export const staffTypeSlice = createSlice({
  name: 'staffType',
  initialState: {
    staffTypeList: {},
    staffTypeId: '',
    staffTypeListId: [],
    staffTypeQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    },
    isShowTimeKeepingAction: false,
    currentStaffTimeKeeping: null
  },
  reducers: {
    // staff_type
    clearStaffTypeList: (state, action) => {
      state.staffTypeList = {};
    },
    clearStaffTypeRow: (state, action) => {
      state.staffTypeList.rows = [];
    },
    setStaffTypeId: (state, action) => {
      state.staffTypeId = action.payload;
    },
    clearStaffTypeId: (state, action) => {
      state.staffTypeId = '';
    },
    setStaffTypeQueryData: (state, action) => {
      state.staffTypeQueryData = action.payload;
    },
    setStaffTypeListId: (state, action) => {
      state.staffTypeListId = action.payload;
    },
    setIsShowTimeKeepingAction: (state, action) => {
      state.isShowTimeKeepingAction = action.payload;
    },
    setCurrentStaffTimeKeeping: (state, action) => {
      state.currentStaffTimeKeeping = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [staffThunk.staffTypeList.fulfilled]: (state, action) => {
      state.staffTypeList = action.payload;
    }
  }
});

export const {
  // staff_type
  clearStaffTypeList,
  clearStaffTypeRow,
  setStaffTypeId,
  setStaffTypeQueryData,
  setStaffTypeListId,
  clearStaffTypeId
} = staffTypeSlice.actions;

export default staffTypeSlice.reducer;
