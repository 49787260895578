import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Divider, List, Skeleton } from 'antd'
import lang from '../../../language/language'
import InfiniteScroll from 'react-infinite-scroll-component';
import { getActivityHistoryList } from '../service';
import { formatDate } from '../../Common/components/FormatData';
import { useSelector } from 'react-redux';
import parse from 'html-react-parser';

const DashBoardActivityHistory = () => {
    const [loading, setLoading] = useState(false);
    const generalSetting = useSelector(state => state.generalSetting)
    const [data, setData] = useState([]);
    const loadMoreData = () => {
        let page = 1
        const sizePerPage = 5
        if (loading) {
            return;
        }
        setLoading(true);
        getActivityHistoryList({ page, sizePerPage }).then(res => {
            if (res.data) {
                setData([...data, ...res.data]);
                page = page + 1
                setLoading(false);
            }
        }).finally(x => {
            setLoading(false);
        }
        )
    };

    useEffect(() => {
        loadMoreData();
    }, []);

    return (
        <Card className=" mb-15 pb-5">
            <Row gutter={24}>
                <Col xs={24} md={24} className="font-weight-bold mb-15">{lang.recent_activity}</Col>
                <Col xs={24} md={24}>
                    <div
                        id="scrollableDiv"
                        style={{
                            height: 267,
                            overflow: 'auto'
                        }}
                    >
                        <InfiniteScroll
                            dataLength={data.length}
                            next={loadMoreData}
                            hasMore={data.length < 100}
                            loader={
                                <Skeleton
                                    paragraph={{
                                        rows: 1
                                    }}
                                    active
                                />
                            }
                            endMessage={<Divider plain>{lang.load_more_end}</Divider>}
                            scrollableTarget="scrollableDiv"
                        >
                            <List
                                dataSource={data}
                                renderItem={(item) => (
                                    <List.Item key={item.activity_history_id}>
                                        <div>
                                            <span className="font-weight-bold">{item.activity_history_user_fullname} </span>
                                            {lang.has_been} {parse(item.activity_history_content_vi)} {lang.at} {formatDate(item.create_date, true, generalSetting)}
                                        </div>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                </Col>
            </Row>
        </Card>
    )
}
export default DashBoardActivityHistory
