/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import lang from '../../../../language/language';
import { Row, Col, Form, Checkbox } from 'antd';
import * as c from '../../../../constants/common';
import { useDispatch } from 'react-redux';
import { appointmentSettingThunk } from '../thunk'
import TextArea from 'antd/lib/input/TextArea';
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';

const AppointmentSettingSMS = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appointmentSettingThunk({}))
    }, []);

    return (
        <Row gutter={24}>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_sms_confirm"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_confirm}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_sms_confirm"
                    >
                        <TextArea rows={5} maxLength={c.SMS_MAX_LENGTH} showCount />
                    </Form.Item>
                </div>

            </Col>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_admin_sms_cancel"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_admin_cancel}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_admin_sms_cancel"
                    >
                        <TextArea rows={5} maxLength={c.SMS_MAX_LENGTH} showCount />
                    </Form.Item>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_customer_sms_cancel"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_customer_cancel}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_customer_sms_cancel"
                    >
                        <TextArea rows={5} maxLength={c.SMS_MAX_LENGTH} showCount />
                    </Form.Item>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_sms_remind"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_remind}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_sms_remind"
                    >
                        <TextArea rows={5} maxLength={c.SMS_MAX_LENGTH} showCount />
                    </Form.Item>
                </div>
            </Col>
        </Row>
    );
};

export default AppointmentSettingSMS;
