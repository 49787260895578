import { useSelector } from 'react-redux';

const CheckPermission = (props) => {
    const { children, permission } = props;
    const role = useSelector(state => state.role)
    const userInfo = useSelector(state => state.user.userInfo)

    if (children && permission) {
        if (userInfo.is_main === 1 && permission.every(val => !role.roleListByAppDeActive.includes(val))) {
            return children
        } else {
            if (permission && role.getRoleByUser.some(i => permission.indexOf(i.permission_code) >= 0)) {
                return children
            } else {
                return null
            }
        }
    } else {
        return null
    }
};

export default CheckPermission;
