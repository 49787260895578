import { createAsyncThunk } from '@reduxjs/toolkit';
import * as appointmentService from './service';
import { getCustomer } from '../../Common/service';
import moment from 'moment-timezone';
import * as config from '../../../constants/config';

export const appointmentGetListThunk = createAsyncThunk(
    'appointment/get',
    async (data, thunkAPI) => {
        const response = await appointmentService.getAppointmentList(data);
        return response.data;
    }
);
export const customerPhoneListThunk = createAsyncThunk(
    'customerPhoneList/get',
    async (data, thunkAPI) => {
        const response = await getCustomer(data);
        return response.data;
    }
);

export const appointmentWaitListThunk = createAsyncThunk(
    'appointment/wait',
    async (data, thunkAPI) => {
        const response = await appointmentService.getAppointmentWaitList(data);
        return response.data.filter(e => e.appointment_hour >= moment(new Date()).tz(config.TIME_ZONE).format('HH:mm')).sort((a, b) => { return a.appointment_hour.localeCompare(b.appointment_hour) });
    }
);
