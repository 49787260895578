import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as staffThunk from '../../thunk';
import { setStaffTypeListId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import { Menu, Button, Dropdown, Popconfirm, message } from 'antd';
import {
  SettingOutlined
} from '@ant-design/icons';
import * as c from '../../../../constants/common';

const StaffTypeActionChecked = (props) => {
  const staffTypeListId = useSelector(state => state.staffType.staffTypeListId);
  const queryData = useSelector(state => state.staffType.staffTypeQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (staffTypeListId.length > 0) {
      const data = {
        list_id: staffTypeListId,
        field_name: 'staff_type_id',
        table: 'staff_typef',
        update_user: userInfo.user_id,
        permission: ['STAFF_TYPE_DELETE']
      };
      commonService.commonDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(staffThunk.staffTypeList(queryData));
            dispatch(setStaffTypeListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (staffTypeListId.length > 0) {
      const data = {
        list_id: staffTypeListId,
        field_name: 'staff_type_id',
        table: 'staff_type',
        status,
        update_user: userInfo.user_id,
        permission: ['STAFF_TYPE_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(staffThunk.staffTypeList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TYPE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TYPE_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_TYPE_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    staffTypeListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default StaffTypeActionChecked;
