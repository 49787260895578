/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Tabs, message } from 'antd'
import InvoiceServiceList from './InvoiceActionServiceList';
import InvoiceActionItemList from './InvoiceActionItemList';
import InvoiceActionDetail from './InvoiceActionDetail/InvoiceActionDetail';
import { useDispatch, useSelector } from 'react-redux';
import BarcodeReader from 'react-barcode-reader';
import { setInvoiceId } from '../../slice';
import * as c from '../../../../constants/common';
import * as commonService from '../../../Common/service';
import { setInvoiceList } from '../../../Common/slice';

const { TabPane } = Tabs;

const InvoiceAction = (props) => {
  const invoiceId = useSelector(state => state.invoice.invoiceId);
  const dispatch = useDispatch();
  /// /funtion///////

  const onScan = (codeScan) => {
    if (codeScan) {
      const code = codeScan.slice(0, 3)
      if (code !== c.CODE_TYPE.INVOICE && code !== c.CODE_TYPE.GIFT_CARD && code !== c.CODE_TYPE.SERVICE) {
        return message.error(lang.code_not_found)
      }
      if (code === c.CODE_TYPE.INVOICE) {
        commonService.getInvoice({}).then(res => {
          if (res.data) {
            dispatch(setInvoiceList(res.data))
            const dataInvoice = res.data.filter(z => z.invoice_code === codeScan)
            if (dataInvoice && dataInvoice.length > 0) {
              if (!invoiceId) {
                dispatch(setInvoiceId(dataInvoice[0].invoice_id))
              }
            } else {
              message.error(lang.code_not_found)
            }
          }
        })
      }
    }
  }

  const onScanError = (err) => {
    console.log(err)
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.invoice}</title>
      </Helmet>
      <Row className='container-pos'>
        <Col xs={24} md={16} className="pr-15">
          <Row>
            <BarcodeReader
              onError={onScanError}
              onScan={onScan}
            />
            <Col xs={24} md={24} className="pos-service ant-card pl-10 pr-10 mb-15">
              <Tabs defaultActiveKey="1" >
                <TabPane tab={lang.service} key="1">
                  <InvoiceServiceList />
                </TabPane>
                {/* <TabPane tab={lang.product} key="2">
                  Sản phẩm
                </TabPane> */}
              </Tabs>

            </Col>
            <Col xs={24} md={24} className="pos-cart ant-card p-10">
              <InvoiceActionItemList />
            </Col>
          </Row>
        </Col>

        <Col xs={24} md={8}>
          <InvoiceActionDetail />
        </Col>
      </Row>
    </div>
  );
};

export default InvoiceAction;
