import { createSlice } from '@reduxjs/toolkit';
import * as appointmentThunk from './thunk';
import moment from 'moment-timezone';
import * as config from '../../../constants/config';

export const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: {
    appointmentId: '',
    isShowAppointmentAction: false,
    dataAddAppointment: {},
    appointmentList: [],
    appointmentWaitList: [],
    loadingTable: false,
    serviceStaffSelect: [],
    appointmentQueryData: {
      appointment_date: moment(moment(new Date(), 'YYYY-MM-DD')).tz(config.TIME_ZONE).format('YYYY-MM-DD'),
      appointment_status: []
    }
  },
  reducers: {
    setAppointmentId: (state, action) => {
      state.appointmentId = action.payload;
    },
    setIsShowAppointmentAction: (state, action) => {
      state.isShowAppointmentAction = action.payload;
    },
    setDataAddAppointment: (state, action) => {
      state.dataAddAppointment = action.payload;
    },
    setLoadingTable: (state, action) => {
      state.loadingTable = action.payload;
    },
    setDataEditAppointment: (state, action) => {
      state.dataEditAppointment = action.payload;
    },
    setServiceStaffSelect: (state, action) => {
      state.serviceStaffSelect = action.payload;
    },
    setAppointmentQueryData: (state, action) => {
      state.appointmentQueryData = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [appointmentThunk.appointmentGetListThunk.fulfilled]: (state, action) => {
      state.loadingTable = false
      state.appointmentList = action.payload;
    },
    [appointmentThunk.appointmentWaitListThunk.fulfilled]: (state, action) => {
      state.appointmentWaitList = action.payload;
    }
  }
});

export const {
  setAppointmentId,
  setIsShowAppointmentAction,
  setDataAddAppointment,
  setLoadingTable,
  setDataEditAppointment,
  setServiceStaffSelect,
  setAppointmentQueryData
} = appointmentSlice.actions;

export default appointmentSlice.reducer;
