/* eslint-disable array-callback-return */

import { Col, Row } from 'antd';
import React from 'react';
import Barcode from 'react-barcode/lib/react-barcode';
import { useSelector } from 'react-redux';
import lang from '../../../language/language';
import { formatCurrency, formatDate, formatPhone } from '../../Common/components/FormatData';

const InvoicePrintTicket = (props) => {
    const { printRef, data, dataItem } = props;
    const generalSetting = useSelector(state => state.generalSetting);

    const showContent = (data) => {
        return <div style={{ position: 'absolute', bottom: 10000, zIndex: 0, right: 0 }}>
            <div id="invoice-print" className='bg-white section-print invoice-print' ref={printRef}>
                <Row gutter={12} className="mb-15 pt-15 pb-15 bg-white page-break" >
                    <Col md={24} xs={24} className="text-center border-bottom-2 pb-15 mb-15">
                        <b className="text-uppercase f-20">{data.branch}</b>
                        <div>{data.branch_address}</div>
                        <div>{formatPhone(data.branch_phone_number)}</div>
                    </Col>
                    <Col md={24} xs={24} className="pb-10 f-12 border-bottom-2">
                        <div className="text-center pb-10 f-14"><b>{lang.service_ticket}</b></div>
                        <Row >
                            <Col md={8} xs={9}>
                                <div>{lang.invoice_code}:</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{data.invoice_code}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.create_date}:</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{formatDate(new Date(), true, generalSetting)}</div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} xs={9}>
                                <div>{lang.customer}:</div>
                            </Col>
                            <Col md={16} xs={15}>
                                <div>{data.customer || data.customer_fullname}</div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={24} xs={24} className="pb-10 table-white pt-10">
                        <table width="100%" className='text-left'>
                            <tr className="border-bottom-2 pb-10 f-13">
                                <th>{lang.invoice_item_name}</th>
                                <th className="text-right">{lang.invoice_total_price}</th>
                            </tr>
                            {dataItem.map((item, index) => {
                                return (
                                    <tr className="border-bottom-2 f-12" key={index}>
                                        <td>
                                            <div>
                                                {item.invoice_item_name} ({item.staff_fullname})
                                            </div>
                                            <div>
                                                {item.invoice_item_quantity} x <span className={`${item.invoice_item_price_discount && 'line-through limiter'}`}>{formatCurrency(item.invoice_item_price, generalSetting)}</span>
                                                {item.invoice_item_price_discount && <span> {formatCurrency(item.invoice_item_price_discount, generalSetting)}</span>}
                                            </div>
                                        </td>
                                        <td className="text-right" style={{ width: '30%' }}>{formatCurrency(item.invoice_item_total_price, generalSetting)}</td>
                                    </tr>
                                )
                            })}
                        </table>
                    </Col>
                    <Col md={24} xs={24} className="text-center">
                        <Barcode
                            value={data.invoice_code}
                            width={1.5}
                            height={40}
                            fontSize={20}
                            displayValue={false}
                        />
                    </Col>
                    <Col md={24} xs={24} className="text-center">
                        <div className="f-11">{lang.print_ticket_note}</div>
                    </Col>
                </Row>
            </div>
        </div>
    }

    return (
        showContent(data)
    )
    /// /render///////
};

export default InvoicePrintTicket;
