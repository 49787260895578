import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as staffThunk from '../../thunk';
import { setStaffListId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as commonService from '../../../Common/service';
import { Menu, Button, Dropdown, Popconfirm, message } from 'antd';
import { setLoading } from '../../../Common/slice';
import { staffExport } from '../../service';
import * as c from '../../../../constants/common';

import {
  SettingOutlined
} from '@ant-design/icons';

const StaffActionChecked = (props) => {
  const staffListId = useSelector(state => state.staff.staffListId);
  const queryData = useSelector(state => state.staff.staffQueryData);
  const userInfo = useSelector(state => state.user.userInfo);
  const dispatch = useDispatch();

  // click button delete
  const onDelete = () => {
    if (staffListId.length > 0) {
      const data = {
        list_id: staffListId,
        field_name: 'staff_id',
        table: 'staff',
        update_user: userInfo.user_id,
        permission: ['STAFF_DELETE']
      };
      commonService.commonDelete(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            dispatch(staffThunk.staffList(queryData));
            dispatch(setStaffListId([]));
            message.success(lang.delete_success);
          }
        }
      })
    }
  };

  const onUpdateActiveStatus = (status) => {
    if (staffListId.length > 0) {
      const data = {
        list_id: staffListId,
        field_name: 'staff_id',
        table: 'staff',
        status,
        update_user: userInfo.user_id,
        permission: ['STAFF_EDIT']
      };
      commonService.updateActiveStatus(data).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_update) {
            dispatch(staffThunk.staffList(queryData));
            message.success(lang.save_success);
          }
        }
      });
    }
  }

  const onExportData = () => {
    dispatch(setLoading(true));
    const data = { staff_id: staffListId }
    staffExport(data).then(res => {
      if (res && res.data) {
        dispatch(setLoading(false));
        window.location.href = res.data.file;
      }
    }).finally(() => {
      dispatch(setLoading(false))
    });
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <CheckPermission permission={['STAFF_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_ACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_EDIT']}>
          <Popconfirm
            placement="top"
            title={lang.sure_save}
            onConfirm={() => onUpdateActiveStatus(c.IS_DEACTIVE)}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.de_active}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_EXPORT']}>
          <a onClick={onExportData}>
            {lang.export_excel}
          </a>
        </CheckPermission>
      </Menu.Item>
      <Menu.Item>
        <CheckPermission permission={['STAFF_DELETE']}>
          <Popconfirm
            placement="top"
            title={lang.sure_delete}
            onConfirm={onDelete}
            okText={lang.ok}
            cancelText={lang.cancel}
          >
            <a >
              {lang.delete}
            </a>
          </Popconfirm>
        </CheckPermission>
      </Menu.Item>
    </Menu>
  );

  return (
    staffListId.length > 0 &&
    <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']}>
      <Button size="large" type="primary" icon={<SettingOutlined />} />
    </Dropdown>
  );
};

export default StaffActionChecked;
