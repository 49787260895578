
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Route, useLocation } from 'react-router-dom';
import Routes from '../../routes';
import { Breadcrumb, Layout } from 'antd';
const { Content } = Layout;

const BreadCrumbs = (props) => {
  // const { collapsed } = props;
  const isHome = useSelector(state => state.common.isHome);
  // const role = useSelector(state => state.role)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname && document.querySelector('.ant-breadcrumb-separator')) {
      document.querySelector('.ant-breadcrumb-separator').innerText = '»';
    }
  }, [location.pathname])

  return (
    !isHome &&
    <Content
      style={{
        margin: '65px 16px 10px 16px',
        padding: '5px 16px'
        // marginLeft: !collapsed ? 230 : 60
      }}
      className="bread-crumbs"
    >
      <Breadcrumb>
        {
          Routes && Routes.map(({ path, name, Component }, key) => (
            <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = Routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                        (path, param) => path.replace(
                          `:${param}`, props.match.params[param]
                        ), path
                      )
                      : path,
                    ...rest
                  }));
                if (crumbs.length <= 1) {
                  return null;
                }
                return (
                  crumbs.map(({ name, path }, key) => {
                    return key + 1 === crumbs.length
                      ? <Breadcrumb.Item key={key}>{name}</Breadcrumb.Item>
                      : (
                        <Breadcrumb.Item key={key}>
                          <Link key={key} to={path}>
                            {name}
                          </Link>
                        </Breadcrumb.Item>
                      );
                  })
                );
              }}
            />
          ))
        }
      </Breadcrumb>
    </Content>
  );
};

export default BreadCrumbs;
