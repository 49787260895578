import { createSlice } from '@reduxjs/toolkit';
import * as giftCardSettingThunk from './thunk'
import * as c from '../../constants/common';

export const giftCardSettingSlice = createSlice({
  name: 'giftCardSetting',
  initialState: {
    giftCardSettingData: {},
    giftCardSettingBackgroundAlbumList: [],
    giftCardSettingSelectBackgroundImage: [],
    giftCardSettingBackground: {},
    backgroundQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: 12,
      gift_card_background_album_id: ''
    }
  },
  reducers: {
    setBackgroundQueryData: (state, action) => {
      state.backgroundQueryData = action.payload;
    },
    setGiftCardSettingSelectBackgroundImage: (state, action) => {
      state.giftCardSettingSelectBackgroundImage = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [giftCardSettingThunk.giftCardSettingThunk.fulfilled]: (state, action) => {
      state.giftCardSettingData = action.payload;
    },
    [giftCardSettingThunk.giftCardSettingBackgroundAlbumThunk.fulfilled]: (state, action) => {
      state.giftCardSettingBackgroundAlbumList = action.payload;
    },
    [giftCardSettingThunk.giftCardSettingBackgroundThunk.fulfilled]: (state, action) => {
      state.giftCardSettingBackground = action.payload;
    }
  }
});

export const {
  setBackgroundQueryData,
  setGiftCardSettingSelectBackgroundImage
} = giftCardSettingSlice.actions;

export default giftCardSettingSlice.reducer;
