export const API_VERSION = process.env.REACT_APP_API_VERSION || 'v1';
export const ROOT_API = process.env.REACT_APP_ROOT_API || 'http://localhost:4000';
export const API_URL = `${ROOT_API}/${API_VERSION}`;
export const ROOT_IMAGE = `${ROOT_API}/files/images`;
export const ROOT_FILE_PDF = `${ROOT_API}/files/pdf`;
export const APP_TITLE = 'Hexcore';
export const MENU_TYPE_LEFT = 'left';
export const MENU_TYPE_GRID = 'grid';
export const MENU_TYPE_DROPDOWN = 'dropdown';
export const INTERVAL = {
    APPOINTMENT_LIST: 20000
}
export const TIME_ZONE = 'Asia/Ho_Chi_Minh'
