/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../../language/language';
import { Table, Row, Col } from 'antd';
import { useSelector } from 'react-redux';

const UserDetailBranchRole = (props) => {
    const { dataDetail } = props;
    const branchByUser = useSelector(state => state.branch.branchByUser)

    const dataSource = (data = null) => {
        let result = [];
        if (data && data.user_branch_role_list &&
            data.user_branch_role_list.length > 0
        ) {
            result = data.user_branch_role_list.map((item, index) => {
                return {
                    key: index,
                    branch: item.branch.label,
                    role: item.role.label
                }
            })
        }
        return result.filter(e => branchByUser.some(i => e.branch === i.branch_name));
    }

    const columns = [
        {
            title: lang.branch,
            dataIndex: 'branch',
            key: 'branch',
            ellipsis: true,
            textWrap: 'word-break',
            width: 200
        },
        {
            title: lang.role,
            dataIndex: 'role',
            key: 'role',
            textWrap: 'word-break',
            width: 200
        }
    ];
    /// /funtion///////

    /// /render///////
    return (
        <Row className="table-detail">
            <Col xs={24} md={24} lg={24}>
                <Table
                    className="table-normal"
                    pagination={false}
                    size="large"
                    dataSource={dataSource(dataDetail)}
                    columns={columns}
                />
            </Col>
        </Row>
    );
};

export default UserDetailBranchRole;
