import { createSlice } from '@reduxjs/toolkit';
import * as emailThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from '../Common/components/GetInfoUser';

export const emailSlice = createSlice({
  name: 'email',
  initialState: {
    emailHistoryList: {},
    emailHistoryInfo: {},
    emailHistoryId: '',
    emailHistoryListId: [],
    emailCustomerListId: [],
    emailHistoryListAction: [],
    emailHistoryQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      orderBy: '',
      userId: getInfoUser.user_id,
      userIdList: null
    },
    // email sample
    emailSampleList: {},
    emailSampleInfo: {},
    emailSampleId: '',
    emailSampleListId: [],
    emailSampleListAction: [],
    emailSampleQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      orderBy: '',
      userId: getInfoUser.user_id,
      userIdList: null,
      isActive: null
    }
  },
  reducers: {
    setEmailCustomerListId: (state, action) => {
      state.emailCustomerListId = action.payload;
    },
    clearEmailHistoryList: (state, action) => {
      state.emailHistoryList = {};
    },
    clearEmailHistoryRow: (state, action) => {
      state.emailHistoryList.rows = [];
    },
    setEmailHistoryQueryData: (state, action) => {
      state.emailHistoryQueryData = action.payload;
    },
    setEmailHistoryListId: (state, action) => {
      state.emailHistoryListId = action.payload;
    },
    clearEmailHistoryId: (state, action) => {
      state.emailHistoryId = '';
    },
    setEmailHistoryId: (state, action) => {
      state.emailHistoryId = action.payload;
    },
    // email sample
    clearEmailSampleList: (state, action) => {
      state.emailSampleList = {};
    },
    clearEmailSampleRow: (state, action) => {
      state.emailSampleList.rows = [];
    },
    setEmailSampleQueryData: (state, action) => {
      state.emailSampleQueryData = action.payload;
    },
    setEmailSampleListId: (state, action) => {
      state.emailSampleListId = action.payload;
    },
    clearEmailSampleId: (state, action) => {
      state.emailSampleId = '';
    },
    setEmailSampleId: (state, action) => {
      state.emailSampleId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [emailThunk.emailHistoryList.fulfilled]: (state, action) => {
      state.emailHistoryList = action.payload;
    },
    [emailThunk.emailSampleList.fulfilled]: (state, action) => {
      state.emailSampleList = action.payload;
    }
  }
});

export const {
  clearEmailHistoryList,
  clearEmailHistoryRow,
  setEmailHistoryQueryData,
  setEmailHistoryListId,
  clearEmailHistoryId,
  setEmailHistoryId,
  // email sample
  clearEmailSampleList,
  clearEmailSampleRow,
  setEmailSampleQueryData,
  setEmailSampleListId,
  clearEmailSampleId,
  setEmailSampleId,
  setEmailCustomerListId
} = emailSlice.actions;

export default emailSlice.reducer;
