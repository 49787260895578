import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as staffService from '../../service';
import { setLoading, setIsClearImage } from '../../../Common/slice';
import { clearStaffId } from '../../slice';
import CheckPermission from '../../../Common/components/CheckPermission';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
import * as staffThunk from '../../thunk';
import moment from 'moment';
import { Drawer, Space, Button, Tabs, Form, message } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import StaffActionInfo from './StaffActionInfo';
// import StaffActionBranchTitle from './StaffActionBranchTitle';
import StaffActionService from './StaffActionService';
import { RandomCode } from '../../../Common/components/RandomCode';

const { TabPane } = Tabs;

const StaffAction = (props) => {
  const dispatch = useDispatch();
  const [dataEdit, setDataEdit] = useState({});
  const queryData = useSelector(state => state.staff.staffQueryData);
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const staffId = useSelector(state => state.staff.staffId);
  const serviceListAction = useSelector(state => state.staff.serviceListAction);
  const [actionType, setActionType] = useState('');
  const [branchId, setBranchId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [countSubmit, setCountSubmit] = useState(0);
  // const [addressData, setAddressData] = useState({});
  const [clearAddress, setClearAddress] = useState('');
  const [checkedService, setCheckedService] = useState([]);
  // const branchByUser = useSelector(state => state.branch.branchByUser)
  const branchLocal = JSON.parse(localStorage.getItem('branch'));
  const [dataAvatar, setDataAvatar] = useState({});
  const [valuesForm, setValuesForm] = useState({});

  useEffect(() => {
    dispatch(staffThunk.serviceListAction());
  }, []);

  useEffect(() => {
    if (staffId) {
      dispatch(setLoading(true));
      staffService.staffDetail({ staff_id: staffId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          setCheckedService(data.service_id_list.map(x => parseInt(x)))
          form.setFieldsValue({
            province: data.province_id || null,
            district: data.district_id || null,
            ward: data.ward_id || null,
            staff_code: data.staff_code,
            staff_fullname: data.staff_fullname,
            staff_phone_number: data.staff_phone_number,
            staff_email: data.staff_email,
            staff_note: data.staff_note,
            staff_address: data.staff_address,
            is_active: data.is_active === c.IS_ACTIVE,
            staff_birthday: data.staff_birthday ? moment(data.staff_birthday, 'YYYY-MM-DD') : null,
            branch: data.branch_id || null,
            staff_facebook: data.staff_facebook,
            staff_gender: data.staff_gender,
            staff_id_card: data.staff_id_card,
            title: data.staff_title_id || null,
            user: data.user_id ? parseInt(data.user_id) : null,
            staff_type: data.staff_type_id,
            staff_allow_checkin: data.staff_allow_checkin === 1,
            staff_allow_booking: data.staff_allow_booking === 1,
            is_technician: data.is_technician === 1

            // staff_branch_title_list: data.staff_branch_title_list.filter(e => branchByUser.some(i => parseInt(e.branch.value) === i.branch_id)).map((item) => {
            //   return { title: item.title.value ? parseInt(item.title.value) : null, branch: item.branch.value ? parseInt(item.branch.value) : null }
            // }) || null
          });
          setDataEdit(data)
          setBranchId(data.branch_id)
          setDataAvatar({ image_gallery_name: data.staff_avatar_name, image_gallery_url: data.staff_avatar })
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        staff_branch: branchLocal.branch_name
      });
    }
  }, [staffId]);
  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!staffId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ staff_code: RandomCode() });
  };

  const onSubmit = (data) => {
    if (
      !!data.staff_phone_number && !!data.staff_phone_number2 &&
      data.staff_phone_number === data.staff_phone_number2) {
      message.error(lang.phone_no_duplicate);
      return;
    }

    setCountSubmit(1);

    dispatch(setLoading(true));
    const method = staffId ? 'PUT' : 'POST';
    const dataReq = {
      staff_id: staffId || '',
      staff_code: data.staff_code || '',
      staff_fullname: data.staff_fullname || '',
      staff_phone_number: data.staff_phone_number || '',
      staff_note: data.staff_note || '',
      staff_address: data.staff_address || '',
      staff_email: data.staff_email || '',
      staff_birthday: data.staff_birthday && data.staff_birthday !== 'Invalid date' ? moment(data.staff_birthday).format('YYYY-MM-DD') : '',
      province_id: data.province || '',
      district_id: data.district || '',
      ward_id: data.ward || '',
      branch_id: staffId ? branchId : data.branch || '',
      create_user: userInfo.user_id || '',
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      staff_facebook: data.staff_facebook || '',
      staff_gender: data.staff_gender || null,
      staff_avatar: dataAvatar.image_gallery_name || '',
      staff_id_card: data.staff_id_card || '',
      user_id: data.user || '',
      staff_title_id: data.title || null,
      staff_type_id: data.staff_type,
      service_id_list: checkedService,
      staff_allow_checkin: data.staff_allow_checkin ? 1 : 0,
      staff_allow_booking: data.staff_allow_booking ? 1 : 0,
      is_technician: data.is_technician ? 1 : 0
    };

    staffService.staffCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));

        // check exist
        if (data.staff_phone_number) {
          message.error(lang.phone_number + data.staff_phone_number + lang.exist);
        } else if (data.staff_code) {
          message.error(lang.staff_code + lang.exist);
        } else if (data.staff_fullname) {
          message.error(lang.staff_fullname + lang.exist);
        } else if (data.staff_birthday) {
          message.error(lang.staff_birthday + lang.exist);
        } else if (data.staff_email) {
          message.error(lang.email + lang.exist);
        }
        // luu thanh cong
        if (data.staff_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!staffId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(staffThunk.staffList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    genCode();
    dispatch(clearStaffId(''));
    dispatch(clearStaffId(''));
    form.resetFields();
    setBranchId('')
    dispatch(setIsClearImage(true))
    setClearAddress(Date.parse(new Date()));
    setCheckedService([]);
    setDataAvatar({})
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <>
        <CheckPermission permission={['STAFF_ADD']}>
          <Button size="large" type="primary" id="staffAction" onClick={showModal} icon={<PlusOutlined st />} />
        </CheckPermission>
        <Drawer
          title={!staffId
            ? lang.add + lang.staff.toLowerCase()
            : lang.edit + lang.staff.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
            initialValues={{
              is_active: c.IS_ACTIVE
            }}
            onValuesChange={(v) => setValuesForm(v)}
          >
            <Tabs style={{ paddingTop: 0 }} defaultActiveKey={`1${dataEdit.staff_id}`} >
              <TabPane forceRender style={{ paddingTop: 0 }} tab={lang.staff_info} key={`1${dataEdit.staff_id}`}>
                <StaffActionInfo
                  countSubmit={countSubmit}
                  clearAddress={clearAddress}
                  dataEdit={dataEdit}
                  dataAvatar={dataAvatar}
                  setDataAvatar={setDataAvatar}
                  form={form}
                />
              </TabPane>
              {/* <TabPane forceRender tab={lang.branch_and_title} key={`2${dataEdit.staff_id}`}>
                <StaffActionBranchTitle
                  dataEdit={dataEdit}
                  countSubmit={countSubmit}
                />
              </TabPane> */}
              {(valuesForm.is_technician === true || form.getFieldValue('is_technician') === true) && <TabPane forceRender tab={lang.service} key={`3${dataEdit.staff_id}`}>
                <StaffActionService
                  dataEdit={dataEdit}
                  countSubmit={countSubmit}
                  dataTree={serviceListAction}
                  checkedService={checkedService}
                  setCheckedService={setCheckedService}
                />
              </TabPane>}
            </Tabs>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default StaffAction;
