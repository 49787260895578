/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import { updateTitle, setLoadingTable } from '../../../Common/slice';
import { setCheckinUserId, setCheckinUserQueryData, setCheckinUserListId } from '../../slice';
import * as checkinUserThunk from '../../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import { Row, Col, Input, Space } from 'antd';
import AntdToggleColumn from '../../../Common/components/AntdTable/AntdToggleColumn';
import CheckinUserFilter from './CheckinUserFilter';
import * as c from '../../../../constants/common';
import CheckinUserAction from './CheckinUserAction';
import CheckinUserDetail from './CheckinUserDetail';
import CheckinUserActionChecked from './CheckinUserActionChecked';

const { Search } = Input;

const CheckinUser = (props) => {
  const dispatch = useDispatch();
  const checkinUserQueryData = useSelector(state => state.checkin.checkinUserQueryData);
  const checkinUserListId = useSelector(state => state.checkin.checkinUserListId);
  const dataList = useSelector(state => state.checkin.checkinUserList);
  const [listNoSelect, setListNoSelect] = useState([]);
  // const currentBranch = JSON.parse(localStorage.getItem('branch'))

  useEffect(() => {
    return function cleanup() {
      dispatch(setCheckinUserListId([]));
      dispatch(setCheckinUserId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.setting));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(checkinUserQueryData);
  }, [checkinUserQueryData]);

  useEffect(() => {
    if (dataList.rows && dataList.rows.length > 0) {
      const data = [...listNoSelect]
      dataList.rows.map((item) => {
        if (item.is_main === 1) {
          data.push(item.checkinUser_id)
        }
      })
      setListNoSelect(data)
    }
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    dispatch(checkinUserThunk.checkinUserList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setCheckinUserQueryData({ ...checkinUserQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.checkin_user}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_checkin_user}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <CheckinUserActionChecked />
                <CheckinUserAction />
                <CheckinUserFilter />
                <AntdToggleColumn
                  data={dataList}
                  queryData={checkinUserQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={checkinUserListId}
            queryData={checkinUserQueryData}
            keyField="user_id"
            clickField="user_code"
            setListIdChecked={setCheckinUserListId}
            setQueryData={setCheckinUserQueryData}
            expandedRowRender={(record) => <CheckinUserDetail data={record} queryData={checkinUserQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CheckinUser;
