import { createAsyncThunk } from '@reduxjs/toolkit';
import * as staffService from './service';

export const staffTypeList = createAsyncThunk(
  'staffType/list',
  async (data, thunkAPI) => {
    const response = await staffService.staffTypeList(data);
    return response.data;
  }
);
