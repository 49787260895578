import CallApi from '../../utils/CallAPI';
import {
  EMAIL_SAMPLE_LIST,
  EMAIL_HISTORY_LIST,
  EMAIL_SAMPLE_DETAIL,
  EMAIL_SETTING
} from './constants/endpoint';

export const emailHistoryList = (data) => {
  return CallApi(EMAIL_HISTORY_LIST, 'GET', data)
};
export const emailHistoryCreate = (data) => {
  return CallApi(EMAIL_HISTORY_LIST, 'POST', data)
};

export const emailSampleList = (data) => {
  return CallApi(EMAIL_SAMPLE_LIST, 'GET', data)
};
export const emailSampleCreateUpdate = (data, method) => {
  return CallApi(EMAIL_SAMPLE_LIST, method, data)
};
export const emailSampleDetail = (data) => {
  return CallApi(EMAIL_SAMPLE_DETAIL, 'GET', data)
};
export const emailSetting = (data, method) => {
  return CallApi(EMAIL_SETTING, method, data)
};
