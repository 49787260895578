/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import noImage from '../../../../assets/images/default-user.png'
import { List, Row, Col, Typography, Image } from 'antd';
import { formatDate, formatPhone, formatIsActive } from '../../../Common/components/FormatData';
const { Text } = Typography;

const StaffDetailInfo = (props) => {
    const { data } = props;

    // const color = useSelector(state => state.user.userInfo.theme_color);

    /// /render///////
    return (
        <Row>
            <Col xs={8} md={4} lg={4}>
                <Image
                    width="100%"
                    className="mb-15 img-avatar"
                    preview={false}
                    src={data.staff_avatar || noImage}
                />
            </Col>
            <Col xs={24} md={20} lg={20} className="pl-15">
                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'staff_id' &&
                                x !== 'key' &&
                                x !== 'staff_avatar' &&
                                x !== 'user_id' &&
                                x !== 'branch_id' &&
                                x !== 'staff_title_id' &&
                                x !== 'staff_type_id'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    (
                                        item === 'create_date' ||
                                        item === 'staff_birthday'
                                    )
                                        ? formatDate(data[item])
                                        : (
                                            item === 'staff_phone_number'
                                        )
                                            ? formatPhone(data[item])
                                            : (item === 'is_active')
                                                ? formatIsActive(data[item])
                                                : (
                                                    item === 'is_technician'
                                                )
                                                    ? <Text>{data[item] === 1 ? lang.yes : lang.no}</Text>
                                                    : (
                                                        item === 'staff_allow_booking'
                                                    )
                                                        ? <Text>{data[item] === 1 ? lang.yes : lang.no}</Text>
                                                        : (
                                                            item === 'staff_allow_checkin'
                                                        )
                                                            ? <Text>{data[item] === 1 ? lang.yes : lang.no}</Text>
                                                            : (String(data[item]).includes('{DELETE}'))
                                                                ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                                : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    );
};

export default StaffDetailInfo;
