import React from 'react';
import { useSelector } from 'react-redux';
import GeneralSettingColor from '../../../../modules/Setting/GeneralSetting/components/GeneralSettingColor';
import GeneralSettingTheme from '../../../../modules/Setting/GeneralSetting/components/GeneralSettingTheme';
import GeneralSettingLeftMenuColor from '../../../../modules/Setting/GeneralSetting/components/GeneralSettingLeftMenuColor';
import lang from '../../../../language/language';

const HeaderRightSetting = (props) => {
  const isHome = useSelector(state => state.common.isHome);

  /// ///// useEffect

  /// //funtion/////

  return (
    !isHome &&
    <div className="modal fade come-from-modal right" id="settingModel" >
    <div className="modal-dialog modal-dialog-scrollable">
      <div className="modal-content">
        <div className="text-dark">
          <ul className="list-group">
            <li className="list-group-item">
              <div className="modal-header border-bottom-0 p-0">
                <h6 className="text-dark modal-title" id="settingModelLabel">{lang.quick_setting}</h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            </li>
          </ul>
          <div className="container mt-3 mb-3">
            <GeneralSettingColor isQuickSetting/>
            <GeneralSettingLeftMenuColor isQuickSetting/>
            <GeneralSettingTheme isQuickSetting/>
          </div>

        </div>
      </div>
    </div>
  </div>
  );
};

export default HeaderRightSetting;
