import { createAsyncThunk } from '@reduxjs/toolkit';
import * as appointmentSettingService from './service';

export const appointmentSettingThunk = createAsyncThunk(
    'appointmentSetting/get',
    async (data, thunkAPI) => {
        const response = await appointmentSettingService.appointmentSetting(data);
        return response.data;
    }
);
