import { createSlice } from '@reduxjs/toolkit';
import * as commonThunk from './thunk';

export const commonSlice = createSlice({
    name: 'common',
    initialState: {
        title: '',
        isHome: null,
        app: [],
        getFileExportDemo: {},
        appList: [],
        loading: false,
        urlImage: [],
        dataImage: [],
        isClearImage: false,
        loadingTable: false,
        userIdAccount: '',
        customerPhoneList: [],
        invoiceList: []
    },
    reducers: {
        updateTitle: (state, action) => {
            state.title = action.payload;
        },
        updateIsHome: (state, action) => {
            state.isHome = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setUrlImage: (state, action) => {
            state.urlImage = action.payload;
        },
        setDataImage: (state, action) => {
            state.dataImage = action.payload;
        },
        setIsClearImage: (state, action) => {
            state.isClearImage = action.payload;
        },
        setLoadingTable: (state, action) => {
            state.loadingTable = action.payload;
        },
        setUserIdAccount: (state, action) => {
            state.userIdAccount = action.payload;
        },
        setInvoiceList: (state, action) => {
            state.invoiceList = action.payload;
        }
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [commonThunk.getFileExportDemo.fulfilled]: (state, action) => {
            state.getFileExportDemo = action.payload;
        },
        [commonThunk.appList.fulfilled]: (state, action) => {
            state.appList = action.payload;
        },
        [commonThunk.customerPhoneList.fulfilled]: (state, action) => {
            state.customerPhoneList = action.payload;
        },
        [commonThunk.getInvoiceList.fulfilled]: (state, action) => {
            state.invoiceList = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateTitle,
    updateIsHome,
    setLoading,
    setUrlImage,
    setDataImage,
    setIsClearImage,
    setLoadingTable,
    setUserIdAccount,
    setInvoiceList
} = commonSlice.actions;

export default commonSlice.reducer;
