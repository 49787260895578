/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setInvoiceQueryData, setInvoiceListId } from '../slice';
import * as invoiceThunk from '../thunk';
// import ServiceFilter from './InvoiceFilter';
// import InvoiceAction from './InvoiceAction/InvoiceAction';
// import ServiceActionChecked from './InvoiceAction/InvoiceActionChecked';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import InvoiceDetail from './InvoiceDetail';
import InvoiceFilter from './InvoiceDetailFilter';
import { Row, Col, Space, Input, Button, message } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import * as c from '../../../constants/common';
import CheckPermission from '../../Common/components/CheckPermission';
import {
  PlusOutlined,
  BarcodeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ROUTE_POS } from '../../Invoice/constants/routes'
import BarcodeReader from 'react-barcode-reader';
const { Search } = Input;

const Invoice = (props) => {
  const dispatch = useDispatch();
  const invoiceQueryData = useSelector(state => state.invoice.invoiceQueryData);
  const invoiceListId = useSelector(state => state.invoice.invoiceListId);
  const dataList = useSelector(state => state.invoice.invoiceList);
  const history = useHistory()
  useEffect(() => {
    return function cleanup() {
      dispatch(setInvoiceListId([]));
      // dispatch(setInvoiceId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.service));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(invoiceQueryData);
  }, [invoiceQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(invoiceThunk.invoiceList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setInvoiceQueryData({ ...invoiceQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  const onScan = (codeScan) => {
    if (codeScan) {
      const code = codeScan.slice(0, 3)
      if (code === c.CODE_TYPE.INVOICE) {
        onSearch(codeScan)
      } else {
        return message.error(lang.code_not_found)
      }
    }
  }

  const onScanError = (err) => {
    console.log(err)
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.invoice}</title>
      </Helmet>
      <Row>
        <BarcodeReader
          onError={onScanError}
          onScan={onScan}
        />
        <Col md={9}>
          <Search
            placeholder={lang.search_invoice}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col md={1} className="pb-15 pl-5">
          <Button size='large' className='pl-10 pr-10 pb-0'>
            <BarcodeOutlined onClick={() => { message.success(lang.barcode_actived) }} style={{ fontSize: 30 }} />
          </Button>
        </Col>
        <Col md={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <CheckPermission permission={['INVOICE_ADD_EDIT']}>
                  <Button type="primary" size="large" onClick={() => history.push(ROUTE_POS)} icon={<PlusOutlined />} />
                </CheckPermission>
                <InvoiceFilter />
                <AntdToggleColumn
                  data={dataList}
                  queryData={invoiceQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col md={24}>
          <AntdTable
            hideCheckbox
            data={dataList}
            listIdChecked={invoiceListId}
            queryData={invoiceQueryData}
            keyField="invoice_id"
            clickField="invoice_code"
            setListIdChecked={setInvoiceListId}
            setQueryData={setInvoiceQueryData}
            expandedRowRender={(record) => <InvoiceDetail data={record} queryData={invoiceQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Invoice;
