import CallApi from '../../utils/CallAPI';
import {
  STAFF,
  STAFF_DETAIL,
  STAFF_EXPORT,
  STAFF_IMPORT,
  SERVICE_LIST_ACTION
} from './constants/endpoint';

export const staffList = (data) => {
  return CallApi(STAFF, 'GET', data)
};

export const staffCreateUpdate = (data, method) => {
  return CallApi(STAFF, method, data)
};

export const staffDetail = (data) => {
  return CallApi(STAFF_DETAIL, 'GET', data)
};

export const staffExport = (data) => {
  return CallApi(STAFF_EXPORT, 'POST', data)
};

export const staffImport = (data) => {
  return CallApi(STAFF_IMPORT, 'POST', data)
};

export const serviceListAction = (data) => {
  return CallApi(SERVICE_LIST_ACTION, 'GET', data)
};
