/* eslint-disable camelcase */
/* eslint-disable no-unmodified-loop-condition */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Table, DatePicker, Space, Button, message, Card, List } from 'antd';
import { updateTitle } from '../../../Common/slice';
import { getStaff } from '../../../Common/service';
import { setLoadingTable, setIsShowAppointmentAction, setDataAddAppointment, setAppointmentId } from './../slice';
import * as c from '../../../../constants/common';
import * as con from '../../../../constants/config';
// import { formatPhone } from '../../../Common/components/FormatData';
import moment from 'moment';
import {
	UserOutlined,
	BarcodeOutlined,
	PlusOutlined,
	ClockCircleOutlined,
	PhoneOutlined,
	DoubleRightOutlined,
	DoubleLeftOutlined,
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import AppointmentAction from './AppointmentAction/AppointmentAction';
import { GetListDateByNumber } from '../../../Common/components/GetListDateByNumber';
import * as appointmentThunk from '../thunk';
import AppointmentAutoComplete from '../../../Common/components/AppointmentAutoComplete';
import { formatPhone } from '../../../Common/components/FormatData';
// import AppointmentFilter from './AppointmentFilter';
import { dayOffSetting } from './../../../Setting/BusinessHours/service';

const AppointmentList = (props) => {
	const currentBranch = JSON.parse(localStorage.getItem('branch'));
	const loadingTable = useSelector((state) => state.appointment.loadingTable);
	const appointmentSetting = useSelector((state) => state.appointmentSetting);
	const generalSetting = useSelector((state) => state.generalSetting);
	const appointmentList = useSelector((state) => state.appointment.appointmentList);
	const appointmentWaitList = useSelector((state) => state.appointment.appointmentWaitList);
	const appointmentQueryData = useSelector((state) => state.appointment.appointmentQueryData);
	const [dayOffList, setDayOffList] = useState([]);
	const getDayOffList = () => {
		dayOffSetting().then((res) => {
			if (res) {
				setDayOffList(res?.data);
			}
		});
	};

	useEffect(() => {
		getDayOffList();
	}, []);

	const currentTime = moment()
		.add(appointmentSetting.appointment_setting_min_booking_time, 'minutes')
		.format('HH:mm')
		.toString();

	const dispatch = useDispatch();
	const [staffList, setStaffList] = useState([
		{
			title: lang.time + ' / ' + lang.staff,
			dataIndex: 'appointment_hour',
			key: 'appointment_hour',
			width: 150,
			fixed: 'left',
			align: 'center',
			ellipsis: true,
		},
	]);
	const [appointmentDate, setAppointmentDate] = useState(moment(new Date(), 'YYYY-MM-DD').tz(con.TIME_ZONE));
	const [isShowWaitList, setIsShowWaitList] = useState(false);

	useEffect(() => {
		getAppointmentList();
		fetchAppointmentWaitList();
	}, [appointmentDate, appointmentQueryData]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			getAppointmentList();
			fetchAppointmentWaitList();
		}, con.INTERVAL.APPOINTMENT_LIST);
		return () => clearInterval(intervalId);
	}, [appointmentDate]);

	useEffect(() => {
		getStaffList();
	}, [appointmentSetting]);

	useEffect(() => {
		dispatch(updateTitle(lang.appointment));
	}, [dispatch]);

	/// /funtion///////

	const onAddAppointment = (data, staff_id) => {
		dispatch(setIsShowAppointmentAction(true));
		dispatch(
			setDataAddAppointment({ ...data, staff_id, appointment_date: moment(appointmentDate).format('YYYY-MM-DD') })
		);
	};

	const onEditAppointment = (data, staffId) => {
		dispatch(setAppointmentId(data.appointment_id));
	};

	const getAppointmentList = () => {
		dispatch(setLoadingTable(true));
		dispatch(
			appointmentThunk.appointmentGetListThunk({
				...appointmentQueryData,
				appointment_date: moment(appointmentDate).format('YYYY-MM-DD'),
			})
		);
	};

	const fetchAppointmentWaitList = () => {
		const reqData = { appointment_date: moment(appointmentDate).format('YYYY-MM-DD') };
		dispatch(appointmentThunk.appointmentWaitListThunk(reqData));
	};

	const getStaffList = () => {
		const reqData = { listBranchId: [currentBranch.branch_id] };
		getStaff(reqData).then((res) => {
			const result = [...staffList];
			const { data } = res;
			if (data.length > 0) {
				data
					.filter(
						(e) => e.is_active === 1 && e.is_technician === c.STAFF_TYPE.TECHNICIAN && e.staff_allow_booking === 1
					)
					.map((item) => {
						result.push({
							title: item.staff_fullname,
							dataIndex: item.staff_id,
							key: String(item.staff_id),
							width: 200,
							align: 'center',
						});
					});
			}
			setStaffList(result);
		});
	};

	const checkColorAppointment = (status = '') => {
		let result = '';
		if (status) {
			if (status === c.APPOINTMENT_STATUS.WAIT_CONFIRM) {
				result = 'bg-info';
			} else if (status === c.APPOINTMENT_STATUS.CONFIRMED) {
				result = 'bg-primary';
			} else if (status === c.APPOINTMENT_STATUS.DONE) {
				result = 'bg-success';
			} else if (status === c.APPOINTMENT_STATUS.CANCELLED) {
				result = 'bg-danger';
			} else if (status === c.APPOINTMENT_STATUS.VISITED) {
				result = 'bg-success';
			}
		}
		return result;
	};

	const onClickExpired = () => {
		message.warning(lang.time_expired);
	};

	const customColumn = (columns = []) => {
		const result = [];
		const hourKey = moment(appointmentSetting.appointment_setting_fromtime, 'hh:mm').format('hh:mm A');
		const hourKeyList = [];

		if (columns.length > 0) {
			columns.map((item) => {
				return result.push({
					title: item.title,
					dataIndex: item.dataIndex,
					key: item.key,
					width: item.width,
					align: item.align,
					fixed: item.key === 'appointment_hour' ? 'left' : null,
					render: (text, record, index) => {
						const appointment = record[item.key];
						if (item.key === 'appointment_hour') {
							// check các mốc thời gian được setting để tô đậm
							const newHourKey = moment(hourKey, 'hh:mm')
								.add(appointmentSetting.appointment_setting_range * index, 'minutes')
								.format('hh:mm A');
							hourKeyList.push(newHourKey);
							if (hourKeyList.includes(record.appointment_hour)) {
								return <div className="appointment-hour-row font-weight-bold">{text}</div>;
							} else {
								return <div className="appointment-hour-row ">{text}</div>;
							}
						} else if (appointment) {
							const end = moment(appointment.appointment_end_hour, 'hh:mm A');
							const start = moment(appointment.appointment_hour, 'hh:mm A');
							const height = parseInt(end.diff(start, 'minutes')) / 15 + 1;
							// khi có lịch hẹn
							return (
								<div
									id="appointmentAction"
									style={{ height: height * 65 + height - 2 - 65 }}
									className={`cursor-pointer appointment-ticket f-13 pl-5 pr-5 text-left text-white ${checkColorAppointment(
										appointment.appointment_status
									)}`}
									onClick={() => onEditAppointment(appointment, item.key)}
								>
									<div>
										<BarcodeOutlined /> {appointment.appointment_code} -{' '}
										{c.APPOINTMENT_STATUS_LIST.filter((e) => e.value === appointment.appointment_status)[0].label}
									</div>
									<div>
										<ClockCircleOutlined /> {moment(appointment.appointment_hour, 'hh:mm').format('hh:mm A')} -{' '}
										{moment(appointment.appointment_end_hour, 'hh:mm').format('hh:mm A')}
									</div>
									<div>
										<UserOutlined /> {appointment.customer_fullname}
									</div>
									{/* <div><PhoneOutlined /> {formatPhone(appointment.customer_phone_number)}</div> */}
									{/* <div><Text className="text-white" ellipsis={true}><ContainerOutlined /> {appointment.staff_service.map(o => o.service_name).join(', ')}</Text></div> */}
									{/* <div><SnippetsOutlined /> {appointment.appointment_note}</div> */}
								</div>
							);
						} else {
							if (!record?.allowBook) {
								return <div onClick={onClickExpired} className="cell-appointment-expired"></div>;
							} else {
								if (
									record.key < currentTime &&
									moment(appointmentDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
								) {
									// khi hết giờ đặt lịch
									return <div onClick={onClickExpired} className="cell-appointment-expired"></div>;
								} else {
									// khi chưa có lịch hẹn
									return (
										<CheckPermission permission={['APPOINTMENT_ADD']}>
											<div
												className="cell-appointment-add cursor-pointer"
												id="appointmentAction"
												onClick={() => onAddAppointment(record, item.key)}
											></div>
										</CheckPermission>
									);
								}
							}
						}
					},
				});
			});
		}

		return result;
	};

	const onShowWaitList = () => {
		setIsShowWaitList(!isShowWaitList);
	};

	// Hàm chuyển đổi 'MM-DD' thành 'YYYY-MM-DD'
	function convertToDateString(monthDay) {
		const year = new Date().getFullYear(); // Sử dụng năm hiện tại
		return `${year}-${monthDay}`;
	}

	/// /render///////
	return (
		<div name="content" className="content">
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{APP_TITLE} - {lang.appointment}
				</title>
			</Helmet>
			<Row gutter={12}>
				<Col md={14}>
					<Space>
						<AppointmentAction staffList={staffList} fetchAppointmentWaitList={fetchAppointmentWaitList} />
						<DatePicker
							allowClear={false}
							size="large"
							style={{ marginBottom: 10 }}
							defaultValue={appointmentDate}
							format={generalSetting.general_setting_date_format}
							onChange={(value) => setAppointmentDate(value)}
							disabledDate={(current) => {
								const listDayOff = dayOffList
									?.filter((x) => x?.day_off_setting_status === 1)
									?.map((a) => moment(new Date().getFullYear() + '-' + a?.day_off_setting_date).format('YYYY-MM-DD'));
								const allowDate = GetListDateByNumber(appointmentSetting.appointment_setting_booking_date_limit);
								const filteredAllowDate = allowDate?.filter((date) => !listDayOff.includes(date));
								return !filteredAllowDate?.includes(moment(current).format('YYYY-MM-DD'));
							}}
						/>
						<AppointmentAutoComplete
							label={lang.search_appointment_by_customer_phone}
							isHideLabel
							required
							showSearch
							allowClear
							onSelect={(data) => onEditAppointment(data)}
						/>
					</Space>
				</Col>
				<Col md={10}>
					<Row type="flex" justify="end">
						<Col>
							<Space>
								<Button
									size="large"
									icon={<PlusOutlined />}
									onClick={() => dispatch(setIsShowAppointmentAction(true))}
								/>
								{/* <AppointmentFilter /> */}
								<Button
									size="large"
									icon={!isShowWaitList ? <DoubleLeftOutlined /> : <DoubleRightOutlined onClick={onShowWaitList} />}
									onClick={onShowWaitList}
								/>
							</Space>
						</Col>
					</Row>
				</Col>
				<Col className="mt-5" xs={24} md={isShowWaitList ? 20 : 24}>
					<Table
						className="table-appointment"
						dataSource={appointmentList || null}
						columns={customColumn(staffList)}
						pagination={false}
						bordered
						loading={loadingTable}
						scroll={{ y: '73vh' }}
					/>
				</Col>
				<Col className="mt-5" xs={24} md={isShowWaitList ? 4 : 0}>
					<Card
						headStyle={{ textAlign: 'center' }}
						className="card-appointment"
						title={isShowWaitList ? lang.wait_appointment : ''}
					>
						{isShowWaitList && (
							<List
								style={{ height: '71.8vh', overflowY: 'auto' }}
								itemLayout="horizontal"
								dataSource={appointmentWaitList}
								renderItem={(item) => {
									return (
										<List.Item>
											<div
												id="appointmentAction"
												className="appointment-wait-ticket cursor-pointer"
												onClick={() => onEditAppointment(item, item.key)}
											>
												<div>
													<BarcodeOutlined /> {item.appointment_code} - <PhoneOutlined />{' '}
													{formatPhone(item.customer_phone_number)}
												</div>
												<div>
													<ClockCircleOutlined />{' '}
													<span className="text-danger">
														{moment(item.appointment_hour, 'hh:mm').format('hh:mm A')}
													</span>{' '}
													- {moment(item.appointment_end_hour, 'hh:mm').format('hh:mm A')}
												</div>
												<div>
													<UserOutlined /> {item.customer_fullname}
												</div>
											</div>
										</List.Item>
									);
								}}
							/>
						)}
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default AppointmentList;
