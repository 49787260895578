import React, { useEffect, useState } from 'react';
import lang from '../../../language/language';
import { getCustomerGroup } from '../service';
import { Form, Select } from 'antd';
import * as c from '../../../constants/common';
const { Option } = Select;

const CustomerGroupSelect = (props) => {
  const { listIdRemove } = props;
  const [options, setOptions] = useState([])
  useEffect(() => {
    getCustomerGroup().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  // const validateCustomerGroupSelect = (rule, value, getFieldValue) => {
  //   const arr = options.filter(i => value.includes(i.customer_group_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
  //   const text = arr.map((y) => y.customer_group_name)
  //   if (arr.length > 0 && value.length > 0) {
  //     return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
  //   }
  //   return Promise.resolve();
  // }

  /// render/////
  return (
    <Form.Item
      label={lang.customer_group}
      name="customer_group"
    // rules={[
    //   ({ getFieldValue }) => ({
    //     validator: (rule, value) => validateCustomerGroupSelect(rule, value, getFieldValue)
    //   })
    // ]}
    >
      <Select
        size="large"
        showSearch
        allowClear
        mode="multiple"
        maxTagCount='responsive'
        placeholder={lang.customer_group}
      >
        {options.map((item) => {
          return <Option
            className={listIdRemove && listIdRemove.length > 0 && listIdRemove.filter(e => e !== undefined).includes(item.customer_group_id) ? 'd-none' : ''}
            key={item.customer_group_id} value={item.customer_group_id}>
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.customer_group_name}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default CustomerGroupSelect;
