/* eslint-disable react/display-name */
import React from 'react';
import lang from './language/language';
import * as settingRoute from './modules/Setting/GeneralSetting/constants/routes';
import * as userRoute from './modules/Setting/User/constants/routes';
import * as roleRoute from './modules/Setting/Role/constants/routes';
import * as branchRoute from './modules/Setting/Branch/constants/routes';
import * as dashboardRoute from './modules/DashBoard/constants/routes';
import * as customerRoute from './modules/Customer/constants/routes';
import * as customerGroupRoute from './modules/CustomerGroup/constants/routes';
import * as serviceGroupRoute from './modules/ServiceGroup/constants/routes';
import * as serviceRoute from './modules/Service/constants/routes';
import * as staffRoute from './modules/Staff/constants/routes';
import * as appointmentRoute from './modules/Appointment/AppointmentList/constants/routes';
import * as appointmentSettingRoute from './modules/Appointment/AppointmentSetting/constants/routes';
import * as emailRoute from './modules/Email/constants/routes';
import * as smsRoute from './modules/SMS/constants/routes';
import * as posSettingRoute from './modules/Setting/POSSetting/constants/routes';
import * as giftCardRoute from './modules/GiftCard/constants/routes';
import * as giftCardSettingRoute from './modules/GiftCardSetting/constants/routes';
import * as imageGalleryRoute from './modules/ImageGallery/constants/routes';
import * as invoiceRoute from './modules/Invoice/constants/routes';
import * as checkinRoute from './modules/Checkin/constants/routes';
import * as staffTitleRoute from './modules/StaffTitle/constants/routes';
import * as staffTypeRoute from './modules/StaffType/constants/routes';
import * as timeKeeping from './modules/TimeKeeping/constants/routes';
import * as turnRoute from './modules/TurnSetting/constants/routes';
import * as reportIncomeRoute from './modules/ReportIncome/constants/routes';
import * as reportAppointmentRoute from './modules/ReportAppointment/constants/routes';
import * as businessHoursRoute from './modules/Setting/BusinessHours/constants/routes';

// customer
import CustomerListPage from './modules/Customer/pages/CustomerListPage';
/** Login */
// Home
// import GridMenu from './themes/Antd/Menu/GridMenu'

// customer group
import CustomerGroupListPage from './modules/CustomerGroup/pages/CustomerGroupListPage';

// branch
import BranchListPage from './modules/Setting/Branch/pages/BranchListPage';

// role
import RoleListPage from './modules/Setting/Role/pages/RoleListPage';

// user
import UserListPage from './modules/Setting/User/pages/UserListPage';

// Setting
import GeneralSettingPage from './modules/Setting/GeneralSetting/pages/GeneralSettingPage';

// Dardboard
import DashBoardPage from './modules/DashBoard/pages/DashBoardPage';

// service group
import ServiceGroupListPage from './modules/ServiceGroup/pages/ServiceGroupListPage';

// service
import ServiceListPage from './modules/Service/pages/ServiceListPage';
// title
import StaffTitleListPage from './modules/StaffTitle/pages/StaffTitleListPage';

// staff
import StaffListPage from './modules/Staff/pages/StaffListPage';

// appointment
import AppointmentPage from './modules/Appointment/AppointmentList/pages/AppointmentListPage';
import AppointmentSettingPage from './modules/Appointment/AppointmentSetting/pages/AppointmentSettingPage';

// email
import EmailHistoryListPage from './modules/Email/pages/EmailHistoryListPage';
import EmailSampleListPage from './modules/Email/pages/EmailSampleListPage';
import EmailSettingPage from './modules/Email/pages/EmailSettingPage';

// sms
import SMSHistoryListPage from './modules/SMS/pages/SMSHistoryListPage';
import SMSSampleListPage from './modules/SMS/pages/SMSSampleListPage';

// pos
import InvoiceActionPage from './modules/Invoice/pages/InvoiceActionPage';
import POSSettingPage from './modules/Setting/POSSetting/pages/POSSettingPage';

// gift card
import GiftCardListPage from './modules/GiftCard/pages/GiftCardListPage';

// gift card setting
import GiftCardSettingPage from './modules/GiftCardSetting/pages/GiftCardSettingPage';

// image gallery
import ImageGalleryPage from './modules/ImageGallery/pages/ImageGalleryListPage';

// invoice
import InvoiceListPage from './modules/Invoice/pages/InvoiceListPage';

// checkin account
import CheckinUserPage from './modules/Checkin/pages/CheckinUserPage';
import CheckinSettingPage from './modules/Checkin/pages/CheckinSettingPage';
// staff type
import StaffTypeListPage from './modules/StaffType/pages/StaffTypeListPage';

// timekeeping
import TimeKeepingPage from './modules/TimeKeeping/pages/StaffTimeKeepingPage';

// turn
import TurnSettingPage from './modules/TurnSetting/pages/TurnSettingPage';

// report income
import ReportIncomePage from './modules/ReportIncome/pages/ReportIncomePage';
import ReportAppointmentPage from './modules/ReportAppointment/pages/ReportAppointmentPage';

import BusinessHours from './modules/Setting/BusinessHours';

const Routes = [
	{
		path: '/',
		exact: true,
		main: ({ match, props }) => <DashBoardPage match={match} props={props} />,
		name: lang.home,
		permission: 'ALL',
	},
	{
		path: settingRoute.ROUTE_GENERAL_SETTING,
		exact: true,
		main: ({ match, props }) => <GeneralSettingPage match={match} props={props} />,
		name: lang.setting_general,
		permission: 'GENERAL_SETTING_VIEW',
	},
	{
		path: dashboardRoute.ROUTE_DASH_BOARD,
		exact: true,
		main: ({ match, props }) => <DashBoardPage match={match} props={props} />,
		name: lang.dash_board,
		permission: 'ALL',
	},
	{
		path: userRoute.ROUTE_SETTING_USERS,
		exact: true,
		main: ({ match, props }) => <UserListPage match={match} props={props} />,
		name: lang.user,
		permission: ['USER_VIEW'],
	},
	{
		path: roleRoute.ROUTE_SETTING_ROLES,
		exact: true,
		main: ({ match, props }) => <RoleListPage match={match} props={props} />,
		name: lang.role,
		permission: ['ROLE_VIEW'],
	},
	{
		path: branchRoute.ROUTE_SETTING_BRANCH,
		exact: true,
		main: ({ match, props }) => <BranchListPage match={match} props={props} />,
		name: lang.branch,
		permission: ['BRANCH_VIEW'],
	},
	{
		path: customerRoute.ROUTE_CUSTOMER,
		exact: true,
		main: ({ match, props }) => <CustomerListPage match={match} props={props} />,
		name: lang.customer_list,
		permission: ['CUSTOMER_VIEW'],
	},
	{
		path: customerGroupRoute.ROUTE_CUSTOMER_GROUP,
		exact: true,
		main: ({ match, props }) => <CustomerGroupListPage match={match} props={props} />,
		name: lang.customer_group,
		permission: ['CUSTOMER_GROUP_VIEW'],
	},
	{
		path: serviceGroupRoute.ROUTE_SERVICE_GROUP,
		exact: true,
		main: ({ match, props }) => <ServiceGroupListPage match={match} props={props} />,
		name: lang.service_group,
		permission: ['SERVICE_GROUP_VIEW'],
	},
	{
		path: serviceRoute.ROUTE_SERVICE,
		exact: true,
		main: ({ match, props }) => <ServiceListPage match={match} props={props} />,
		name: lang.service,
		permission: ['SERVICE_VIEW'],
	},
	{
		path: staffTitleRoute.ROUTE_STAFF_TITLE,
		exact: true,
		main: ({ match, props }) => <StaffTitleListPage match={match} props={props} />,
		name: lang.title,
		permission: ['STAFF_TITLE_VIEW'],
	},
	{
		path: staffRoute.ROUTE_STAFF,
		exact: true,
		main: ({ match, props }) => <StaffListPage match={match} props={props} />,
		name: lang.staff_list,
		permission: ['STAFF_VIEW'],
	},
	{
		path: appointmentRoute.ROUTE_APPOINTMENT,
		exact: true,
		main: ({ match, props }) => <AppointmentPage match={match} props={props} />,
		name: lang.appointment,
		permission: ['APPOINTMENT_VIEW'],
	},
	{
		path: appointmentSettingRoute.ROUTE_APPOINTMENT_SETTING,
		exact: true,
		main: ({ match, props }) => <AppointmentSettingPage match={match} props={props} />,
		name: lang.appointment_setting,
		permission: ['APPOINTMENT_SETTING_VIEW'],
	},
	{
		path: emailRoute.ROUTE_EMAIL_HISTORY,
		exact: true,
		main: ({ match, props }) => <EmailHistoryListPage match={match} props={props} />,
		name: lang.email_history,
		permission: ['EMAIL_HISTORY_VIEW'],
	},
	{
		path: emailRoute.ROUTE_EMAIL_SAMPLE,
		exact: true,
		main: ({ match, props }) => <EmailSampleListPage match={match} props={props} />,
		name: lang.email_sample,
		permission: ['EMAIL_SAMPLE_VIEW'],
	},
	{
		path: emailRoute.ROUTE_EMAIL_SETTING,
		exact: true,
		main: ({ match, props }) => <EmailSettingPage match={match} props={props} />,
		name: lang.email_setting,
		permission: ['EMAIL_SETTING_VIEW'],
	},
	{
		path: smsRoute.ROUTE_SMS_HISTORY,
		exact: true,
		main: ({ match, props }) => <SMSHistoryListPage match={match} props={props} />,
		name: lang.sms_history,
		permission: ['SMS_HISTORY_VIEW'],
	},
	{
		path: smsRoute.ROUTE_SMS_SAMPLE,
		exact: true,
		main: ({ match, props }) => <SMSSampleListPage match={match} props={props} />,
		name: lang.sms_sample,
		permission: ['SMS_SAMPLE_VIEW'],
	},
	{
		path: invoiceRoute.ROUTE_POS,
		exact: true,
		main: ({ match, props }) => <InvoiceActionPage match={match} props={props} />,
		name: lang.pos,
		permission: ['INVOICE_ADD_EDIT'],
	},
	{
		path: posSettingRoute.ROUTE_POS_SETTING,
		exact: true,
		main: ({ match, props }) => <POSSettingPage match={match} props={props} />,
		name: lang.pos_setting,
		permission: ['POS_SETTING_VIEW'],
	},
	{
		path: giftCardRoute.ROUTE_GIFT_CARD,
		exact: true,
		main: ({ match, props }) => <GiftCardListPage match={match} props={props} />,
		name: lang.gift_card_list,
		permission: ['GIFT_CARD_VIEW'],
	},
	{
		path: giftCardSettingRoute.ROUTE_GIFT_CARD_SETTING,
		exact: true,
		main: ({ match, props }) => <GiftCardSettingPage match={match} props={props} />,
		name: lang.gift_card_setting,
		permission: ['GIFT_CARD_SETTING_VIEW'],
	},
	{
		path: imageGalleryRoute.ROUTE_IMAGE_GALLERY,
		exact: true,
		main: ({ match, props }) => <ImageGalleryPage match={match} props={props} />,
		name: lang.image_gallery,
		permission: ['IMAGE_GALLERY_LIST_VIEW'],
	},
	// invoice
	{
		path: invoiceRoute.ROUTE_INVOICE,
		exact: true,
		main: ({ match, props }) => <InvoiceListPage match={match} props={props} />,
		name: lang.invoice,
		permission: ['INVOICE_VIEW'],
	},
	// checkin account
	{
		path: checkinRoute.ROUTE_CHECKIN_USER,
		exact: true,
		main: ({ match, props }) => <CheckinUserPage match={match} props={props} />,
		name: lang.checkin_user,
		permission: ['CHECKIN_USER_VIEW'],
	},
	{
		path: checkinRoute.ROUTE_CHECKIN_SETTING,
		exact: true,
		main: ({ match, props }) => <CheckinSettingPage match={match} props={props} />,
		name: lang.checkin_setting,
		permission: ['CHECKIN_SETTING_VIEW'],
	},
	{
		path: staffTypeRoute.ROUTE_STAFF_TYPE,
		exact: true,
		main: ({ match, props }) => <StaffTypeListPage match={match} props={props} />,
		name: lang.staff_type,
		permission: ['STAFF_TYPE_VIEW'],
	},
	{
		path: timeKeeping.ROUTE_TIMEKEEPING,
		exact: true,
		main: ({ match, props }) => <TimeKeepingPage match={match} props={props} />,
		name: lang.staff_timekeeping_title,
		permission: ['TIME_KEEPING_VIEW'],
	},
	{
		path: turnRoute.ROUTE_TURN_SETTING,
		exact: true,
		main: ({ match, props }) => <TurnSettingPage match={match} props={props} />,
		name: lang.turn_setting,
		permission: ['TURN_SETTING_VIEW'],
	},
	{
		path: reportIncomeRoute.ROUTE_REPORT_INCOME,
		exact: true,
		main: ({ match, props }) => <ReportIncomePage match={match} props={props} />,
		name: lang.report_income,
		permission: ['REPORT_INCOME_VIEW'],
	},
	{
		path: reportAppointmentRoute.ROUTE_REPORT_APPOINTMENT,
		exact: true,
		main: ({ match, props }) => <ReportAppointmentPage match={match} props={props} />,
		name: lang.report_appointment,
		permission: ['REPORT_APPOINTMENT_VIEW'],
	},
	{
		path: businessHoursRoute.ROUTE_BUSINESS_HOURS,
		exact: true,
		main: ({ match, props }) => <BusinessHours match={match} props={props} />,
		name: lang.business_hours,
		permission: ['ALL'],
	},
];
export default Routes;
