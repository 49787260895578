
// service
const rootInvoice = 'invoice'
export const INVOICE = `${rootInvoice}`;
export const INVOICE_DETAIL = `${rootInvoice}/detail`;
export const INVOICE_COPY = `${rootInvoice}/copy`;
export const INVOICE_ITEM = `${rootInvoice}/item`;
export const INVOICE_UPDATE_STATUS = `${rootInvoice}/update-status`;
export const INVOICE_CUSTOM_PROMOTION = `${rootInvoice}/custom-promotion`;
export const INVOICE_PROMOTION = `${rootInvoice}/promotion`;
export const INVOICE_SURCHARGE = `${rootInvoice}/surcharge`;
export const INVOICE_PRINT_TICKET = `${rootInvoice}/print-ticket`;
