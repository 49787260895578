import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic } from 'antd';
import { AppstoreOutlined, FieldTimeOutlined, MessageOutlined } from '@ant-design/icons';

import '../styles/style.css';
import lang from '../../../language/language';
import { setLoading } from '../../Common/slice';
import { getReportPack } from '../service';
import { useDispatch } from 'react-redux';
import { diffInDays } from '../../Common/components/Caculator';
import moment from 'moment';

const DashBoardServicePack = () => {
	const dispatch = useDispatch();
	const [dataServicePack, setDataServicePack] = useState({});

	useEffect(() => {
		getNewReportPack();
	}, []);

	const getNewReportPack = () => {
		dispatch(setLoading(true));
		const reqData = {};
		getReportPack(reqData)
			.then((res) => {
				if (res.data) {
					setDataServicePack(res.data);
				}
			})
			.finally((x) => {
				dispatch(setLoading(false));
			});
	};

	return (
		<Card className="statistic-body-card mb-15 pb-5">
			<Row gutter={24}>
				<Col xs={24} md={24} className="font-weight-bold mb-15">
					{lang.report_service_pack}
				</Col>

				<Col md={8}>
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<AppstoreOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic title={lang.your_service_pack} value={dataServicePack.package_name} />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col md={8}>
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<FieldTimeOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic
									title={lang.time_left}
									value={dataServicePack.expiration_date ? diffInDays(moment(), dataServicePack.expiration_date) : 0}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col md={8}>
					<Card>
						<Row gutter={24}>
							<Col md={4} className="statistic-icon">
								<MessageOutlined style={{ fontSize: 35 }} />
							</Col>
							<Col md={20}>
								<Statistic title={lang.sms_left} value={dataServicePack.sms_quantity} />
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</Card>
	);
};

export default DashBoardServicePack;
