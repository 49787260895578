/* eslint-disable react/jsx-key */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import lang from '../../../../../language/language';
import { Row, Col, Button, Space, Input, message, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import {
    BarcodeOutlined
} from '@ant-design/icons';
import { giftCardDetail } from '../../../../GiftCard/service';
import { setLoading } from '../../../../Common/slice';
import * as c from '../../../../../constants/common';
import moment from 'moment';
import { setInvoiceDetail } from '../../../slice';
import { getGiftCardTotal, getInvoiceMoneyReceived } from '../../../common';
import BarcodeReader from 'react-barcode-reader';

const InvoiceActionDetailGiftCard = (props) => {
    const { onAdd, isVisible } = props
    const [giftCardCode, setGiftCardCode] = useState('')
    const [giftCardCodeScan, setGiftCardCodeScan] = useState('')

    const dispatch = useDispatch();
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const userInfo = useSelector(state => state.user.userInfo);
    const [form] = Form.useForm();

    useEffect(() => {
        if (giftCardCodeScan) {
            onSave()
        }
    }, [giftCardCodeScan])
    /// /funtion///////

    const onSave = () => {
        const cardCode = giftCardCodeScan || giftCardCode
        const giftTotal = getGiftCardTotal(invoiceDetail.giftCardList)
        const needToPay = Number((invoiceDetail.invoice_customer_need_to_pay - invoiceDetail.invoice_money_received).toFixed(2))
        if (giftTotal > needToPay) {
            message.error(lang.gift_card_max_use)
        } else if (invoiceDetail.giftCardList && invoiceDetail.giftCardList.some(e => e.gift_card_code === cardCode)) {
            message.error(lang.gift_card_exist)
        } else if (invoiceDetail.invoice_money_received >= invoiceDetail.invoice_customer_need_to_pay) {
            message.error(lang.gift_card_max_use)
        } else {
            dispatch(setLoading(true));
            giftCardDetail({ gift_card_code: cardCode }).then(res => {
                if (!res.data || res.data.is_delete === c.IS_DELETE) {
                    message.error(lang.gift_card_code_invalid)
                } else if (res.data.is_active === c.IS_DEACTIVE) {
                    message.error(lang.gift_card_not_active)
                } else if (res.data.gift_card_expiry_date && res.data.gift_card_expiry_date < moment(new Date()).format('YYYY-MM-DD HH:mm:ss A')) {
                    message.error(lang.gift_card_expiry)
                } else {
                    const giftCardMoneyUse = res.data.gift_card_balance > needToPay ? needToPay : res.data.gift_card_balance
                    // nếu đã tồn tại method gift card
                    if (invoiceDetail.invoicePaymentMethodList && invoiceDetail.invoicePaymentMethodList.some(e => e.invoice_payment_method === c.PAYMENT_METHOD[2].value)) {
                        const listGift = [...invoiceDetail.giftCardList]
                        const listPaymentMethod = [...invoiceDetail.invoicePaymentMethodList]

                        listGift.push({ ...res.data, gift_card_money_use: giftCardMoneyUse })
                        const filterListPaymentMethod = listPaymentMethod.filter(z => z.invoice_payment_method !== c.PAYMENT_METHOD[2].value)

                        const newPaymentMethodGift = {
                            invoice_payment_method: c.PAYMENT_METHOD[2].value,
                            invoice_payment_method_money: getGiftCardTotal(listGift),
                            giftCardList: listGift,
                            invoice_id: invoiceId,
                            create_user: userInfo.user_id
                        }
                        filterListPaymentMethod.push(newPaymentMethodGift)
                        const invoiceMoneyReceived = getInvoiceMoneyReceived(filterListPaymentMethod)
                        const invoiceChange = invoiceMoneyReceived - invoiceDetail.invoice_customer_need_to_pay
                        dispatch(setInvoiceDetail({
                            ...invoiceDetail,
                            giftCardList: listGift,
                            invoicePaymentMethodList: filterListPaymentMethod,
                            invoice_change: invoiceChange > 0 ? invoiceChange : 0,
                            invoice_tip: 0
                        }))
                    } else {
                        // nếu chưa có method gift card
                        if (onAdd) {
                            const data = {
                                invoice_payment_method: c.PAYMENT_METHOD[2].value,
                                invoice_payment_method_money: giftCardMoneyUse,
                                giftCardList: [{ ...res.data, gift_card_money_use: giftCardMoneyUse }]
                            }
                            onAdd(data)
                        }
                    }
                    setGiftCardCode(null)
                }
            }).finally(x => {
                dispatch(setLoading(false));
                form.setFieldsValue({ gift_card_code: '' })
                setGiftCardCodeScan('')
            })
        }
    }

    const onScan = (codeScan) => {
        if (codeScan && isVisible) {
            const code = codeScan.slice(0, 3)
            if (code === c.CODE_TYPE.GIFT_CARD) {
                setGiftCardCodeScan(codeScan)
            } else {
                message.error(lang.code_not_found)
            }
        }
    }

    const onScanError = (err) => {
        console.log(err)
    }

    /// /render///////
    return (
        <Row>
            {isVisible && <BarcodeReader
                onError={onScanError}
                onScan={onScan}
            />}
            <Form
                autoComplete="off"
                layout="vertical"
                onFinish={onSave}
                form={form}
            >
                <Col xs={24} md={24}>
                    <Form.Item
                        label={lang.gift_card_code}
                        name="gift_card_code"
                        rules={[
                            { required: true, message: lang.value_is_required }
                        ]}
                    >
                        <Input
                            placeholder={lang.gift_card_code}
                            onChange={(e) => setGiftCardCode(e.target.value)}
                            value={giftCardCode}
                            size="large"
                            addonAfter={
                                <Space>
                                    <BarcodeOutlined onClick={() => { message.success(lang.barcode_actived) }} style={{ fontSize: 25 }} />
                                    <Button
                                        className="w-100"
                                        type="primary"
                                        size="middle"
                                        block
                                        onClick={() => form.submit()}
                                    >
                                        {lang.add}
                                    </Button>
                                </Space>
                            }
                        />
                    </Form.Item>
                </Col>
            </Form>
        </Row>
    );
};

export default InvoiceActionDetailGiftCard;
