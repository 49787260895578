import { createAsyncThunk } from '@reduxjs/toolkit';
import * as giftCardSettingService from './service';

export const giftCardSettingThunk = createAsyncThunk(
    'giftCardSetting/get',
    async (data, thunkAPI) => {
        const response = await giftCardSettingService.giftCardSetting(data);
        return response.data;
    }
);

export const giftCardSettingBackgroundAlbumThunk = createAsyncThunk(
    'giftCardSetting/backgroundAlbum',
    async (data, thunkAPI) => {
        const response = await giftCardSettingService.giftCardBackgroundAlbum(data);
        return response.data;
    }
);

export const giftCardSettingBackgroundThunk = createAsyncThunk(
    'giftCardSetting/background',
    async (data, thunkAPI) => {
        const response = await giftCardSettingService.giftCardBackground(data);
        return response.data;
    }
);
