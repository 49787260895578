import CallApi from '../../utils/CallAPI';
import {
  GIFT_CARD_SETTING,
  GIFT_CARD_SETTING_BACKGROUND_ALBUM,
  GIFT_CARD_SETTING_BACKGROUND
} from './constants/endpoint';

export const giftCardSetting = (data) => {
  return CallApi(GIFT_CARD_SETTING, 'GET', data)
};

export const giftCardSettingCreateUpdate = (data, method) => {
  return CallApi(GIFT_CARD_SETTING, method, data)
};

export const giftCardBackgroundAlbum = (data, method = 'GET') => {
  return CallApi(GIFT_CARD_SETTING_BACKGROUND_ALBUM, method, data)
};

export const giftCardBackground = (data, method = 'GET') => {
  return CallApi(GIFT_CARD_SETTING_BACKGROUND, method, data)
};
