import React from 'react';
import OpenHours from './OpenHours';
import DayOff from './DayOff';

const BusinessHours = () => {
	return (
		<div>
			<OpenHours />
			<DayOff />
		</div>
	);
};

export default BusinessHours;
