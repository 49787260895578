import React, { useEffect, useState } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Form, Button, message, Tabs, Card } from 'antd';
import { setLoading } from '../../../Common/slice';
import { useDispatch } from 'react-redux';
import { SaveOutlined } from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import * as emailService from '../../service';
import * as c from '../../../../constants/common';
import EmailSettingDefault from './EmailSettingDefault';
import EmailSettingCustom from './EmailSettingCustom';

const EmailSetting = (props) => {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [emailSetting, setEmailSetting] = useState({});
	const [emailSettingType, setEmailSettingType] = useState(c.EMAIL_SETTING_TYPE.DEFAULT);
	const { TabPane } = Tabs;

	useEffect(() => {
		getEmailSetting();
	}, []);

	const getEmailSetting = () => {
		dispatch(setLoading(true));
		emailService
			.emailSetting({}, 'GET')
			.then((res) => {
				if (res) {
					dispatch(setLoading(false));
					const { data } = res;
					form.setFieldsValue({
						email_setting_from: data.email_setting_from,
						email_setting_smtp_server: data.email_setting_smtp_server,
						email_setting_port: data.email_setting_port,
						email_setting_user: data.email_setting_user,
						email_setting_password: data.email_setting_password,
						email_setting_secure: data.email_setting_secure,
						email_setting_type: data.email_setting_type,
					});
					setEmailSettingType(data.email_setting_type || c.EMAIL_SETTING_TYPE.DEFAULT);
					setEmailSetting(data);
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};
	const onSubmit = (data) => {
		dispatch(setLoading(true));
		const method = emailSetting.email_setting_id ? 'PUT' : 'POST';
		const dataReq = {
			email_setting_id: emailSetting.email_setting_id || null,
			email_setting_from: data.email_setting_from,
			email_setting_smtp_server: data.email_setting_smtp_server || '',
			email_setting_port: parseInt(data.email_setting_port) || null,
			email_setting_user: data.email_setting_user || '',
			email_setting_password: data.email_setting_password || '',
			email_setting_secure: data.email_setting_secure ? 1 : 2 || null,
			email_setting_type: emailSettingType,
		};
		emailService
			.emailSetting(dataReq, method)
			.then((res) => {
				if (res) {
					dispatch(setLoading(false));
					message.success(lang.save_success);
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

	return (
		<Card>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{APP_TITLE} - {lang.email_setting}
				</title>
			</Helmet>
			<Form onFinish={onSubmit} autoComplete="off" form={form} layout="vertical">
				<Tabs tabPosition="left">
					<TabPane tab={lang.email_setting_default} key="1">
						<EmailSettingDefault />
					</TabPane>
					<TabPane tab={lang.email_setting_custom} key="2">
						<EmailSettingCustom />
					</TabPane>
				</Tabs>
				<Row>
					<Col xs={24} md={24} className="text-center">
						<CheckPermission permission={['EMAIL_SETTING_EDIT']}>
							<Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary">
								{lang.save}
							</Button>
						</CheckPermission>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default EmailSetting;
