/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import { List, Row, Col, Typography } from 'antd';

import { formatDate, formatPhone, formatIsActive, formatCurrency } from '../../../Common/components/FormatData';
const { Text } = Typography;

const GiftCardDetailInfo = (props) => {
    const { data } = props;

    // const color = useSelector(state => state.user.userInfo.theme_color);

    /// /funtion///////

    // click button delete

    /// /render///////
    if (data) {
        return (
            <Row >
                <Col xs={24} md={24} lg={24} className="ml-15">
                    <List
                        size="large"
                        itemLayout="horizontal"
                        grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                        dataSource={
                            Object.keys(data)
                                .filter(x =>
                                    x !== 'key' &&
                                    x !== 'gift_card_id' &&
                                    x !== 'gift_card_type' &&
                                    x !== 'user_id' &&
                                    x !== 'gift_card_file' &&
                                    x !== 'gift_card_html' &&
                                    x !== 'gift_card_background_image_url'
                                )}
                        renderItem={item => (
                            <List.Item >
                                <Row className="border-detail-list">
                                    <Col xs={12} lg={9}>{lang[item]}</Col>
                                    <Col xs={12} lg={15}>{
                                        (item === 'create_date' || item === 'gift_card_expiry_date')
                                            ? formatDate(data[item], true)
                                            : (item === 'is_active')
                                                ? formatIsActive(data[item])
                                                : (String(data[item]).includes('{DELETE}'))
                                                    ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                    : (item === 'customer_phone_number')
                                                        ? formatPhone(data[item])
                                                        : (item === 'gift_card_balance')
                                                            ? formatCurrency(data[item])
                                                            : data[item]
                                    }</Col>
                                </Row>
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        );
    } else {
        return <div></div>
    }
};

export default GiftCardDetailInfo;
