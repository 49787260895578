/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { getCustomer } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { formatPhone } from './FormatData';
import { useSelector } from 'react-redux';

const { Option } = Select;

const CustomerSelect = (props) => {
  const {
    hidePhone,
    isMultiple,
    label,
    name,
    isHideLabel,
    onSelect,
    onClear,
    style,
    required,
    allowClear,
    showSearch,
    size,
    showSelectAll,
    showEmail,
    onChange,
    onInput,
    disabled
  } = props;
  const [options, setOptions] = useState([])
  const loading = useSelector(state => state.common.loading);

  useEffect(() => {
    getCustomer().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, [loading]);

  const onSelected = (value, option) => {
    if (onSelect) {
      onSelect(option.data)
    }
  }

  const onChangeData = (value, option) => {
    if (onChange) {
      onChange(value)
    }
  }

  // const validateCustomerSelect = (rule, value, getFieldValue) => {
  //   if (value) {
  //     if (!isMultiple) {
  //       const res = options.find((i) => i.customer_id === value);
  //       if (value && res.is_active === c.IS_DEACTIVE) {
  //         return Promise.reject(new Error(`${res.customer_fullname} ${lang.option_deactive_validation}`));
  //       }
  //       return Promise.resolve();
  //     } else {
  //       if (value) {
  //         const arr = options.filter(i => value.includes(i.customer_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
  //         const text = arr.map((y) => y.customer_fullname)
  //         if (arr.length > 0 && value.length > 0) {
  //           return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
  //         }
  //         return Promise.resolve();
  //       }
  //     }
  //   } else return Promise.resolve();
  // }

  /// render/////
  return (
    <Form.Item
      label={!isHideLabel ? (label || lang.customer) : ''}
      name={name || 'customer'}
      style={style}
      rules={[
        { required: required || false, message: lang.value_is_required }
        // ({ getFieldValue }) => ({
        //   validator: (rule, value) => validateCustomerSelect(rule, value, getFieldValue)
        // })
      ]}
    >
      <Select
        size={size || 'large'}
        showSearch={showSearch || false}
        allowClear={allowClear || false}
        onClear={onClear || null}
        onSelect={onSelected || null}
        mode={isMultiple ? 'multiple' : ''}
        onChange={onChangeData}
        maxTagCount='responsive'
        disabled={disabled}
        placeholder={label || lang.customer}
        filterOption={(input, option) => {
          if (onInput) {
            onInput(input)
          }
          const phone = option.data.customer_phone_number
          const email = option.data.customer_email
          const name = option.data.customer_fullname
          return phone.toLowerCase().indexOf(input.toLowerCase()) !== -1 || email.toLowerCase().indexOf(input.toLowerCase()) !== -1 || name.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {showSelectAll && <Option key={-1} value={-1} data={{ customer_email: '-1', customer_phone_number: '', customer_fullname: lang.all }}>
          {lang.all}
        </Option>}
        {options.filter(e => e.customer_phone_number !== '' && e.is_active === 1).map((item) => {
          return <Option key={item.customer_id} value={item.customer_id} data={item}>
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{!hidePhone && formatPhone(item.customer_phone_number) + ' - '}{showEmail && item.customer_email && item.customer_email + ' - '}{item.customer_fullname}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default CustomerSelect;
