import React from 'react'
import { Popconfirm } from 'antd';
import lang from '../../../language/language';

const PopConfirm = (props) => {
    const { children, onConfirm } = props
    function confirm (e) {
        onConfirm();
      }

      function cancel (e) {
        console.log(e);
      }
  return (
        <Popconfirm
        title={lang.alert_exit_without_save}
        onConfirm={confirm}
        onCancel={cancel}
        okText={lang.yes}
        cancelText={lang.no}
        >
            {children}
        </Popconfirm>
  )
}

export default PopConfirm
