import CallApi from '../../../utils/CallAPI';
import { DAY_OFF_SETTING, OPEN_HOUR_SETTING } from './constants/endpoint';

export const openHourSetting = (data) => {
	return CallApi(OPEN_HOUR_SETTING, 'GET', data);
};

export const openHourSettingCreateUpdate = (data) => {
	return CallApi(OPEN_HOUR_SETTING, 'POST', data);
};

export const dayOffSetting = (data) => {
	return CallApi(DAY_OFF_SETTING, 'GET', data);
};

export const dayOffSettingCreateUpdate = (data) => {
	return CallApi(DAY_OFF_SETTING, 'POST', data);
};
