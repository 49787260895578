/* eslint-disable array-callback-return */
import { setInvoiceDetail } from './slice';

export const updateInvoiceInfo = (invoiceDetail, dispatch, posSetting) => {
    const tax = (getInvoiceTempMoney(invoiceDetail.invoiceItemList) * posSetting.tax_percent) / 100
    const tempMoney = getInvoiceTempMoney(invoiceDetail.invoiceItemList)
    const customerNeedToPay = (tempMoney + tax) -
        invoiceDetail.invoice_customer_group_discount + invoiceDetail.invoice_total_surcharge - invoiceDetail.invoice_total_promotion
    dispatch(setInvoiceDetail({
        ...invoiceDetail,
        invoice_temp_money: getInvoiceTempMoney(invoiceDetail.invoiceItemList),
        invoice_tax: tax,
        invoice_customer_need_to_pay: customerNeedToPay
    }))
}

export const getInvoiceTotalPromotion = (data = []) => {
    let total = 0
    if (data.length > 0) {
        data.map((item) => {
            total = total + Number(item.invoice_promotion_money)
        })
    }
    return total
}

export const getInvoiceTotalSurcharge = (data = []) => {
    let total = 0
    if (data.length > 0) {
        data.map((item) => {
            total = total + Number(item.invoice_surcharge_money)
        })
    }
    return total
}

export const getInvoiceTempMoney = (data = []) => {
    let total = 0
    if (data.length > 0) {
        data.map((item) => {
            total = total + Number(item.invoice_item_total_price)
        })
    }
    return total
}
export const getInvoiceMoneyReceived = (data = []) => {
    let total = 0
    if (data.length > 0) {
        data.map((item) => {
            total = total + Number(item.invoice_payment_method_money)
        })
    }
    return total
}

export const getGiftCardTotal = (data = []) => {
    let total = 0
    if (data.length > 0) {
        data.map((item) => {
            total = total + Number(item.gift_card_money_use)
        })
    }
    return total
}
