/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import * as userService from '../../../modules/Setting/User/service';
import { setLoading } from '../../../modules/Common/slice';
// import moment from 'moment';
import * as c from '../../../constants/common';
import {
    Drawer, Space, Button, Form, Row, Col, Input, message
} from 'antd';
import {
    PlusOutlined,
    SaveOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';

const AccountChangePassword = (props) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const [actionType, setActionType] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();

    /// //////////////////////// Validate /////////////////////////

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onSubmit = (data) => {
        dispatch(setLoading(true))
        const dataReq = {
            user_id: userInfo.user_id,
            user_password_old: data.user_password_old,
            user_password: data.user_password_new
        };
        if (data.user_password_new !== data.user_password_new_confirm) {
            dispatch(setLoading(false));
            message.error(lang.password_new_not_match);
        } else {
            userService.userChangePassword(dataReq, 'PUT').then(res => {
                if (res) {
                    const dataRes = res.data
                    if (dataRes.user_password_old === false) {
                        message.error(lang.password_old_not_match);
                    } else if (dataRes.user_id > 0) {
                        if (actionType === c.SAVE) {
                            message.success(lang.save_success);
                            setTimeout(() => {
                                resetData();
                                window.location.href = '/'
                                localStorage.clear();
                            }, 1000)
                        }
                    }
                }
            }).finally(() => {
                dispatch(setLoading(false))
            });
        }
    };

    const resetData = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    const onSave = (type) => {
        setActionType(type);
        form.submit()
    }

    /// render/////
    return (
        <span>
            <Button type="primary" className="d-none" id="accountChangePasswordModal" onClick={showModal} icon={<PlusOutlined />} />
            <Drawer
                title={lang.change_password}
                placement="right"
                width={c.WIDTH_DRAWER.SMALL}
                onClose={resetData}
                visible={isModalVisible}
                footer={
                    <Space className="text-center">
                        <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={resetData}>
                            {lang.close}
                        </Button>
                        <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                            {lang.save}
                        </Button>
                    </Space>
                }
            >
                <Form
                    onFinish={onSubmit}
                    autoComplete="off"
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={lang.password_old}
                                name="user_password_old"
                                required
                                rules={[
                                    { min: 6, message: lang.password_min_6 }
                                ]}
                            >
                                <Input type="password" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={lang.password_new}
                                name="user_password_new"
                                required
                                rules={[
                                    { min: 6, message: lang.password_min_6 }
                                ]}
                            >
                                <Input type="password" size="large" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label={lang.password_new_confirm}
                                name="user_password_new_confirm"
                                required
                                rules={[
                                    { min: 6, message: lang.password_min_6 }
                                ]}
                            >
                                <Input type="password" size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Drawer>
        </span>
    );
};

export default AccountChangePassword;
