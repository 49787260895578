const vi = {
	setting: 'Cài đặt',
	not_found: 'Không tìm thấy trang',
	dash_board: 'Tổng quan',
	setting_general: 'Cài đặt chung',
	actived: 'Đang kích hoạt',
	theme: 'Giao diện',
	quick_setting: 'Cài đặt nhanh',
	view_all_setting: 'Xem tất cả cài đặt',
	theme_color: 'Chủ đề',
	search: 'Tìm kiếm',
	filter: 'Lọc kết quả',
	status: 'Trạng thái',
	close: 'Đóng',
	apply: 'Áp dụng',
	search_users_placeholder: 'Nhập mã, tên người dùng',
	not_found_page: 'Không tìm thấy trang',
	home: 'Trang chủ',
	user: 'Người dùng',
	user_code: 'Mã người dùng',
	user_fullname: 'Tên người dùng',
	user_name: 'Tên đăng nhập',
	user_phone_number: 'Điện thoại',
	user_email: 'Email',
	user_birthday: 'Ngày sinh',
	create_date: 'Ngày tạo',
	active: 'Kích hoạt',
	de_active: 'Tạm ngưng',
	user_address: 'Địa chỉ',
	user_note: 'Ghi chú',
	from_date: 'Từ ngày',
	to_date: 'Đến ngày',
	all: 'Tất cả',
	re_new: 'Làm mới',
	cancel: 'Huỷ bỏ',
	save_and_close: 'Lưu và đóng',
	save: 'Lưu',
	password: 'Mật khẩu',
	province: 'Tỉnh/Thành phố',
	ward: 'Phường/Xã',
	district: 'Quận/Huyện',
	address: 'Địa chỉ',
	province_required: 'Tỉnh/Thành phố là bắt buộc',
	ward_required: 'Phường/Xã là bắt buộc',
	district_required: 'Quận/Huyện là bắt buộc',
	user_code_required: 'Mã người dùng là bắt buộc',
	user_name_required: 'Tên đăng nhập là bắt buộc',
	user_password_required: 'Mật khẩu là bắt buộc',
	user_fullname_required: 'Tên người dùng là bắt buộc',
	phone: 'Điện thoại',
	email: 'Email',
	birthday: 'Ngày sinh',
	note: 'Ghi chú',
	description: 'Mô tả',
	search_user: 'Tìm kiếm mã, tên, tên đăng nhập, điện thoại, email...',
	save_success: 'Lưu thành công',
	not_allow_special_characters: 'Không được nhập kí tự đặc biệt',
	phone_not_format: 'Số điện thoại không đúng định dạng',
	phone_min_max: 'Số điện thoại phải từ 10 - 11 số',
	email_not_format: 'Email không đúng định dạng',
	password_min_6: 'Mật khẩu phải từ 6 ký tự',
	no_data: 'Không có dữ liệu',
	exist: ' đã tồn tại',
	delete: 'Xoá',
	confirm: 'Xác nhận',
	ok: 'Đồng ý',
	sure_delete: 'Bạn có chắc chắn muốn xoá không?',
	sure_save: 'Bạn có chắc chắn muốn lưu không?',
	delete_success: 'Xoá thành công',
	edit: 'Chỉnh sửa ',
	import: 'Nhập dữ liệu',
	export: 'Xuất dữ liệu',
	showing: 'Hiển thị',
	from: 'từ',
	to: 'đến',
	data: 'dữ liệu',
	total: 'Tổng',
	is_active: 'Trạng thái',
	login: 'Đăng nhập',
	forgot_password: 'Quên mật khẩu',
	login_not_correct: 'Thông tin đăng nhập không chính xác',
	add: 'Thêm ',
	info_person: 'Thông tin cá nhân',
	change_password: 'Đổi mật khẩu',
	logout: 'Đăng xuất',
	password_old: 'Mật khẩu cũ',
	password_new: 'Mật khẩu mới',
	password_new_confirm: 'Xác nhận mật khẩu mới',
	user_password_new_required: 'Mật khẩu mới là bắt buộc',
	user_password_new_confirm_required: 'Xác nhận mật khẩu mới là bắt buộc',
	password_old_not_match: 'Mật khẩu cũ không đúng',
	password_new_not_match: 'Mật khẩu mới và xác nhận mật khẩu mới không khớp',
	role: 'Vai trò',
	choose_file: 'Chọn file',
	please_select_file: 'Vui lòng chọn file',
	role_name: 'Tên vai trò',
	role_note: 'Ghi chú',
	search_role: 'Tìm kiếm tên vai trò...',
	role_name_required: 'Tên vai trò là bắt buộc',
	branch_and_role: 'Chi nhánh và vai trò',
	branch: 'Chi nhánh',
	user_info: 'Thông tin người dùng',
	sure_save_update_status_role: `Nếu quyền này ngưng kích hoạt sẽ không hiển thị lúc cấp quyền cho người dùng,
  những người dùng đang thuộc quyền này vẫn có thể sử dụng`,
	sure_delete_role: 'Nếu xoá thì bạn sẽ phải cấp lại quyền mới cho tất cả người dùng thuộc quyền này',
	branch_code: 'Mã chi nhánh',
	branch_name: 'Tên chi nhánh',
	branch_address: 'Địa chỉ',
	branch_note: 'Ghi chú',
	search_branch: 'Tìm kiếm mã, tên, điện thoại chi nhánh...',
	branch_code_required: 'Mã chi nhánh là bắt buộc',
	branch_name_required: 'Tên chi nhánh là bắt buộc',
	server_error: 'Lỗi máy chủ',
	sure_delete_branch: 'Nếu xoá thì bạn sẽ phải cấp lại chi nhánh mới cho tất cả người dùng thuộc chi nhánh này',
	branch_phone_number: 'Điện thoại',
	sure_save_update_status_branch: `Nếu chi nhánh này ngưng kích hoạt sẽ không hiển thị lúc cấp quyền cho người dùng,
  những người dùng đang thuộc chi nhánh này vẫn có thể sử dụng`,
	main: 'Chính',
	customer: 'Khách hàng',
	customer_code: 'Mã khách hàng',
	customer_fullname: 'Tên khách hàng',
	customer_phone_number: 'Điện thoại',
	customer_email: 'Email',
	customer_address: 'Địa chỉ',
	customer_note: 'Ghi chú',
	customer_birthday: 'Ngày sinh',
	search_customer: 'Tìm kiếm mã, tên, điện thoại, email...',
	customer_code_required: 'Mã khách hàng là bắt buộc',
	customer_fullname_required: 'Tên khách hàng là bắt buộc',
	customer_list: 'Danh sách khách hàng',
	customer_group: 'Nhóm khách hàng',
	customer_group_name: 'Tên nhóm',
	discount: 'Giảm giá',
	discount_type: 'Loại giảm giá',
	customer_group_note: 'Ghi chú',
	customer_group_info: 'Thông tin nhóm khách hàng',
	condition: 'Điều kiện',
	discount_required: 'Giảm giá là bắt buộc',
	customer_group_name_required: 'Tên nhóm là bắt buộc',
	only_allow_number: 'Chỉ cho phép nhập số',
	search_customer_group: 'Tìm kiếm tên nhóm khách hàng...',
	error_delete_current_branch: 'Bạn không thể xoá chi nhánh đang sử dụng',
	condition_type: 'Loại điều kiện',
	condition_value: 'Giá trị',
	info_customer_is_required: 'Thông tin khách hàng là bắt buộc',
	value_is_required: 'Giá trị này là bắt buộc',
	customer_phone_number2: 'Điện thoại 2',
	customer_gender: 'Giới tính',
	gender: 'Giới tính',
	male: 'Nam',
	female: 'Nữ',
	tax_code: 'Mã số thuế',
	personal: 'Cá nhân',
	company: 'Công ty',
	company_name: 'Tên công ty',
	choose_image: 'Chọn ảnh',
	customer_facebook: 'Facebook',
	customer_yelp: 'Yelp',
	customer_type: 'Loại khách',
	customer_tax_code: 'Mã số thuế',
	customer_company_name: 'Tên công ty',
	phone2: 'Điện thoại 2',
	phone_no_duplicate: 'Số điện thoại không được trùng nhau',
	phone_number: 'Số điện thoại ',
	file_not_image: 'File không đúng định dạng hình ảnh',
	file_max_size: 'Dung lượng file tải lên không được lớn hơn 2 MB',
	create_user: 'Người tạo',
	no_permission: 'Không có quyền truy cập, vui lòng liên hệ quản trị viên',
	left_menu_color: 'Màu menu',
	branch_role_is_required: 'Chi nhánh và quyền là bắt buộc',
	condition_is_required: 'Điều kiện là bắt buộc',
	service_group: 'Nhóm dịch vụ',
	service_group_code: 'Mã nhóm dịch vụ',
	service_group_name: 'Tên nhóm dịch vụ',
	service_group_avatar: 'Ảnh đại diện',
	service_group_description: 'Mô tả nhóm dịch vụ',
	search_service_group: 'Tìm kiếm mã, tên nhóm dịch vụ',
	service: 'Dịch vụ',
	service_code: 'Mã dịch vụ',
	service_name: 'Tên dịch vụ',
	service_avatar: 'Ảnh đại diện',
	service_description: 'Mô tả dịch vụ',
	service_price: 'Giá dịch vụ',
	service_price_discount: 'Giá giảm',
	search_service: 'Tìm kiếm mã, tên dịch vụ',
	value_not_allow_special_characters: 'Giá trị này không cho phép nhập ký tự đặc biệt',
	reset: 'Làm mới',
	export_excel: 'Xuất Excel',
	download_demo: 'Tải về file mẫu',
	not_format: 'Không đúng định dạng',
	login_main_top: 'Chào mừng bạn đến với Hexcore!',
	login_main_bottom: 'Hãy đăng nhập để trải nghiệm dịch vụ tốt nhất',
	is_main: 'Chính',
	not_main: 'Phụ',
	branch_not_duplicate: 'Chi nhánh không được giống nhau',
	selected: ' đã chọn',
	select_all: ' Chọn tất cả',
	unselect_all: 'Bỏ chọn tất cả',
	infomation: 'Thông tin',
	condition_not_duplicate: 'Loại điều kiện không được giống nhau',
	service_count: 'Số dịch vụ',
	service_group_not_allow_delete: 'Không thể xoá do đã có dịch vụ sử dụng',
	service_price_not_greater_discount: 'Giá dịch vụ không được nhỏ hơn giá giảm',
	discount_not_greater_service_price: 'Giá giảm không được lớn hơn giá dịch vụ',
	other: 'Khác',
	title: 'Chức danh',
	search_title: 'Tìm kiếm chức danh',
	title_name: 'Chức danh',
	title_note: 'Ghi chú',
	title_not_allow_delete: 'Không thể xoá do đã có chức danh sử dụng',
	staff_list: 'Danh sách nhân viên',
	staff_code: 'Mã nhân viên',
	staff_fullname: 'Tên nhân viên',
	staff_birthday: 'Ngày sinh',
	staff_email: 'Email',
	staff_phone_number: 'Điện thoại',
	staff_id_card: 'Căn cước công dân',
	staff_gender: 'Giới tính',
	staff_address: 'Địa chỉ',
	staff_note: 'Ghi chú',
	staff_facebook: 'Facebook',
	search_staff: 'Tìm kiếm mã, tên, CCCD, điện thoại, email...',
	staff: 'Nhân viên',
	staff_info: 'Thông tin nhân viên',
	branch_and_title: 'Chi nhánh và chức danh',
	option_deactive_validation: 'đang bị tạm ngưng',
	setting_currency: 'Tiền tệ',
	setting_date_format: 'Định dạng ngày',
	appointment: 'Lịch hẹn',
	time: 'Giờ',
	branch_title_is_required: 'Chi nhánh và chức danh là bắt buộc',
	appointment_setting: 'Cài đặt lịch hẹn',
	appointment_setting_fromtime: 'Giờ bắt đầu',
	appointment_setting_totime: 'Giờ kết thúc',
	appointment_setting_range: 'Khoảng cách 1 khung giờ',
	appointment_setting_booking_date_limit: 'Số ngày cho đặt trước',
	day: 'Ngày',
	minute: 'phút',
	alert_exit_without_save: 'Thay đổi chưa được lưu lại, bạn có chắc chắn muốn thoát không?',
	yes: 'Có',
	no: 'Không',
	wait_confirm: 'Chờ xác nhận',
	confirmed: 'Đã xác nhận',
	done: 'Hoàn thành',
	cancelled: 'Đã huỷ',
	appointment_code: 'Mã lịch hẹn',
	appointment_date: 'Ngày hẹn',
	appointment_hour: 'Giờ hẹn',
	service_staff: 'Dịch vụ và nhân viên',
	appointment_quantity: 'Số người',
	min_quantity_1: 'Số lượng phải lớn hơn 1',
	search_customer_phone: 'Tìm số điện thoại khách hàng',
	spent: 'Chi tiêu',
	visit: 'Ghé thăm',
	point: 'Điểm',
	service_duration: 'Thời gian làm',
	time_expired: 'Đã quá giờ tạo lịch',
	appointment_setting_min_booking_time: 'Thời gian đặt trước tối thiểu',
	search_appointment_by_customer_phone: 'Nhập số điện thoại để tìm lịch hẹn',
	cancel_appointment: 'Huỷ lịch',
	print_ticket: 'In phiếu',
	cancel_appointment_success: 'Huỷ lịch hẹn thành công',
	confirm_appointment_success: 'Xác nhận lịch hẹn thành công',
	sure_cancel: 'Bạn có chắc chắn muốn huỷ không',
	search_staff_code_name: 'Tìm mã, tên nhân viên',
	add_close: 'Thêm và đóng',
	of: 'của ',
	service_not_empty: 'Dịch vụ không được bỏ trống',
	service_staff_exist: 'Dịch vụ và nhân viên đã tồn tại',
	service_is_required: 'Bạn chưa chọn dịch vụ',
	staff_is_required: 'Bạn chưa chọn nhân viên',
	appointment_end_hour: 'Giờ hoàn thành',
	conflict_end_hour: 'Giờ hoàn thành vượt quá lịch hẹn kế tiếp',
	staff_type: 'Loại nhân viên',
	technician: 'Thợ',
	staff_normal: 'Nhân viên thường',
	wait_appointment: 'Chờ đến lượt',
	email_history_title: 'Tiêu đề',
	email_history_content: 'Nội dung',
	email_history: 'Email đã gửi',
	search_email_history: 'Tìm kiếm tiêu đề, nội dung email',
	email_sample: 'Email mẫu',
	email_sample_title: 'Tiêu đề',
	email_sample_content: 'Nội dung',
	search_email_sample: 'Tìm kiếm tiêu đề, nội dung email',
	email_setting: 'Cài đặt email',
	email_setting_smtp_server: 'SMTP Server',
	email_setting_port: 'Port',
	email_setting_from: 'Người gửi',
	email_setting_user: 'Tài khoản',
	email_setting_password: 'Mật khẩu',
	email_setting_secure: 'Sử dụng SSL',
	email_setting_default: 'Email hệ thống',
	email_setting_custom: 'Email tuỳ chỉnh',
	email_history_to: 'Người nhận',
	email_create: 'Gửi email',
	send: 'Gửi',
	send_and_close: 'Gửi và đóng',
	sms_history: 'SMS đã gửi',
	sms_history_content: 'Nội dung',
	sms_history_to: 'Người nhận',
	search_sms_history: 'Tìm kiếm nội dung, điện thoại người nhận',
	sms_create: 'Gửi SMS',
	setting_country: 'Quốc gia',
	system: 'Hệ thống',
	sms_sample: 'SMS mẫu',
	sms_sample_title: 'Tiêu đề',
	sms_sample_content: 'Nội dung',
	search_sms_sample: 'Tìm kiếm tiêu đề, nội dung sms',
	sms_send: 'Gửi SMS',
	email_send: 'Gửi email',
	appointment_confirm: 'Xác nhận lịch hẹn',
	appointment_admin_cancel: 'Tiệm huỷ lịch hẹn',
	appointment_customer_cancel: 'Khách huỷ lịch hẹn',
	appointment_remind: 'Nhắc lịch hẹn',
	appointment_content_confirm: 'Nội dung xác nhận lịch hẹn',
	appointment_content_admin_cancel: 'Nội dụng tiệm huỷ lịch hẹn',
	appointment_content_customer_cancel: 'Nội dung khách huỷ lịch hẹn',
	appointment_content_remind: 'Nội dung huỷ lịch hẹn',
	appointment_setting_time_remind: 'Nhắc lịch trước giờ hẹn',
	title_label: 'Tiêu đề',
	content: 'Nội dung',
	hour: 'giờ',
	appointment_history: 'Lịch sử đặt lịch',
	appointment_status: 'Trạng thái',
	branch_website: 'Website',
	cancellation_reason: 'Lý do huỷ',
	cancellation_reason_is_required: 'Lý do huỷ là bắt buộc',
	pos: 'Thanh toán',
	product: 'Sản phẩm',
	sort_data: 'Sắp xếp',
	name_a_z: 'Tên A-Z',
	name_z_a: 'Tên Z-A',
	price_decrease: 'Giá giảm dần',
	price_ascending: 'Giá tăng dần',
	invoice_item_price: 'Giá',
	invoice_item_name: 'Dịch vụ / sản phẩm',
	invoice_item_quantity: 'Số lượng',
	invoice_item_code: 'Mã',
	invoice_item_total_price: 'Tổng tiền',
	other_cost: 'Khoản thu khác',
	gift_card: 'Thẻ quà tặng',
	tax: 'Thuế',
	customer_need_to_pay: 'Khách cần thanh toán',
	money_received: 'Tiền khách đưa',
	change: 'Tiền thừa trả khách',
	payment_method: 'Hình thức thanh toán',
	cash: 'Tiền mặt',
	payment: 'Thanh toán',
	invoice: 'Hoá đơn',
	total_price: 'Tổng tiền',
	credit: 'Thẻ tín dụng',
	check: 'Check',
	cancellation_invoice: 'Huỷ hoá đơn',
	pos_setting: 'Cài đặt thanh toán',
	on: 'Bật',
	off: 'Tắt',
	auto_apply_discount: 'Áp dụng giảm giá',
	auto_payment_print: 'In khi thanh toán',
	tax_percent: 'Thuế (%)',
	apply_discount_customer_group: 'Áp dụng giảm giá nhóm khách hàng',
	discount_invalid: 'Giá giảm không được lớn hơn tổng tiền',
	promotion_invalid: 'Khuyến mãi không được lớn hơn tổng tiền',
	money_received_invalid: 'Tiền khách đưa không được nhỏ hơn tiền cần trả',
	gift_card_balance: 'Số dư',
	gift_card_code: 'Mã thẻ quà tặng',
	search_gift_card: 'Tìm kiếm mã thẻ quà tặng...',
	deposit: 'Nạp tiền',
	gift_card_money: 'Số tiền',
	gift_card_history_money: 'Số tiền giao dịch',
	invoice_code: 'Mã hoá đơn',
	transaction_history: 'Lịch sử giao dịch',
	gift_card_code_required: 'Mã thẻ quà tặng không được bỏ trống',
	gift_card_code_invalid: 'Mã thẻ quà tặng không hợp lệ',
	print_barcode: 'In mã vạch',
	cash_gift_card: 'Tiền mặt/thẻ quà tặng',
	cash_credit_card: 'Tiền mặt/thẻ tín dụng',
	cash_check: 'Tiền mặt/check',
	credit_card_gift_card: 'Thẻ tín dụng/thẻ quà tặng',
	credit_card_check: 'Thẻ tín dụng/check',
	gift_card_check: 'Thẻ quà tặng/check',
	accumulate_reward_point_percent: 'Tích điểm (%)',
	quantity_error: 'Số lượng không được nhỏ hơn 1',
	gift_card_from_name: 'Tên người gửi',
	gift_card_to_name: 'Tên người nhận',
	gift_card_message: 'Lời nhắn',
	max_150: ' (Tối đa 150 ký tự)',
	gift_card_from_email: 'Email người gửi',
	gift_card_to_email: 'Email người nhận',
	gift_card_type: 'Mục đích mua',
	someone_else: 'Cho người khác',
	your_self: 'Cho bản thân',
	quantity: 'Số lượng',
	print: 'In',
	string_length_limit: 'Độ dài ký tự đã vượt quá giới hạn',
	gift_card_list: 'Danh sách thẻ quà tặng',
	on_check: 'Kiểm tra',
	input_money: 'Nhập số tiền',
	gift_card_setting: 'Cài đặt thẻ quà tặng',
	background_list: 'Ảnh nền',
	gift_card_setting_message_max_length: 'Số ký tự lời nhắn tối đa',
	gift_card_setting_terms_conditions: 'Điều khoản và điều kiện',
	gift_card_setting_title: 'Tiêu đề',
	gift_card_setting_description: 'Mô tả ngắn',
	btn_change: 'Thay đổi',
	gift_card_setting_allow_send_from_email: 'Gửi mail cho người mua',
	gift_card_setting_allow_send_to_email: 'Gửi mail cho người nhận',
	background_image_default: 'Ảnh nền mặc định',
	appointment_content_sms_confirm:
		'Dear [customer_fullname]. This is a confirmation that you have booked [service]. We are waiting you at our salon on [appointment_date] at [appointment_hour]. Thank you for choosing us.',
	appointment_content_admin_sms_cancel:
		'Dear [customer_fullname]. Your booking of [service] on [appointment_date] at [appointment_hour] has been rejected. Reason: [cancellation_reason]. Thank you for choosing us.',
	appointment_content_customer_sms_cancel:
		'Dear [customer_fullname]. You have cancelled your booking of [service] on [appointment_date] at [appointment_hour]. Thank you for choosing us.',
	appointment_content_sms_remind:
		'Dear [customer_fullname]. We would like to remind you that you have booked [service] on [appointment_date] at [appointment_hour]. We are waiting for you at [branch_address]. Thank you for choosing us.',
	appointment_content_email_confirm: `<p>Dear [customer_fullname].</p>
                                            <p>This is a confirmation that you have booked [service].</p>
                                            <p>We are waiting you at our salon on [appointment_date] at [appointment_hour].</p>
                                            <p>Thank you for choosing us.</p>
                                            <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_admin_email_cancel: `<p>Dear [customer_fullname].</p>
                                                <p>Your booking of [service] on [appointment_date] at [appointment_hour] has been rejected.</p>
                                                <p>Reason: [cancellation_reason]</p>
                                                <p>Thank you for choosing us.</p>
                                                <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_customer_email_cancel: `<p>Dear [customer_fullname].</p>
                                                    <p>You have cancelled your booking of [service] on [appointment_date] at [appointment_hour].</p>
                                                    <p>Thank you for choosing us.</p>
                                                    <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_content_email_remind: `<p>Dear [customer_fullname].</p>
                                          <p>We would like to remind you that you have booked [service] on [appointment_date] at [appointment_hour]. We are waiting for you at [branch_address].</p>
                                          <p>Thank you for choosing us.</p>
                                          <p>[branch_name]<br />[branch_address]<br />[branch_phone_number]<br />[branch_website]</p>`,
	appointment_title_email_confirm: 'Xác nhận lịch hẹn',
	appointment_title_admin_email_cancel: 'Thông báo huỷ lịch hẹn',
	appointment_title_customer_email_cancel: 'Thông báo huỷ lịch hẹn',
	appointment_title_email_remind: 'Nhắc lịch hẹn',
	gift_card_setting_from_email_title: 'Xác nhận mua thẻ quà tặng',
	gift_card_setting_from_email_content: 'Nội dung gửi người mua',
	gift_card_setting_to_email_title: 'Bạn được tặng thẻ quà tặng',
	gift_card_setting_to_email_content: 'Nội dung gửi người được tặng',
	add_album: 'Thêm album',
	delete_gift_card_error: 'Không thể xoá Album có chứa ảnh',
	album_name: 'Tên album',
	album: 'album',
	image_gallery: 'Thư viện hình ảnh',
	background_image: 'Ảnh nền',
	gift_card_send_email_from: 'Người gửi',
	gift_card_send_email_to: 'Người nhận',
	send_success: 'Gửi thành công',
	gift_card_expiry_date: 'Ngày hết hạn',
	not_expiry: 'Không thời hạn',
	one_month: '1 tháng',
	three_month: '3 tháng',
	six_month: '6 tháng',
	nine_month: '9 tháng',
	one_year: '1 năm',
	custom_date: 'Tuỳ chọn ngày',
	save_and_print: 'Lưu và in',
	logo_url: 'Đường dẫn logo',
	gift_card_exist: 'Mã thẻ quà tặng đã tồn tại',
	gift_card_balance_total: 'Tổng tiền thẻ',
	gift_card_not_active: 'Thẻ quà tặng này đã bị tạm ngưng',
	gift_card_expiry: 'Thẻ quà tặng đã hết hạn',
	invoice_total_price: 'Tổng tiền',
	invoice_other_cost: 'Khoản thu khác',
	invoice_discount: 'Giảm giá',
	invoice_tax: 'Thuế',
	invoice_customer_need_to_pay: 'Khách cần thanh toán',
	invoice_payment_method: 'Hình thức thanh toán',
	invoice_status: 'Trạng thái',
	paid: 'Đã thanh toán',
	unpaid: 'Chưa thanh toán',
	search_invoice: 'Tìm kiếm mã hoá đơn...',
	invoice_money_received: 'Tiền khách đưa',
	invoice_change: 'Tiền thừa trả khách',
	invoice_note: 'Ghi chú',
	update_date: 'Ngày cập nhật',
	invoice_cancellation_reason: 'Lý do huỷ',
	invoice_customer_group_discount: 'Nhóm khách hàng',
	copy: 'Sao chép',
	invoice_payment: 'HOÁ ĐƠN THANH TOÁN',
	invoice_tip: 'Tiền tip',
	tip: 'Tip',
	service_ticket: 'PHIẾU DỊCH VỤ',
	print_ticket_note: 'Vui lòng mang phiếu này đến quầy thanh toán sau khi sử dụng dịch vụ',
	visited: 'Đã đến',
	surcharge: 'Phụ phí',
	promotion: 'Khuyến mãi',
	custom: 'Tuỳ chỉnh',
	voucher: 'Voucher',
	invoice_total_promotion: 'Tổng khuyến mãi',
	invoice_promotion_type: 'Loại khuyến mãi',
	from_program: 'Từ chương trình',
	invoice_promotion_name: 'Tên khuyến mãi',
	invoice_promotion_money: 'Số tiền giảm',
	invoice_total_surcharge: 'Tổng phụ phí',
	invoice_surcharge_name: 'Tên phụ phí',
	invoice_surcharge_money: 'Số tiền',
	invoice_temp_money: 'Tạm tính',
	invoice_payment_method_money: 'Số tiền',
	method_duplicate: 'Hình thức thanh toán đã tồn tại',
	method_price_greater_than_0: 'Số tiền phải lớn hơn 0',
	pay_success: 'Thanh toán thành công',
	invoice_money_received_error: 'Tiền khách đưa không được nhỏ hơn tiền cần thanh toán',
	please_select_customer: 'Bạn chưa chọn khách hàng',
	gift_card_max_use: 'Đã vượt quá số tiền cần thanh toán',
	gift_card_use: 'Sử dụng',
	gift_card_use_than_total: 'Số tiền sử dụng vượt quá tổng tiền thẻ',
	gift_card_money_use: 'Số tiền sử dụng',
	description_btn_tip: 'Nhấn vào nút tip để típ toàn bộ tiền thừa',
	description_percent_tip: 'Phần trăm được tính theo số tiền cần thanh toán',
	percent_tip_error: 'Số tiền tip không được lớn hơn tiền thừa',
	invoice_not_invalid: 'Hoá đơn không hợp lệ',
	money_than_0: 'Số tiền phải lớn hơn 0',
	money_than_balance: 'Số tiền vượt quá số dư',
	code_not_found: 'Mã không tồn tại',
	barcode_actived: 'Quét mã kích hoạt thành công',
	reward_point_history: 'Lịch sử tích điểm',
	visit_history: 'Lịch sử ghé thăm',
	customer_reward_point_history: 'Điểm',
	spent_history: 'Lịch sử chi tiêu',
	total_payment: 'Tổng chi tiêu',
	checkin_app: 'App check-in',
	checkin_history_type: 'Loại ghé thăm',
	create_from: 'Nơi tạo',
	website_client: 'Website',
	app_client: 'App khách hàng',
	web_admin: 'Admin',
	checkin_user: 'Người dùng check-in',
	search_checkin_user: 'Tìm kiếm mã, tên người dùng, tên đăng nhập',
	income: 'Doanh thu',
	today: 'Hôm nay',
	yesterday: 'Hôm qua',
	this_week: 'Tuần này',
	last_week: 'Tuần trước',
	this_month: 'Tháng này',
	last_month: 'Tháng trước',
	checkin_setting: 'Cài đặt check-in',
	slider: 'Slider',
	top_ten_service: 'Top 10 dịch vụ',
	used_times: 'Số lần sử dụng',
	booking: 'Lịch hẹn',
	your_service_pack: 'Gói đang sử dụng',
	sms_left: 'SMS còn lại',
	time_left: 'Số ngày còn lại',
	checkin_setting_welcome: 'Lời chào',
	checkin_setting_description: 'Mô tả dưới lời chào',
	report_statistic: 'Thống kê trong ngày',
	report_service_pack: 'Thông tin gói dịch vụ',
	from_day: 'Từ',
	to_day: 'Đến',
	pls_choose_range_time: 'Bạn chưa chọn khoảng thời gian',
	recent_activity: 'Hoạt động gần đây',
	load_more_end: 'Đã tải hết dữ liệu',
	has_been: 'đã',
	at: 'vào lúc',
	sure_logout: 'Bạn có chắc chắn muốn đăng xuất không?',
	logout_success: 'Đăng xuất thành công',
	staff_title: 'Chức danh',
	search_staff_title: 'Tìm kiếm chức danh',
	staff_title_name: 'Chức danh',
	staff_title_note: 'Ghi chú',
	staff_title_not_allow_delete: 'Không thể xoá do đã có chức danh sử dụng',
	report_customer: 'Thống kê lượt khách',
	report_rated: 'Thống kê đánh giá',
	// khúc trên đang dịch
	staff_type_name: 'Loại nhân viên',
	staff_type_note: 'Ghi chú',
	staff_timekeeping: 'Bảng chấm công',
	staff_timekeeping_title: 'Chấm công',
	search_staff_type: 'Tìm kiếm tên loại nhân viên',
	time_keeping_out_of_time: 'Thời gian chấm công không hợp lệ',
	month: 'tháng',
	time_keeping_note: 'Ghi chú',
	time_keeping_day: 'Chấm công ngày',
	time_keeping_time_in: 'Giờ vào',
	time_keeping_time_out: 'Giờ ra',
	staff_allow_booking: 'Cho phép đặt lịch',
	staff_allow_checkin: 'Cho phép check-in',
	is_technician: 'Thợ',
	is_turn_priority: 'Ưu tiên khi chia lượt làm việc',
	checkin_setting_policies: 'Chính sách',
	turn_setting: 'Cài đặt lượt làm việc',
	auto_turn_by_timekeeping: 'Tự động chia lượt làm việc theo giờ chấm công',
	turn_prioritize: 'Ưu tiên',
	turn_prioritize_staff_type: 'Ưu tiên theo loại nhân viên',
	turn_prioritize_income: 'Ưu tiên doanh thu từ thấp đến cao',
	general_setting_hour_format: 'Định dạng giờ',
	sms_history_status: 'Trạng thái',
	fail: 'Thất bại',
	success: 'Thành công',
	report_income: 'Báo cáo doanh thu',
	report_income_by_service: 'Doanh thu theo dịch vụ',
	report_income_by_technician: 'Doanh thu theo thợ',
	report_income_by_customer: 'Doanh thu theo khách hàng',
	report_type: 'Loại báo cáo',
	time_select: 'Thời gian',
	usage_count_service: 'Số lần sử dụng',
	usage_count_technician: 'Số lần làm',
	usage_count_customer: 'Số lần sử dụng',
	report_income_by_payment_method: 'Doanh thu theo hình thức thanh toán',
	usage_count_payment_method: 'Số lần thanh toán',
	report_appointment_by_date: 'Lch hẹn theo thời gian',
	report_appointment_by_status: 'Lch hẹn theo trạng thái',
	appointment_count: 'Số lượng lịch hẹn',
	appointment_status_count: 'Số lượng lịch hẹn',
	report_appointment: 'Báo cáo lịch hẹn',
	create_date_appointment: 'Ngày tạo',
	email_contains_invalid_characters: 'Email chứa ký tự không hợp lệ',
	business_hours: 'Giờ mở cửa',
	monday: 'Thứ hai',
	tuesday: 'Thứ ba',
	wednesday: 'Thứ tư',
	thursday: 'Thứ năm',
	friday: 'Thứ sáu',
	saturday: 'Thứ bảy',
	sunday: 'Chủ nhật',
	fromTime: 'Giờ mở',
	toTime: 'Giờ đóng',
	isOpen: 'Mở cửa',
	add_breaks: 'Thêm giờ nghỉ',
	fromTimeBreak: 'Từ giờ',
	toTimeBreak: 'Đến giờ',
	break: 'Giờ nghỉ',
	hour_open: 'Giờ mở cửa',
	day_off: 'Ngày nghỉ',
	name: 'Tên',
};
export default vi;
