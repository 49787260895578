
// service
const rootAppointment = 'appointment'
export const APPOINTMENT_LIST = `${rootAppointment}/list`;
export const APPOINTMENT = `${rootAppointment}`;
export const APPOINTMENT_DETAIL = `${rootAppointment}/detail`;
export const APPOINTMENT_UPDATE_STATUS = `${rootAppointment}/update-status`;
export const APPOINTMENT_LIST_STAFF_ID_REMOVE = `${rootAppointment}/list-staff-id-remove`;
export const APPOINTMENT_HOUR_LIST_BY_STAFF_REMOVE = `${rootAppointment}/list-hour-remove-by-staff`;
export const APPOINTMENT_WAIT_LIST = `${rootAppointment}/appointment-wait-list`;
export const APPOINTMENT_CHECKIN = `${rootAppointment}/checkin`;
export const APPOINTMENT_PRINT_TICKET = `${rootAppointment}/print-ticket`;
