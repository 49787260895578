import React, { useEffect, useState } from 'react'
import { Pie, G2 } from '@ant-design/plots'
import { Card, Col, Row } from 'antd'
import FilterDay from '../../Common/components/FilterDay'
import lang from '../../../language/language'
import { useDispatch, useSelector } from 'react-redux'
import { OPTIONS_CHART_FILTER } from '../../../constants/common'
import { formatDate } from '../../Common/components/FormatData'
import moment from 'moment'
import { customGetDay } from '../../Common/components/CustomGetDay'
import { setLoading } from '../../Common/slice'
import { getReportCustomerVisit } from '../service'
// import moment from 'moment'

const DashBoardCustomerVisit = () => {
    const [filtered, setFiltered] = useState(lang.this_week)
    const generalSetting = useSelector(state => state.generalSetting)
    const branchByUser = useSelector(state => state.branch.branchByUser)
    const [dataReport, setDataReport] = useState([])

    const dispatch = useDispatch()
    const getDate = customGetDay()

    const G = G2.getEngine('canvas')

    // const data = [
    //     {
    //         type: 'walk-ins',
    //         value: 2000
    //     },
    //     {
    //         type: 'online',
    //         value: 300
    //     }
    // ]

    useEffect(() => {
        getNewReportCustomerVisit()
    }, [])

    const getNewReportCustomerVisit = (fromDate = moment(getDate.thisMonday).format('YYYY-MM-DD'), toDate = moment(getDate.thisSunday).format('YYYY-MM-DD')) => {
        dispatch(setLoading(true))
        const reqData = {
            fromDate: fromDate,
            toDate: toDate,
            branch_list_id: branchByUser
                ? branchByUser.map(item => {
                    return item.branch_id
                })
                : []
        }
        getReportCustomerVisit(reqData).then(res => {
            if (res.data) {
                const newData = res.data
                setDataReport(newData)
            }
        }).finally(x => {
            dispatch(setLoading(false))
        }
        )
    }

    const cfg = {
        appendPadding: 10,
        data: dataReport || [],
        angleField: 'value',
        colorField: 'type',
        radius: 0.75,
        legend: true,
        label: {
            type: 'spider',
            labelHeight: 40,
            formatter: (data, mappingData) => {
                const group = new G.Group({});
                group.addShape({
                    type: 'circle',
                    attrs: {
                        x: 0,
                        y: 0,
                        width: 40,
                        height: 50,
                        r: 5,
                        fill: mappingData.color
                    }
                })
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 10,
                        y: 8,
                        text: `${data.type}`,
                        fill: mappingData.color
                    }
                })
                let perc = data.percent * 10000
                const percTemp = Math.round(perc)
                perc = percTemp / 100
                group.addShape({
                    type: 'text',
                    attrs: {
                        x: 0,
                        y: 25,
                        text: `${data.value}( ${perc}%)`,
                        fill: 'rgba(0, 0, 0, 0.65)',
                        fontWeight: 700
                    }
                })
                return group;
            }
        },
        interactions: [
            {
                type: 'element-selected'
            },
            {
                type: 'element-active'
            }
        ]
    }
    const config = cfg;

    const onSelectFilterDay = (data) => {
        if (data.value === OPTIONS_CHART_FILTER[6].value) {
            const titleFilter = `${lang.from_day.toLowerCase()} ${formatDate(data.fromDate, false, generalSetting)} ${lang.to_day.toLowerCase()} ${formatDate(data.toDate, false, generalSetting)}`
            setFiltered(titleFilter)
        } else { setFiltered(OPTIONS_CHART_FILTER.filter(x => x.value === data.value)[0].label) }
        getNewReportCustomerVisit(moment(data.fromDate).format('YYYY-MM-DD'), moment(data.toDate).format('YYYY-MM-DD'))
    }

    return (
        <Card className='mb-15'>
            <Row>
                <Col xs={24} md={10} className="font-weight-bold">
                    {lang.report_customer} {filtered.toLowerCase()}
                </Col>
                <Col xs={24} md={14} className="text-right">
                    {/* Filter */}
                    <FilterDay onSelect={onSelectFilterDay} isShowDay />
                </Col>
            </Row>
            <Pie {...config} />
        </Card>
    )
}

export default DashBoardCustomerVisit
