/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import lang from '../../../../language/language';
import { Row, Col, Form, Checkbox, Input } from 'antd';
import * as c from '../../../../constants/common';
import { useDispatch } from 'react-redux';
import { appointmentSettingThunk } from '../thunk'
import TinyEditor from './../../../Common/components/TinyEditor';
import ToolTipShortCode from '../../../Common/components/ToolTipShortCode';

const AppointmentSettingEmail = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(appointmentSettingThunk({}))
    }, []);

    return (
        <Row gutter={24} >
            <Col xs={24} md={12}>
                <div className="shadow-hide">

                    <Form.Item
                        name="appointment_allow_email_confirm"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_confirm}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={lang.title_label}
                        name="appointment_title_email_confirm"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_email_confirm"
                    >
                        <TinyEditor toolbar={false} />
                    </Form.Item>
                </div>
            </Col>

            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_admin_email_cancel"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_admin_cancel}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={lang.title_label}
                        name="appointment_title_admin_email_cancel"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_admin_email_cancel"
                    >
                        <TinyEditor toolbar={false} />
                    </Form.Item>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_customer_email_cancel"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_customer_cancel}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={lang.title_label}
                        name="appointment_title_customer_email_cancel"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_customer_email_cancel"
                    >
                        <TinyEditor toolbar={false} />
                    </Form.Item>
                </div>
            </Col>
            <Col xs={24} md={12}>
                <div className="shadow-hide">
                    <Form.Item
                        name="appointment_allow_email_remind"
                        valuePropName="checked"
                    >
                        <Checkbox className="f-16">{lang.appointment_remind}</Checkbox>
                    </Form.Item>
                    <Form.Item
                        label={lang.title_label}
                        name="appointment_title_email_remind"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={<div className='cursor-pointer'>{lang.content} <ToolTipShortCode data={c.SHORT_CODE_APPOINTMENT} /></div>}
                        name="appointment_content_email_remind"
                    >
                        <TinyEditor toolbar={false} />
                    </Form.Item>
                </div>
            </Col>
        </Row>
    );
};

export default AppointmentSettingEmail;
