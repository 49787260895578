/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import lang from '../../../../../language/language';
import { Row, Col, Button, Popover, Space, message, Popconfirm, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from '../../../../Common/slice';
import {
    SaveOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import * as c from '../../../../../constants/common';
import TextArea from 'antd/lib/input/TextArea';
import * as invoiceService from '../../../service';

const InvoiceActionDetailCancel = (props) => {
    const { resetData, form } = props
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);
    const [isVisible, setIsvisible] = useState(false)
    const [cancellationReason, setCancellationReason] = useState('');
    const userInfo = useSelector(state => state.user.userInfo);

    const dispatch = useDispatch()

    /// /funtion///////
    const onSubmitCancel = () => {
        if (cancellationReason) {
            if (invoiceId) {
                dispatch(setLoading(true));
                const data = {
                    invoice_id: invoiceId,
                    invoice_status: c.INVOICE_STATUS.CANCEL,
                    invoice_note: invoiceDetail.invoice_note || '',
                    invoice_cancellation_reason: cancellationReason || '',
                    update_user: userInfo.user_id,
                    invoice_tip: invoiceDetail.invoiceDetail || 0,
                    invoice_change: invoiceDetail.invoice_change || 0
                }
                invoiceService.invoiceUpdateStatus(data).then(res => {
                    if (res.data) {
                        message.success(lang.save_success)
                    }
                }).finally(x => {
                    dispatch(setLoading(false));
                    resetData()
                })
            }
        } else {
            message.error(lang.cancellation_reason_is_required)
        }
    }

    const onCancel = () => {
        setIsvisible(true)
    }
    const onClose = () => {
        setIsvisible(false)
        setCancellationReason('')
        form.setFieldsValue({ cancellation_reason: '' })
    }
    /// /render///////

    return (
        <Popover
            placement="topLeft"
            trigger="click"
            style={{ width: '100%' }}
            overlayClassName="popover-pos"
            visible={isVisible}
            content={
                <Row>
                    <Col xs={24} md={24} className="border-bottom-1">
                        <h3>{lang.cancellation_invoice}</h3>
                    </Col>
                    <Col xs={24} md={24} className="pb-10 pt-15">
                        <Form.Item
                            label={lang.cancellation_reason}
                            name="cancellation_reason"
                        >
                            <TextArea onChange={(e) => setCancellationReason(e.target.value)} size="large" allowClear rows={1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24} className="text-right border-top-1 pt-15">
                        <Space>
                            <Button
                                className="w-100"
                                disabled={!invoiceId}
                                onClick={onClose}
                                type="primary"
                                size="large"
                                block
                            ><CloseCircleOutlined /> {lang.close}
                            </Button>
                            <Popconfirm
                                placement="topRight"
                                title={lang.sure_cancel}
                                onConfirm={onSubmitCancel}
                                okText={lang.ok}
                                cancelText={lang.cancel}
                                className="popover-reason-cancel"
                            >
                                <Button
                                    onClick={() => onCancel(c.INVOICE_STATUS.CANCEL)}
                                    disabled={!invoiceId}
                                    type="primary"
                                    size="large"
                                ><SaveOutlined /> {lang.save}</Button>
                            </Popconfirm>
                        </Space>
                    </Col>
                </Row>
            }
        >
            <Button
                onClick={() => onCancel(c.INVOICE_STATUS.CANCEL)}
                className="w-100"
                disabled={!invoiceId}
                size="large"
                icon={<CloseCircleOutlined

                />} key="confirm" type="primary" >
                {lang.cancel}
            </Button>
        </Popover>
    );
};

export default InvoiceActionDetailCancel;
