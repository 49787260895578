import React from 'react';
import { useSelector } from 'react-redux';
import AccountChangePassword from '../../../modules/Account/components/AccountChangePassword';
import * as c from '../../../constants/config';
import HeaderRightSetting from '../Header/HeaderRight/HeaderRightSetting';
import Account from './../../../modules/Account/components/Account';

const Footer = (props) => {
  const isHome = useSelector(state => state.common.isHome);

  return (
    <div style={{ display: 'none' }}>
      {
        !isHome &&
        <footer className="main-footer pt-1 pb-1">
          <div className="float-right d-none d-sm-block font-14">
            <b>Version</b> 1.0.0
          </div>
          <strong className="font-14">Copyright © 2021 <a href="http://hawk.vn">{c.APP_TITLE}</a>.</strong> All rights reserved.
        </footer>
      }
      <HeaderRightSetting />
      <Account />
      <AccountChangePassword />
    </div>
  );
};

export default Footer;
