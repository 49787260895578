/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import { Drawer, Button, Image, Form } from 'antd'
import lang from '../../../../language/language';
import * as c from '../../../../constants/common';
import CustomerDetail from '../../../Customer/components/CustomerDetail/CustomerDetail'
import {
    EyeOutlined,
    PhoneOutlined,
    MailOutlined
} from '@ant-design/icons';
import noImage from './../../../../assets/images/default-user.png'
import { formatPhone } from '../../../Common/components/FormatData';
const InvoiceActionInfoCustomer = (props) => {
    const { data } = props;
    const [isVisibleDetailCustomer, setIsVisibleDetailCustomer] = useState(false)
    const [form] = Form.useForm();
    /// /funtion///////
    const resetData = () => {
        form.resetFields();
        setIsVisibleDetailCustomer(false)
    };

    /// /render///////
    return (
        <>
            <Button
                style={{ width: 40, height: 40, marginLeft: 5 }}
                size="middle"
                icon={<EyeOutlined />}
                className='float-right'
                id="btn-view-detail-customer"
                onClick={() => setIsVisibleDetailCustomer(true)}
            />
            <Drawer
                title={lang.customer_info}
                placement="right"
                width={c.WIDTH_DRAWER.LARGE}
                onClose={resetData}
                visible={isVisibleDetailCustomer}
            >
                <Form
                    autoComplete="off"
                    form={form}
                    layout="vertical"
                // initialValues={{ is_active: c.IS_ACTIVE }}
                >
                    <div className="text-center border-bottom-1 pb-15">
                        <Image
                            style={{ width: 100, height: 100 }}
                            className="img-avatar"
                            preview={false}
                            src={(data && data.customer_avatar) || noImage}
                        />
                        <h3>{data.customer_fullname}</h3>
                        {data.customer_phone_number && <div><PhoneOutlined /> {formatPhone(data.customer_phone_number)}</div>}
                        {data.customer_email && <div><MailOutlined /> {data.customer_email}</div>}
                    </div>
                    <CustomerDetail classContentTab="content-customer-detail-pos" data={data} isView />
                </Form>
            </Drawer>
        </>
    );
};

export default InvoiceActionInfoCustomer;
