import React, { useEffect } from 'react';
import lang from '../../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../../constants/config';
import { Row, Col, Form, Select, Button, message, Card } from 'antd';
import * as c from '../../../../constants/common';
import { setLoading } from '../../../Common/slice';
import { useSelector, useDispatch } from 'react-redux';
import * as generalSettingService from './../service';
import { generalSettingThunk } from './../thunk'
import {
  SaveOutlined
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';

const { Option } = Select;

const GeneralSetting = (props) => {
  const [form] = Form.useForm();
  const generalSetting = useSelector(state => state.generalSetting);
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({
      general_setting_currency: generalSetting.general_setting_currency,
      general_setting_date_format: generalSetting.general_setting_date_format,
      general_setting_hour_format: generalSetting.general_setting_hour_format,
      general_setting_country: generalSetting.general_setting_country
    });
  }, [generalSetting]);

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = generalSetting.general_setting_id ? 'PUT' : 'POST';
    const dataReq = {
      general_setting_date_format: data.general_setting_date_format,
      general_setting_hour_format: data.general_setting_hour_format,
      general_setting_currency: data.general_setting_currency,
      general_setting_id: generalSetting.general_setting_id,
      general_setting_country: data.general_setting_country
    };

    generalSettingService.generalSettingCreateUpdate(dataReq, method).then(res => {
      if (res) {
        dispatch(setLoading(false));
        message.success(lang.save_success);
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(generalSettingThunk({}));
    })
  }

  return (
    <Card>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.setting_general}</title>
      </Helmet>
      <Form
        onFinish={onSubmit}
        autoComplete="off"
        form={form}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.setting_country}
              name="general_setting_country"
            >
              <Select
                size="large"
                value={generalSetting.general_setting_country}
                placeholder={lang.setting_country}
              >
                {c.OPTION_COUNTRY.map((item) => {
                  return <Option key={item.value} value={item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.setting_currency}
              name="general_setting_currency"
            >
              <Select
                size="large"
                value={generalSetting.general_setting_currency}
                placeholder={lang.setting_currency}
              >
                {c.OPTION_SETTING_CURRENCY.map((item) => {
                  return <Option key={item.value} value={item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.setting_date_format}
              name="general_setting_date_format"
            >
              <Select
                size="large"
                placeholder={lang.setting_date_format}
              >
                {c.OPTION_SETTING_DATE_FORMAT.map((item) => {
                  return <Option key={item.value} value={item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              label={lang.general_setting_hour_format}
              name="general_setting_hour_format"
            >
              <Select
                size="large"
                placeholder={lang.general_setting_hour_format}
              >
                {c.OPTION_SETTING_HOUR_FORMAT.map((item) => {
                  return <Option key={item.value} value={item.value}>{item.label}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={24} className="text-center mt-15">
            <CheckPermission permission={['GENERAL_SETTING_EDIT']}>
              <Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary" >
                {lang.save}
              </Button>
            </CheckPermission>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GeneralSetting;
