import React, { useEffect, useState } from 'react';
import { getService } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import {
  DollarCircleOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import { formatCurrency } from '../../Common/components/FormatData';
import { useSelector } from 'react-redux';
const { Option } = Select;

const ServiceSelect = (props) => {
  const {
    isMultiple,
    label, name,
    showSearch,
    allowClear,
    hideLabel,
    required,
    style,
    disabled,
    listIdSelected,
    staffId,
    onChange,
    onSelect
  } = props;
  const [options, setOptions] = useState([])
  const generalSetting = useSelector(state => state.generalSetting);

  useEffect(() => {
    getService({ staff_id: staffId }).then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const onSelected = (data, options) => {
    if (onSelect) {
      onSelect(options.data)
    }
  }
  const validateServiceSelect = (rule, value, getFieldValue) => {
    if (value) {
      if (!isMultiple) {
        const res = options.find((i) => i.service_id === value);
        if (value && res.is_active === c.IS_DEACTIVE) {
          return Promise.reject(new Error(`${res.service_name} ${lang.option_deactive_validation}`));
        }
        return Promise.resolve();
      } else {
        if (value) {
          const arr = options.filter(i => value.includes(i.service_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
          const text = arr.map((y) => y.service_name)
          if (arr.length > 0 && value.length > 0) {
            return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
          }
          return Promise.resolve();
        }
      }
    } else return Promise.resolve();
  }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : (label || lang.service)}
      name={name || 'service'}
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateServiceSelect(rule, value, getFieldValue)
        })
      ]}
    >
      <Select
        style={style}
        size="large"
        showSearch={showSearch || true}
        allowClear={allowClear || true}
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={label || lang.service}
        disabled={disabled || false}
        onChange={onChange}
        onSelect={onSelected}
        filterOption={(input, option) => {
          const data = option.data.service_name
          return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {options.map((item) => {
          return <Option
            key={item.service_id}
            value={item.service_id}
            data={item}
            className={listIdSelected && listIdSelected.length > 0 && listIdSelected.filter(e => e !== undefined).map(a => a.service).includes(item.service_id) ? 'd-none' : ''}
          >
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.service_name}
              <span className="f-13 float-right"><DollarCircleOutlined /> <span className={`${item.service_price_discount && 'line-through limiter'}`}>{formatCurrency(item.service_price, generalSetting)}</span> {item.service_price_discount && <span>{formatCurrency(item.service_price_discount, generalSetting)}</span>} - <ClockCircleOutlined /> {item.service_duration} {lang.minute}</span>
            </p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default ServiceSelect;
