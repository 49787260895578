/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React from 'react';
import lang from '../../../../language/language';
import { Table, Row, Col } from 'antd';
import * as c from '../../../../constants/common';

const CustomerGroupDetailCondition = (props) => {
    const { dataDetail } = props

    /// /funtion///////
    const dataSource = (data = null) => {
        let result = [];
        if (data && data.customer_group_condition &&
            data.customer_group_condition.length > 0
        ) {
            result = data.customer_group_condition.map((item, index) => {
                return {
                    key: index,
                    condition_type: item.condition_type.label,
                    condition_compare: c.CONDITION_COMPARE.filter((i) => i.value === item.condition_compare.value)[0].label,
                    condition_value: item.condition_value
                }
            })
        }
        return result;
    }

    const columns = [
        {
            title: lang.condition_type,
            dataIndex: 'condition_type',
            key: 'condition_type',
            ellipsis: true,
            textWrap: 'word-break',
            width: 200
        },
        {
            title: lang.condition,
            dataIndex: 'condition_compare',
            key: 'condition_compare',
            textWrap: 'word-break',
            width: 200
        },
        {
            title: lang.condition_value,
            dataIndex: 'condition_value',
            key: 'condition_value',
            textWrap: 'word-break',
            width: 200
        }
    ];

    /// /render///////
    return (
        <Row className="table-detail">
            <Col xs={24} md={24} lg={24}>
                <Table
                    className="table-normal"
                    pagination={false}
                    size="large"
                    dataSource={dataSource(dataDetail)}
                    columns={columns}
                />
            </Col>
        </Row>
    );
};

export default CustomerGroupDetailCondition;
