import React, { useEffect, useState } from 'react';
import { getStaff } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';

const { Option } = Select;

const StaffSelect = (props) => {
  const {
    isMultiple,
    label,
    showSearch,
    allowClear,
    hideLabel,
    required,
    style,
    disabled,
    name,
    onSelect,
    onClear,
    listIdRemove,
    isTechnician,
    size,
    classNameForm,
    allowBooking,
    allowCheckin,
    defaultValue,
    serviceId
  } = props;
  const [options, setOptions] = useState([])
  const currentBranch = JSON.parse(localStorage.getItem('branch'))

  useEffect(() => {
    const reqData = {
      listBranchId: [currentBranch.branch_id],
      service_id: serviceId || null
    }
    getStaff(reqData).then(res => {
      if (res.data) {
        let newData = res.data
        if (isTechnician) {
          newData = newData.filter(z => z.is_technician === 1)
        }
        if (allowBooking) {
          newData = newData.filter(z => z.staff_allow_booking === 1)
        }
        if (allowCheckin) {
          newData = newData.filter(z => z.staff_allow_checkin === 1)
        }
        setOptions(newData)
      }
    })
  }, []);

  const validateStaffSelect = (rule, value, getFieldValue) => {
    if (value) {
      if (!isMultiple) {
        const res = options.find((i) => i.staff_id === value);
        if (value && res.is_active === c.IS_DEACTIVE) {
          return Promise.reject(new Error(`${res.staff_fullname} ${lang.option_deactive_validation}`));
        }
        return Promise.resolve();
      } else {
        if (value) {
          const arr = options.filter(i => value.includes(i.staff_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
          const text = arr.map((y) => y.staff_fullname)
          if (arr.length > 0 && value.length > 0) {
            return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
          }
          return Promise.resolve();
        }
      }
    } else return Promise.resolve();
  }

  const onSelected = (data) => {
    if (onSelect) {
      onSelect(data)
    }
  }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : (label || lang.staff)}
      name={name || 'staff'}
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateStaffSelect(rule, value, getFieldValue)
        })
      ]}
      className={classNameForm}
    >
      <Select
        style={style}
        size={size || 'large'}
        showSearch={showSearch || true}
        allowClear={allowClear === false ? allowClear : true}
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={label || lang.staff}
        disabled={disabled || false}
        onSelect={onSelected}
        onClear={onClear}
        defaultValue={defaultValue || null}
        filterOption={(input, option) => {
          const data = option.data.staff_fullname
          return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {options.map((item) => {
          return <Option
            className={listIdRemove && listIdRemove.length > 0 && listIdRemove.filter(e => e !== undefined).includes(item.staff_id) ? 'd-none' : ''}
            key={item.staff_id} value={item.staff_id} data={item}>
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.staff_fullname}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default StaffSelect;
