/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { Col, List, Row, Typography } from 'antd';
import React from 'react';
import lang from '../../../../language/language';
import { formatDate } from '../../../Common/components/FormatData';
const { Text } = Typography;

const EmailHistoryDetail = (props) => {
    const { data } = props;

    /// /funtion///////

    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24} lg={24} className="ml-15">
                <List
                    size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'key' &&
                                x !== 'email_history_id' &&
                                x !== 'email_history_content'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date'
                                        ? formatDate(data[item], true)
                                        : (String(data[item]).includes('{DELETE}'))
                                            ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                            : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
                {/* <Row>
                     <Col xs={12} lg={9}>{lang.service_group_description}</Col>
                    <Col xs={24} lg={24}>
                        <TinyEditor value={data.service_group_description} />
                    </Col>
                </Row> */}
            </Col>
        </Row>
    );
};

export default EmailHistoryDetail;
