/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { setStaffTypeId, setStaffTypeQueryData, setStaffTypeListId } from '../slice';
import * as staffThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import StaffTypeDetail from './StaffTypeDetail';
import StaffTypeActionChecked from './StaffTypeAction/StaffTypeActionChecked';
import StaffTypeFilter from './StaffTypeFilter';
import { Row, Col, Space, Input } from 'antd'
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { updateTitle, setLoadingTable } from '../../Common/slice';
import AntdTable from '../../Common/components/AntdTable/AntdTable';
import StaffTypeAction from './StaffTypeAction/StaffTypeAction';
import * as c from '../../../constants/common';

const { Search } = Input;

const StaffType = (props) => {
  const dispatch = useDispatch();
  const staffTypeQueryData = useSelector(state => state.staffType.staffTypeQueryData);
  const staffTypeListId = useSelector(state => state.staffType.staffTypeListId);
  const dataList = useSelector(state => state.staffType.staffTypeList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setStaffTypeListId([]));
      dispatch(setStaffTypeId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.service));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(staffTypeQueryData);
  }, [staffTypeQueryData]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (queryData) => {
    setLoadingTable(true);
    dispatch(staffThunk.staffTypeList(queryData));
  };

  const onSearch = (value, e) => {
    dispatch(setStaffTypeQueryData({ ...staffTypeQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  /// /render///////
  return (
    <div name="content" className="content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.title}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            placeholder={lang.search_staff_type}
            enterButton
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <Space>
                <StaffTypeActionChecked />
                <StaffTypeAction />
                <StaffTypeFilter />
                {/* <Button onClick={onExportData} type="primary" icon={<DownloadOutlined />} /> */}
                {/* <CustomerImport/> */}
                <AntdToggleColumn
                  data={dataList}
                  queryData={staffTypeQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            listIdChecked={staffTypeListId}
            queryData={staffTypeQueryData}
            keyField="staff_type_id"
            clickField="staff_type_name"
            setListIdChecked={setStaffTypeListId}
            setQueryData={setStaffTypeQueryData}
            expandedRowRender={(record) => <StaffTypeDetail data={record} queryData={staffTypeQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StaffType;
