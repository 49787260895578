import { createAsyncThunk } from '@reduxjs/toolkit';
import * as staffService from './service';

export const staffTitleList = createAsyncThunk(
  'staffTitle/staffTitleList',
  async (data, thunkAPI) => {
    const response = await staffService.staffTitleList(data);
    return response.data;
  }
);
