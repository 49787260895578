import { Button, Image, Popconfirm } from 'antd';
import React, { useState } from 'react';
import lang from '../../../language/language';
import {
    DeleteOutlined,
    CheckCircleFilled,
    EyeFilled
} from '@ant-design/icons';

const CustomImage = (props) => {
    const { onSelectImage, selectList, onDelete, item, className, width, height, src } = props
    const [visible, setVisible] = useState(false);

    const onSelectImageData = (data) => {
        if (onSelectImage) {
            onSelectImage(data)
        }
    }
    /// render/////
    return (
        <div>
            <div className="chk-img" style={{ zIndex: 1 }}>
                <Button
                    size="middle"
                    icon={<EyeFilled />}
                    shape="circle"
                    onClick={() => setVisible(true)}
                >
                </Button>
            </div>

            <div className="cursor-pointer" onClick={(e) => onSelectImageData(item)}>
                <Image
                    src={src}
                    className={className}
                    width={width || '100%'}
                    height={height}
                    preview={false}
                />
                <Image
                    src={src}
                    className='d-none'
                    preview={{
                        visible,
                        src: src,
                        onVisibleChange: (value) => {
                            setVisible(value);
                        },
                        mask: <div className='d-none'></div>
                    }}
                />
                {selectList && selectList.length > 0 && selectList.includes(item) && <div className="icon-check-select-image">
                    <CheckCircleFilled />
                </div>}
            </div>
            <div className="btn-remove-image">
                <Popconfirm
                    placement="top"
                    title={lang.sure_delete}
                    onConfirm={() => onDelete(item)}
                    okText={lang.ok}
                    cancelText={lang.cancel}
                >
                    <Button
                        size="middle"
                        icon={<DeleteOutlined />}
                        shape="circle"
                    >
                    </Button>
                </Popconfirm>
            </div>
        </div>
    );
};

export default CustomImage;
