import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Col, Row } from 'antd';

import { updateTitle } from '../../../modules/Common/slice';
import lang from '../../../language/language';
import { APP_TITLE } from '../../../constants/config';
import DashBoardReportIncome from './DashBoardReportIncome';
import DashBoardTopTenService from './DashBoardTopTenService';
import DashBoardDailyStatistical from './DashBoardDailyStatistical';
import DashBoardServicePack from './DashBoardServicePack';
import DashBoardActivityHistory from './DashBoardActivityHistory';
import DashBoardCustomerVisit from './DashBoardCustomerVisit'
import DashBoardRated from './DashBoardRated';
import CheckPermission from '../../Common/components/CheckPermission';

const DashBoard = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateTitle(lang.dash_board));
  }, [dispatch]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.dash_board}</title>
      </Helmet>
      <Row>
        <Col xs={24} md={18}>
          <CheckPermission permission={['DASHBOARD_REPORT_STATISTIC_VIEW']}>
            <DashBoardDailyStatistical />
          </CheckPermission>
          <CheckPermission permission={['DASHBOARD_REPORT_PACK_VIEW']}>
            <DashBoardServicePack />
          </CheckPermission>
        </Col>
        <CheckPermission permission={['DASHBOARD_ACTIVITY_HISTORY_VIEW']}>
          <Col xs={24} md={6} className="pl-15">
            <DashBoardActivityHistory />
          </Col>
        </CheckPermission>
        <CheckPermission permission={['DASHBOARD_REPORT_INCOME_VIEW']}>
          <Col xs={24} md={24}>
            <DashBoardReportIncome />
          </Col>
        </CheckPermission>
        <CheckPermission permission={['DASHBOARD_REPORT_CUSTOMER_VISIT_VIEW']}>
          <Col xs={24} md={12} className="pr-10">
            <DashBoardCustomerVisit />
          </Col>
        </CheckPermission>
        <CheckPermission permission={['DASHBOARD_REPORT_RATING_VIEW']}>
          <Col xs={24} md={12} className="pl-10">
            <DashBoardRated />
          </Col>
        </CheckPermission>
        <CheckPermission permission={['DASHBOARD_REPORT_TOP_TEN_SERVICE_VIEW']}>
          <Col xs={24} md={24}>
            <DashBoardTopTenService />
          </Col>
        </CheckPermission>
      </Row>
    </div>
  );
};

export default DashBoard;
