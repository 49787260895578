import { createAsyncThunk } from '@reduxjs/toolkit';
import * as imageGalleryService from './service';

export const imageGalleryListThunk = createAsyncThunk(
  'imageGallery/imageGalleryList',
  async (data, thunkAPI) => {
    const response = await imageGalleryService.imageGallery(data);
    return response.data;
  }
);
