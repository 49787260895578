import CallApi from '../../../utils/CallAPI';
import {
  GET_ROLE_BY_USER,
  ROLE,
  ROLE_LIST_ACTION,
  ROLE_DETAIL,
  ROLE_ALL,
  ROLE_BY_APP_DEACTIVE
} from './constants/endpoint';

export const getRoleByUser = (data) => {
  return CallApi(GET_ROLE_BY_USER, 'GET', data)
};

export const roleList = (data) => {
  return CallApi(ROLE, 'GET', data)
};

export const roleListAction = (data) => {
  return CallApi(ROLE_LIST_ACTION, 'GET', data)
};

export const roleCreateUpdate = (data, method) => {
  return CallApi(ROLE, method, data)
};

export const roleDetail = (data) => {
  return CallApi(ROLE_DETAIL, 'GET', data)
};

export const roleListAll = (data) => {
  return CallApi(ROLE_ALL, 'GET', data)
};

export const roleDelete = (data) => {
  return CallApi(ROLE, 'DELETE', data)
};
export const getRoleListByAppDeActive = (data) => {
  return CallApi(ROLE_BY_APP_DEACTIVE, 'GET', data)
};
