import { createSlice } from '@reduxjs/toolkit';
import * as appointmentSettingThunk from './thunk';

export const appointmentSettingSlice = createSlice({
  name: 'appointmentSetting',
  initialState: {

  },
  reducers: {

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [appointmentSettingThunk.appointmentSettingThunk.fulfilled]: (state, action) => {
      return action.payload;
    }
  }
});

// export const {

// } = appointmentSlice.actions;

export default appointmentSettingSlice.reducer;
