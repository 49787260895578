/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect, useState } from 'react';
import AntdTable from '../../../Common/components/AntdTable/AntdTable';
import { Row, Col } from 'antd';
import * as c from '../../../../constants/common';
import * as giftCardService from '../../service';
import { getInfoUser } from '../../../Common/components/GetInfoUser';
import GiftCardDetailHistoryFilter from './GiftCardDetailHistoryFilter';
import { setLoadingTable } from '../../../Common/slice';
import { useSelector } from 'react-redux';

const GiftCardDetailHistory = (props) => {
  const { data } = props
  const [dataList, setDataList] = useState({})
  const [queryData, setQueryData] = useState({
    page: c.SIZE_PAGE.PAGE,
    sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_DETAIL,
    search: '',
    fromDate: '',
    toDate: '',
    orderBy: '',
    userId: getInfoUser.user_id,
    branchId: null,
    userIdList: [],
    giftCardId: null
  })
  const loading = useSelector(state => state.common.loading);

  useEffect(() => {
    if (data.gift_card_id) {
      setDataList({})
      setQueryData({
        page: c.SIZE_PAGE.PAGE,
        sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE_DETAIL,
        search: '',
        fromDate: '',
        toDate: '',
        orderBy: '',
        userId: getInfoUser.user_id,
        branchId: null,
        userIdList: [],
        giftCardId: data.gift_card_id
      })
    }
  }, [data.gift_card_id]);

  useEffect(() => {
    if (queryData.giftCardId) {
      fetchDataList()
    }
  }, [queryData]);

  useEffect(() => {
    if (queryData.giftCardId) {
      setTimeout(() => {
        fetchDataList()
      }, 500)
    }
  }, [loading]);

  useEffect(() => {
    setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = () => {
    giftCardService.giftCardHistoryList(queryData).then(res => {
      if (res) {
        setDataList(res.data)
      }
    })
  };

  /// /render///////
  return (
    <div >
      <Row className="table-detail pl-10 pr-10">
        <Col span={24}>
          <Row type="flex" justify="end">
            <Col>
              <GiftCardDetailHistoryFilter queryData={queryData} setQueryData={setQueryData} />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <AntdTable
            data={dataList}
            queryData={queryData}
            keyField="gift_card_history_id"
            clickField="invoice_code"
            // onClickKey={(record) => onClickKey(record)}
            hideCheckbox
            setQueryDataLocal={setQueryData}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardDetailHistory;
