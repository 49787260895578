/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
    CloseCircleOutlined,
    SaveOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Card, Col, Drawer, Form, Input, message, Row, Space } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../language/language'
import * as c from '../../../constants/common'
import CheckPermission from '../../Common/components/CheckPermission';
import { RandomCode } from '../../Common/components/RandomCode';
import StaffSelect from '../../Common/components/StaffSelect';
import { setLoading, setLoadingTable } from '../../Common/slice';
import { setCurrentStaffTimeKeeping, setIsShowTimeKeepingAction, setTimeKeepingId } from '../slice';
import * as timeKeepingService from '../service';
import * as timeKeepingThunk from '../thunk';
import { formatDate } from '../../Common/components/FormatData';

const TimeKeepingAction = (props) => {
    const dispatch = useDispatch();
    const timeKeepingId = useSelector(state => state.timeKeeping.timeKeepingId);
    const userInfo = useSelector(state => state.user.userInfo);
    const isShowTimeKeepingAction = useSelector(state => state.timeKeeping.isShowTimeKeepingAction)
    const currentStaffTimeKeeping = useSelector(state => state.timeKeeping.currentStaffTimeKeeping)
    const timeKeepingQueryData = useSelector(state => state.timeKeeping.timeKeepingQueryData)
    const generalSetting = useSelector(state => state.generalSetting)
    const currentBranch = useSelector(state => state.branch.currentBranch)
    const [form] = Form.useForm();
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss')
    const [dataDetail, setDataDetail] = useState({})
    const [listIdRemove, setListIdRemove] = useState([])

    /// //////////////////////// Validate /////////////////////////
    useEffect(() => {
        createListIdRemove()
    }, [isShowTimeKeepingAction])
    useEffect(() => {
        form.setFieldsValue({
            staff: currentStaffTimeKeeping
        });
    }, [currentStaffTimeKeeping])

    useEffect(() => {
        fetchDataList(timeKeepingQueryData);
    }, [timeKeepingQueryData]);

    useEffect(() => {
        if (timeKeepingId) getNewTimeKeepingDetail()
    }, [timeKeepingId])
    /// ///function////

    const fetchDataList = (queryData) => {
        setLoadingTable(true);
        dispatch(timeKeepingThunk.timeKeepinglist(queryData));
    };

    const getNewTimeKeepingDetail = (data) => {
        dispatch(setLoading(true));
        const dataReq = {
            time_keeping_id: timeKeepingId
        };
        timeKeepingService.getTimeKeepingDetail(dataReq).then(res => {
            if (res) {
                const data = res.data
                form.setFieldsValue({
                    checkin_time: moment(data.time_keeping_time_in).format('hh:mm A'),
                    checkout_time: data.time_keeping_time_out ? moment(data.time_keeping_time_out).format('hh:mm A') : '',
                    time_keeping_note: data.time_keeping_note
                });
                setDataDetail(data)
            }
        }).finally(() => {
            dispatch(setLoading(false));
        })
    };

    const createListIdRemove = () => {
        dispatch(setLoading(true));
        const dataReq = {
            currentDate: moment().format('YYYY-MM-DD'),
            branch_id: currentBranch.branch_id
        };
        timeKeepingService.getListIdRemove(dataReq).then(res => {
            if (res) {
                setListIdRemove(res.data)
            }
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }
    const onSubmit = (data) => {
        dispatch(setLoading(true));
        const method = timeKeepingId ? 'PUT' : 'POST';
        const dataReq = {
            time_keeping_id: timeKeepingId || '',
            time_keeping_time_in: dataDetail.time_keeping_time_in ? dataDetail.time_keeping_time_in : currentTime,
            time_keeping_time_out: timeKeepingId ? currentTime : '',
            time_keeping_code: RandomCode(),
            time_keeping_note: data.time_keeping_note || '',
            create_user: userInfo.user_id,
            staff_id: data.staff,
            branch_id: currentBranch.branch_id
        };
        timeKeepingService.checkinTimeKeeping(dataReq, method).then(res => {
            if (res) {
                dispatch(setLoading(false));
                // luu thanh cong
                message.success(lang.save_success)
                resetData()
                onClose()
            }
        }).finally(() => {
            dispatch(setLoading(false));
        })
    };

    const resetData = () => {
        // cho phep edit
        // genCode();
        dispatch(setCurrentStaffTimeKeeping(null))
        form.resetFields();
        dispatch(setTimeKeepingId(null))
        setDataDetail({})
    };

    const onClose = () => {
        resetData()
        dispatch(timeKeepingThunk.timeKeepinglist(timeKeepingQueryData));
        dispatch(setIsShowTimeKeepingAction(false))
    }

    const onSave = () => {
        form.submit()
    }

    const showTitle = () => {
        let result = lang.staff_timekeeping_title
        if (!dataDetail.time_keeping_time_in) {
            result = result + ' ' + lang.time_keeping_time_in.toLowerCase() + ' ' + lang.day.toLowerCase() + ' ' + formatDate(moment(), false, generalSetting)
        } else if (dataDetail.time_keeping_time_in && !dataDetail.time_keeping_time_out) {
            result = result + ' ' + lang.time_keeping_time_out.toLowerCase() + ' ' + lang.day.toLowerCase() + ' ' + formatDate(moment(), false, generalSetting)
        } else {
            result = result + ' ' + lang.day.toLowerCase() + ' ' + formatDate(moment(), false, generalSetting)
        }
        return result
    }

    /// render/////
    return (
        <span>
            <>
                <CheckPermission permission={['TIME_KEEPING_ADD']}>
                    <Button className="mb-15" type="primary" size="large" id="timeKeepingAction" onClick={() => dispatch(setIsShowTimeKeepingAction(true))} icon={<PlusOutlined />} />
                </CheckPermission>
                <Drawer
                    title={showTitle()}
                    placement="right"
                    width={c.WIDTH_DRAWER.SMALL}
                    onClose={onClose}
                    visible={isShowTimeKeepingAction}
                    footer={
                        <Space className="text-center">
                            <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                                {lang.close}
                            </Button>
                            <CheckPermission permission={['TIME_KEEPING_ADD']}>
                                {!dataDetail.time_keeping_time_out && <Button size="large" icon={<SaveOutlined />} onClick={onSave} key="submit" type="primary" >
                                    {lang.save}
                                </Button>}
                            </CheckPermission>
                        </Space>
                    }
                >

                    <Form
                        onFinish={onSubmit}
                        autoComplete="off"
                        form={form}
                        layout="vertical"
                        initialValues={{
                            staff: currentStaffTimeKeeping
                        }}
                    >
                        <Row gutter={24}>
                            {dataDetail.time_keeping_time_in && <Col className="font-weight-bold f-14 text-center" xs={24} md={12}>
                                <Card>
                                    {lang.time_keeping_time_in}: {moment(dataDetail.time_keeping_time_in).format('hh:mm A')}
                                </Card>
                            </Col>}
                            {dataDetail.time_keeping_time_out && <Col className="font-weight-bold f-14 text-center" xs={24} md={12}>
                                <Card>
                                    {lang.time_keeping_time_out}: {moment(dataDetail.time_keeping_time_out).format('hh:mm A')}
                                </Card>
                            </Col>}
                            <Col xs={24} md={24} className="mt-5">
                                <StaffSelect
                                    disabled={!!dataDetail.time_keeping_time_in}
                                    listIdRemove={listIdRemove}
                                />
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    label={lang.time_keeping_note}
                                    name="time_keeping_note"
                                >
                                    <Input.TextArea
                                        disabled={dataDetail.time_keeping_time_in && dataDetail.time_keeping_time_out}
                                        size="large" allowClear rows={1} />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Drawer>
            </>
        </span>
    );
};

export default TimeKeepingAction;
