import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { branchByUser } from '../../../../modules/Setting/Branch/thunk';
import { setCurrentBranch } from '../../../../modules/Setting/Branch/slice';
import { getRoleByUser } from '../../../../modules/Setting/Role/thunk';
import lang from '../../../../language/language';
import { Select } from 'antd';
const { Option } = Select;

const HeaderRightBranch = (props) => {
  const userInfo = useSelector(state => state.user.userInfo)
  const branchList = useSelector(state => state.branch.branchByUser)
  // const currentBranch = useSelector(state => state.branch.currentBranch)
  const branchListData = useSelector(state => state.branch.branchList);

  const dispatch = useDispatch();

  const branch = JSON.parse(localStorage.getItem('branch')) || {}
  /// ///// useEffect

  useEffect(() => {
    if (userInfo.user_id) {
      const data = {
        user_id: userInfo.user_id
      }
      dispatch(branchByUser(data))
    }
  }, [userInfo.user_id, branchListData])

  useEffect(() => {
    if (Object.keys(branch).length === 0 && branchList.length > 0 && userInfo.user_id) {
      localStorage.setItem('branch', JSON.stringify(branchList[0]))
      dispatch(getRoleByUser({ user_id: userInfo.user_id, branch_id: branchList[0].branch_id }))
    }
  }, [branchList, userInfo])

  useEffect(() => {
    if (userInfo.user_id && branch && Object.keys(branch).length > 0) {
      dispatch(getRoleByUser({ user_id: userInfo.user_id, branch_id: branch.branch_id }))
      dispatch(setCurrentBranch(branch))
    }
  }, [userInfo])

  /// //funtion/////

  const handleBranch = (value) => {
    localStorage.setItem('branch', JSON.stringify(branchList.filter(i => i.branch_id === value)[0]))
    window.location.reload();
  }

  return (
    <Select
      showSearch
      size="middle"
      placeholder={lang.branch}
      onChange={handleBranch}
      value={branchList.length > 0 ? branch.branch_id : null}
      style={{ minWidth: '50%' }}
      loading={!(branchList.length > 0)}
    >
      {branchList.map((item) => {
        return <Option key={item.branch_id} value={item.branch_id}>{item.branch_name}</Option>
      })}
    </Select>

  );
};

export default HeaderRightBranch;
