/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import { Button, Col, List, Row, Space, Table, Typography, Tooltip } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import lang from '../../../language/language';
import { formatCurrency, formatDate, formatIsActive, formatPhone } from '../../Common/components/FormatData';
// import TinyEditor from '../../Common/components/TinyEditor';
import * as c from '../../../constants/common';
import { invoiceCopy, invoiceDetail } from '../service'
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Common/slice';
import CheckPermission from '../../Common/components/CheckPermission';
import {
    EditOutlined,
    PrinterOutlined,
    CopyOutlined,
    EyeOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { ROUTE_POS } from '../../Invoice/constants/routes';
import { setInvoiceId } from '../../Invoice/slice';
import InvoiceDetailCancel from './InvoiceDetailCancel';
import { useReactToPrint } from 'react-to-print';
import InvoicePrint from './InvoicePrint';
import InvoicePrintTicket from './InvoicePrintTicket';
import { RandomCode } from '../../Common/components/RandomCode';
import { getInvoiceTotalSurcharge } from '../common';

const { Text } = Typography;

const InvoiceDetail = (props) => {
    const { data } = props;
    const [dataInvoiceDetail, setDataInvoiceDetail] = useState({})
    const [dataInvoiceItemList, setDataInvoiceItemList] = useState([])
    const generalSetting = useSelector(state => state.generalSetting);
    const dispatch = useDispatch();
    const history = useHistory();
    const posSetting = useSelector(state => state.posSetting);
    const userInfo = useSelector(state => state.user.userInfo);
    const printRef = useRef();
    const printTicketRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    });

    const handlePrintTicket = useReactToPrint({
        content: () => printTicketRef.current
    });

    useEffect(() => {
        if (data.invoice_id) {
            dispatch(setLoading(true))
            invoiceDetail({ invoice_id: data.invoice_id }).then(res => {
                if (res.data) {
                    setDataInvoiceDetail(res.data)
                    setDataInvoiceItemList(res.data.invoiceItemList)
                }
            }).finally(x => {
                dispatch(setLoading(false))
            })
        }
    }, [data.invoice_id])

    useEffect(() => {
        if (!dataInvoiceDetail.invoice_cancellation_reason) {
            delete dataInvoiceDetail.invoice_cancellation_reason
            setDataInvoiceDetail(dataInvoiceDetail)
        }
    }, [dataInvoiceDetail])

    useEffect(() => {
        dispatch(setInvoiceId(''))
    }, [])

    /// /funtion///////

    const onEdit = () => {
        dispatch(setInvoiceId(data.invoice_id))
        history.push(ROUTE_POS)
    }
    const columns = [
        {
            title: lang.invoice_item_code,
            dataIndex: 'invoice_item_code',
            width: 90
        },
        {
            title: lang.invoice_item_name,
            dataIndex: 'invoice_item_name',
            width: 165
        },
        {
            title: lang.invoice_item_quantity,
            dataIndex: 'invoice_item_quantity',
            width: 90,
            align: 'center'
        },
        {
            title: lang.invoice_item_price,
            dataIndex: 'invoice_item_price',
            align: 'center',
            width: 120,
            render: (text, record) => {
                return <div className='text-center font-weight-bold'>
                    <span className={`${record.invoice_item_price_discount && 'line-through limiter'}`}>{formatCurrency(record.invoice_item_price, generalSetting)}</span>
                    {record.invoice_item_price_discount && <span> {formatCurrency(record.invoice_item_price_discount, generalSetting)}</span>}
                </div>
            }
        },
        {
            title: lang.invoice_item_total_price,
            dataIndex: 'invoice_item_total_price',
            align: 'center',
            width: 120,
            render: (text, record) => {
                return <div className='text-center font-weight-bold'>{formatCurrency(record.invoice_item_total_price, generalSetting)}</div>
            }
        }
    ];

    const onCopy = () => {
        if (data.invoice_id) {
            dispatch(setLoading(true))

            const dataCopy = {
                invoice_id: data.invoice_id,
                invoice_code: RandomCode(c.CODE_TYPE.INVOICE),
                create_user: userInfo.user_id
            }
            invoiceCopy(dataCopy).then(res => {
                if (res.data) {
                    dispatch(setInvoiceId(res.data.invoice_id))
                    history.push(ROUTE_POS)
                }
            }).finally(x => {
                dispatch(setLoading(false))
            })
        }
    }

    const showGiftCardList = () => {
        if (dataInvoiceDetail.invoiceGiftCardList && dataInvoiceDetail.invoiceGiftCardList.length > 0) {
            return dataInvoiceDetail.invoiceGiftCardList.map((x, index) => {
                return <Row className={`text-black ${dataInvoiceDetail.invoiceGiftCardList.length > 1 && 'border-bottom-1'}`} key={index}>
                    <Col xs={20} md={20}>
                        {x.gift_card_code}
                    </Col>
                    <Col className='text-right' xs={4} md={4}>
                        {formatCurrency(x.gift_card_history_money, generalSetting)}
                    </Col>
                </Row>
            })
        }
    }

    /// /render///////
    return (
        <Row>

            <InvoicePrint dataItem={dataInvoiceItemList} data={dataInvoiceDetail} printRef={printRef} />
            <InvoicePrintTicket dataItem={dataInvoiceItemList} data={dataInvoiceDetail} printRef={printTicketRef} />
            <Col xs={24} md={24} lg={24} className="ml-15">
                <List
                    size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(dataInvoiceDetail)
                            .filter(x =>
                                x !== 'key' &&
                                x !== 'invoice_id' &&
                                x !== 'branch_id' &&
                                x !== 'customer_id' &&
                                x !== 'user_id' &&
                                x !== 'company_id' &&
                                x !== 'invoice_total_surcharge' &&
                                x !== 'invoice_temp_money' &&
                                x !== 'invoice_tax' &&
                                x !== 'invoice_other_cost' &&
                                x !== 'invoice_total_promotion' &&
                                x !== 'invoice_customer_need_to_pay' &&
                                x !== 'invoice_money_received' &&
                                x !== 'invoice_change' &&
                                x !== 'invoice_customer_group_discount' &&
                                x !== 'invoicePaymentMethod' &&
                                x !== 'branch_address' &&
                                x !== 'branch_phone_number' &&
                                x !== 'invoice_tip' &&
                                x !== 'invoiceItemList' &&
                                x !== 'invoicePromotionList' &&
                                x !== 'invoiceSurchargeList' &&
                                x !== 'invoiceGiftCardList' &&
                                x !== 'is_checkin'
                            )
                    }
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date' || item === 'update_date'
                                        ? formatDate(dataInvoiceDetail[item], true, generalSetting)
                                        : (item === 'is_active')
                                            ? formatIsActive(dataInvoiceDetail[item])
                                            : (item === 'invoice_status' && item)
                                                ? c.INVOICE_STATUS_LABEL.filter(e => e.value === parseInt(dataInvoiceDetail[item]))[0].label
                                                : (String(dataInvoiceDetail[item]).includes('{DELETE}'))
                                                    ? <Text delete>{String(dataInvoiceDetail[item]).replace('{DELETE}', '')}</Text>
                                                    : (item === 'customer_phone_number')
                                                        ? formatPhone(dataInvoiceDetail[item])
                                                        : (item === 'invoice_tip')
                                                            ? formatCurrency(dataInvoiceDetail[item], generalSetting)
                                                            : dataInvoiceDetail[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
                <div className="pl-15 pr-15">
                    <div className="table-detail">
                        <Table
                            columns={columns}
                            dataSource={(dataInvoiceItemList && dataInvoiceItemList.map((x, index) => ({ ...x, key: x.invoice_item_code + index }))) || []}
                            size="small"
                            pagination={false}
                        />
                    </div>
                </div>
            </Col>
            <Col className="text-right pl-15 pr-15" xs={24} md={24}>
                <div>
                    <Row className="pt-15 border-bottom-1 font-weight-bold">
                        <Col xs={20} md={20}>
                            <div>{lang.invoice_temp_money}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(dataInvoiceDetail.invoice_temp_money, generalSetting)}</div>
                        </Col>
                    </Row>
                    <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.tax} ({`${posSetting.allow_apply_tax === c.IS_ACTIVE ? posSetting.tax_percent : 0}%`})</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(dataInvoiceDetail.invoice_tax, generalSetting)}</div>
                        </Col>
                    </Row>

                    {dataInvoiceDetail.invoiceSurchargeList && dataInvoiceDetail.invoiceSurchargeList.length > 1 && <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            {lang.surcharge}
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(getInvoiceTotalSurcharge(dataInvoiceDetail.invoiceSurchargeList), generalSetting)}</div>
                        </Col>
                        <Col xs={24} md={24} className="text-right">
                            <Row className="f-12 pl-15">
                                {dataInvoiceDetail.invoiceSurchargeList && dataInvoiceDetail.invoiceSurchargeList.map((x, index) => {
                                    return <>
                                        <Col xs={20} md={20}>
                                            {x.invoice_surcharge_name}
                                        </Col>
                                        <Col className='text-right' xs={4} md={4}>
                                            {formatCurrency(x.invoice_surcharge_money, generalSetting)}
                                        </Col>
                                    </>
                                })}
                            </Row>
                        </Col>
                    </Row>}

                    {dataInvoiceDetail.invoiceSurchargeList && dataInvoiceDetail.invoiceSurchargeList.length === 1 && <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            {dataInvoiceDetail.invoiceSurchargeList[0].invoice_surcharge_name}
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(getInvoiceTotalSurcharge(dataInvoiceDetail.invoiceSurchargeList), generalSetting)}</div>
                        </Col>
                    </Row>}

                    {dataInvoiceDetail.invoicePromotionList && dataInvoiceDetail.invoicePromotionList.length > 1 && <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.promotion}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>-{formatCurrency(dataInvoiceDetail.invoice_total_promotion, generalSetting)}</div>
                        </Col>
                        <Col xs={24} md={24} className="text-right">
                            <Row className="f-12 pl-15">
                                {dataInvoiceDetail.invoicePromotionList && dataInvoiceDetail.invoicePromotionList.map((x, index) => {
                                    return <>
                                        <Col xs={20} md={20}>
                                            {x.invoice_promotion_name}
                                        </Col>
                                        <Col className='text-right' xs={4} md={4}>
                                            -{formatCurrency(x.invoice_promotion_money, generalSetting)}
                                        </Col>
                                    </>
                                })}
                            </Row>
                        </Col>
                    </Row>}
                    {dataInvoiceDetail.invoicePromotionList && dataInvoiceDetail.invoicePromotionList.length === 1 && <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{dataInvoiceDetail.invoicePromotionList[0].invoice_promotion_name}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>-{formatCurrency(dataInvoiceDetail.invoicePromotionList[0].invoice_promotion_money, generalSetting)}</div>
                        </Col>
                    </Row>}

                    <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.customer_group}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{dataInvoiceDetail.invoice_customer_group_discount > 0 && '-'}{formatCurrency(dataInvoiceDetail.invoice_customer_group_discount, generalSetting)}</div>
                        </Col>
                    </Row>

                    <Row className="pt-15 border-bottom-1 font-weight-bold">
                        <Col xs={20} md={20}>
                            <div>{lang.customer_need_to_pay}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(dataInvoiceDetail.invoice_customer_need_to_pay, generalSetting)}</div>
                        </Col>
                    </Row>
                    <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.money_received} {`${dataInvoiceDetail.invoicePaymentMethod && dataInvoiceDetail.invoicePaymentMethod.length === 1 ? '(' + c.PAYMENT_METHOD.filter(z => z.value === dataInvoiceDetail.invoicePaymentMethod[0].invoice_payment_method)[0].label + ')' : ''}`}
                                {dataInvoiceDetail.invoicePaymentMethod && dataInvoiceDetail.invoicePaymentMethod.length === 1 && dataInvoiceDetail.invoicePaymentMethod[0].invoice_payment_method === c.PAYMENT_METHOD[2].value && dataInvoiceDetail.invoiceGiftCardList.length > 0 && <Tooltip title={showGiftCardList()}>
                                    <span className="pl-5 cursor-pointer"><EyeOutlined /></span>
                                </Tooltip>}
                            </div>

                        </Col>

                        <Col xs={4} md={4} className="text-right">
                            {formatCurrency(dataInvoiceDetail.invoice_money_received, generalSetting)}
                        </Col>
                        {dataInvoiceDetail.invoicePaymentMethod && dataInvoiceDetail.invoicePaymentMethod.length > 1 && <Col xs={24} md={24} className="text-right">
                            <Row className="f-12 pl-15">
                                {dataInvoiceDetail.invoicePaymentMethod && dataInvoiceDetail.invoicePaymentMethod.map((x, index) => {
                                    return <>
                                        <Col xs={20} md={20}>
                                            {c.PAYMENT_METHOD.filter(z => z.value === x.invoice_payment_method)[0].label}
                                            {x.invoice_payment_method === c.PAYMENT_METHOD[2].value && dataInvoiceDetail.invoiceGiftCardList.length > 0 && <Tooltip title={showGiftCardList()}>
                                                <span className="pl-5 cursor-pointer"><EyeOutlined /></span>
                                            </Tooltip>}

                                        </Col>
                                        <Col className='text-right' xs={4} md={4}>
                                            {formatCurrency(x.invoice_payment_method_money, generalSetting)}
                                        </Col>
                                    </>
                                })}
                            </Row>
                        </Col>}
                    </Row>
                    <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.change}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(dataInvoiceDetail.invoice_change, generalSetting)}</div>
                        </Col>
                    </Row>
                    <Row className="pt-15 border-bottom-1">
                        <Col xs={20} md={20}>
                            <div>{lang.invoice_tip}</div>
                        </Col>
                        <Col xs={4} md={4} className="text-right">
                            <div>{formatCurrency(dataInvoiceDetail.invoice_tip, generalSetting)}</div>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            {data.invoice_status === c.INVOICE_STATUS.UNPAID && <CheckPermission permission={['INVOICE_PRINT_TICKET']}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={handlePrintTicket}
                                    icon={<PrinterOutlined />}
                                >{lang.print_ticket}</Button>
                            </CheckPermission>}
                            {data.invoice_status === c.INVOICE_STATUS.PAID && <CheckPermission permission={['INVOICE_PRINT']}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={handlePrint}
                                    icon={<PrinterOutlined />}
                                >{lang.print}</Button>
                            </CheckPermission>}
                            <CheckPermission permission={['INVOICE_COPY']}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={onCopy}
                                    icon={<CopyOutlined />}
                                >{lang.copy}</Button>
                            </CheckPermission>
                            {data.invoice_status !== c.INVOICE_STATUS.CANCEL && data.invoice_status !== c.INVOICE_STATUS.PAID && <CheckPermission permission={['INVOICE_ADD_EDIT']}>
                                <Button
                                    type="primary"
                                    size="large"
                                    onClick={onEdit}
                                    icon={<EditOutlined />}
                                >{lang.edit}</Button>
                            </CheckPermission>}
                            {data.invoice_status !== c.INVOICE_STATUS.CANCEL && data.invoice_status !== c.INVOICE_STATUS.PAID && <CheckPermission permission={['INVOICE_CANCEL']}>
                                <InvoiceDetailCancel data={dataInvoiceDetail} />
                            </CheckPermission>}
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default InvoiceDetail;
