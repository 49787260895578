/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { getCustomer } from '../service';
import { Form, AutoComplete } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';
import { formatPhone } from './FormatData';
import {
  PhoneOutlined,
  UserOutlined
} from '@ant-design/icons';
const { Option } = AutoComplete;

const CustomerAutoComplete = (props) => {
  const {
    label,
    name,
    isHideLabel,
    onSelect,
    onClear,
    style,
    required,
    allowClear,
    showSearch,
    size,
    onChange,
    disabled
  } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getCustomer().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const onSelected = (value, option) => {
    if (onSelect) {
      onSelect(option.data)
    }
  }

  const onChangeData = (value) => {
    if (onChange) {
      onChange(value)
    }
  }

  const validateCustomerAutoComplete = (rule, value, getFieldValue) => {
    if (value) {
      const res = options.find((i) => i.customer_phone_number === value);
      if (value && res && res.is_active === c.IS_DEACTIVE) {
        return Promise.reject(new Error(`${res.customer_fullname} ${lang.option_deactive_validation}`));
      }
      return Promise.resolve();
    } else return Promise.resolve();
  }
  /// render/////
  return (
    <Form.Item
      label={!isHideLabel ? (label || lang.customer) : ''}
      name={name || 'customer'}
      style={style}
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateCustomerAutoComplete(rule, value, getFieldValue)
        })
      ]}
    >
      <AutoComplete
        size={size || 'large'}
        showSearch={showSearch || false}
        allowClear={allowClear || false}
        onClear={onClear || null}
        onSelect={onSelected || null}
        maxTagCount='responsive'
        placeholder={label || lang.customer}
        onChange={onChangeData}
        disabled={disabled || false}
        filterOption={(input, option) => {
          const data = option.data.customer_phone_number + option.data.customer_fullname
          return data && data.toLowerCase().indexOf(input.toString().toLowerCase()) !== -1
        }}
      >
        {options.filter(e => e.customer_phone_number !== '').map((item) => {
          return <Option key={item.customer_id} value={`${formatPhone(item.customer_phone_number)} - ${item.customer_fullname}`} data={item}>
            <div className={`${item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'} f-13`}><PhoneOutlined /> {formatPhone(item.customer_phone_number)} - <UserOutlined /> {item.customer_fullname}</div>
          </Option>
        })}
      </AutoComplete>
    </Form.Item>
  )
};

export default CustomerAutoComplete;
