import { createAsyncThunk } from '@reduxjs/toolkit';
import * as turnSettingService from './service';

export const turnSettingThunk = createAsyncThunk(
    'turn/get',
    async (data, thunkAPI) => {
        const response = await turnSettingService.turnSetting(data, 'GET');
        return response.data;
    }
);
