import {
  CloseCircleOutlined, PlusOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, message, Drawer, Space, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../../constants/common';
import lang from '../../../../../language/language';
// import Address from '../../../../Common/components/Address';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../../Common/components/CheckPermission';
import { setLoading } from '../../../../Common/slice';
import * as roleThunk from '../../../Role/thunk';
import * as branchService from '../../service';
import { clearBranchId } from '../../slice';
import * as branchThunk from '../../thunk';
import { RandomCode } from '../../../../Common/components/RandomCode';
const { TextArea } = Input;

const BranchAction = (props) => {
  const queryData = useSelector(state => state.branch.branchQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const branchId = useSelector(state => state.branch.branchId);
  // const [addressData, setAddressData] = useState({});
  // const [clearAddress, setClearAddress] = useState('');
  const [dataEdit, setDataEdit] = useState({});
  // const [countSubmit, setCountSubmit] = useState(0);
  const [actionType, setActionType] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(roleThunk.roleListAction());
  }, []);

  useEffect(() => {
    if (branchId) {
      dispatch(setLoading(true));
      branchService.branchDetail({ branch_id: branchId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          setDataEdit(data);
          form.setFieldsValue({
            branch_code: data.branch_code,
            branch_name: data.branch_name,
            branch_phone_number: data.branch_phone_number,
            branch_note: data.branch_note,
            branch_address: data.branch_address,
            is_active: data.is_active === c.IS_ACTIVE,
            branch_website: data.branch_website
          });
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    }
  }, [branchId]);
  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!branchId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ branch_code: RandomCode() });
  };

  const onSubmit = (data) => {
    // submit > 1 để validate address
    dispatch(setLoading(true));
    // setCountSubmit(1);
    const method = branchId ? 'PUT' : 'POST';
    const dataReq = {
      branch_id: branchId || '',
      branch_code: data.branch_code,
      branch_name: data.branch_name,
      branch_phone_number: data.branch_phone_number,
      branch_note: data.branch_note,
      branch_address: data.branch_address,
      province_id: data.province || '',
      district_id: data.district || '',
      ward_id: data.ward || '',
      create_user: userInfo.user_id,
      is_active: dataEdit.is_main === 1 ? c.IS_ACTIVE : data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE,
      branch_website: data.branch_website
    };
    branchService.branchCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.branch_phone_number) {
          message.error(lang.phone + lang.exist);
        } else if (data.branch_code) {
          message.error(lang.branch_code + lang.exist);
        } else if (data.branch_name) {
          message.error(lang.branch_name + lang.exist);
        }
        // luu thanh cong
        if (data.branch_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!branchId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(branchThunk.branchList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    genCode();
    dispatch(clearBranchId(''));
    // setClearAddress(Date.parse(new Date()));
    // setCountSubmit(0);
    dispatch(clearBranchId(''));
    // setDataEdit({});
    form.resetFields();
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  /// render/////
  return (
    <span>
      <>
        <CheckPermission permission={['BRANCH_ADD']}>
          <Button size="large" type="primary" id="branchAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!branchId
            ? lang.add + lang.branch.toLowerCase()
            : lang.edit + lang.branch.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.SMALL}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          // initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.branch_code}
                  name="branch_code"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.branch_name}
                  name="branch_name"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.branch_phone_number}
                  name="branch_phone_number"
                  rules={[
                    { pattern: /^[0-9]*$/, message: lang.not_format },
                    { min: 10, message: lang.phone_min_max },
                    { max: 11, message: lang.phone_min_max }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.branch_address}
                  name="branch_address"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              {/* <Address
                    dataEdit={dataEdit}
                    clearAddress={clearAddress}
                    countSubmit={countSubmit}
                  /> */}
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.branch_website}
                  name="branch_website"
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.note}
                  name="branch_note"
                >
                  <TextArea allowClear rows={1} size="large" />
                </Form.Item>
              </Col>
              {dataEdit.is_main === 0 &&
                <Col xs={24} md={24} className="text-right">
                  <Form.Item
                    name="is_active"
                    valuePropName="checked"
                  >
                    <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                  </Form.Item>
                </Col>
              }

            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default BranchAction;
