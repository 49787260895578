/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import {
    DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import { Button, Col, List, message, Popconfirm, Row, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../../language/language';
import CheckPermission from '../../../Common/components/CheckPermission';
import { formatDate, formatIsActive } from '../../../Common/components/FormatData';
import { setRoleId } from '../../Role/slice';
import * as roleService from '../service';
import * as roleThunk from '../thunk';
const { Text } = Typography;

const RoleDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);
    // const color = useSelector(state => state.user.userInfo.theme_color);

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.role_id) {
            const roleId = data.role_id
            const dataDelete = {
                list_id: [roleId],
                update_user: userInfo.user_id
            };
            roleService.roleDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(roleThunk.roleList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.role_id) {
            document.getElementById('roleAction').click();
            dispatch(setRoleId(data.role_id));
        }
    };
    /// /render///////
    return (
        <Row >
            <Col xs={24} md={24} lg={24}>
                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'role_id' &&
                                x !== 'key' &&
                                x !== 'branch_id' &&
                                x !== 'discount_type' &&
                                x !== 'is_main'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date'
                                        ? formatDate(data[item])
                                        : (item === 'is_active')
                                            ? formatIsActive(data[item])
                                            : (String(data[item]).includes('{DELETE}'))
                                                ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['ROLE_EDIT']}>
                                {data.role_id &&
                                    <Button
                                        type="primary"
                                        size="large"
                                        onClick={onEdit}
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['ROLE_DELETE']}>
                                {data.role_id && data.is_main === 0 &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button
                                            size="large"

                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default RoleDetail;
