import React, { useEffect, useState } from 'react';
import lang from '../../../language/language';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import { Row, Col, Form, Button, Card, Select, Checkbox, Space, Switch, message } from 'antd';
import { SaveOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { getListHourAppointment } from '../../Common/components/GetListHourAppointment';
import { useDispatch } from 'react-redux';
import { openHourSetting, openHourSettingCreateUpdate } from './service';
import * as c from '../../../constants/common';
import { setLoading } from '../../Common/slice';
import moment from 'moment';
const OpenHours = () => {
	const [form] = Form.useForm();
	const [hourList, setHourList] = useState([]);
	const [openHourList, setOpenHourList] = useState([]);
	const [disabledState, setDisabledState] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		gethourList();
		getOpenHourList();
	}, []);

	const gethourList = () => {
		const hours = getListHourAppointment('01:00', '23:30', 30);
		setHourList(hours);
	};

	const getOpenHourList = () => {
		openHourSetting().then((res) => {
			if (res) {
				setOpenHourList(res?.data);
			}
		});
	};

	const handleCheckboxChange = (status, value) => {
		form.setFieldsValue({ [`open_${value}`]: status });
		setDisabledState((prevState) => ({
			...prevState,
			[value]: !status,
		}));
	};

	useEffect(() => {
		openHourList.forEach((element) => {
			const breakTime = element[`break_${element?.open_hour_setting_day}`];
			form.setFieldsValue({
				[`from_time_${element?.open_hour_setting_day}`]: element?.open_hour_setting_from_time,
				[`to_time_${element?.open_hour_setting_day}`]: element?.open_hour_setting_to_time,
				[`open_${element?.open_hour_setting_day}`]: element?.open_hour_setting_open === c.IS_ACTIVE,
			});
			if (breakTime?.length > 0) {
				form.setFieldsValue({
					[`break_${element?.open_hour_setting_day}`]: breakTime?.map((item) => ({
						[`from_time_break_${element?.open_hour_setting_day}`]: item?.open_hour_break_setting_from_time,
						[`to_time_break_${element?.open_hour_setting_day}`]: item?.open_hour_break_setting_to_time,
					})),
				});
			}
		});
	}, [openHourList, form]);

	const listDay = [
		{ value: 'monday', label: lang.monday },
		{ value: 'tuesday', label: lang.tuesday },
		{ value: 'wednesday', label: lang.wednesday },
		{ value: 'thursday', label: lang.thursday },
		{ value: 'friday', label: lang.friday },
		{ value: 'saturday', label: lang.saturday },
		{ value: 'sunday', label: lang.sunday },
	];

	const onSubmit = (data) => {
		const reqData = listDay?.map((item) => {
			const openHourId = openHourList?.filter((x) => x?.open_hour_setting_day === item?.value)[0]?.open_hour_setting_id;
			const breakDetail = openHourList?.filter((x) => x?.open_hour_setting_day === item?.value)[0]?.[
				`break_${item?.value}`
			];
			const breakData = data[`break_${item?.value}`];
			return {
				open_hour_setting_day: item?.value,
				open_hour_setting_from_time: data[`from_time_${item?.value}`] || '',
				open_hour_setting_to_time: data[`to_time_${item?.value}`] || '',
				open_hour_setting_open: data[`open_${item?.value}`] ? c.IS_ACTIVE : c.IS_DEACTIVE,
				open_hour_setting_id: openHourId,
				breaks: breakData?.map((a) => {
					const breakId = breakDetail?.[0]?.open_hour_break_setting_id;
					return {
						open_hour_break_setting_id: breakId || null,
						open_hour_setting_id: openHourId || null,
						open_hour_break_setting_from_time: a[`from_time_break_${item?.value}`] || '',
						open_hour_break_setting_to_time: a[`to_time_break_${item?.value}`] || '',
					};
				}),
			};
		});

		openHourSettingCreateUpdate({ openHours: reqData })
			.then((res) => {
				if (res) {
					const { data } = res;
					dispatch(setLoading(false));
					if (data?.open_hour_setting_id > 0) {
						message.success(lang.save_success);
						getOpenHourList();
					}
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

	// Hàm kiểm tra from_time < to_time
	const validateFromTimeToTime = (form, itemValue) => ({
		validator(_, toTime) {
			const fromTime = form.getFieldValue(itemValue);
			console.log(itemValue);

			if (!fromTime || !toTime) {
				return Promise.resolve();
			}
			if (moment(fromTime, 'HH:mm').isAfter(moment(toTime, 'HH:mm'))) {
				return Promise.reject(new Error('From time must be before To time'));
			}

			return Promise.resolve();
		},
	});

	return (
		<Card title={lang.hour_open}>
			<Helmet>
				<meta charSet="utf-8" />
				<title>
					{APP_TITLE} - {lang.hour_open}
				</title>
			</Helmet>
			<Form autoComplete="off" form={form} layout="vertical" onFinish={onSubmit}>
				<Row gutter={24}>
					{listDay.map((item, index) => {
						return (
							<Col xs={24} md={24} key={index}>
								<h3 className="font-weight-bold">{item.label}</h3>
								<Row gutter={24}>
									<Col xs={24} md={8}>
										<Form.Item
											label={lang.fromTime}
											name={`from_time_${item.value}`}
											rules={[
												{
													required: form.getFieldValue(`open_${item.value}`),
													message: lang.value_is_required,
												},
											]}
										>
											<Select size="large" placeholder={lang.fromTime}>
												{hourList.map((hour) => (
													<Select.Option key={hour.value} value={hour.value}>
														{hour.label}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<Form.Item
											label={lang.toTime}
											name={`to_time_${item.value}`}
											rules={[
												{
													required: form.getFieldValue(`open_${item.value}`),
													message: lang.value_is_required,
												},
												validateFromTimeToTime(form, `from_time_${item.value}`),
											]}
										>
											<Select size="large" placeholder={lang.toTime}>
												{hourList.map((hour) => (
													<Select.Option key={hour.value} value={hour.value}>
														{hour.label}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Col>

									<Col xs={24} md={8}>
										<Form.Item label={' '} name={`open_${item.value}`} valuePropName="checked">
											<Switch
												className="mt-10"
												onChange={(e) => handleCheckboxChange(e, item.value)}
												checkedChildren={lang.isOpen}
												unCheckedChildren={lang.close}
											/>
										</Form.Item>
									</Col>
									<Col xs={24} md={8}>
										<h3>{lang.break}</h3>
										<Form.List name={`break_${item.value}`}>
											{(fields, { add, remove }) => (
												<>
													{fields.map(({ key, name, ...restField }) => (
														<Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
															<Form.Item
																{...restField}
																name={[name, `from_time_break_${item.value}`]}
																rules={[
																	{
																		required: form.getFieldValue(`open_${item.value}`),
																		message: lang.value_is_required,
																	},
																]}
															>
																<Select style={{ width: 150 }} size="middle" placeholder={lang.fromTimeBreak}>
																	{hourList.map((hour) => (
																		<Select.Option key={hour.value} value={hour.value}>
																			{hour.label}
																		</Select.Option>
																	))}
																</Select>
															</Form.Item>
															<Form.Item
																{...restField}
																name={[name, `to_time_break_${item.value}`]}
																rules={[
																	{
																		required: form.getFieldValue(`open_${item.value}`),
																		message: lang.value_is_required,
																	},
																]}
															>
																<Select style={{ width: 150 }} size="middle" placeholder={lang.toTimeBreak}>
																	{hourList.map((hour) => (
																		<Select.Option key={hour.value} value={hour.value}>
																			{hour.label}
																		</Select.Option>
																	))}
																</Select>
															</Form.Item>
															<MinusCircleOutlined onClick={() => remove(name)} />
														</Space>
													))}
													<Form.Item>
														<Button
															style={{ width: 300 }}
															type="dashed"
															onClick={() => add()}
															block
															icon={<PlusOutlined />}
														>
															{lang.add_breaks}
														</Button>
													</Form.Item>
												</>
											)}
										</Form.List>
									</Col>
								</Row>
							</Col>
						);
					})}
					<Col xs={24} md={24} className="text-center mt-15">
						<Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary">
							{lang.save}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default OpenHours;
