import * as XLSX from 'xlsx';

export const ImportExcel = (e, setState) => {
    if (e.target.files.length > 0) {
        const files = e.target.files;
        const f = files[0];
        const reader = new FileReader();
        reader.onload = function (e) {
            const data = e.target.result;
            const readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* convert array-of-arrays */
            let dataJSON = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });
            dataJSON = dataJSON.filter(item => item[0] !== '').filter((item, i) => i !== 0)
            setState(dataJSON);
        };
        reader.readAsBinaryString(f);
    }
}
