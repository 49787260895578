import React, { useEffect, useState } from 'react';
import lang from '../../../language/language';
import { getServiceGroup } from '../service';
import { Form, Select } from 'antd';
import * as c from '../../../constants/common';

const { Option } = Select;

const ServiceGroupSelect = (props) => {
  const { required, size, label, className, isMultiple, onChange } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getServiceGroup().then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const validateServiceGroupSelect = (rule, value, getFieldValue) => {
    const res = options.find((i) => i.service_group_id === value);
    if (value && res.is_active === c.IS_DEACTIVE) {
      return Promise.reject(new Error(`${res.service_group_name} ${lang.option_deactive_validation}`));
    }
    return Promise.resolve();
  }

  const onChangeData = (data, option) => {
    if (onChange) {
      onChange(option)
    }
  }

  /// render/////
  return (
    <Form.Item
      className={className}
      label={label === null ? label : lang.service_group}
      name="service_group"
      rules={[
        { required: required || false, message: lang.value_is_required },
        ({ getFieldValue }) => ({
          validator: (rule, value) => validateServiceGroupSelect(rule, value, getFieldValue)
        })
      ]}
    >
      <Select
        showSearch
        allowClear
        maxTagCount='responsive'
        placeholder={lang.service_group}
        size={size || 'large'}
        mode={isMultiple ? 'multiple' : ''}
        onChange={onChangeData}
        filterOption={(input, option) => {
          const data = option.data.service_group_name
          return data.toLowerCase().indexOf(input.toLowerCase()) !== -1
        }}
      >
        {options.map((item) => {
          return <Option key={item.service_group_id} value={item.service_group_id} data={item}>
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.service_group_name}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default ServiceGroupSelect;
