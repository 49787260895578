/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../../language/language';
import * as serviceGroupService from '../../service';
import { setLoading, setIsClearImage } from '../../../Common/slice';
import { clearServiceGroupId } from './../../slice';
// import * as c from '../../../constants/common';
import CheckPermission from '../../../Common/components/CheckPermission';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as c from '../../../../constants/common';
import * as serviceGroupThunk from '../../thunk';
import TinyEditor from './../../../Common/components/TinyEditor';
import noImage from '../../../../assets/images/no-image.png';
import { Drawer, Space, Button, Row, Col, Form, Input, Switch, message, Image } from 'antd';
import {
  PlusOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  CameraOutlined
} from '@ant-design/icons';
import ImageGallerySelect from '../../../ImageGallery/components/ImageGallerySelect';
import { RandomCode } from '../../../Common/components/RandomCode';

const ServiceGroupAction = (props) => {
  const queryData = useSelector(state => state.serviceGroup.serviceGroupQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const serviceGroupId = useSelector(state => state.serviceGroup.serviceGroupId);
  const [actionType, setActionType] = useState('');
  const [description, setDescription] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dataAvatar, setDataAvatar] = useState({});
  const branchLocal = JSON.parse(localStorage.getItem('branch'));

  /// //////////////////////// Validate /////////////////////////

  useEffect(() => {
    if (serviceGroupId) {
      dispatch(setLoading(true));
      serviceGroupService.serviceGroupDetail({ service_group_id: serviceGroupId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          form.setFieldsValue({
            service_group_name: data.service_group_name,
            service_group_code: data.service_group_code,
            is_active: data.is_active === c.IS_ACTIVE
          });
          setDataAvatar({ image_gallery_name: data.service_group_avatar_name, image_gallery_url: data.service_group_avatar })
          setDescription(data.service_group_description)
        }
      }).finally(() => {
        dispatch(setLoading(false));
      });
    } else {
      form.setFieldsValue({
        customer_branch: branchLocal.branch_name
      });
    }
  }, [serviceGroupId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!serviceGroupId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ service_group_code: RandomCode(), is_active: true });
  };

  const onSubmit = (data) => {
    dispatch(setLoading(true));
    const method = serviceGroupId ? 'PUT' : 'POST';
    const dataReq = {
      service_group_id: serviceGroupId || '',
      service_group_code: data.service_group_code,
      service_group_name: data.service_group_name,
      service_group_avatar: dataAvatar.image_gallery_name || '',
      service_group_description: description,
      create_user: userInfo.user_id,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE
    };
    serviceGroupService.serviceGroupCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.service_group_name) {
          message.error(lang.service_group_name + lang.exist);
        }
        // luu thanh cong
        if (data.service_group_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')
            if (!serviceGroupId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
            // document.getElementById('closeServiceGroupAction').click();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(serviceGroupThunk.serviceGroupList(queryData));
    })
  };

  const resetData = () => {
    // cho phep edit
    genCode();
    dispatch(clearServiceGroupId(''));
    form.resetFields();
    dispatch(setIsClearImage(true))
    setDescription('')
    setDataAvatar({})
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onSaveChangeImage = (data) => {
    if (data.length > 0) {
      setDataAvatar(data[0])
    }
  }

  /// render/////
  return (
    <span>
      <ImageGallerySelect id="galleryServiceGroupAction" onSave={onSaveChangeImage} />
      <>
        <CheckPermission permission={['SERVICE_GROUP_ADD']}>
          <Button type="primary" size="large" id="serviceGroupAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!serviceGroupId
            ? lang.add + lang.service_group.toLowerCase()
            : lang.edit + lang.service_group.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.LARGE}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >

          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          // initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24} className="mb-15">
                <div className="text-center">
                  <Image
                    width="100%"
                    style={{ margin: 'auto' }}
                    className="img-avatar-square"
                    preview={false}
                    src={dataAvatar.image_gallery_url || noImage}
                  />
                </div>
                <div className="text-center pt-10">
                  <Button icon={<CameraOutlined />}
                    onClick={() => document.getElementById('galleryServiceGroupAction').click()}
                    key="update_service_group_avatar"
                    type="primary"
                    size="middle"
                  >
                    {lang.choose_image}
                  </Button>
                </div>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_group_code}
                  name="service_group_code"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    { pattern: /^[A-Za-z0-9]*$/, message: lang.not_allow_special_characters }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item
                  label={lang.service_group_name}
                  name="service_group_name"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} md={24}>
                <label style={{ fontSize: 16 }}>{lang.service_group_description}</label>
                <TinyEditor

                  value={description}
                  onChange={(value) => setDescription(value)}
                />
              </Col>
              <Col xs={24} md={24} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default ServiceGroupAction;
