import { createSlice } from '@reduxjs/toolkit';
import * as generalSettingThunk from './thunk';

export const generalSettingSlice = createSlice({
    name: 'generalSetting',
    initialState: {

    },
    reducers: {

    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [generalSettingThunk.generalSettingThunk.fulfilled]: (state, action) => {
            return action.payload;
        }
    }
});

export default generalSettingSlice.reducer;
