import CallApi from '../../utils/CallAPI';
import {
  SMS_HISTORY_LIST,
  SMS_SAMPLE_LIST,
  SMS_SAMPLE_DETAIL
} from './constants/endpoint';

export const smsHistoryList = (data) => {
  return CallApi(SMS_HISTORY_LIST, 'GET', data)
};

export const smsHistoryCreate = (data) => {
  return CallApi(SMS_HISTORY_LIST, 'POST', data)
};

export const smsSampleList = (data) => {
  return CallApi(SMS_SAMPLE_LIST, 'GET', data)
};
export const smsSampleCreateUpdate = (data, method) => {
  return CallApi(SMS_SAMPLE_LIST, method, data)
};
export const smsSampleDetail = (data) => {
  return CallApi(SMS_SAMPLE_DETAIL, 'GET', data)
};
