/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState, useEffect } from 'react';
import lang from '../../../../language/language';
import { Row, Col, Button, Popover, Space, Input, message, Form, Popconfirm } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import {
    SaveOutlined,
    CloseCircleOutlined,
    DollarCircleOutlined
} from '@ant-design/icons';
import CheckPermission from '../../../Common/components/CheckPermission';
import { setLoading } from '../../../Common/slice';
import * as giftCardService from '../../service';
import * as giftCardThunk from '../../thunk';

const GiftCardDetailDeposit = (props) => {
    const { data } = props
    const [isVisible, setIsvisible] = useState(false)
    const queryData = useSelector(state => state.giftCard.giftCardQueryData);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);

    useEffect(() => {
        onClearData()
    }, [data])
    /// /funtion///////

    const onClose = () => {
        onClearData()
    }

    const onClearData = () => {
        form.resetFields();
        setIsvisible(false)
    }

    const onSave = () => {
        form.submit()
    }

    const onSubmit = (dataSubmit) => {
        dispatch(setLoading(true));
        const dataReq = {
            gift_card_money: parseFloat(dataSubmit.gift_card_money),
            gift_card_code: data.gift_card_code,
            update_user: userInfo.user_id
        };

        giftCardService.giftCardDeposit(dataReq).then(res => {
            if (res) {
                const { data } = res;
                dispatch(setLoading(false));
                if (data.gift_card_id > 0) {
                    message.success(lang.save_success);
                    onClearData()
                }
                // luu thanh cong
            }
        }).finally(() => {
            dispatch(setLoading(false));
            dispatch(giftCardThunk.giftCardList(queryData));
        })
    };
    /// /render///////
    return (
        <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="horizontal"
        >
            <Popover
                placement="topRight"
                trigger="click"
                overlayClassName="popover-pos"
                visible={isVisible}
                content={

                    <Row gutter={12}>
                        <Col xs={24} md={24} className="border-bottom-1 mb-15">
                            <h3>{lang.deposit}</h3>
                        </Col>
                        <Col xs={24} md={24} className="mb-15">
                            <Form.Item
                                label={lang.gift_card_money}
                                name="gift_card_money"
                                rules={[
                                    { required: true, message: lang.value_is_required },
                                    { pattern: /^[0-9]*$/, message: lang.not_format }
                                ]}
                            >
                                <Input
                                    placeholder={lang.input_money}
                                    size="large"
                                    type="number"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24} className="text-right pt-15 mt-5 border-top-1">
                            <Space>
                                <Button
                                    onClick={onClose}
                                    className="w-100"
                                    type="primary"
                                    size="large"
                                    block
                                ><CloseCircleOutlined /> {lang.close}
                                </Button>
                                <Popconfirm
                                    placement="topRight"
                                    title={lang.sure_save}
                                    onConfirm={onSave}
                                    okText={lang.ok}
                                    cancelText={lang.cancel}
                                >
                                    <Button
                                        className="w-100"
                                        type="primary"
                                        size="large"
                                        block
                                    >
                                        <SaveOutlined /> {lang.save}
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </Col>
                    </Row>
                }

            >
                <CheckPermission permission={['GIFT_CARD_DEPOSIT']}>
                    <Button
                        onClick={() => { setIsvisible(true) }}
                        size="large"
                        icon={<DollarCircleOutlined />}
                    >
                        {lang.deposit}
                    </Button>
                </CheckPermission>
            </Popover>
        </Form>

    );
};

export default GiftCardDetailDeposit;
