/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import lang from '../../../language/language';
import { updateTitle, setLoadingTable, setLoading } from '../../../modules/Common/slice';
// import CustomTable from '../../../modules/Common/components/CustomTable/CustomTable';
import { setCustomerId, setCustomerQueryData, setCustomerListId } from '../slice';
import { customerExport } from '../service';
import * as customerThunk from '../thunk';
import { Helmet } from 'react-helmet';
import { APP_TITLE } from '../../../constants/config';
import CustomerDetail from './CustomerDetail/CustomerDetail';
import CustomerActionChecked from './CustomerAction/CustomerActionChecked';
import CustomerFilter from './CustomerFilter';
import CustomerAction from './CustomerAction/CustomerAction';
import AntdTable from './../../Common/components/AntdTable/AntdTable';
import CustomerImport from './CustomerImport';
import {
  DownloadOutlined
} from '@ant-design/icons';
import AntdToggleColumn from '../../Common/components/AntdTable/AntdToggleColumn';
import { Row, Col, Space, Input, Button } from 'antd';
import * as c from '../../../constants/common';
import SMSHistoryAction from '../../SMS/components/SMSHistory/SMSHistoryAction';
import EmailHistoryAction from '../../Email/components/EmailHistory/EmailHistoryAction';
import AppointmentAction from '../../Appointment/AppointmentList/components/AppointmentAction/AppointmentAction';
import CheckPermission from '../../Common/components/CheckPermission';

const { Search } = Input;

const Customer = (props) => {
  const dispatch = useDispatch();
  const customerQueryData = useSelector(state => state.customer.customerQueryData);
  const customerListId = useSelector(state => state.customer.customerListId);
  const dataList = useSelector(state => state.customer.customerList);

  useEffect(() => {
    return function cleanup() {
      dispatch(setCustomerListId([]));
      dispatch(setCustomerId(''));
    };
  }, []);

  useEffect(() => {
    dispatch(setLoadingTable(true))
    dispatch(updateTitle(lang.customer));
  }, [dispatch]);

  useEffect(() => {
    fetchDataList(customerQueryData);
  }, [customerQueryData]);

  useEffect(() => {
    // setLoadingTable(false);
  }, [dataList.rows]);

  /// /funtion///////
  const fetchDataList = (customerQueryData) => {
    dispatch(customerThunk.customerList(customerQueryData));
  };

  const onSearch = (value, e) => {
    dispatch(setCustomerQueryData({ ...customerQueryData, page: c.SIZE_PAGE.PAGE, search: value }))
    dispatch(setLoadingTable(true))
  }

  const onExportData = () => {
    dispatch(setLoading(true));
    const data = { customer_id: [] }
    customerExport(data).then(res => {
      if (res && res.data) {
        dispatch(setLoading(false));
        window.location.href = res.data.file;
      }
    }).finally(() => {
      dispatch(setLoading(false))
    });
  }

  /// /render///////
  return (
    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE} - {lang.customer_list}</title>
      </Helmet>
      <Row>
        <Col span={10}>
          <Search
            size="large"
            placeholder={lang.search_customer}
            enterButton
            onSearch={onSearch}
          />
        </Col>
        <Col span={14}>
          <Row type="flex" justify="end">
            <Col>
              <SMSHistoryAction isHide />
              <EmailHistoryAction isHide />
              <Space>
                <CustomerActionChecked />
                <CustomerAction />
                <CustomerFilter />
                <Button size="large" onClick={onExportData} type="primary" icon={<DownloadOutlined />} />
                <CustomerImport />
                <AntdToggleColumn
                  data={dataList}
                  queryData={customerQueryData}
                  reloadData={fetchDataList}
                />
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <CheckPermission permission={['APPOINTMENT_VIEW']}>
            <AppointmentAction />
          </CheckPermission>
          <AntdTable
            data={dataList}
            listIdChecked={customerListId}
            queryData={customerQueryData}
            keyField="customer_id"
            clickField="customer_code"
            setListIdChecked={setCustomerListId}
            setQueryData={setCustomerQueryData}
            expandedRowRender={(record) => <CustomerDetail data={record} queryData={customerQueryData} />}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Customer;
