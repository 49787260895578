import { createAsyncThunk } from '@reduxjs/toolkit';
import * as posSettingService from './service';

export const posSettingThunk = createAsyncThunk(
    'posSetting/get',
    async (data, thunkAPI) => {
        const response = await posSettingService.posSetting(data);
        return response.data;
    }
);
