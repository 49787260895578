/* eslint-disable no-return-assign */
/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
import {
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Col, message, Pagination, Popconfirm, Row, Space } from 'antd';
import React, { useState, useEffect } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../language/language';
import CustomImage from '../../Common/components/CustomImage';
import UploadImage from '../../Common/components/UploadImage';
import * as commonService from '../../Common/service'
import { setDataImage, setLoading, setUrlImage } from '../../Common/slice';
import { imageGallery } from '../service';
import { setDataImageSelected, setImageGalleryQueryData } from '../slice';

const ImageGallery = (props) => {
  const { isMultiple } = props
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user.userInfo);
  const dataImageSelected = useSelector(state => state.imageGallery.dataImageSelected);
  const [imageGalleryList, setImageGalleryList] = useState({})
  const queryData = useSelector(state => state.imageGallery.imageGalleryQueryData);
  const dataImage = useSelector(state => state.common.dataImage);

  useEffect(() => {
    dispatch(setLoading(false))
  }, [imageGalleryList.rows])

  useEffect(() => {
    if (dataImage.length > 0) {
      dispatch(setLoading(true))
      const newData = dataImage.map((item) => {
        return { image_gallery_name: item, create_user: userInfo.user_id }
      })
      imageGallery({ image_gallery_list: newData }, 'POST').then(res => {
        if (res.data) {
          if (res.data.status) {
            message.success(lang.save_success)
            dispatch(setDataImage([]))
            dispatch(setUrlImage([]))
          }
        }
      }).finally(x => {
        dispatch(setLoading(false))
        getImageGallery()
      })
    }
  }, [dataImage])

  useEffect(() => {
    dispatch(setLoading(true))
    getImageGallery()
  }, [queryData])

  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const getImageGallery = () => {
    imageGallery(queryData, 'GET').then(res => {
      if (res.data) {
        setImageGalleryList(res.data)
      }
    })
  }

  // click button delete
  const onDelete = (listId = []) => {
    if (listId.length > 0) {
      const dataDelete = {
        list_id: listId,
        field_name: 'image_gallery_id',
        table: 'image_gallery',
        update_user: userInfo.user_id,
        permission: ['IMAGE_GALLERY_IMAGE_DELETE']
      };
      commonService.commonDelete(dataDelete).then(res => {
        if (res) {
          const { data } = res;
          if (data.is_delete) {
            message.success(lang.delete_success);
            dispatch(setDataImageSelected([]))
            getImageGallery()
          }
        }
      })
    }
  };

  const onSelectImage = (item) => {
    if (isMultiple) {
      const result = [...dataImageSelected]
      if (!dataImageSelected.includes(item)) {
        result.push(item)
        dispatch(setDataImageSelected(result))
      } else {
        const newResult = result.filter(x => x.image_gallery_id !== item.image_gallery_id)
        dispatch(setDataImageSelected(newResult))
      }
    } else {
      if (!dataImageSelected.includes(item)) {
        dispatch(setDataImageSelected([item]))
      } else {
        dispatch(setDataImageSelected([]))
      }
    }
  }

  const onChangePagination = (page, pageSize) => {
    dispatch(setImageGalleryQueryData({ ...queryData, page }))
  }

  /// render/////
  return (

    <Row gutter={24} className="mt-5 img-gallery-container">
      <Col className="mb-15" xs={24} sm={3} lg={3}>
        <UploadImage
          id="add_image_gallery"
          name="add_image_gallery"
          multiple
        />
        <Button
          style={{ minHeight: imageGalleryList.rows && imageGalleryList.rows.length > 0 ? 0 : 150 }}
          className="btn-add-image"
          type="primary" icon={<PlusOutlined />}
          block
          onClick={() => document.getElementById('add_image_gallery').click()}
        />
      </Col>
      {imageGalleryList.rows && imageGalleryList.rows.length > 0 && imageGalleryList.rows.map((item, index) => {
        return (
          <Col className="mb-15" key={index} xs={24} sm={3} lg={3}>
            <CustomImage
              onSelectImage={onSelectImage}
              selectList={dataImageSelected}
              onDelete={(data) => onDelete([data.image_gallery_id])}
              className="img-cover img-gallery"
              item={item}
              src={item.image_gallery_url}
            />
          </Col>
        )
      })}
      {imageGalleryList.rows && imageGalleryList.rows.length > 0 && <Col className="text-right" xs={24} md={24} sm={24}>
        <Space>
          {dataImageSelected.length > 0 && isMultiple &&
            <Popconfirm
              placement="top"
              title={lang.sure_delete}
              onConfirm={() => onDelete(dataImageSelected)}
              okText={lang.ok}
              cancelText={lang.cancel}
            >
              <Button
                size="middle"
                icon={<DeleteOutlined />}
                style={{ marginBottom: 20 }} >
              </Button>
            </Popconfirm>
          }
          <Pagination
            showTotal={(total, range) => `${lang.showing} ${range[0]}-${range[1]} / ${lang.total} ${total} ${dataImageSelected && dataImageSelected.length > 0 ? dataImageSelected && '(' + dataImageSelected.length + lang.selected + ')' : ''}`}
            total={imageGalleryList.total}
            pageSize={queryData.sizePerPage}
            current={queryData.page}
            onChange={onChangePagination}
            className="mb-15 align-right"
          />
        </Space>
      </Col>}
    </Row>
  );
};

export default ImageGallery;
