/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import {
    DeleteOutlined, EditOutlined
} from '@ant-design/icons';
import { Button, Col, List, message, Popconfirm, Row, Space, Typography } from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lang from '../../../language/language';
import CheckPermission from '../../Common/components/CheckPermission';
import { formatDate, formatIsActive } from '../../Common/components/FormatData';
// import TinyEditor from '../../Common/components/TinyEditor';
import * as commonService from '../../Common/service';
import { setStaffTypeId } from '../slice';
import * as staffThunk from '../thunk';
const { Text } = Typography;

const StaffTypeDetail = (props) => {
    const { data, queryData } = props;
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.user.userInfo);

    /// /funtion///////

    // click button delete
    const onDelete = () => {
        if (data.staff_type_id) {
            const staffTypeId = data.staff_type_id
            const dataDelete = {
                list_id: [staffTypeId],
                field_name: 'staff_type_id',
                table: 'staff_type',
                update_user: userInfo.user_id,
                permission: ['STAFF_TYPE_DELETE']
            };
            commonService.commonDelete(dataDelete).then(res => {
                if (res) {
                    const { data } = res;
                    if (data.is_delete) {
                        dispatch(staffThunk.staffTypeList(queryData));
                        message.success(lang.delete_success);
                    }
                }
            })
        }
    };

    const onEdit = (row) => {
        if (data.staff_type_id) {
            document.getElementById('staffTypeAction').click();
            dispatch(setStaffTypeId(data.staff_type_id));
        }
    };
    /// /render///////
    return (
        <Row>
            <Col xs={24} md={24} lg={24} className="ml-15">
                <List
                    size="large"
                    itemLayout="horizontal"
                    grid={{ gutter: 24, column: 2, md: 2, xs: 1 }}
                    dataSource={
                        Object.keys(data)
                            .filter(x =>
                                x !== 'key' &&
                                x !== 'staff_type_id'
                            )}
                    renderItem={item => (
                        <List.Item >
                            <Row className="border-detail-list">
                                <Col xs={12} lg={9}>{lang[item]}</Col>
                                <Col xs={12} lg={15}>{
                                    item === 'create_date'
                                        ? formatDate(data[item])
                                        : (item === 'is_active')
                                            ? formatIsActive(data[item])
                                            : (
                                                item === 'is_turn_priority'
                                            )
                                                ? <Text>{data[item] === 1 ? lang.yes : lang.no}</Text>
                                                : (String(data[item]).includes('{DELETE}'))
                                                    ? <Text delete>{String(data[item]).replace('{DELETE}', '')}</Text>
                                                    : data[item]
                                }</Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </Col>
            <Col className="mt-15" xs={24} md={24}>
                <Row type="flex" justify="end">
                    <Col>
                        <Space>
                            <CheckPermission permission={['STAFF_TYPE_EDIT']}>
                                {data.staff_type_id &&
                                    <Button
                                        type="primary"
                                        onClick={onEdit}
                                        size="large"
                                        icon={<EditOutlined />}
                                    >{lang.edit}</Button>
                                }
                            </CheckPermission>
                            <CheckPermission permission={['STAFF_TYPE_DELETE']}>
                                {data.staff_type_id &&
                                    <Popconfirm
                                        placement="topRight"
                                        title={lang.sure_delete}
                                        onConfirm={onDelete}
                                        okText={lang.ok}
                                        cancelText={lang.cancel}
                                    >
                                        <Button

                                            size="large"
                                            icon={<DeleteOutlined />}
                                        >{lang.delete}</Button>
                                    </Popconfirm>

                                }
                            </CheckPermission>
                        </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default StaffTypeDetail;
