/* eslint-disable array-callback-return */
import React, { useEffect } from 'react';
import { Dropdown, Menu, message, Popconfirm, Tabs } from 'antd';
import {
    SettingOutlined
} from '@ant-design/icons';
import lang from '../../../language/language';
import { giftCardSettingBackgroundAlbumThunk, giftCardSettingBackgroundThunk } from '../thunk';
import { useDispatch, useSelector } from 'react-redux';
import GiftCardSettingBackgroundImage from './GiftCardSettingBackgroundImage';
import { setBackgroundQueryData } from '../slice';
import * as c from '../../../constants/common';
import { setLoading } from '../../Common/slice';
import CheckPermission from '../../Common/components/CheckPermission';
import * as commonService from '../../Common/service';
import GiftCardAlbumAction from './GiftCardAlbumAction';

const GiftCardSettingBackgroundList = (props) => {
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const giftCardSettingBackgroundAlbumList = useSelector(state => state.giftCardSetting.giftCardSettingBackgroundAlbumList);
    const backgroundQueryData = useSelector(state => state.giftCardSetting.backgroundQueryData);
    const giftCardSettingBackground = useSelector(state => state.giftCardSetting.giftCardSettingBackground);
    const userInfo = useSelector(state => state.user.userInfo);

    useEffect(() => {
        dispatch(setLoading(true))
        dispatch(giftCardSettingBackgroundAlbumThunk({}))
        dispatch(giftCardSettingBackgroundThunk(backgroundQueryData))
    }, [backgroundQueryData.gift_card_background_album_id, backgroundQueryData.page])

    const onTabClick = (key) => {
        dispatch(setBackgroundQueryData({
            ...backgroundQueryData,
            page: c.SIZE_PAGE.PAGE,
            sizePerPage: key ? 11 : 12,
            gift_card_background_album_id: key
        }))
    }

    const onDeleteAlbum = (id) => {
        if (id) {
            if (giftCardSettingBackground.data.length === 0) {
                const data = {
                    list_id: [id],
                    field_name: 'gift_card_background_album_id',
                    table: 'gift_card_background_album',
                    update_user: userInfo.user_id,
                    permission: ['GIFT_CARD_SETTING_EDIT']
                };
                commonService.commonDelete(data).then(res => {
                    if (res) {
                        const { data } = res;
                        if (data.is_delete) {
                            dispatch(giftCardSettingBackgroundAlbumThunk({}))
                            message.success(lang.delete_success);
                        }
                    }
                })
            } else {
                message.error(lang.delete_gift_card_error);
            }
        }
    };

    const showActionAlbum = (data) => {
        const menuActionAlbum = (
            <Menu>
                <Menu.Item>
                    <CheckPermission permission={['GIFT_CARD_SETTING_EDIT']}>
                        <GiftCardAlbumAction data={data} />
                    </CheckPermission>
                </Menu.Item>
                <Menu.Item>
                    <CheckPermission permission={['GIFT_CARD_SETTING_EDIT']}>
                        <Popconfirm
                            placement="top"
                            title={lang.sure_delete}
                            onConfirm={() => onDeleteAlbum(data.gift_card_background_album_id)}
                            okText={lang.ok}
                            cancelText={lang.cancel}
                        >
                            <a>
                                {lang.delete}
                            </a>
                        </Popconfirm>
                    </CheckPermission>
                </Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menuActionAlbum} placement="bottomRight" arrow trigger={['click']}>
                <SettingOutlined style={{ size: 30 }} />
            </Dropdown>
        )
    }

    /// render

    return (
        <div>
            <Tabs
                tabPosition="top"
                tabBarExtraContent={<GiftCardAlbumAction />}
                onTabClick={onTabClick}
                destroyInactiveTabPane
            >
                <TabPane forceRender tab={<span>{lang.all}</span>} key={''}>
                    <GiftCardSettingBackgroundImage isMultiple isHideAdd />
                </TabPane>
                {giftCardSettingBackgroundAlbumList.map((item, index) => {
                    return (
                        <TabPane forceRender tab={<span>{item.gift_card_background_album_name} {showActionAlbum(item)}</span>} key={item.gift_card_background_album_id}>
                            <GiftCardSettingBackgroundImage isMultiple />
                        </TabPane>
                    )
                })}

            </Tabs>
        </div>

    );
};

export default GiftCardSettingBackgroundList;
