import React, { useEffect, useState } from 'react';
import { getStaffType } from '../service';
import { Form, Select } from 'antd';
import lang from '../../../language/language';
import * as c from '../../../constants/common';

const { Option } = Select;

const StaffTypeSelect = (props) => {
  const {
    isMultiple,
    label,
    showSearch,
    allowClear,
    hideLabel,
    required,
    style,
    disabled,
    name,
    onSelect,
    onClear,
    listIdRemove,
    size,
    classNameForm
  } = props;
  const [options, setOptions] = useState([])

  useEffect(() => {
    getStaffType({}).then(res => {
      if (res.data) {
        setOptions(res.data)
      }
    })
  }, []);

  const onSelected = (data) => {
    if (onSelect) {
      onSelect(data)
    }
  }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : (label || lang.staff_type)}
      name={name || 'staff_type'}
      rules={[
        { required: required || false, message: lang.value_is_required }
      ]}
      className={classNameForm}
    >
      <Select
        style={style}
        size={size || 'large'}
        showSearch={showSearch || true}
        allowClear={allowClear === false ? allowClear : true}
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={label || lang.staff}
        disabled={disabled || false}
        onSelect={onSelected}
        onClear={onClear}
      >
        {options.map((item) => {
          return <Option
            className={listIdRemove && listIdRemove.length > 0 && listIdRemove.filter(e => e !== undefined).includes(item.staff_type_id) ? 'd-none' : ''}
            key={item.staff_type_id} value={item.staff_type_id} data={item}>
            <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.staff_type_name}</p>
          </Option>
        })}
      </Select>
    </Form.Item>
  )
};

export default StaffTypeSelect;
