import { createSlice } from '@reduxjs/toolkit';
import * as checkinUserThunk from './thunk';
import * as c from '../../constants/common';
import { getInfoUser } from './../Common/components/GetInfoUser';
const currentBranch = JSON.parse(localStorage.getItem('branch')) || {};
export const checkinUserSlice = createSlice({
  name: 'checkinUser',
  initialState: {
    checkinUserList: {},
    checkinUserDetail: {},
    checkinUserId: '',
    checkinUserQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      branchIdList: currentBranch.branch_id ? [currentBranch.branch_id] : [],
      userId: getInfoUser.user_id
    },
    checkinUserListId: []
  },
  reducers: {
    clearCheckinUserList: (state, action) => {
      state.checkinUserList = {};
    },
    clearCheckinUserRow: (state, action) => {
      state.checkinUserList.rows = [];
    },
    setCheckinUserId: (state, action) => {
      state.checkinUserId = action.payload;
    },
    clearCheckinUserId: (state, action) => {
      state.checkinUserId = '';
    },
    setCheckinUserQueryData: (state, action) => {
      state.checkinUserQueryData = action.payload;
    },
    setCheckinUserListId: (state, action) => {
      state.checkinUserListId = action.payload;
    }

  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [checkinUserThunk.checkinUserList.fulfilled]: (state, action) => {
      state.checkinUserList = action.payload;
    }

  }
});
export const {
  clearCheckinUserList,
  clearCheckinUserRow,
  setCheckinUserId,
  clearCheckinUserId,
  setCheckinUserQueryData,
  setCheckinUserListId
} = checkinUserSlice.actions;

export default checkinUserSlice.reducer;
