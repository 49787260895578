import React, { useEffect, useState } from 'react';
import lang from '../../../language/language';
import { Row, Col, Form, Button, Card, Space, Switch, Input, DatePicker, message } from 'antd';
import { SaveOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { dayOffSetting, dayOffSettingCreateUpdate } from './service';
import * as c from '../../../constants/common';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Common/slice';

const DayOff = () => {
	const [form] = Form.useForm();
	const [dayOffList, setDayOffList] = useState([]);
	const dispatch = useDispatch();

	const getDayOffList = () => {
		dayOffSetting().then((res) => {
			if (res) {
				setDayOffList(res?.data);
			}
		});
	};

	useEffect(() => {
		getDayOffList();
	}, []);

	useEffect(() => {
		form.setFieldsValue({
			days_off: dayOffList.map((item) => ({
				...item,
				day_off_setting_date: item.day_off_setting_date ? moment(item.day_off_setting_date, 'MM-DD') : null,
				day_off_setting_status: item?.day_off_setting_status === c.IS_ACTIVE ? true : false,
			})),
		});
	}, [form, dayOffList]);

	const onSubmit = (data) => {
		const reqData = data?.days_off?.map((item) => {
			return {
				...item,
				day_off_setting_date: moment(item?.day_off_setting_date).format('MM-DD'),
				day_off_setting_status: item?.day_off_setting_status || c.IS_DEACTIVE,
			};
		});
		dayOffSettingCreateUpdate({ dayOffs: reqData })
			.then((res) => {
				if (res) {
					const { data } = res;
					dispatch(setLoading(false));
					if (data?.day_off_setting_id > 0) {
						message.success(lang.save_success);
					}
				}
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

	return (
		<Card title={lang.day_off} className="mt-30">
			<Form autoComplete="off" form={form} layout="vertical" onFinish={onSubmit}>
				<Row gutter={24}>
					<Col xs={24} md={24}>
						<Form.List name="days_off">
							{(fields, { add, remove }) => (
								<>
									{fields.map(({ key, name, ...restField }) => (
										<Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
											<Form.Item
												{...restField}
												name={[name, 'day_off_setting_name']}
												rules={[
													{
														required: true,
														message: lang.value_is_required,
													},
												]}
											>
												<Input placeholder={lang.name} size="large" style={{ minWidth: 500 }} />
											</Form.Item>
											<Form.Item
												{...restField}
												name={[name, 'day_off_setting_date']}
												rules={[
													{
														required: true,
														message: lang.value_is_required,
													},
												]}
											>
												<DatePicker format={'MM-DD'} placeholder={lang.day} size="large" />
											</Form.Item>
											<Form.Item {...restField} name={[name, 'day_off_setting_status']} valuePropName="checked">
												<Switch />
											</Form.Item>
											<MinusCircleOutlined onClick={() => remove(name)} />
										</Space>
									))}
									<Form.Item>
										<Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
											{lang.add}
										</Button>
									</Form.Item>
								</>
							)}
						</Form.List>
					</Col>
					<Col xs={24} md={24} className="text-center mt-15">
						<Button size="large" icon={<SaveOutlined />} onClick={() => form.submit()} key="submit" type="primary">
							{lang.save}
						</Button>
					</Col>
				</Row>
			</Form>
		</Card>
	);
};

export default DayOff;
