import CallApi from '../../../utils/CallAPI';
import {
    BRANCH_BY_USER,
    BRANCH_ALL,
    BRANCH,
    BRANCH_DETAIL
} from './constants/endpoint';

export const branchByUser = (data) => {
  return CallApi(BRANCH_BY_USER, 'GET', data)
};

export const branchListAll = (data) => {
  return CallApi(BRANCH_ALL, 'GET', data)
};

export const branchList = (data) => {
  return CallApi(BRANCH, 'GET', data)
};

export const branchCreateUpdate = (data, method) => {
  return CallApi(BRANCH, method, data)
};

export const branchDetail = (data) => {
  return CallApi(BRANCH_DETAIL, 'GET', data)
};

export const branchDelete = (data) => {
  return CallApi(BRANCH, 'DELETE', data)
};
