import Main from './Main';
import Login from './themes/Antd/Login';
import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Spinner from './themes/Antd/Spinner';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css';

const App = () => {
  const loading = useSelector(state => state.common.loading);

  const showContent = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken !== 'undefined' && accessToken) {
      return <Main />;
    } else {
      return <Login />;
    }
  };

  return (
    <Router>
      <div>
        <div className="App wrapper">
          {showContent()}
          <Spinner loading={loading || false} />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </Router>
  );
};

export default App;
