/* eslint-disable no-useless-escape */
/* eslint-disable no-new */
/* eslint-disable array-callback-return */
import {
  CloseCircleOutlined, PlusOutlined,
  SaveOutlined, CheckSquareOutlined, StopOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, message, Drawer, Space, Row, Switch, Tree } from 'antd';
import React, { useEffect, useState } from 'react';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useDispatch, useSelector } from 'react-redux';
import * as c from '../../../../../constants/common';
import lang from '../../../../../language/language';
import CheckPermission from '../../../../Common/components/CheckPermission';
import { RandomCode } from '../../../../Common/components/RandomCode';
import { setLoading } from '../../../../Common/slice';
import * as roleService from '../../service';
import { clearRoleId } from '../../slice';
import * as roleThunk from '../../thunk';

const RoleAction = (props) => {
  const queryData = useSelector(state => state.role.roleQueryData);
  const dispatch = useDispatch();
  // const color = useSelector(state => state.user.userInfo.theme_color);
  const userInfo = useSelector(state => state.user.userInfo);
  const roleId = useSelector(state => state.role.roleId);
  const roleListAction = useSelector(state => state.role.roleListAction);
  const [actionType, setActionType] = useState('');
  const [checkedRole, setCheckedRole] = useState([]);
  // const [expandedRole, setExpandedRole] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(roleThunk.roleListAction());
  }, []);

  /// //////////////////////// Validate ////////////////////////
  useEffect(() => {
    if (roleId) {
      dispatch(setLoading(true));
      roleService.roleDetail({ role_id: roleId }).then(res => {
        if (res) {
          dispatch(setLoading(false));
          const { data } = res;
          setCheckedRole(data.permission_id_list)
          form.setFieldsValue({
            role_name: data.role_name,
            role_note: data.role_note,
            is_active: data.is_active === c.IS_ACTIVE
          });
        }
      }).finally(() => {
        dispatch(setLoading(false))
      });
    }
  }, [roleId]);

  /// ///function////

  const showModal = () => {
    setIsModalVisible(true);
    if (!roleId) {
      genCode();
    }
  };

  const genCode = () => {
    form.setFieldsValue({ customer_code: RandomCode() });
  };

  const onSubmit = (data) => {
    // submit > 1 để validate address
    const method = roleId ? 'PUT' : 'POST';
    dispatch(setLoading(true));
    const dataReq = {
      role_id: roleId || '',
      role_code: '',
      role_name: data.role_name,
      role_note: data.role_note,
      create_user: userInfo.user_id,
      permission_id_list: checkedRole,
      is_active: data.is_active ? c.IS_ACTIVE : c.IS_DEACTIVE
    };
    roleService.roleCreateUpdate(dataReq, method).then(res => {
      if (res) {
        const { data } = res;
        dispatch(setLoading(false));
        // check exist
        if (data.role_name) {
          message.error(lang.role_name + lang.exist);
        }
        // luu thanh cong
        if (data.role_id > 0) {
          if (actionType === c.SAVE) {
            message.success(lang.save_success);
            setActionType('')

            if (!roleId) {
              resetData();
            }
          } else {
            message.success(lang.save_success);
            onClose();
            // document.getElementById('closeRoleAction').click();
          }
        }
      }
    }).finally(() => {
      dispatch(setLoading(false));
      dispatch(roleThunk.roleList(queryData));
    });
  };

  const resetData = () => {
    // cho phep edit
    // clear roleId edit
    dispatch(clearRoleId(''));
    setCheckedRole([]);
    // setExpandedRole([]);
    form.resetFields();
  };

  const onClose = () => {
    resetData()
    setIsModalVisible(false);
  }

  const onSave = (type) => {
    setActionType(type);
    form.submit()
  }

  const onCheck = (checkedKeys, info) => {
    setCheckedRole(checkedKeys.filter((item) => Number.isInteger(item)));
  };

  const checkArrays = (arrA = [], arrB = []) => {
    if (arrA.length !== arrB.length) return false;

    const cA = arrA.slice().sort().join(',');
    const cB = arrB.slice().sort().join(',');

    return cA === cB;
  }

  const handleCheck = () => {
    if (roleListAction.length > 0) {
      const newSelect = [];
      roleListAction.map(item => {
        item[0]?.children?.map(({ value }) => newSelect.push(value))
      })
      if (checkArrays(newSelect, checkedRole)) {
        setCheckedRole([]);
      } else {
        setCheckedRole(newSelect);
      }
    }
  };

  const showContentButton = (type) => {
    if (roleListAction.length > 0) {
      const newSelect = [];
      roleListAction.map(item => {
        item[0]?.children?.map(({ value }) => newSelect.push(value))
      })
      if (type === 1) {
        return checkArrays(newSelect, checkedRole) ? <StopOutlined /> : <CheckSquareOutlined />
      } else {
        return !checkArrays(newSelect, checkedRole) ? lang.select_all : lang.unselect_all
      }
    }
  }

  const formatTree = (roleListAction = []) => {
    if (roleListAction.length > 0) {
      return roleListAction.map((item) => {
        return {
          title: item[0]?.label,
          key: item[0]?.value,
          children: item[0]?.children?.map(({ value, label }) => {
            return {
              title: label,
              key: value
            }
          })
        }
      })
    } else {
      return [];
    }
  };

  /// render/////
  return (
    <span>
      <>
        <CheckPermission permission={['ROLE_ADD']}>
          <Button size="large" type="primary" id="roleAction" onClick={showModal} icon={<PlusOutlined />} />
        </CheckPermission>
        <Drawer
          title={!roleId
            ? lang.add + lang.role.toLowerCase()
            : lang.edit + lang.role.toLowerCase()
          }
          placement="right"
          width={c.WIDTH_DRAWER.SMALL}
          onClose={onClose}
          visible={isModalVisible}
          footer={
            <Space className="text-center">
              <Button size="large" icon={<CloseCircleOutlined />} key="back" onClick={onClose}>
                {lang.close}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE)} key="submit" type="primary" >
                {lang.save}
              </Button>
              <Button size="large" icon={<SaveOutlined />} onClick={() => onSave(c.SAVE_CLOSE)} key="submit2" type="primary" >
                {lang.save_and_close}
              </Button>
            </Space>
          }
        >
          <Form
            onFinish={onSubmit}
            autoComplete="off"
            form={form}
            layout="vertical"
          // initialValues={{ is_active: c.IS_ACTIVE }}
          >
            <Row gutter={24}>
              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.role_name}
                  name="role_name"
                  rules={[
                    { required: true, message: lang.value_is_required },
                    {
                      pattern: /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ]*$/,
                      message: lang.not_allow_special_characters
                    }
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>

              <Col xs={24} md={24}>
                <Form.Item
                  label={lang.note}
                  name="role_note"
                >
                  <Input.TextArea size="large" allowClear rows={1} />
                </Form.Item>
              </Col>
              <Col xs={24} md={24} className="pb-15 pt-15">
                <Button
                  size="middle"
                  icon={showContentButton(1)}
                  onClick={handleCheck}
                  type="primary">
                  {showContentButton(2)}
                </Button>
              </Col>
              <Col xs={24} md={24}>
                <Tree
                  onCheck={onCheck}
                  checkable
                  treeData={formatTree(roleListAction)}
                  // expandedKeys={checkedRole}
                  selectedKeys={checkedRole}
                  checkedKeys={checkedRole}
                  autoExpandParent
                />
              </Col>
              <Col xs={24} md={24} className="text-right">
                <Form.Item
                  name="is_active"
                  valuePropName="checked"
                >
                  <Switch checkedChildren={lang.active} unCheckedChildren={lang.de_active} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </>
    </span>
  );
};

export default RoleAction;
