/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import { Input, Table, Popover, Row, Col, Button, Space, message } from 'antd';
import lang from '../../../../language/language';
import {
    MinusCircleOutlined,
    SaveOutlined
} from '@ant-design/icons';
import { formatCurrency } from '../../../Common/components/FormatData';
import { useSelector, useDispatch } from 'react-redux';
import * as invoiceService from '../../service';
import { setLoading } from '../../../Common/slice';
import { updateInvoiceInfo } from '../../common';
import * as c from '../../../../constants/common';
import { getInvoiceDetail } from '../../thunk';
import StaffSelect from '../../../Common/components/StaffSelect';

const InvoiceActionItemList = (props) => {
    const generalSetting = useSelector(state => state.generalSetting);
    const dispatch = useDispatch()
    const invoiceId = useSelector(state => state.invoice.invoiceId);
    const posSetting = useSelector(state => state.posSetting);
    const [itemQuantity, setItemQuantity] = useState(0)
    const invoiceDetail = useSelector(state => state.invoice.invoiceDetail);

    /// /funtion///////
    const onRemoveItem = (data) => {
        dispatch(setLoading(true));
        invoiceService.invoiceItemDelete(data).then(res => {
            if (res.data) {
                updateInvoiceInfo(
                    {
                        ...invoiceDetail,
                        invoicePromotionList: [],
                        invoice_total_promotion: 0,
                        invoice_total_surcharge: 0,
                        invoiceSurchargeList: []
                    }
                    , dispatch, posSetting)
                invoiceService.invoiceSurchargeDelete({ invoice_id: invoiceId }).then(x => {
                    if (x.data) {
                        dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                    }
                })
                dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
            }
        })
    }

    // const onChangeQuantity = (e, record) => {
    //     const quantity = e.target.value
    //     let result = [...invoiceDetail.invoiceItemList]
    //     result = result.map((obj) => {
    //         const totalPrice = obj.invoice_item_price_discount ? quantity * obj.invoice_item_price_discount : quantity * obj.invoice_item_price
    //         obj.key === record.key
    //             ? {
    //                 ...obj,
    //                 invoice_item_quantity: parseInt(quantity),
    //                 invoice_item_total_price: totalPrice
    //             }
    //             : obj
    //     }

    //     );
    //     dispatch(setinvoiceDetail.InvoiceItemList(result))
    // }

    const onSelectStaff = (data, record) => {
        dispatch(setLoading(true));
        invoiceService.invoiceItemCreateUpdate({ ...record, staff_id: data }).then(res => {
            if (res.data) {
                dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
            }
        }).finally(x => {
            dispatch(setLoading(false));
        })
    }

    // const onChangePrice = (e, record) => {
    //     const price = formatPrice(e.target.value)
    //     let result = [...invoiceDetail.invoiceItemList]
    //     result = result.map(obj =>
    //         obj.key === record.key
    //             ? {
    //                 ...obj,
    //                 invoice_item_price: price,
    //                 invoice_item_total_price: obj.invoice_item_quantity * price
    //             }
    //             : obj
    //     );
    //     dispatch(setinvoiceDetail.InvoiceItemList(result))
    // }

    const onSaveQuantity = (data) => {
        if (itemQuantity < 1) {
            message.error(lang.quantity_error)
            return;
        }
        dispatch(setLoading(true));
        const totalPrice = data.invoice_item_price_discount ? itemQuantity * data.invoice_item_price_discount : itemQuantity * data.invoice_item_price

        const dataReq = {
            invoice_item_quantity: itemQuantity,
            invoice_item_price: data.invoice_item_price,
            invoice_item_price_discount: data.invoice_item_price_discount,
            invoice_item_total_price: totalPrice,
            invoice_id: invoiceId,
            invoice_item_id: data.invoice_item_id,
            invoice_item_code: data.invoice_item_code,
            invoice_item_name: data.invoice_item_name,
            invoice_item_type: c.CART_ITEM_TYPE.SERVICE,
            staff_id: data.staff_id
        }
        invoiceService.invoiceItemCreateUpdate(dataReq, 'PUT').then(res => {
            if (res.data) {
                updateInvoiceInfo(
                    {
                        ...invoiceDetail,
                        invoicePromotionList: [],
                        invoice_total_surcharge: 0,
                        invoice_total_promotion: 0,
                        invoiceSurchargeList: []
                    }
                    , dispatch, posSetting)
                invoiceService.invoiceSurchargeDelete({ invoice_id: invoiceId }).then(res => {
                    dispatch(getInvoiceDetail({ invoice_id: invoiceId }))
                })
            }
        })
    }

    const columns = [

        {
            title: lang.invoice_item_name,
            dataIndex: 'invoice_item_name',
            width: 165
        },
        {
            title: lang.staff,
            dataIndex: 'staff_id',
            width: 100,
            render: (text, record) => {
                return <StaffSelect
                    onSelect={(data) => onSelectStaff(data, record)}
                    size='small'
                    showSearch={true}
                    allowClear={false}
                    maxTagCount='responsive'
                    placeholder={lang.staff}
                    hideLabel
                    defaultValue={text}
                    className="w-100"
                    classNameForm='mb-0'
                    isTechnician
                    serviceId={record.service_id}
                />
            }
        },
        {
            title: lang.invoice_item_quantity,
            dataIndex: 'invoice_item_quantity',
            width: 80,
            align: 'center',
            render: (text, record) => {
                return (
                    <Popover
                        placement="bottomLeft"
                        trigger="click"
                        style={{ width: '100%' }}
                        overlayClassName="popover-pos"
                        content={
                            <Row>
                                <Col xs={24} md={24} className="pb-10 pt-15">
                                    <Input
                                        size="middle"
                                        type="number"
                                        min={1}
                                        value={itemQuantity}
                                        onChange={(e) => { setItemQuantity(parseInt(e.target.value)) }}
                                        addonAfter={
                                            <Space>
                                                <Button
                                                    type="primary"
                                                    size="small"
                                                    onClick={() => onSaveQuantity(record)}
                                                ><SaveOutlined /> {lang.save}</Button>
                                            </Space>
                                        } />
                                </Col>
                            </Row>
                        }
                    >
                        <Input
                            // onChange={(e) => onChangeQuantity(e, record)}
                            value={record.invoice_item_quantity}
                            className="text-center"
                            onClick={() => setItemQuantity(record.invoice_item_quantity)}
                            size="small"
                        />
                    </Popover>
                )
            }
        },

        {
            title: lang.invoice_item_price,
            dataIndex: 'invoice_item_price',
            align: 'center',
            width: 80,
            render: (text, record) => {
                return <div className='text-center font-weight-bold'>
                    <span className={`${record.invoice_item_price_discount && 'line-through limiter'}`}>{formatCurrency(record.invoice_item_price, generalSetting)}</span>
                    {record.invoice_item_price_discount && <span> {formatCurrency(record.invoice_item_price_discount, generalSetting)}</span>}
                </div>
            }
        },
        {
            title: lang.invoice_item_total_price,
            dataIndex: 'invoice_item_total_price',
            align: 'center',
            width: 120,
            render: (text, record) => {
                return <div className='text-center font-weight-bold'>{formatCurrency(record.invoice_item_total_price, generalSetting)}</div>
            }
        },
        {
            title: invoiceDetail?.invoiceItemList?.length > 0 && <MinusCircleOutlined className="cursor-pointer" style={{ fontSize: 18 }} onClick={() => onRemoveItem({ invoice_id: invoiceId })} />,
            dataIndex: 'action',
            width: 40,
            align: 'center',
            render: (text, record) => {
                return <MinusCircleOutlined className="cursor-pointer" style={{ fontSize: 18 }} onClick={() => onRemoveItem({ invoice_item_id: record.invoice_item_id })} />
            }
        }
    ];

    /// /render///////
    return (
        <div className="pos-cart-list">
            { /* <Input
                    onChange={(e) => onChangePrice(e, record)}
                    value={formatCurrency(record.invoice_item_price, generalSetting)}
                    className="text-right"
                    size="small"
                /> */ }
            <Table scroll={{ y: '16vh' }} columns={columns} dataSource={invoiceDetail.invoiceItemList.map((x, index) => ({ ...x, key: x.invoice_item_code + index })) || []} size="small" pagination={false} />
        </div>
    );
};

export default InvoiceActionItemList;
