
// customer
const rootCustomer = 'customer'
export const CUSTOMER = `${rootCustomer}`;
export const CUSTOMER_DETAIL = `${rootCustomer}/detail`;
export const CUSTOMER_EXPORT = `${rootCustomer}/export-excel`;
export const CUSTOMER_IMPORT = `${rootCustomer}/import-excel`;
export const CUSTOMER_APPOINTMENT_HISTORY = `${rootCustomer}/appointment-history`;
export const CUSTOMER_REWARD_POINT_HISTORY = `${rootCustomer}/reward-point-history`;
export const CUSTOMER_SPENT_HISTORY = `${rootCustomer}/spent-history`;
export const CUSTOMER_CHECKIN_HISTORY = `${rootCustomer}/checkin-history`;
