import { createAsyncThunk } from '@reduxjs/toolkit';
import * as reportIncomeService from './service';

export const reportIncomeList = createAsyncThunk(
  'reportIncome/reportIncomeList',
  async (data, thunkAPI) => {
    const response = await reportIncomeService.reportIncomeList(data);
    return response.data;
  }
);
