import CallApi from '../../utils/CallAPI'
import {
  CHECKIN_USER,
  CHECKIN_SETTING,
  CHECKIN_SETTING_SLIDER
} from './constants/endpoint'

export const checkinUserList = (data) => {
  return CallApi(CHECKIN_USER, 'GET', data)
}

export const checkinSetting = (data, method = 'GET') => {
  return CallApi(CHECKIN_SETTING, method, data)
}

export const checkinSettingSlider = (data, method = 'GET') => {
  return CallApi(CHECKIN_SETTING_SLIDER, method, data)
}
