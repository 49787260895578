import axios from 'axios';
import * as c from '../constants/config';
import { toast } from 'react-toastify';
import lang from '../language/language';

const CallApi = (endpoint, method = 'GET', data, contentType = '') => {
	const refreshToken = window.localStorage.getItem('refreshToken');
	let accessToken = window.localStorage.getItem('accessToken');
	const language = localStorage.getItem('language');
	const branchLocal = JSON.parse(localStorage.getItem('branch')) || null;
	// const infoUser = JSON.parse(localStorage.getItem('infoUser')) || null;

	// // Add a request interceptor
	axios.interceptors.request.use(
		(config) => {
			if (accessToken) {
				config.headers['x-access-token'] = accessToken;
			}
			config.headers['Content-Type'] = contentType || 'application/json';
			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	// Add a response interceptor

	axios.interceptors.response.use(
		(response) => {
			// kiem tra thay doi token
			// axios.get(`${c.API_URL}/user/detail`,
			//     {
			//       params: {
			//         user_id: infoUser.user_id,
			//         company_code: 'hanaspa',
			//         token: accessToken
			//       }
			//     })
			//     .then(res => {
			//       console.log(res.data)
			//     })
			return response;
		},
		function (error) {
			const originalRequest = error.config;
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true;
				return axios
					.post(`${c.API_URL}/refresh-token`, {
						refreshToken: refreshToken,
					})
					.then((res) => {
						if (res) {
							localStorage.setItem('accessToken', res.data.accessToken);
							axios.defaults.headers.common['x-access-token'] = res.data.accessToken;
							originalRequest.headers['x-access-token'] = res.data.accessToken;
							accessToken = res.data.accessToken;
							return axios(originalRequest);
						}
					})
					.catch(function (error) {
						if (error.response.status === 403) {
							window.location.href = '/';
							localStorage.clear();
						}
					});
			}
			return Promise.reject(error);
		}
	);

	return axios({
		method: method,
		url: `${c.API_URL}/${endpoint}`,
		data: data,
		params: method === 'GET' ? data : {},
		headers: {
			'content-type': contentType || 'application/json',
			language: language || 'vi',
			branch_id: branchLocal && branchLocal.branch_id,
		},
	}).catch((error) => {
		// check token thay doi
		if (error.response.status === 403) {
			window.location.href = '/';
			localStorage.clear();
		} else {
			toast.error(lang.server_error);
		}
	});
};
export default CallApi;
