import lang from '../language/language';
import moment from 'moment-timezone';
import * as config from '../constants/config';

export const IS_ACTIVE_ALL = 0;
export const IS_ACTIVE = 1;
export const IS_DEACTIVE = 2;
export const SAVE = 'SAVE';
export const SAVE_CLOSE = 'SAVE_CLOSE';
export const SAVE_PRINT = 'SAVE_PRINT';
export const IS_DELETE = 1;

export const DISCOUNT_TYPE = {
    PERCENT: 1,
    MONEY: 2
};

export const CONDITION_COMPARE = [
    { value: '>', label: '>' },
    { value: '>=', label: '>=' },
    { value: '==', label: '=' },
    { value: '<=', label: '<=' },
    { value: '<', label: '<' }
]

export const GENDER = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
};
export const CUSTOMER_TYPE = {
    PERSONAL: 1,
    COMPANY: 2
};

export const themeColor = [
    { colorClass: 'primary' },
    { colorClass: 'success' },
    { colorClass: 'info' },
    { colorClass: 'danger' },
    { colorClass: 'warning' },
    { colorClass: 'dark' },
    { colorClass: 'indigo' },
    { colorClass: 'purple' },
    { colorClass: 'gray' },
    { colorClass: 'light' },
    { colorClass: 'orange' },
    { colorClass: 'navy' },
    { colorClass: 'fuchsia' },
    { colorClass: 'maroon' },
    { colorClass: 'teal' },
    { colorClass: 'olive' }
];
export const FORMAT_DATE = 'DD/MM/YYYY';
export const WIDTH_MODAL = {
    SMALL: '60%',
    LARGE: '100%',
    MEDIUM: '80%'
};
export const WIDTH_DRAWER = {
    SMALL: 425,
    LARGE: 736,
    FULL: 1024
};

export const OPTION_SETTING_CURRENCY = [
    { value: 'VND', label: 'VND' },
    { value: 'USD', label: 'USD' }
];
export const OPTION_SETTING_DATE_FORMAT = [
    { value: 'DD/MM/YYYY', label: 'DD/MM/YYYY' },
    { value: 'MM/DD/YYYY', label: 'MM/DD/YYYY' },
    { value: 'DD-MM-YYYY', label: 'DD-MM-YYYY' },
    { value: 'MM-DD-YYYY', label: 'MM-DD-YYYY' }
];

export const OPTION_SETTING_HOUR_FORMAT = [
    { value: 'hh:mm A', label: '12h' },
    { value: 'HH:mm', label: '24h' }
];
export const APPOINTMENT_SETTING_BOOKING_DATE_LIMIT = [
    { value: 1, label: `1 ${lang.day}` },
    { value: 3, label: `3 ${lang.day}` },
    { value: 7, label: `7 ${lang.day}` },
    { value: 14, label: `14 ${lang.day}` },
    { value: 30, label: `30 ${lang.day}` }
];
export const APPOINTMENT_SETTING_RANGE = [
    { value: 15, label: `15 ${lang.minute}` },
    { value: 30, label: `30 ${lang.minute}` },
    { value: 45, label: `45 ${lang.minute}` },
    { value: 60, label: `60 ${lang.minute}` },
    { value: 90, label: `90 ${lang.minute}` },
    { value: 120, label: `120 ${lang.minute}` },
    { value: 180, label: `180 ${lang.minute}` }
];
export const APPOINTMENT_SETTING_TIME_REMIND = [
    { value: 1, label: `1 ${lang.hour}` },
    { value: 2, label: `2 ${lang.hour}` },
    { value: 3, label: `3 ${lang.hour}` },
    { value: 4, label: `4 ${lang.hour}` },
    { value: 5, label: `5 ${lang.hour}` },
    { value: 6, label: `6 ${lang.hour}` },
    { value: 7, label: `7 ${lang.hour}` },
    { value: 8, label: `8 ${lang.hour}` },
    { value: 9, label: `9 ${lang.hour}` },
    { value: 10, label: `10 ${lang.hour}` },
    { value: 11, label: `11 ${lang.hour}` },
    { value: 12, label: `12 ${lang.hour}` },
    { value: 13, label: `13 ${lang.hour}` },
    { value: 14, label: `14 ${lang.hour}` },
    { value: 15, label: `15 ${lang.hour}` },
    { value: 16, label: `16 ${lang.hour}` },
    { value: 17, label: `17 ${lang.hour}` },
    { value: 18, label: `18 ${lang.hour}` },
    { value: 19, label: `19 ${lang.hour}` },
    { value: 20, label: `20 ${lang.hour}` },
    { value: 21, label: `21 ${lang.hour}` },
    { value: 22, label: `22 ${lang.hour}` },
    { value: 23, label: `23 ${lang.hour}` },
    { value: 24, label: `24 ${lang.hour}` }
];
export const APPOINTMENT_STATUS_LIST = [
    { value: 1, label: `${lang.wait_confirm}` },
    { value: 2, label: `${lang.confirmed}` },
    { value: 3, label: `${lang.done}` },
    { value: 4, label: `${lang.cancelled}` },
    { value: 5, label: `${lang.visited}` }
];
export const APPOINTMENT_STATUS = {
    WAIT_CONFIRM: 1,
    CONFIRMED: 2,
    DONE: 3,
    CANCELLED: 4,
    VISITED: 5
};
export const SIZE_PAGE = {
    PAGE: 1,
    SIZE_PER_PAGE: 10,
    SIZE_PER_PAGE_GRID: 24,
    SIZE_PER_PAGE_DETAIL: 5,
    SIZE_PER_PAGE_IMAGE_GALLERY: 23
}

export const APPOINTMENT_SETTING_MIN_BOOKING_TIME = [
    { value: 15, label: `15 ${lang.minute}` },
    { value: 30, label: `30 ${lang.minute}` },
    { value: 45, label: `45 ${lang.minute}` },
    { value: 60, label: `60 ${lang.minute}` },
    { value: 75, label: `75 ${lang.minute}` },
    { value: 90, label: `90 ${lang.minute}` }
]
export const SERVICE_DURATION = [
    { value: 15, label: `15 ${lang.minute}` },
    { value: 30, label: `30 ${lang.minute}` },
    { value: 45, label: `45 ${lang.minute}` },
    { value: 60, label: `60 ${lang.minute}` },
    { value: 75, label: `75 ${lang.minute}` },
    { value: 90, label: `90 ${lang.minute}` },
    { value: 105, label: `105 ${lang.minute}` },
    { value: 120, label: `120 ${lang.minute}` }
]
export const STAFF_TYPE = {
    TECHNICIAN: 1,
    NORMAL: 2
};

export const EMAIL_SETTING_TYPE = {
    DEFAULT: 1,
    CUSTOM: 2
};

export const TRUE_FALSE = {
    TRUE: 1,
    FALSE: 2
};

export const OPTION_COUNTRY = [
    { value: 'VN', label: 'Việt Nam' },
    { value: 'US', label: 'United States' }
];

export const COUNTRY_LIST = {
    VN: 'VN',
    US: 'US'
};

export const SHORT_CODE_APPOINTMENT = [
    { value: '[appointment_code]', label: lang.appointment_code },
    { value: '[appointment_status]', label: lang.status },
    { value: '[appointment_date]', label: lang.appointment_date },
    { value: '[appointment_hour]', label: lang.appointment_hour },
    { value: '[appointment_end_hour]', label: lang.appointment_end_hour },
    { value: '[appointment_quantity]', label: lang.appointment_quantity },
    { value: '[appointment_note]', label: lang.note },
    { value: '[staff_code]', label: lang.staff_code },
    { value: '[staff_fullname]', label: lang.staff_fullname },
    { value: '[service]', label: lang.service },
    { value: '[customer_code]', label: lang.customer_code },
    { value: '[customer_fullname]', label: lang.customer_fullname },
    { value: '[customer_phone_number]', label: lang.customer_phone_number },
    { value: '[customer_email]', label: lang.customer_email },
    { value: '[branch_name]', label: lang.branch_name },
    { value: '[branch_address]', label: lang.branch_address },
    { value: '[branch_phone_number]', label: lang.branch_phone_number },
    { value: '[branch_website]', label: lang.branch_website },
    { value: '[cancellation_reason]', label: lang.cancellation_reason }
]
export const SHORT_CODE_CUSTOMER = [
    { value: '[customer_code]', label: lang.customer_code },
    { value: '[customer_fullname]', label: lang.customer_fullname },
    { value: '[customer_phone_number]', label: lang.customer_phone_number },
    { value: '[customer_email]', label: lang.customer_email },
    { value: '[branch_name]', label: lang.branch_name },
    { value: '[branch_address]', label: lang.branch_address },
    { value: '[branch_phone_number]', label: lang.branch_phone_number },
    { value: '[branch_website]', label: lang.branch_website }
]

export const SORT_SERVICE = [
    { value: 'service_name asc', label: lang.name_a_z },
    { value: 'service_name desc', label: lang.name_z_a },
    { value: 'service_price desc', label: lang.price_decrease },
    { value: 'service_price asc', label: lang.price_ascending }
]

export const CART_ITEM_TYPE = {
    SERVICE: 1,
    PRODUCT: 2
}

export const INVOICE_STATUS = {
    UNPAID: 1, // chưa thanh toán
    PAID: 2, // đã thanh toán
    CANCEL: 3 // đã huỷ
};
export const INVOICE_STATUS_LABEL = [
    { label: lang.unpaid, value: 1 },
    { label: lang.paid, value: 2 },
    { label: lang.cancel, value: 3 }
]

export const PAYMENT_METHOD = [
    { label: lang.cash, value: 1 },
    { label: lang.credit, value: 2 },
    { label: lang.gift_card, value: 3 },
    { label: lang.check, value: 4 }
]

export const GIFT_CARD_TYPE = [
    { label: lang.someone_else, value: 1 },
    { label: lang.your_self, value: 2 }
]

export const GIFT_CARD_QUANTITY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const GIFT_CARD_MONEY = [5, 10, 20, 50, 100, 200, 500, 1000]
export const GIFT_CARD_MESSAGE_LENGTH = 150
export const SMS_MAX_LENGTH = 250
export const GIFT_CARD_SEND_MAIL_TYPE = {
    FROM_EMAIL: 1,
    TO_EMAIL: 2
}

export const GIFT_CARD_EXPIRY = [
    { label: lang.not_expiry, value: '' },
    { label: lang.one_month, value: moment().tz(config.TIME_ZONE).add(1, 'months').format('YYYY-MM-DD HH:mm:ss') },
    { label: lang.three_month, value: moment().tz(config.TIME_ZONE).add(3, 'months').format('YYYY-MM-DD HH:mm:ss') },
    { label: lang.six_month, value: moment().tz(config.TIME_ZONE).add(6, 'months').format('YYYY-MM-DD HH:mm:ss') },
    { label: lang.nine_month, value: moment().tz(config.TIME_ZONE).add(9, 'months').format('YYYY-MM-DD HH:mm:ss') },
    { label: lang.one_year, value: moment().tz(config.TIME_ZONE).add(1, 'years').format('YYYY-MM-DD HH:mm:ss') },
    { label: lang.custom_date, value: -1 }
]

export const SHORT_CODE_GIFT_CARD = [
    { value: '[gift_card_balance]', label: lang.gift_card_balance },
    { value: '[create_date]', label: lang.create_date },
    { value: '[gift_card_from_name]', label: lang.gift_card_from_name },
    { value: '[gift_card_to_name]', label: lang.gift_card_to_name },
    { value: '[gift_card_message]', label: lang.gift_card_message },
    { value: '[gift_card_to_email]', label: lang.gift_card_to_email },
    { value: '[gift_card_from_email]', label: lang.gift_card_from_email },
    { value: '[gift_card_expiry_date]', label: lang.gift_card_expiry_date }
]

export const PROMOTION_TYPE = [
    { value: 1, label: lang.custom }
    // { value: 2, label: lang.voucher },
    // { value: 3, label: lang.from_program }
]

export const CODE_TYPE = {
    INVOICE: 'INV',
    SERVICE: 'SER',
    PRODUCT: 'PRO',
    VOUCHER: 'VOU',
    GIFT_CARD: 'GIF'
}

export const TIP_PERCENT = [10, 15, 20, 25]

export const CHECKIN_TYPE = [
    { label: lang.appointment, value: 1 },
    { label: lang.checkin_app, value: 2 },
    { label: lang.invoice, value: 3 }
]

export const CREATE_FROM = [
    { label: lang.web_admin, value: 1 },
    { label: lang.checkin_app, value: 2 },
    { label: lang.website_client, value: 3 },
    { label: lang.app_client, value: 4 }
]

export const USER_TYPE = {
    NORMAL: 1,
    CHECKIN: 2
}

export const OPTIONS_CHART_FILTER = [
    {
        value: 'today',
        label: lang.today
    },
    {
        value: 'yesterday',
        label: lang.yesterday
    },
    {
        value: 'thisWeek',
        label: lang.this_week
    },
    {
        value: 'lastWeek',
        label: lang.last_week
    },
    {
        value: 'thisMonth',
        label: lang.this_month
    },
    {
        value: 'lastMonth',
        label: lang.last_month
    },
    {
        value: 'custom',
        label: lang.custom
    }
]

export const TURN_PRIORITIZE = [
    { value: 1, label: lang.turn_prioritize_staff_type }
    // { value: 2, label: lang.turn_prioritize_income }
];

export const SMS_HISTORY_STATUS = {
    SUCCESS: 1,
    FAIL: 0
};

export const REPORT_INCOME_TYPE = [
    { value: 1, label: lang.report_income_by_service },
    { value: 2, label: lang.report_income_by_technician },
    { value: 3, label: lang.report_income_by_customer },
    { value: 4, label: lang.report_income_by_payment_method }
];

export const REPORT_APPOINTMENT_TYPE = [
    { value: 1, label: lang.report_appointment_by_date },
    { value: 2, label: lang.report_appointment_by_status }
];
