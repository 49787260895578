/* eslint-disable camelcase */
import React from 'react';
import lang from '../../../language/language';
import { useSelector } from 'react-redux';
import { Form, Select } from 'antd';
import * as c from '../../../constants/common';

const { Option } = Select;

const BranchSelect = (props) => {
  const { name, isMultiple, hideLabel, style, required, defaultValue, allowClear, onChange } = props;
  // required truyền lên nếu bắt buộc
  const branchByUser = useSelector(state => state.branch.branchByUser)
  /// //////////////////////// Validate /////////////////////////

  /// ///function////

  const onChangeData = (data) => {
    if (onChange) {
      onChange(data)
    }
  }
  // const validateBranchSelect = (rule, value, getFieldValue) => {
  //   if (value) {
  //     if (!isMultiple) {
  //       const res = branchByUser.find((i) => i.branch_id === value);
  //       if (value && res.is_active === c.IS_DEACTIVE) {
  //         return Promise.reject(new Error(`${res.branch_name} ${lang.option_deactive_validation}`));
  //       }
  //       return Promise.resolve();
  //     } else {
  //       if (value) {
  //         const arr = branchByUser.filter(i => value.includes(i.branch_id)).filter((z) => z.is_active === c.IS_DEACTIVE)
  //         const text = arr.map((y) => y.branch_name)
  //         if (arr.length > 0 && value.length > 0) {
  //           return Promise.reject(new Error(`${text.join(', ')} ${lang.option_deactive_validation}`));
  //         }
  //         return Promise.resolve();
  //       }
  //     }
  //   } else return Promise.resolve();
  // }

  /// render/////
  return (
    <Form.Item
      label={hideLabel ? '' : lang.branch}
      name={name || 'branch'}
      rules={[
        { required: required || false, message: lang.value_is_required }
        // ({ getFieldValue }) => ({
        //   validator: (rule, value) => validateBranchSelect(rule, value, getFieldValue)
        // })
      ]}
    >
      <Select
        defaultValue={defaultValue}
        style={style}
        size="large"
        showSearch
        allowClear={allowClear || true}
        mode={isMultiple ? 'multiple' : ''}
        maxTagCount='responsive'
        placeholder={lang.branch}
        onChange={onChangeData}
      >
        {
          branchByUser.length > 0
            ? branchByUser.map((item) => {
              return <Option key={item.branch_id} value={item.branch_id}>
                <p className={item.is_active === c.IS_DEACTIVE ? 'line-through limiter' : 'limiter'}>{item.branch_name}</p>
              </Option>
            })
            : []
        }
      </Select>
    </Form.Item>
  );
};

export default BranchSelect;
