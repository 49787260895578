import { createSlice } from '@reduxjs/toolkit';
import * as staffThunk from './thunk';
import { getInfoUser } from '../Common/components/GetInfoUser';
import * as c from '../../constants/common';

export const staffTitleSlice = createSlice({
  name: 'staffTitle',
  initialState: {
    staffTitleList: {},
    staffTitleId: '',
    staffTitleListId: [],
    staffTitleQueryData: {
      page: c.SIZE_PAGE.PAGE,
      sizePerPage: c.SIZE_PAGE.SIZE_PER_PAGE,
      search: '',
      fromDate: '',
      toDate: '',
      isActive: null,
      orderBy: '',
      userId: getInfoUser.user_id
    }
  },
  reducers: {
    clearStaffTitleList: (state, action) => {
      state.staffTitleList = {};
    },
    clearStaffTitleRow: (state, action) => {
      state.staffTitleList.rows = [];
    },
    setStaffTitleId: (state, action) => {
      state.staffTitleId = action.payload;
    },
    clearStaffTitleId: (state, action) => {
      state.staffTitleId = '';
    },
    setStaffTitleQueryData: (state, action) => {
      state.staffTitleQueryData = action.payload;
    },
    setStaffTitleListId: (state, action) => {
      state.staffTitleListId = action.payload;
    }
  },
  extraReducers: {
    // Add reducers for additional action types here, and handle loading state as needed
    [staffThunk.staffTitleList.fulfilled]: (state, action) => {
      state.staffTitleList = action.payload;
    }
  }
});

export const {
  clearStaffTitleList,
  clearStaffTitleRow,
  setStaffTitleId,
  setStaffTitleQueryData,
  setStaffTitleListId,
  clearStaffTitleId
} = staffTitleSlice.actions;

export default staffTitleSlice.reducer;
