import { createAsyncThunk } from '@reduxjs/toolkit';
import * as giftCardService from './service';

export const giftCardList = createAsyncThunk(
  'giftCard/giftCardList',
  async (data, thunkAPI) => {
    const response = await giftCardService.giftCardList(data);
    return response.data;
  }
);
